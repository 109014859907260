<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="sub_container">
        <OtcSearch></OtcSearch>
        <div class="otc_confirm">
          <div class="otc_img_wrap">
            <img src="/img/finish.png" :alt="$t('message.finish')" />
          </div>
          <p class="fs16">
            <span class="fwb">{{ $route.params.nickname }} </span>
            <span v-if="$route.params.offerType === 'buy'">{{ $t('message.buyOffered') }}</span>
            <span v-else>{{ $t('message.sellOffered') }}</span>
          </p>
          <div class="p_btn_wrap">
            <div class="p_btn">
              <router-link :to="{ name: 'TradeHistoryOtcSellOffers' }">{{ $t('message.goToOffers') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcSearch from "../otc/cmp/otc_search"

export default {
  name: "OtcConfirm",
  components: {
    'OtcSearch': OtcSearch
  },
}
</script>

<style scoped>

</style>
