import historyProvider from './historyProvider.js'
import Socket from '../socket'
let _subs = null
import store from '../store'

export default {
    // eslint-disable-next-line no-unused-vars
    subscribeBars: function(symbolInfo, resolution, updateCb, uid, resetCache) {
        const channelString = createChannelString(symbolInfo)
        let newSub = {
            channelString,
            uid,
            resolution,
            symbolInfo,
            lastBar: historyProvider.history[symbolInfo.name].lastBar,
            // lastBar: null,
            lastTime: 0,
            lastVolume: 0,
            listener: updateCb,

        }
        _subs = newSub
        Socket.$on("message", socketOn)
    },
    unsubscribeBars: function() {
        //console.log('uid', uid)
        _subs = null
        Socket.$off("message", socketOn)
        store.state.isSubscribed = false
    },
}

function socketOn(msg) {
    let promise = new Promise(resolve => {
        resolve(msg.text())
    })
    promise.then((value) => {
        let message = JSON.parse(value)
        if(message.t === 't') {
            let input = message.m[0]
            let candleStick = {
                exid: input.e,
                marketPair: input.m,
                isBuy: input.i,
                price: input.p,
                tradedAmount: input.ta,
                feeAmount: input.fa,
                date: input.d
            }

            if(store.getters.getRequestedTime >= candleStick.date) {
                return
            }
            let time = candleStick.date
            let data = {
                time: Number(time / 1000),
                price: Number(candleStick.price),
                volume: Number(candleStick.tradedAmount)
            }
            if (_subs) {
                // if (_subs.lastBar === null) {
                //     _subs.lastBar = {
                //         time:0,
                //         close: 0,
                //     }
                // }
                const sub = _subs;
                // if (data.time < sub.lastBar.time / 1000) {
                //     return;
                // }

                if(sub.lastBar) {
                    let _lastBar = updateBar(data, sub)
                    // send the most recent bar back to TV's realtimeUpdate callback
                    sub.listener(_lastBar)
                    // update our own record of lastBar
                    sub.lastBar = _lastBar
                }

            }
        }
    })
}



function updateBar(data, sub) {
    let lastBar = sub.lastBar
    let resolution = sub.resolution
    if (resolution.includes('D')) {
        // 1 day in minutes === 1440
        resolution = 1440
    } else if (resolution.includes('W')) {
        // 1 week in minutes === 10080
        resolution = 10080
    }
    let coeff = resolution * 60
    let rounded = Math.floor(data.time / coeff) * coeff
    let lastBarSec = Math.floor(lastBar.time / 1000)
    let _lastBar

    if (rounded > lastBarSec) {
        // create a new candle, use last close as open **PERSONAL CHOICE**
        _lastBar = {
            time: rounded * 1000,
            open: lastBar.close,
            high: lastBar.close,
            low: lastBar.close,
            close: data.price,
            volume: data.volume
        }
    } else {
        // update lastBar candle!
        if (data.price < lastBar.low) {
            lastBar.low = data.price
        } else if (data.price > lastBar.high) {
            lastBar.high = data.price
        }

        lastBar.volume = Number(lastBar.volume) + Number(data.volume)
        lastBar.close = data.price
        _lastBar = lastBar
    }

    return _lastBar
}

// takes symbolInfo object as input and creates the subscription string to send to CryptoCompare
function createChannelString(symbolInfo) {
    var channel = symbolInfo.name.split(/[:-]/)
    const exchange = channel[0] === 'GDAX' ? 'Coinbase' : channel[0]
    const to = channel[2]
    const from = channel[1]
    // subscribe to the CryptoCompare trade channel for the pair and exchange
    return `0~${exchange}~${from}~${to}`
}
