<template>
  <div class="body_wrap">
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container otc_list_all otc_list_mine">
        <OtcMenuPC ref="OtcMenuPC"></OtcMenuPC>
        <div class="wkx_content mt">
          <div class="wkx_right w100">
            <div class="checks otc_offer_ckh">
              <p class="title">{{ $t('message.myPost') }}</p>
            </div>
            <div class="table_top">
              <div class="btn_link point" @click="$router.push({ name: 'OtcSuggestionWrite' })">
                <span>{{ $t('message.registration') }}</span>
              </div>
              <div style="display: flex">
                <div>
                  <select class="point" style="width: 70px; height: 40px;" v-model="searchFilter" @change="searchFilter === 'all' ? keyword = '' : ''">
                    <option value="all">{{ $t('message.all') }}</option>
                    <option value="title">{{ $t('message.title') }}</option>
                    <option value="nickname">{{ $t('message.nickname') }}</option>
                  </select>
                </div>
                <p class="coin_search_c_wrap">
                  <input class="search_c" type="text" v-model="keyword" :disabled="searchFilter === 'all'" @keyup.enter="getPostList(1)" />
                  <em class="coin_search_c_icon"><i class="fas fa-search"></i></em>
                </p>
                <div class="btn_link point" @click="getPostList(1)">
                  <span>{{ $t('message.search') }}</span>
                </div>
              </div>
            </div>
            <div class="scroll mt20">
              <table class="table_i">
                <colgroup>
                  <col style="width: 15%;">
                  <col style="width: auto;">
                  <col style="width: 18%;">
                  <col style="width: 18%;">
                  <col style="width: 18%;">
                </colgroup>
                <thead>
                <tr class="columns">
                  <th scope="col">{{ $t('message.sort') }}</th>
                  <th scope="col">{{ $t('message.title') }}</th>
                  <th scope="col">{{ $t('message.nickname') }}</th>
                  <th scope="col">{{ $t('message.time') }}</th>
                  <th scope="col">{{ $t('message.endOfSale') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="post in postList" :key="post.createdAt" @click="goToDetail(post)" class="point">
                  <td scope="row">{{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '(직접거래)' }} <span :class="{ 'price_red': post.stateVal === 'doBuy', 'price_blue': post.stateVal === 'doSell', 'orange': post.stateVal === 'doTrading' }">{{ $t(`message.${post.stateVal}`) }}</span></td>
                  <td scope="row">{{ post.title }}</td>
                  <td scope="row" class="t_center">{{ post.writerNickname }}</td>
                  <td scope="row" class="t_center">{{ post.timeGap }}</td>
                  <td scope="row" class="t_center">
                    <a class="outline_s_btn" v-show="post.state === 'OPEN'" @click="cancelSale($event, post.idx)">
                      {{ $t('message.endOfSale') }}
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="no_list mt20" v-show="!postList.length">{{ $t('message.noData') }}</p>
            <div class="buttonwrap t_center">
              <paginate
                  :page-count="pageLast"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="getPostList"
                  :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :first-last-button=true
                  :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :container-class="'pagination'">
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcMenuPC from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_pc'
import OtcMenuMobile from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_mobile'

export default {
  name: "OtcSuggestionMypostList",

  data() {
    return {
      pageLast: 0,
      postList: [],
      searchFilter: 'all',
      keyword: '',
      limit: 10,
      myIdx: null,
    }
  },

  components: {
    'OtcMenuPC': OtcMenuPC,
    'OtcMenuMobile': OtcMenuMobile,
  },

  created() {
    this.getUserProfile((result) => {
      if(result.success) {
        this.myIdx = result.userProfile.userIdx
        this.getPostList(1)
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.$refs.OtcMenuPC.focusTab(3)
    this.$refs.OtcMenuMobile.selectMenu(this.$t('message.myPost'))
  },

  methods: {
    cancelSale(e, idx) {
      e.stopPropagation()
      let formData = new FormData()
      formData.append('idx', idx)
      this.$post(this.$OTC_TEMP_TRADE_DONE, 'otcMypostListDone', formData, true, (result) => {
        console.log(result)
        this.notify('success', this.$t('message.didTradeDone'))
        this.getPostList(1)
      }, (result) => {
        console.log(result)
      })
    },

    goToDetail(post) {
      this.$router.push({ name: 'OtcSuggestionMarketDetail', params: { postIdx: post.idx, tabNo: 3  } })
    },

    getPostList(page) {
      let formData = new FormData()
      formData.append('filter', this.searchFilter.toUpperCase())
      formData.append('keyword', this.keyword)
      formData.append('limit', this.limit)
      formData.append('page', page)
      formData.append('state', 'OPEN')
      formData.append('state', 'TRADING')
      formData.append('state', 'SOLDOUT')
      formData.append('userIdx', this.myIdx)

      // 내가 작성한 글만 조회
      this.$post(this.$OTC_TEMP_LIST, 'otcTempMyPosts', formData, true, (result) => {
        this.pageLast = Math.ceil(result.total / this.limit)
        this.postList = result.data

        let currentDate = new Date()
        let timestamp = currentDate.getTime() / 1000

        this.postList.forEach(post => {
          let timeGap = timestamp - post.createdAt / 1000
          // 24시간 = 86400초
          if(timeGap < 86400) {
            post.timeGap = this.secondsToHms(timeGap)
          } else if(timeGap >= 86400) {
            post.timeGap = this.secondsToDay(timeGap)
          }

          if(post.state === 'OPEN') {
            if(post.boardIsBuy) {
              post.stateVal = 'doBuy'
            } else {
              post.stateVal = 'doSell'
            }
          } else if (post.state === 'SOLDOUT') {
            post.stateVal = 'endOfSale'
          } else if(post.state === 'TRADING') {
            post.stateVal = 'doTrading'
          }
        })
      }, (result) => {
        console.log(result)
      })
    },

    secondsToHms(timeGap) {
      let h = Math.floor(timeGap % (3600*24) / 3600)
      let m = Math.floor(timeGap % 3600 / 60)
      let s = Math.floor(timeGap % 60)

      let hDisplay = h > 0 ? h + this.$t('message.time') + ' ' : ''
      let mDisplay = m > 0 ? m + this.$t('message.min') + ' ' : ''
      let sDisplay = s > 0 ? s + this.$t('message.sec') + ' ' : ''
      return hDisplay + mDisplay + sDisplay + this.$t('message.before')
    },

    secondsToDay(timeGap) {
      let d = Math.floor(timeGap / (3600*24))

      let dDisplay = d > 0 ? d + this.$t('message.day') + ' ' : ''
      return dDisplay + this.$t('message.before')
    },
  }
}
</script>

<style scoped>

</style>
