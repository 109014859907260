<template>
  <div class="body_wrap">
    <MyPageMenuMobile ref="MyPageMenuMobile"></MyPageMenuMobile>
    <div class="pagew">
      <div class="subcontainer1">
        <MyPageMenuPC ref="MyPageMenuPC"></MyPageMenuPC>
        <div class="photo">
          <img id="user_img" :src="userInfo.profileImage ? userInfo.profileImage : '/img/profile.svg'" :alt="$t('message.img')">
          <div class="filewrap">
            <input type="text" :placeholder="$t('message.placeholderProfileImg')" v-model="userImgFileName" disabled>
            <div class="middle_btn upload_btn">
              <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
              <a>{{ $t('message.attach') }}</a>
            </div>
          </div>
        </div>
        <div class="content_f">
          <div class="inf">
            <h5>{{ $t('message.info') }}</h5>
            <table class="table1">
              <tbody>
                <tr>
                  <td class="td_title">{{ $t('message.nickname') }}</td>
                  <td>{{ userInfo.nickName }}</td>
                </tr>
                <tr>
                  <td class="td_title">{{ $t('message.email') }}</td>
                  <td>{{ userInfo.email }}</td>
                </tr>
                <tr>
                  <td class="td_title">{{ $t('message.phone') }}</td>
                  <td>{{ userInfo.phone }}</td>
                </tr>
                <tr>
                  <td class="td_title">{{ $t('message.level') }}</td>
                  <td>
                    <div class="td_wrap">
                      {{ userInfo.level.substring(2, 3) }}<div class="outline_s_btn m30">
                      <router-link :to="{ name: 'MyPageLevel' }">{{ $t('message.levelUpgrade') }}</router-link>
                    </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="inf">
            <h5>{{ $t('message.pwdChange') }}</h5>
            <table class="table2">
              <tbody>
                <tr>
                  <td class="td_title">{{ $t('message.oldPwd') }}</td>
                  <td><input type="password" :placeholder="$t('message.placeholderOldPwd')" v-model="oldPwd"></td>
                </tr>
                <tr>
                  <td class="td_title">{{ $t('message.newPwd') }}</td>
                  <td class="right-inner-addon">
                    <input :type="passwordType" :placeholder="$t('message.placeholderNewPwd')" v-model="newPwd">
                    <i class="fa fa-eye fa-lg" @click="hiddenPassword()"></i>
                  </td>
                </tr>
                <tr>
                  <td class="td_title">{{ $t('message.newPwdConfirm') }}</td>
                  <td class="right-inner-addon">
                    <input :type="passwordConfirmType" :placeholder="$t('message.placeholderConfirmPwd')" v-model="newPwdConfirm">
                    <i class="fa fa-eye fa-lg" @click="hiddenPasswordConfirm"></i>
                    <p class="pwtext">({{ $t('message.pwdLimit') }})</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pw_btn_wrap" @click="changePwd">
              <div class="pw_btn">
                <a>{{ $t('message.pwdChange') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPageMenuPC from './my_page_menu/menu_pc'
import MyPageMenuMobile from './my_page_menu/menu_mobile'

export default {
  name: "MyPageInfo",
  components: {
    'MyPageMenuPC': MyPageMenuPC,
    'MyPageMenuMobile': MyPageMenuMobile
  },

  data() {
    return {
      userInfo: {
        userIdx: 0,
        email: '',
        nickName: '',
        phone: '',
        level: '',
        profileImage: ''
      },
      oldPwd: '',
      newPwd: '',
      newPwdConfirm: '',
      userImgFileName: this.$t('message.placeholderProfileImg'),
      passwordType: 'password',
      passwordConfirmType: 'password'
    }
  },

  mounted() {
    this.$refs.MyPageMenuPC.focusTab(0)
    this.$refs.MyPageMenuMobile.selectMenu(this.$t('message.account'))

    this.getUserProfile((result) => {
      if(result.success) {
        this.userInfo = result.userProfile
      } else {
        console.log(result.userProfile)
      }
    })
  },

  computed: {
    userImgFileChanged: {
      get: function() {
        return this.userImgFileName
      },
      set: function(newValue) {
        this.userImgFileName = newValue
      }
    },
  },

  methods: {
    logout() {
      this.$get(this.$USER_LOGIN_LOGOUT, 'Logout', true, (result) => {
        this.notify('success', result.message)
        this.$store.state.isLoggedIn = false
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        this.$router.push({name:'Main'})
      }, (result) => {
        console.log(result)
      })
    },

    changePwd() {
      if(this.oldPwd === '' || this.newPwd === '' || this.newPwdConfirm === '') {
        this.notify('error', this.$t('message.warningEmptyBlank'))
        return
      }

      let formData = new FormData()
      formData.append('existingPassword', this.oldPwd)
      formData.append('newPassword', this.newPwd)
      formData.append('newPasswordConfirm', this.newPwdConfirm)

      this.$put(this.$USER_MYPAGE_PROFILE_PASSWORD, 'myPageInfoChangeUserPwd', formData, true, (result) => {
        this.notify('success', result.message)
        this.oldPwd = ''
        this.newPwd = ''
        this.newPwdConfirm = ''
        this.logout()
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    upload(e) {
      let file = e.target.files
      console.log(file)
      if(!file.length) {
        return
      }

      console.log(file[0].name)
      this.userImgFileChanged = file[0].name
      this.userInfo.profileImage = URL.createObjectURL(file[0])
      let output = document.getElementById('user_img')
      let userImg = this.userInfo.profileImage
      output.onload = function() {
        URL.revokeObjectURL(userImg)
      }

      let formData = new FormData()
      formData.append('profile', file[0])

      this.$put(this.$USER_MYPAGE_PROFILE_IMAGE, 'myPageInfoPutImg', formData, true, (result) => {
        this.notify('success', result.message)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    hiddenPassword() {
      if ('password' === this.passwordType) {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },

    hiddenPasswordConfirm() {
      if ('password' === this.passwordConfirmType) {
        this.passwordConfirmType = 'text';
      } else {
        this.passwordConfirmType = 'password';
      }
    }
  }

}
</script>

<style scoped>

.right-inner-addon i {
  padding-right: 70px;
}


</style>
