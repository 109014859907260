<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="subcontainer1">
        <h2 class="support_title fwm dblue_02">{{ $t('message.coinAnn') }}</h2>
        <div class="support_viewrap">
          <div class="view_top">
            <div class="view_title">
              <h5 class="fwm">{{ coinNoticeDetail.title }}</h5>
            </div>
            <div class="view_date">
              <span class="fwm">{{ $t('message.createdTime') }}</span>
              <span class="fwm">{{ coinNoticeDetail.date }}</span>
            </div>
          </div>
          <div class="view_content">
            <p>{{ coinNoticeDetail.contents.replace(/\n/gi, "-") }}</p>
            <img v-show="coinNoticeDetail.file" :src="coinNoticeDetail.file" :alt="$t('message.file')" />
          </div>
          <div class="p_btn_wrap">
            <router-link :to="{ name: 'CustomerSupportCenter' }" class="p_btn_blue">{{ $t('message.customerCenter') }}</router-link>
            <router-link :to="{ name: 'GeneralMarket' }" class="p_btn_blue">{{ $t('message.exchange') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoinNoticeView",
  data() {
    return {
      coinNoticeIdx: this.$route.params.articleIdx,
      coinNoticeDetail: {},
    }
  },

  created() {
    if(this.coinNoticeIdx) {
      sessionStorage.setItem('coinNoticeIdx', this.coinNoticeIdx)
    } else {
      this.coinNoticeIdx = sessionStorage.getItem('coinNoticeIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('coinNoticeIdx')
  },

  mounted() {
    this.$get(`${this.$BOARD_DETAIL}/${this.coinNoticeIdx}`, this.$options.name + '_mountedDetail', false, (result) => {
      this.coinNoticeDetail = result.data[0]
    }, (result) => {
      this.notify('error', result.message)
    })
  },
}
</script>

<style scoped>

</style>
