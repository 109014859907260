<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="sub_container">
        <OtcSearch @getPairList="getFee"></OtcSearch>
        <Modal ref="Modal">
          <div v-show="showConfirm">
            <div class="otc_detail">
              <dl class="wallet_form2">
                <dt class="wallet_form_tit">{{ $t('message.coin') }}</dt>
                <dd class="wallet_form_text t_left">{{ offer.pair.split('-')[0] }}</dd>
              </dl>
<!--              <dl class="wallet_form2">-->
<!--                <dt class="wallet_form_tit">{{ $t('message.payMethod') }}</dt>-->
<!--                <dd class="wallet_form_text t_left">{{ selectedPayMethod }}</dd>-->
<!--              </dl>-->
              <dl class="wallet_form2">
                <dt class="wallet_form_tit">{{ $t('message.quantity') }}</dt>
                <dd class="wallet_form_text t_left">{{ coinCal }} {{ offer.pair.split('-')[0] }}</dd>
              </dl>
              <dl class="wallet_form2" v-show="offer.offerType === 'buy'">
                <dt class="wallet_form_tit">{{ $t('message.fees') }}({{ fee }} %)</dt>
                <dd class="wallet_form_text t_left">{{ subDec(coinCal * (fee / 100), offer.pair.split('-')[0]) }} {{ offer.pair.split('-')[0] }}</dd>
              </dl>
              <dl class="wallet_form2">
                <dt class="wallet_form_tit">{{ $t('message.unitPrice') }}</dt>
<!--                <dd class="wallet_form_text t_left">{{ offer.price | numberWithCommas }} {{ offer.pair.split('-')[1] }}</dd>-->
                <dd class="wallet_form_text t_left">{{ offer.exchanges === 'NONE' ? $t('message.negoPrice') : offer.exchanges }} {{ offer.exchanges === 'NONE' ? '' : (offer.markUp >= 0 ? $t('message.negoPrice') + ' +' : $t('message.negoPrice')) + (offer.markUp * 100) + '%' }}</dd>
<!--                <dd class="wallet_form_text t_left">{{ offer.price }}%</dd>-->
              </dl>
<!--              <dl class="wallet_form2">-->
<!--                <dt class="wallet_form_tit">{{ $t('message.totalTradePrice') }}</dt>-->
<!--&lt;!&ndash;                <dd class="wallet_form_text t_left">{{ coinCal * offer.price | numberWithCommas}} {{ offer.pair.split('-')[1] }}</dd>&ndash;&gt;-->
<!--                <dd class="wallet_form_text t_left">{{ currCal | numberWithCommas}} {{ offer.pair.split('-')[1] }}</dd>-->
<!--              </dl>-->
              <dl class="wallet_form2" v-show="offer.offerType === 'buy'">
                <dt class="wallet_form_tit">{{ $t('message.ActualTransaction') }}</dt>
                <dd class="wallet_form_text t_left">{{ Number(coinCal) + (Number(coinCal) * (fee / 100)) }} {{ offer.pair.split('-')[0] }}</dd>
              </dl>
              <dl class="wallet_form2">
                <dt class="wallet_form_tit">{{ $t('message.bal') }}</dt>
                <dd class="wallet_form_text t_left">{{ myBalance | numberWithCommas }} {{ offer.pair.split('-')[0] }}</dd>
              </dl>
              <dl class="wallet_form2 wide" v-show="offer.offerType === 'buy'">
                <dt class="wallet_form_tit">{{ $t('message.balAfterTrade') }}</dt>
                <dd class="wallet_form_text t_left">
                  <em>{{ myBalance | numberWithCommas }} {{ offer.pair.split('-')[0] }} - {{ Number(coinCal) + (Number(coinCal) * (fee / 100)) }} {{ offer.pair.split('-')[0] }} = </em>
                  <em class="dblue_02"> {{ subDec(myBalance - (Number(coinCal) + (Number(coinCal) * (fee / 100))), offer.pair.split('-')[0])  | numberWithCommas }} {{ offer.pair.split('-')[0] }}</em>
                </dd>
              </dl>
            </div>
            <div class="alert_box">
              <p v-show="offer.offerType === 'buy'"><i class="fas fa-exclamation-triangle"></i>{{ $t('message.otcDesc1') }}</p>
              <div v-show="offer.offerType === 'sell'">
                <p>
                  <i class="fas fa-exclamation-triangle"></i>
                  <span class="m_left10">{{ $t('message.otcDesc2') }}</span>
                </p>
                <p class="m_left25">{{ $t('message.otcDesc3') }}</p>
                <p class="m_left25">{{ $t('message.otcDesc4') }}</p>
              </div>
            </div>
            <div class="checks otc_offer_ckh">
              <input type="checkbox" id="check_03" v-model="confirmChk" />
              <label for="check_03">{{ $t('message.otcDesc5', { coin: offer.pair.split('-')[0] }) }}</label>
            </div>
          </div>
          <div v-show="showMemo" class="otc_confirm">
            <div v-show="offer.memo" class="fs16">{{ offer.memo }}</div>
          </div>
        </Modal>
        <div class="otc_purchase_wrap">
          <!-- 거래설명 + 비고 영역 -->
          <div class="otc_purchase_left" v-show="showDesc">
            <div class="desc_top">
              <h6>{{ $t('message.tradeDesc') }}</h6>
              <div class="desctxt">
                <p class="p1" v-html="$t('message.tradeDesc1')"></p>
                <p class="p2" v-html="$t('message.tradeDesc2')"></p>
                <p class="p3" v-html="$t('message.tradeDesc3')"></p>
              </div>
            </div>
            <div class="desc_bottom">
              <h6>{{ $t('message.note') }}</h6>
              <p>{{ $t('message.noteDesc1') }}</p>
              <p>{{ $t('message.bankAcc') }}: xxxx</p>
              <p>PPS{{ $t('message.acc') }}: xxxxxxx</p>
            </div>
          </div>
          <div class="otc_purchase_left_btn" @click="toggleDesc($event)">
            <span>{{ $t('message.tradeDesc') }} {{ $t('message.open') }}</span>
            <span class="otc_btn_on">{{ $t('message.tradeDesc') }} {{ $t('message.close') }}</span>
          </div>
          <div class="otc_purchase_right">
            <h6 v-if="offer.offerType === 'buy'">{{ $t('message.sellOrder') }}</h6>
            <h6 v-else>{{ $t('message.buyOrder') }}</h6>
            <div class="table_o">
<!--              <dl class="table_line">-->
<!--                <dt class="table_title">-->
<!--                  <span>{{ $t('message.unitPrice') }}</span></dt>-->
<!--                <dd class="table_contents">-->
<!--                  <div class="inputwrap">-->
<!--                    <input type="text" :value="offer.price | numberWithCommas" disabled="disabled">-->
<!--                    <span>{{ offer.pair.split('-')[1] }}</span>-->
<!--                  </div>-->
<!--                  <p>* {{ $t('message.noFee') }}</p>-->
<!--                </dd>-->
<!--              </dl>-->
              <dl class="table_line">
                <dt class="table_title">
<!--                  <span>{{ $t('message.priceCal') }}</span>-->
                  <span>{{ $t('message.qty') }}</span>
                </dt>
                <dd class="table_contents">
                  <div class="price_cal">
                    <div class="inputwrap1">
                      <input type="text" v-model="coinCal">
                      <span>{{ offer.pair.split('-')[0] }}</span>
                    </div>
<!--                    <div class="i_change">-->
<!--                      <img src="/img/icon_reverse.svg" alt="change">-->
<!--                    </div>-->
<!--                    <div class="inputwrap1">-->
<!--                      <input type="text" v-model="currCal">-->
<!--                      <span>{{ offer.pair.split('-')[1] }}</span>-->
<!--                    </div>-->
<!--                    <a class="btn_max" @click="coinCal = Number(offer.remainAmount); currCal = offer.price * offer.remainAmount">{{ $t('message.max') }}</a>-->
                    <a class="btn_max" @click="coinCal = Number(offer.remainAmount)">{{ $t('message.max') }}</a>
                  </div>
                </dd>
              </dl>
<!--              <dl class="table_line">-->
<!--                <dt class="table_title">-->
<!--                  <span>{{ $t('message.payMethod') }}</span></dt>-->
<!--                <dd class="table_contents">-->
<!--                  <select class="method" v-model="selectedPayMethod">-->
<!--                    <option v-for="pay in payMethod" :key="pay">{{ $t(`message.${pay}`) }}</option>-->
<!--                  </select>-->
<!--                </dd>-->
<!--              </dl>-->
              <dl>
                <dt class="table_title">
                  <span>{{ $t('message.nickname') }}</span></dt>
                <dd class="table_contents">
                  <em>{{ offer.nickname }}</em>
                </dd>
              </dl>
            </div>

            <!-- 하단 테이블 -->
            <div class="mk_t_content">
              <table class="table_o_1">
                <colgroup>
                  <col style="width: 25%;">
                  <col style="width: 25%;">
<!--                  <col style="width: 25%;">-->
                  <col style="width: 25%;">
                </colgroup>
                <tr>
                  <th>{{ $t('message.tradeLimit') }}</th>
                  <th>{{ $t('message.unitPrice') }}</th>
<!--                  <th>{{ $t('message.payMethod') }}</th>-->
                  <th>{{ $t('message.tradeTime') }}</th>
                </tr>
                <tr>
                  <td class="t_center">{{ offer.minUnit }} {{ offer.minUnit ? '~' : '' }} {{ offer.remainAmount }} {{ offer.pair.split('-')[0] }}</td>
<!--                  <td class="t_center">{{ offer.price | numberWithCommas }} {{ offer.pair.split('-')[1] }}</td>-->
                  <td class="t_center">{{ offer.exchanges === 'NONE' ? $t('message.negoPrice') : offer.exchanges }} {{ offer.exchanges === 'NONE' ? '' : (offer.markUp >= 0 ? $t('message.negoPrice') + ' +' : $t('message.negoPrice')) + (offer.markUp * 100) + '%' }}</td>
<!--                  <td class="t_center">{{ offer.price }}%</td>-->
<!--                  <td class="t_center">-->
<!--                    <img v-show="offer.bank" src="/img/Icon_bank.svg" alt="icon_bank">-->
<!--                    <img v-show="offer.pps" src="/img/Icon_pps.svg" alt="icon_pps">-->
<!--                    <img v-show="offer.wechat" src="/img/Icon_wechat.svg" alt="icon_wechat">-->
<!--                    <img v-show="offer.alipay" src="/img/Icon_alipay.svg" alt="Icon_alipay">-->
<!--                  </td>-->
                  <td>15{{ $t('message.min') }}</td>
                </tr>
              </table>
            </div>
            <div class="p_btn_wrap">
              <a class="p_btn_blue" @click="openModal">{{ $t('message.confirm') }}</a>
              <a class="p_btn" @click="$router.push({ name: 'OtcMain' })">{{ $t('message.back') }}</a>
            </div>
            <div class="purchase_inf" v-html="$t('message.otcWarn1')"></div>
            <div class="purchase_notice" v-html="$t('message.otcWarn2')"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcSearch from "./cmp/otc_search"
import Modal from '@/components/cmp/modal/modal'

export default {
  name: "OtcDetail",

  components: {
    'Modal': Modal,
    'OtcSearch': OtcSearch
  },

  data() {
    return {
      offer: this.$route.params.offer,
      coinCal: 0,
      currCal: 0,
      myBalance: 0,
      payMethod: [],
      selectedPayMethod: '',
      fee: 0,
      confirmChk: false,
      showConfirm: false,
      showMemo: false,
      showDesc: true,
    }
  },

  computed: {
    modalTitle() {
      if(this.offer.offerType === 'buy') {
        return this.$t('message.sellOrder')
      } else {
        return this.$t('message.buyOrder')
      }
    }
  },

  created() {
    if(this.offer) {
      sessionStorage.setItem('offerApply', JSON.stringify(this.offer))
    } else {
      this.offer = JSON.parse(sessionStorage.getItem('offerApply'))
      if(!this.offer) {
        this.$router.push({ name: 'OtcMain' })
      }
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('offerApply')
  },

  mounted() {
    if(!this.offer) {
      this.$router.push({ name: 'OtcMain' })
    } else {
      if(this.offer.memo) {
        this.showMemo = true
        this.showConfirm = false
        this.$refs.Modal.showDialog(this.$t('message.msg'), this.$t('message.confirm'), '', () => {}, ()=>{})
      }

      if(this.offer.alipay) {
        this.payMethod.push('aliPay')
      }
      if(this.offer.bank) {
        this.payMethod.push('bankTrans')
      }
      if(this.offer.pps) {
        this.payMethod.push('ppsBank')
      }
      if(this.offer.wechat) {
        this.payMethod.push('wechatPay')
      }
      this.selectedPayMethod = this.$t(`message.${this.payMethod[0]}`)

      this.getMyBalance((result) => {
        if(result.success) {
          this.myBalance = result.myBalance.available
        }
      }, this.offer.pair.split('-')[0])

      if(this.offer.minUnit) {
        this.coinCal = Number(this.offer.minUnit)
      }
    }
  },

  methods: {
    toggleDesc(e) {
      e.currentTarget.querySelectorAll('span').forEach((ele, idx) => {
        if(ele.classList.contains('otc_btn_on')) {
          ele.classList.remove('otc_btn_on')
          if(idx === 0) {
            this.showDesc = true
          }
        } else {
          ele.classList.add('otc_btn_on')
          if(idx === 0) {
            this.showDesc = false
          }
        }
      })
    },

    getFee(pairList) {
      pairList.forEach((pair => {
        if(pair.marketPair === this.offer.pair) {
          this.fee = pair.sellFeeRatio
        }
      }))
    },

     openModal() {
      if(this.offer.minUnit) {
        if(this.coinCal < this.offer.minUnit) {
          this.notify('error', this.$t('message.lessThanAvail'))
          return
        }
      }
      // if(!this.coinCal || !this.currCal) {
      if(!this.coinCal) {
        this.notify('error', this.$t('message.enterPrice'))
        return
      }

      this.confirmChk = false
      this.showConfirm = true
      this.showMemo = false
      this.showModal()
    },

    showModal() {
      if(this.offer.offerType === 'buy' && this.myBalance - (this.coinCal + this.coinCal * this.fee) < 0) {
        this.notify('error', this.$t('message.shortBalance'))
        return
      }

      this.$refs.Modal.showDialog(this.modalTitle, this.$t('message.confirm'), this.$t('message.cancel'), () => {
        let formData = new FormData()

        if(this.selectedPayMethod === this.$t('message.bankTrans')){
          formData.append('otcPayType', 'BANK')
        } else if(this.selectedPayMethod === this.$t('message.aliPay')) {
          formData.append('otcPayType', 'ALIPAY')
        } else if(this.selectedPayMethod === this.$t('message.ppsBank')) {
          formData.append('otcPayType', 'PPS')
        } else {
          formData.append('otcPayType', 'WECHAT')
        }
        formData.append('offerIdx', this.offer.offerIdx)
        formData.append('amount', this.coinCal)
        // formData.append('buy', this.offer.offerType !== 'buy')

        this.$post(this.$OTC_TRADE, 'OtcDetailPostTrade', formData, true, (result) => {
          this.$router.push({ name: 'OtcProcess', params: { tradeIdx: result.data[0].tradeIdx, offerNickname: this.offer.nickname }})
        }, (result) => {
          console.log(result)
        })
      }, ()=>{}, this.confirmChk)
    },
  },

  watch: {
    // 'coinCal': {
    //   handler() {
    //     this.currCal = this.offer.price * this.coinCal
    //   }
    // },

    '$root.$i18n.locale': {
      handler() {
        this.selectedPayMethod = this.$t(`message.${this.payMethod[0]}`)
      }
    },

    'confirmChk': {
      handler() {
        this.showModal()
      }
    }
  }
}
</script>

<style scoped>

</style>
