<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="subcontainer1">
        <div class="secondAuth_con">
          <p class="title">{{ $t('message.2ndAuth') }} ({{ authMethod }})</p>
          <div class="middle_con">
            <vue-country-code @onSelect="onSelect" :preferredCountries="['kr', 'cn', 'us']" defaultCountry="kr"></vue-country-code>
            <input type="number" class="phone" :placeholder="$t('message.placeholderPhoneNo')" v-model="phone" autofocus/>
            <div class="outline_m_btn" @click="sendCode"><a>{{ $t('message.getVerificationCode') }}</a></div>
          </div>
          <div class="" v-show="showInputSmsCode">
            <input type="number" class="phone_input" :placeholder="$t('message.verificationCode')" v-model="authCode" @keyup.enter="login"/>
            <span class="btn_user" @click="login">{{ $t('message.login') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhoneLogin",
  data() {
    return {
      pinCode: '',
      countryCode: '',
      phone: '',
      showInputSmsCode: false,
      authCode: '',
      authMethod: this.$route.params.secondAuthMethod
    }
  },

  created() {
    if(!this.authMethod) {
      this.$router.push({ name: 'Login' })
    }
  },

  methods: {
    sendCode() {
      let formData = new FormData()

      formData.append('phone', this.phone)
      formData.append('verifyCode', this.$route.params.verifyCode)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_LOGIN_SMS, 'loginSms', formData, false, (result) => {
        this.showInputSmsCode = true
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    login() {
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('code', this.authCode)
      formData.append('verifyCode', this.$route.params.verifyCode)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_LOGIN_SMS_CERT, 'loginSmsCert', formData, false, (result) => {
        localStorage.setItem('accessToken', result.data[0].accessToken)
        localStorage.setItem('refreshToken', result.data[0].refreshToken)
        this.$store.state.isLoggedIn = true
        this.$router.push({ name: 'Main' })
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    onSelect({name, iso2, dialCode}) {
      console.log(name, iso2, dialCode)
      this.countryCode = iso2
    },
  }



}
</script>

<style scoped>

</style>
