<template>
  <div class="body_wrap">
    <MyPageMenuMobile ref="MyPageMenuMobile"></MyPageMenuMobile>
    <div class="pagew">
      <div class="subcontainer1 level_auth">
        <MyPageMenuPC ref="MyPageMenuPC"></MyPageMenuPC>
        <Modal ref="Modal">
          <table class="table2" v-show="showPinSetting">
            <tbody>
              <tr>
                <td class="td_title">{{ $t('message.pwd') }}</td>
                <td><input type="password" :placeholder="$t('message.enterPwd')" v-model="pwd"></td>
              </tr>
              <tr>
                <td class="td_title">{{ $t('message.pinCode') }}</td>
                <td><input type="password" :placeholder="$t('message.enterPin')" v-model="pin"></td>
              </tr>
              <tr>
                <td class="td_title">{{ $t('message.pinConfirm') }}</td>
                <td><input type="password" :placeholder="$t('message.pinOneMore')" v-model="pinConfirm"></td>
              </tr>
            </tbody>
          </table>
          <div class="ad_option" v-show="!showPinSetting">
            <div class="pay_desc">
              <input type="text" class="input_f" :placeholder="$t('message.placeholderProfileImg')" v-model="certiFileName" disabled>
              <div class="middle_btn upload_btn">
                <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
                <a><i class="fas fa-folder-open"></i></a>
              </div>
            </div>
          </div>
        </Modal>
        <div class="lv_container">
          <div class="user_lv"><span class="txt_lv" v-html="$t('message.currentLv', { nickName: nickName, lv: lv })"></span></div>
          <div class="sub_con">
<!--           클래스 추가:  인증 완료 - lv_done / 다음 인증 - lv_next -->
            <div class="level_step lv_done">
              <img src="/img/icon-level-1.png"/>
              <p class="lv_num">{{ $t('message.lv') }} <span>1</span></p>
              <p class="fs16 fw400">{{ $t('message.email') }}, {{ $t('message.phone') }} {{ $t('message.auth') }}</p>
            </div>
            <div class="level_step" :class="{ 'lv_done': lv === '2' || lv === '3' || lv === '4' }">
              <img src="/img/icon-level-2.png"/>
              <p class="lv_num">{{ $t('message.lv') }} <span>2</span></p>
              <p class="fs16 fw400 m_bottom5">{{ $t('message.pinCode') }} {{ $t('message.auth') }}</p>
              <button class="middle_btn1" @click="doPinAuth" v-show="!authVeri.pin"><a>{{ $t('message.doAuth') }}</a></button>
            </div>
            <div class="level_step" :class="{ 'lv_next': lv === '1', 'lv_done': lv === '3' || lv === '4' }">
              <img src="/img/icon-level-3.png"/>
              <p class="lv_num">{{ $t('message.lv') }} <span>3</span></p>
              <p class="fs16 fw400 m_bottom5">OTP {{ $t('message.auth') }}</p>
              <button class="middle_btn1" v-show="lv !== '1' && !authVeri.otp"><router-link :to="{ name: 'MyPageSecurityMain' }">{{ $t('message.doAuth') }}</router-link></button>
            </div>
            <div class="level_step" :class="{ 'lv_next': lv === '1' || lv === '2', 'lv_done': lv === '4' }">
              <img src="/img/icon-level-4.png"/>
              <p class="lv_num">{{ $t('message.lv') }} <span>4</span></p>
              <p class="fs16 fw400 m_bottom5">{{ $t('message.idCard') }} {{ $t('message.auth') }}</p>
              <button class="middle_btn1" @click="doIdAuth()" v-show="lv !== '1' && lv !== '2' && !isIdUploaded"><a>{{ $t('message.doAuth') }}</a></button>
            </div>
          </div>
          <div class="bottom-con">
            <div class="desc">
              <p class="fs11">{{ $t('message.lvDesc1') }}</p>
              <p class="fs11">{{ $t('message.lvDesc2') }}</p>
            </div>
            <div class="outline_btn2 t_center" @click="toggleShowLimit">
              <span class="t_center" v-show="showLimit">
                {{ $t('message.lvDesc3') }}
                <i class="fas fa-caret-down"></i>
              </span>
              <span class="t_center" v-show="!showLimit">
                {{ $t('message.lvDesc3') }}
                <i class="fas fa-caret-up"></i>
              </span>
            </div>
          </div>
          <div class="content_f" v-show="showLimit">
            <div class="inf">
              <h5>{{ $t('message.withLimit') }}</h5>
              <table class="table1">
                <tbody v-for="limit in limitByLv" :key="limit.level">
                  <tr>
                    <td class="td_title">{{ $t('message.lv') }}</td>
                    <td>{{ limit.level }}</td>
                  </tr>
                  <tr>
                    <td class="td_title">{{ $t('message.1dayLimit') }}</td>
                    <td>{{ limit.maxBuyValue ? subDec(limit.maxBuyValue, limit.tickerForLimit) : $t('message.noLimit') | numberWithCommas }} {{ limit.maxBuyValue ? limit.tickerForLimit : '' }}</td>
                  </tr>
                  <tr>
                    <td class="td_title">{{ $t('message.1daySellLimit') }}</td>
                    <td>{{ limit.maxSellValue ? subDec(limit.maxSellValue, limit.tickerForLimit) : $t('message.noLimit') | numberWithCommas }} {{ limit.maxSellValue ? limit.tickerForLimit : '' }}</td>
                  </tr>
                  <tr>
                    <td class="td_title">{{ $t('message.1dayWithLimit') }}</td>
                    <td>{{ limit.maxDayWithdrawValue ? subDec(limit.maxDayWithdrawValue, limit.tickerForWithdraw) : $t('message.noLimit') | numberWithCommas }} {{ limit.maxDayWithdrawValue ? limit.tickerForWithdraw : '' }}</td>
                  </tr>
                  <tr>
                    <td class="td_title">{{ $t('message.1timeWithLimit') }}</td>
                    <td>{{ limit.maxOnceWithdrawValue ? subDec(limit.maxOnceWithdrawValue, limit.tickerForWithdraw) : $t('message.noLimit') | numberWithCommas }} {{ limit.maxOnceWithdrawValue ? limit.tickerForWithdraw : '' }}</td>
                  </tr>
                  <tr>
                    <td class="td_title">{{ $t('message.possWith') }}</td>
                    <td>{{ limit.stateWithdraw ? $t('message.poss') : $t('message.imposs') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import MyPageMenuPC from './my_page_menu/menu_pc'
import MyPageMenuMobile from './my_page_menu/menu_mobile'
import Modal from '../cmp/modal/modal'

export default {
  name: "Level",
  components: {
    'MyPageMenuPC': MyPageMenuPC,
    'MyPageMenuMobile': MyPageMenuMobile,
    'Modal': Modal
  },

  data() {
    return {
      certiFileName: '',
      pwd: '',
      pin: '',
      pinConfirm: '',
      certiFile: {},
      nickName: '',
      lv: '',
      showPinSetting: true,
      isIdUploaded: false,
      showLimit: false,
      isFirstCall: true,
      limitByLv: [],
      authVeri: {
        otp: false,
        pin: false,
      }
    }
  },

  created() {
    this.getUserLv()
    this.getAuthVeri()
  },

  mounted() {
    this.$refs.MyPageMenuPC.focusTab(1)
    this.$refs.MyPageMenuMobile.selectMenu(this.$t('message.membershipLevel'))
  },

  methods: {
    getAuthVeri() {
      this.$get(this.$USER_MYPAGE_SECURITY, 'lvAuthSecurityMethod', true, (result) => {
        this.authVeri = result.data[0]
      }, (result) => {
        console.log(result)
      })
    },

    toggleShowLimit() {
      this.showLimit = !this.showLimit

      if(this.isFirstCall) {
        this.$get(this.$HOME_WITHDRAW_SECURELEVEL, 'withdrawSecureLevel', false, (result) => {
          this.limitByLv = result.data
          this.isFirstCall = false
        }, (result) => {
          console.log(result)
        })
      }
    },

    getUserLv() {
      this.getUserProfile((result) => {
        if(result.success) {
          this.nickName = result.userProfile.nickName
          this.isIdUploaded = result.userProfile.idCardUpward
          this.lv = result.userProfile.level.substring(3, 2)
        } else {
          console.log(result.userProfile)
        }
      })
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.certiFile = file[0]
      this.certiFileName = file[0].name
    },

    doIdAuth() {
      this.showPinSetting = false

      this.$refs.Modal.showDialog(this.$t('message.idUpload'), this.$t('message.confirm'), this.$t('message.cancel'), () => {
        if(!this.certiFileName) {
          this.notify('error', this.$t('message.doAttach'))
          return
        }

        let formData = new FormData()
        formData.append('idCard', this.certiFile)

        this.$post(this.$USER_MYPAGE_SECURITY_IDCARD, 'myPageLevelPostID', formData, true, (result) => {
          this.notify('success', result.message)
          this.getUserLv()
          this.getAuthVeri()
          this.isIdUploaded = true
        }, (result) => {
          this.notify('error', result.message)
        })
      }, ()=>{})
    },

    doPinAuth() {
      this.showPinSetting = true

      this.$refs.Modal.showDialog(this.$t('message.pinSetting'), this.$t('message.confirm'), this.$t('message.cancel'), () => {
        let formData = new FormData()
        formData.append('existingPassword', this.pwd)
        formData.append('pin', this.pin)
        formData.append('pinConfirm', this.pinConfirm)

        this.$post(this.$USER_MYPAGE_SECURITY_PIN, 'MyPageInfoPostSecurity', formData, true, (result) => {
          this.pwd = ''
          this.pin = ''
          this.pinConfirm = ''
          this.notify('success', result.message)
          this.getUserLv()
          this.getAuthVeri()
        }, (result) => {
          this.notify('error', result.message)
          this.pwd = ''
          this.pin = ''
          this.pinConfirm = ''
        })
      }, ()=>{})
    }


  }

}
</script>

<style scoped>

</style>
