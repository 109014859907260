<template>
  <div class="user_wrap">
    <div class="container_login">
      <h5 class="dblue_01 t_center">{{ $t('message.setNewPwd') }}</h5>
      <div class="content_wrap">
        <div v-show="!showSuccessTxt">
          <form>
            <input type="password" :placeholder="$t('message.placeholderNewPwd')" v-model="newPwd" />
            <p class="join_desc dblue_01 fs12">* {{ $t('message.pwdLimit') }}</p>
            <input type="password" :placeholder="$t('message.placeholderConfirmPwd')" v-model="confirmPwd" />
          </form>
          <a class="fs18 btn_400" @click="setNewPwd">{{ $t('message.confirm') }}</a>
        </div>
        <div v-show="showSuccessTxt">
          <p class="center">{{ $t('message.pwdChanged') }}</p>
          <router-link class="fs18 btn_400" :to="{ name: 'Login' }">{{ $t('message.goToLogin') }}</router-link>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "NewPwd",
  data() {
    return {
      newPwd: '',
      confirmPwd: '',
      showSuccessTxt: false,
      email: '',
    }
  },

  mounted() {
    this.$parent.showHeaderFooter(false)
    this.$intercom.update({
      hide_default_launcher: true
    })
  },

  beforeDestroy() {
    this.$parent.showHeaderFooter(true)
    this.$intercom.update({
      hide_default_launcher: false
    })
  },

  methods: {
    setNewPwd() {
      let hash = window.location.hash
      // ex) hash = /findpwd/newpwd?verifyCode=41a128a876deedfgd4564557dd8?authMethod=email
      hash = hash.split('=')
      let verifyCode
      let authMethod

      console.log(hash)

      this.$route.params.veriCode ? verifyCode = this.$route.params.veriCode : verifyCode = hash[1].split('&')[0]
      this.$route.params.authMethod ? authMethod = 'phone' : hash[2]

      let formData = new FormData()
      formData.append('password', this.newPwd)
      formData.append('passwordConfirm', this.confirmPwd)
      formData.append('verifyCode', verifyCode)
      formData.append('authMethod', authMethod)

      this.$put(this.$USER_LOSS_PASSWORD_NEW, 'FindPwdNewPwd', formData, false, () => {
        this.showSuccessTxt = true
      }, (result) => {
        this.notify('error', result.message)
      })
    }

  },



}
</script>

<style scoped>

</style>
