<template>
  <div class="body_wrap">
    <MyPageMenuMobile ref="MyPageMenuMobile"></MyPageMenuMobile>
    <div class="content">
      <div class="c5_wrap_b">
        <h6><router-link :to="{ name: 'MyPageQnaList' }">1:1 {{ $t('message.doInquiry') }}</router-link></h6>
        <div class="n_box">
          <span class="img_wrap_g">
              <img src="/img/notice.svg" alt="notice">
          </span>
          <ul>
            <li>{{ $t('message.inquiryDesc1') }}</li>
            <li>{{ $t('message.inquiryDesc2') }}</li>
          </ul>
          <strong class="inquiry_deco">My Inquiry</strong>
        </div>
      </div>
      <!-- 1:1 문의하기 _ 입력란 -->
      <table class="table_inquiry">
        <colgroup>
          <col style="width: 14%;">
          <col style="width: 86%;">
        </colgroup>
        <tr>
          <th class="t_left">{{ $t('message.nickname') }}</th>
          <td><input type="text" :value="nickname" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.contact') }}</th>
          <td><input type="text" :value="phone" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.email') }}</th>
          <td><input type="text" :value="email" disabled></td>
        </tr>
        <tr v-for="(formCol) in formCols.slice().reverse()" :key="formCol.colName">
          <th class="t_left">{{ formCol.colName }}
            <p class="fs11 price_red">{{ formCol.require ? $t('message.required') : '' }}</p>
          </th>
          <td>
            <input class="input1" :type="formCol.type" v-show="formCol.colName === $t('message.title')" :maxlength="formCol.length.toString()" :placeholder="$t('message.enterTit')" v-model="formCol.filled">
            <textarea v-show="formCol.colName !== $t('message.title') && formCol.colName !== $t('message.file')" :maxlength="formCol.length.toString()" :placeholder="$t('message.enterContent')" v-model="formCol.filled"></textarea>
            <div v-show="formCol.type === 'file'" class="file">
              <div>
                <input type="text" :placeholder="$t('message.doAttach')" v-model="fileName" disabled>
                <div class="middle_btn upload_btn m_left10">
                  <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
                  <a>{{ $t('message.attach') }}</a>
                </div>
              </div>
              <p class="inquiry_p">* {{ limitFile(formCol.length) }}</p>
            </div>
          </td>
        </tr>
      </table>
      <div class="en_btn" @click="register">
        <a>{{ $t('message.registration') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import MyPageMenuMobile from '@/components/my_page/my_page_menu/menu_mobile'

export default {
  name: "MyPageQnaRegist",
  components: {
    'MyPageMenuMobile': MyPageMenuMobile,
  },
  data() {
    return {
      columns: [],
      type: [],
      nickname: '',
      phone: '',
      email: '',
      fileName: '',
      fileList: {},
      formCols: [],
      originalCols: [],
    }
  },

  mounted() {
    this.$refs.MyPageMenuMobile.selectMenu(this.$t('message.myInquiries'))

    this.getUserProfile((result) => {
      if(result.success) {
        this.nickname = result.userProfile.nickName
        this.phone = result.userProfile.phone
        this.email = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })

    this.getForm()
  },

  methods: {
    getForm() {
      this.formCols = []

      this.$get(this.$USER_MYPAGE_QNA_FORM, 'myPageQnaGetList', true, (result) => {
        let columns = result.data[0]
        let colKeys = Object.keys(columns)
        let colVals = Object.values(columns)

        colKeys.forEach((col, colIdx) => {
          if(col === 'boardType') {
            return
          }

          this.originalCols.push({
            colName: col,
            type: colVals[colIdx].type === 'text' ? 'text' : 'file',
          })

          if(col === 'file') {
            col = this.$t('message.file')
          } else if (col === 'contents') {
            col = this.$t('message.content')
          } else if (col === 'title') {
            col = this.$t('message.title')
          }
          this.formCols.push({
            colName: col,
            length: colVals[colIdx].length,
            require: colVals[colIdx].require,
            type: colVals[colIdx].type === 'text' ? 'text' : 'file',
            filled: ''
          })
        })

      }, (result) => {
        console.log(result)
      })
    },

    limitFile(leng) {
      return this.$t('message.maxLeng', { leng: leng })
    },

    register() {
      let formData = new FormData()

      let jsonData = {}
      for(let i=0; i<this.originalCols.length; i++) {
        if(this.originalCols[i].type === 'text') {
          jsonData[this.originalCols[i].colName] = this.formCols[i].filled
        } else {
          formData.append(this.originalCols[i].colName, this.fileList)
        }
      }

      console.log(this.originalCols, this.fileList)
      console.log(jsonData)
      formData.append('request', new Blob([JSON.stringify(jsonData)], {type: "application/json"}))

      this.$post(this.$USER_MYPAGE_QNA, 'myPageQnaRegistPost', formData, true, (result) => {
        this.notify('success', result.message)
        this.$router.push({ name: 'MyPageQnaList' })
      }, (result) => {
        console.log(result)
        this.notify('error', result.message)
      })
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.fileName = file[0].name
      this.fileList = file[0]

      console.log(file)
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.getForm()
      }
    },
  }
}
</script>

<style scoped>

</style>
