<template>
  <div class="body_wrap">
    <div class="company_img_wrap">
      <div class="company_title">
        <p class="small">ABOUT US</p>
        <p class="big">World's Leading</p>
        <p class="big">Crytocurrency Exchange</p>
      </div>
    </div>
    <div class="company_info">
      <div class="com_con top">
        <div class="title">
          <p>Company Intro</p>
        </div>
        <div class="com_content">
          <p class="fs16 fwb m_bottom5">{{ $t('message.comIntro1') }}</p>
          <p class="fs16 fwb">{{ $t('message.comIntro2') }}</p>
        </div>
      </div>
      <div class="com_con bottom">
        <div class="title">
          <p>Company Service</p>
        </div>
        <div class="com_content">
          <div class="m_bottom20">
            <p class="fs24 fwb price_blue m_bottom5">{{ $t('message.otc') }}</p>
            <p class="m_bottom5">{{ $t('message.comServiceOtc1') }}</p>
            <p class="m_bottom5">{{ $t('message.comServiceOtc2') }}</p>
            <p class="m_bottom5">{{ $t('message.comServiceOtc3') }}</p>
          </div>
          <div>
            <p class="fs24 fwb price_blue m_bottom5">{{ $t('message.exchange') }}</p>
            <p class="m_bottom5">{{ $t('message.comServiceGen1') }}</p>
            <p class="m_bottom5">{{ $t('message.comServiceGen2') }}</p>
            <p class="m_bottom5">{{ $t('message.comServiceGen3') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>
