<template>
  <div class="body_wrap">
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container history_o">
        <OtcHistoryMenu ref="OtcHistoryMenu" />
        <p class="c5_wrap">
          <span class="c5_title">{{ $t('message.buyOfferSum') }} <span>[ {{ totalItem }} ]</span></span>
        </p>
        <div class="scroll">
          <table class="table_i">
            <tbody>
            <colgroup>
              <col style="width: 9%;">
              <col style="width: 9%;">
              <col style="width: 10%;">
              <col style="width: auto;">
              <col style="width: auto;">
              <col style="width: auto;">
              <col style="width: auto;">
              <col style="width: auto;">
              <col style="width: auto;">
              <col style="width: 9%;">
              <col style="width: 6%;">
            </colgroup>
            <tr>
              <th>{{ $t('message.registNum') }}</th>
              <th>{{ $t('message.coin') }}</th>
              <th>{{ $t('message.payMethod') }}</th>
              <th>{{ $t('message.firstAmt') }}</th>
              <th>{{ $t('message.tradeLimit') }}</th>
              <th>{{ $t('message.unitPrice') }}</th>
              <th>{{ $t('message.totalTradePrice') }}</th>
              <th>{{ $t('message.registDate') }}</th>
              <th>{{ $t('message.detail') }}</th>
              <th>{{ $t('message.state') }}</th>
              <th>{{ $t('message.delete') }}</th>
            </tr>
            <tr v-for="myOffer in myOfferList" :key="myOffer.offerIdx">
              <td class="t_center">{{ myOffer.offerIdx }}</td>
              <td class="t_center">{{ myOffer.marketPair.split('-')[0] }}</td>
              <td class="t_center">
                <div class="payicon">
                  <img v-show="myOffer.bank" src="/img/Icon_bank.svg" alt="icon_bank">
                  <img v-show="myOffer.pps" src="/img/Icon_pps.svg" alt="icon_pps">
                  <img v-show="myOffer.wechat" src="/img/Icon_wechat.svg" alt="icon_wechat">
                  <img v-show="myOffer.alipay" src="/img/Icon_alipay.svg" alt="Icon_alipay">
                </div>
              </td>
              <td class="t_center">{{ myOffer.totalAmount }} {{ myOffer.marketPair.split('-')[0] }}</td>
              <td class="t_center">{{ myOffer.isSplit ? myOffer.minUnit : ''}} {{ myOffer.isSplit ? '~' : '' }} {{ myOffer.remainAmount }} {{ myOffer.marketPair.split('-')[0] }}</td>
              <td class="t_center">{{ myOffer.price | numberWithCommas }} {{ myOffer.marketPair.split('-')[1] }}</td>
              <td class="t_center">{{ myOffer.totalAmount * myOffer.price | numberWithCommas }} {{ myOffer.marketPair.split('-')[1] }}</td>
              <td class="t_center" v-html="myOffer.createdAt"></td>
              <td class="t_center outline_btn2">
                <span class="blue_f" v-show="myOffer.otcState === 'SOLDOUT' || myOffer.otcState === 'TRADING'" @click="goDetailList(myOffer.offerIdx)">{{ $t('message.viewDetail') }}</span>
              </td>
              <td class="t_center outline_btn2">
                <span class="red" v-show="myOffer.otcState === 'OPEN'" @click="cancelOffer(myOffer.offerIdx)">{{ $t('message.registCancel') }}</span>
                <span class="blue" v-show="myOffer.otcState === 'CANCEL'" @click="$router.push({ name: 'OtcOffer', params: { offerType: 'buy' }})">{{ $t('message.registAgain') }}</span>
                <p v-show="myOffer.otcState === 'SOLDOUT'">{{ $t('message.traded') }}</p>
                <p v-show="myOffer.otcState === 'TRADING'">{{ $t('message.doTrading') }}</p>
              </td>
              <td class="t_center outline_btn2"><span class="red_f" v-show="myOffer.otcState !== 'TRADING' && myOffer.otcState !== 'OPEN'" @click="deleteOffer(myOffer.offerIdx)">{{ $t('message.delete') }}</span></td>
            </tr>
            </tbody>
          </table>
        </div>

        <p class="no_list" v-show="!myOfferList.length">{{ $t('message.noData') }}</p>
        <paginate v-show="myOfferList.length" :key="pageKey"
            :page-count="pageLast"
            :page-range="3"
            :margin-pages="2"
            :click-handler="getMyOfferList"
            :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :first-last-button=true
            :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :container-class="'pagination'">
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import OtcHistoryMenu from '@/components/trade_history/history_menu/otc_menu_pc'
import OtcMenuMobile from '@/components/trade_history/history_menu/otc_menu_mobile'

export default {
  name: "BuyOffers",

  components: {
    'OtcHistoryMenu': OtcHistoryMenu,
    'OtcMenuMobile': OtcMenuMobile
  },

  data() {
    return {
      myOfferList: [],
      pageLast: 0,
      pageKey: 0,
      totalItem: 0,
    }
  },

  mounted() {
    this.$refs.OtcHistoryMenu.focusTab(1)
    this.$refs.OtcMenuMobile.selectMenu(this.$t('message.buyOfferSum'))

    this.getMyOfferList(1)
  },

  methods: {
    deleteOffer(idx) {
      let formData = new FormData()
      formData.append('offerIdx', idx)

      this.$post(this.$OTC_OFFER_DELETE, 'HistoryOtcBuyOffersDelete', formData, true, (result) => {
        this.notify('success', result.message)
        this.pageKey += 1
        this.getMyOfferList(1)
      }, (result) => {
        console.log(result)
      })
    },

    cancelOffer(idx) {
      let formData = new FormData()
      formData.append('offerIdx', idx)

      this.$post(this.$OTC_OFFER_CANCEL, 'HistoryOtcBuyOffersCancel', formData, true, (result) => {
        this.notify('success', result.message)
        this.pageKey += 1
        this.getMyOfferList(1)
      }, (result) => {
        console.log(result)
      })
    },

    getMyOfferList(page) {
      let formData = new FormData()
      let limit = 10

      formData.append('isBuy', true)
      formData.append('page', page)
      formData.append('limit', limit)
      this.$post(this.$OTC_OFFER_LIST, 'HistoryOtcBuyOffers', formData,true, (result) => {
        this.myOfferList = result.data
        this.totalItem = result.total
        this.pageLast = Math.ceil(result.total / limit)

        // this.myOfferList.forEach((myOffer) => {
        //   if(!myOffer.price) {
        //     this.getExchangePrice(myOffer)
        //   }
        // })

        this.myOfferList.map((myOffer) => {
          let date = myOffer.createdAt.split('T')[0]
          let time = myOffer.createdAt.split('T')[1].split('.')[0]
          myOffer.createdAt = `${date}<br>${time}`
        })
      }, (result) => {
        console.log(result)
      })
    },

    goDetailList(idx) {
      this.$router.push({ name: 'TradeHistoryOtcOfferDetailList', params: { offerIdx: idx }})
    },

    getExchangePrice(myOffer) {
      if(myOffer.exchanges === 'BITFINEX') {
        let marketPair = `${myOffer.marketPair.split('-')[0]}usd`
        marketPair = marketPair.toLowerCase()
        this.$get(`${this.$OTC_DISPLAY_TICKER_BITFINEX}/${marketPair}`, 'historySellOffersBitfinex_' + myOffer.offerIdx, true, (result) => {
          let usdPrice = result.data[0].mid
          if(usdPrice && myOffer.marketPair.split('-')[1] === 'KRW') {
            this.$get(`${this.$OTC_DISPLAY_USDKRW}`, 'historySellOffersGetUSDKRW_' + myOffer.offerIdx, true, (result) => {
              let rate = result.data[0].rate
              myOffer.price = this.subDec((rate * usdPrice), myOffer.marketPair.split('-')[1])

            }, (result) => {
              console.log(result)
            })
          } else {
            // 환율계산하는 api 화폐당 더 나와야함
          }
        }, (result) => {
          console.log(result)
          this.notify('error', result.message)
        })
      } else if (myOffer.exchanges === 'UPBIT'){
        let marketPair = `${myOffer.marketPair.split('-')[1]}-${myOffer.marketPair.split('-')[0]}`
        this.$get(`${this.$OTC_DISPLAY_TICKER_UPBIT}/${marketPair}`, 'historySellOffersUpbit_' + myOffer.offerIdx, true, (result) => {
          let midPrice = result.data[0].mid
          if(midPrice && myOffer.marketPair.split('-')[1] === 'KRW') {
            myOffer.price = this.subDec(midPrice, myOffer.marketPair.split('-')[1])
          } else {
            // 환율계산하는 api 화폐당 더 나와야함
          }
        }, (result) => {
          this.notify('error', result.message)
        })
      } else if(myOffer.exchanges === 'BINANCE') {
        let marketPair = `${myOffer.marketPair.split('-')[0]}${myOffer.marketPair.split('-')[1]}`
        this.$get(`${this.$OTC_DISPLAY_TICKER_BINANCE}/${marketPair}`, 'historySellOffersBinance_' + myOffer.offerIdx, true, (result) => {
          let midPrice = result.data[0].price
          console.log(midPrice)
        }, (result) => {
          this.notify('error', result.message)
        })
      } else {
        myOffer.price = 0
      }

    },
  },
}
</script>

<style scoped>

</style>
