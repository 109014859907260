<template>
  <div class="mobile_menu">
    <ul>
      <li>
        <a class="mobile_tit" @click="slideMenu">{{ selectedMenu }}<i class="mbtn" :class="{ open: open }"></i></a>
        <ul class="mobile_sub" id="mobile-sub">
          <li v-show="selectedMenu !== $t('message.sellOfferSum')">
            <router-link :to="{ name: 'TradeHistoryOtcSellOffers' }">{{ $t('message.sellOfferSum') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.buyOfferSum')">
            <router-link :to="{ name: 'TradeHistoryOtcBuyOffers' }">{{ $t('message.buyOfferSum') }}</router-link >
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "myPageMenuMobile",
  data() {
    return {
      selectedMenu: '',
      open: false,
    }
  },

  methods: {
    slideMenu() {
      window.$(".mobile_sub").slideToggle("300")
      this.open = !this.open
    },

    selectMenu(selectedMenu) {
      this.selectedMenu = selectedMenu
    },
  }
}
</script>

<style scoped>

</style>
