<template>
  <div class="my-page">
    <div class="my-page-container">
      <table class="table">
        <tbody>
        <tr v-for="(col, colIdx) in columns" :key="colIdx">
          <td>{{ col }}</td>
          <td v-show="col !== 'images'">{{ qnaDetail[col] }}</td>
          <td v-show="col === 'images'"><img :src="qnaDetail.images[0].image"/></td>
        </tr>
        </tbody>
      </table>
      <button @click="deleteQna">{{ $t('message.delete') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyPageQnaDetail",
  data() {
    return {
      qnaIdx: this.$route.params.qnaIdx,
      qnaDetail: {},
      columns: [],
    }
  },

  mounted() {
    this.$get(this.$USER_MYPAGE_QNA_DETAIL + '/' + this.qnaIdx, 'myPageQnaGetDetail', true, (result) => {
      this.qnaDetail = result.data[0]
      for(let qna in this.qnaDetail) {
        this.columns.push(qna)
      }
    }, (result) => {
      console.log(result)
    })
  },

  methods: {
    deleteQna() {
      this.$axios_delete(this.$USER_MYPAGE_QNA + '/' + this.qnaIdx, 'myPageQnaDelete', true, (result) => {
        this.notify('error', result.message)
        this.$router.push({ name: 'MyPageQnaList'})
      }, (result) => {
        this.notify('error', result.message)

      })
    }
  }


}
</script>

<style scoped>

</style>
