<template>
  <div class="body_wrap">
    <MyPageMenuMobile ref="MyPageMenuMobile"></MyPageMenuMobile>
    <div class="pagew">
      <div class="subcontainer1 security">
        <MyPageMenuPC ref="MyPageMenuPC"></MyPageMenuPC>
        <Modal ref="Modal">
          <table class="table2">
            <tbody>
            <tr>
              <td class="td_title">{{ $t('message.pwd') }}</td>
              <td><input type="password" :placeholder="$t('message.enterPwd')" v-model="pwd"></td>
            </tr>
            <tr>
              <td class="td_title">{{ $t('message.oldPin') }}</td>
              <td><input type="password" :placeholder="$t('message.enterOldPin')" v-model="oldPin"></td>
            </tr>
            <tr>
              <td class="td_title">{{ $t('message.newPin') }}</td>
              <td><input type="password" :placeholder="$t('message.enterNewPin')" v-model="newPin"></td>
            </tr>
            <tr>
              <td class="td_title">{{ $t('message.newPin') }} {{ $t('message.confirm') }}</td>
              <td><input type="password" :placeholder="$t('message.enterNewPinAgain')" v-model="newPinConfirm"></td>
            </tr>
            </tbody>
          </table>
        </Modal>
        <div class="security_con" v-show="showSetting">
          <div class="c5_wrap">
            <span class="c5_title">{{ $t('message.securitySetting') }}</span>
            <div>
              <button class="small_btn" @click="changePin">{{ $t('message.changePin') }}</button>
              <select class="m_left10" v-model="securityMethod" @change="changeSecurityMethod">
                <option value="phone">{{ $t('message.changeToPhone') }}</option>
                <option value="pin">{{ $t('message.changeToPin') }}</option>
              </select>
            </div>
          </div>
          <div class="setting_form">
            <table class="table1">
              <tbody v-show="changeToPhone">
                <tr>
                  <td class="td_title">{{ $t('message.pwd') }}</td>
                  <td class="input_txt "><input type="password" class="border" v-model="pwdForPin" :placeholder="$t('message.placeholderPwd')"/></td>
                </tr>
                <tr>
                  <td class="td_title">{{ $t('message.pinCode') }}</td>
                  <td class="input_txt"><input type="password" class="border" v-model="pin" :placeholder="$t('message.enterPin')"/></td>
                </tr>
                <tr>
                  <td class="td_title btn"></td>
                  <td class="input_txt btn"><a @click="confirmPin" class="outline_s_btn m30">{{ $t('message.setSecurity') }}</a></td>
                </tr>
              </tbody>
              <tbody v-show="!changeToPhone">
                <tr>
                  <td class="td_title">{{ $t('message.pwd') }}</td>
                  <td class="input_txt "><input type="password" class="border" v-model="pwdForPhone" :placeholder="$t('message.placeholderPwd')"/></td>
                </tr>
                <tr>
                  <td class="td_title">{{ $t('message.phoneNo') }}</td>
                  <td class="input_txt phone_auth">
                    <vue-country-code @onSelect="onSelect" :preferredCountries="['kr', 'cn', 'us']" defaultCountry="kr"></vue-country-code>
                    <input type="text" class="border" :placeholder="$t('message.placeholderPhoneNo')" v-model="phone"/>
                    <button class="outline_m_btn" @click="sendCodeToSMS">{{ $t('message.authCode') }}</button>
                  </td>
                </tr>
                <tr v-show="showInputSmsVerify">
                  <td class="td_title">{{ $t('message.verificationCode') }}</td>
                  <td class="input_txt">
                    <input type="number" class="border" :placeholder="$t('message.placeholderPutAuthCode')" v-model="smsCode"/>
                  </td>
                </tr>
                <tr>
                  <td class="input_txt btn"></td>
                  <td class="input_txt btn" @click="confirmSmsCode"><a class="outline_s_btn m30">{{ $t('message.setSecurity') }}</a></td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

        <div class="security_con">
          <ul class="c5_wrap">
            <li class="c5_title">OTP {{ $t('message.setting') }}</li>
          </ul>
          <div class="otp_setting">
            <p class="">{{ $t('message.otpDesc1') }}</p>
            <div class="phone_box">
              <div>
                <p class="fs24 fwb">{{ $t('message.android') }}</p>
                <p>{{ $t('message.searchPlayStore') }}</p>
                <img src="/img/otp_img.jpg" :alt="$t('message.otpImage')"/>
                <a :href="otpData.playStore" class="outline_s_btn">{{ $t('message.playStore') }} OTP</a>
              </div>
              <div>
                <p class="fs24 fwb">{{ $t('message.iPhone') }}</p>
                <p>{{ $t('message.searchAppStore') }}</p>
                <img src="/img/otp_img.jpg" :alt="$t('message.otpImage')" />
                <a :href="otpData.appStore" class="outline_s_btn">{{ $t('message.appStore') }} OTP</a>
              </div>
            </div>
            <div class="btn_con">
              <div class="middle_btn" v-show="!isOtpVeri && userLv !== '1'">
                <router-link :to="{ name: 'MyPageSecurityOTP' }">
                  OTP {{ $t('message.verificationApplied') }}
                </router-link>
              </div>
              <div class="otp_done price_red" v-show="isOtpVeri"><i class="fas fa-check-circle"></i>{{ $t('message.securityDone') }}</div>
              <div class="otp_done price_red" v-show="userLv === '1' && !isOtpVeri"><i class="fas fa-check-circle"></i>{{ $t('message.otpNotPos') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPageMenuPC from '@/components/my_page/my_page_menu/menu_pc'
import MyPageMenuMobile from '../my_page_menu/menu_mobile'
import Modal from '@/components/cmp/modal/modal'

export default {
  name: "Security",

  components: {
    'MyPageMenuPC': MyPageMenuPC,
    'MyPageMenuMobile': MyPageMenuMobile,
    'Modal': Modal
  },

  data() {
    return {
     otpData: {
       key: '',
       url: '',
       playStore: '',
       appStore: '',
     },
      pin: '',
      oldPin: '',
      newPin: '',
      newPinConfirm: '',
      securityMethod: 'phone',
      isOtpVeri: false,
      changeToPhone: true,
      userLv: '',
      showSetting: true,
      pwd: '',
      pwdForPin: '',
      countryCode: '',
      phone: '',
      pwdForPhone: '',
      showInputSmsVerify: false,
      smsCode: '',
    }
  },

  created() {
    this.$get(this.$USER_MYPAGE_SECURITY_OTP, 'myPageSecurityMainGetOPT', true, (result) => {
      this.otpData = result.data[0]
    }, (result) => {
      console.log(result)
    })

    this.$get(this.$USER_MYPAGE_SECURITY, 'myPageGetSecurityMethod', true, (result) => {
      this.isOtpVeri = result.data[0].otp
    }, (result) => {
      console.log(result)
    })

    this.getUserProfile((result) => {
      if(result.success) {
        this.userLv = result.userProfile.level.substring(3, 2)
        if(this.userLv === '2') {
          this.showSetting = true
        }
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.$refs.MyPageMenuPC.focusTab(2)
    this.$refs.MyPageMenuMobile.selectMenu(this.$t('message.security'))
  },

  methods: {
    confirmSmsCode() {
      let formData = new FormData()
      formData.append('code', this.smsCode.toString())

      this.$post(this.$USER_MYPAGE_SECURITY_SECOND_PIN_CERT, 'SecurityPhoneCert', formData, true, (result) => {
        console.log('this.verifyCode', result.data[0])
        this.notify('success', result.message)
        this.pwdForPhone = ''
        this.phone = ''
        this.smsCode = ''
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    sendCodeToSMS() {
      if(this.phone === '') {
        return
      }
      let formData = new FormData()
      formData.append('password', this.pwdForPhone)
      formData.append('phone', this.phone)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_MYPAGE_SECURITY_SECOND_PIN, 'SecuritySmsSend', formData, true, (result) => {
        this.notify('success', result.message)
        this.showInputSmsVerify = true
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    onSelect({name, iso2, dialCode}) {
      console.log(name, iso2, dialCode)
      this.countryCode = iso2
    },

    confirmPin() {
      let formData = new FormData()
      formData.append('password', this.pwdForPin)
      formData.append('pin', this.pin)

      this.$post(this.$USER_MYPAGE_SECURITY_SECOND_PHONE, 'SecuritySecondPhone', formData, true, (result) => {
        this.notify('success', result.message)
        this.pwdForPin = ''
        this.pin = ''
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    changeSecurityMethod() {
      this.changeToPhone = this.securityMethod === 'phone'
    },

    changePin() {
      this.$refs.Modal.showDialog(this.$t('message.changePin'), this.$t('message.confirm'), this.$t('message.cancel'), () => {
        let formData = new FormData()
        formData.append('existingPassword', this.pwd)
        formData.append('existingPin', this.oldPin)
        formData.append('newPin', this.newPin)
        formData.append('newPinConfirm', this.newPinConfirm)

        this.$put(this.$USER_MYPAGE_SECURITY_PIN, 'myPageSecurityPutPin', formData, true, (result) => {
          this.notify('success', result.message)
          this.pwd = ''
          this.oldPin = ''
          this.newPin = ''
          this.newPinConfirm = ''
        }, (result) => {
          this.notify('success', result.message)
        })
      }, ()=>{})
    }
  }
}
</script>

<style scoped>

</style>
