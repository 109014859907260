<template>
  <div class="body_wrap">
    <div class="notify-header" v-show="showNotify">
      <div class="swiper-container notify-header-container slide3">
        <div class="swiper-wrapper">
          <div v-for="(coinNoticeThree, index) in coinNoticeThreeList" :key="'coinNoticeThreeList' + index" class="swiper-slide">
            <div v-for="(coinNoticeThreeItem, subIndex) in coinNoticeThree" :key="'coinNoticeThree' + subIndex" class="notify-header-item point" @click="$router.push({ name: 'CustomerSupportCoinView', params: { articleIdx: coinNoticeThreeItem.idx }})">
              <img :src="coinNoticeThreeItem.title | coinImg" :alt="$t('message.coin')">
              <strong>{{ coinNoticeThreeItem.title }}</strong>
            </div>
          </div>
        </div>
        <button style="z-index: 999;" class="notify-header-close bg-white" @click="showNotify = false"><i class="fas fa-times"></i></button>
      </div>
      <div class="swiper-container notify-header-container slide2">
        <div class="swiper-wrapper slide2">
          <div v-for="(coinNoticeTwo, index) in coinNoticeTwoList" :key="'coinNoticeTwoList' + index" class="swiper-slide">
            <div v-for="(coinNoticeTwoItem, subIndex) in coinNoticeTwo" :key="'coinNoticeTwo' + subIndex" class="notify-header-item point" @click="$router.push({ name: 'CustomerSupportCoinView', params: { articleIdx: coinNoticeTwoItem.idx }})">
              <img :src="coinNoticeTwoItem.title | coinImg" :alt="$t('message.coin')">
              <strong>{{ coinNoticeTwoItem.title }}</strong>
            </div>
          </div>
        </div>
        <button style="z-index: 999;" class="notify-header-close bg-white" @click="showNotify = false"><i class="fas fa-times"></i></button>
      </div>
      <div class="swiper-container notify-header-container slide1">
        <div class="swiper-wrapper slide1">
          <div v-for="(coinNoticeOne, index) in coinNoticeOneList" :key="'coinNoticeOneList' + index" class="swiper-slide point" @click="$router.push({ name: 'CustomerSupportCoinView', params: { articleIdx: coinNoticeOne.idx }})">
            <img :src="coinNoticeOne.title | coinImg" :alt="$t('message.coin')">
            <strong>{{ coinNoticeOne.title }}</strong>
          </div>
        </div>
        <button style="z-index: 999;" class="notify-header-close bg-white" @click="showNotify = false"><i class="fas fa-times"></i></button>
      </div>
    </div>
    <Modal ref="Modal">
      <div>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.coinName') }}</dt>
          <dd class="wallet_form_text t_left dblue_02">{{ getCoinFullName(pair.split('-')[0]) }}({{ pair.split('-')[0] }})</dd>
        </dl>
        <dl class="wallet_form2">
          <dt class="wallet_form_tit">{{ $t('message.tradeOrderType') }}</dt>
          <dd class="wallet_form_text t_left" v-show="modalTrade === 'buy'">{{ buyType === 'limit' ? $t('message.limit') : buyType === 'market' ? $t('message.marketPrice') : $t('message.StopLimit') }}</dd>
          <dd class="wallet_form_text t_left" v-show="modalTrade === 'sell'">{{ sellType === 'limit' ? $t('message.limit') : sellType === 'market' ? $t('message.marketPrice') : $t('message.StopLimit') }}</dd>
        </dl>
        <dl class="wallet_form2" v-show="!(buyType === 'market' || sellType === 'market')">
          <dt class="wallet_form_tit">{{ $t('message.orderMarketPrice') }}</dt>
          <dd class="wallet_form_text t_left">
            {{ modalTrade === 'buy' ? !buyPrice ? '0' : buyPrice : !sellPrice ? '0' : sellPrice | numberWithCommas }}
            <em> {{ pair.split('-')[1] }}</em>
          </dd>
        </dl>
        <dl class="wallet_form2" v-show="!(buyType === 'market' || sellType === 'market')">
          <dt class="wallet_form_tit">{{ $t('message.orderQuantity') }}</dt>
          <dd class="wallet_form_text t_left">{{ modalTrade === 'buy' ? !buyQuantity ? '0' : buyQuantity : !sellQuantity ? '0' : sellQuantity }}<em>{{ pair.split('-')[0] }}</em></dd>
        </dl>
        <dl class="wallet_form2" v-show="!(modalTrade === 'sell' && sellType === 'market')">
          <dt class="wallet_form_tit">{{ $t('message.transactionPrice') }}</dt>
          <dd class="wallet_form_text t_left">
            {{ modalTrade === 'buy' ? !buyTotal ? '0' : buyTotal : !sellTotal ? '0' : sellTotal | numberWithCommas }}
            <em>{{ pair.split('-')[1] }}</em>
          </dd>
        </dl>
        <dl class="wallet_form2" v-show="modalTrade === 'sell' && sellType === 'market'">
          <dt class="wallet_form_tit">{{ $t('message.totalOrder') }}</dt>
          <dd class="wallet_form_text t_left">{{ !sellQuantity ? '0' : sellQuantity | numberWithCommas }}<em>{{ pair.split('-')[0] }}</em></dd>
        </dl>
      </div>
    </Modal>

    <div class="pagew">
      <div class="sub_container">
        <div class="content_box">
          <div class="mk_section1">
            <!-- 코인 정보창 -->
            <div class="mk_coin_search">
              <div class="form">
                <input type="text" v-model="coinSearch" :placeholder="$t('message.searchEx')" @keyup.enter="searchCoin">
                <span class="coin_search_icon">
                  <i class="fas fa-search"></i>
                </span>
              </div>
            </div>
            <div class="mk_coin_list">
              <div class="mk_t_tab">
                <span id="tabs">
                  <a class="mk_coin_tab"
                     :class="{ tab_focus: secondCurrItem === selectedSecond && !isFav }"
                     v-for="(secondCurrItem, index) in secondCurr"
                     :key="'secondCurr' + index"
                     @click="activeTab(secondCurrItem)">
                    {{ secondCurrItem }} {{ $t('message.market') }}
                  </a>
                </span>
                <a class="mk_coin_tab" :class="{ tab_focus: isFav }" @click="activeTab('favorite')">{{ $t('message.favorite') }}</a>
              </div>
              <div class="min_w_530 mk_t_content">
                <!-- 코인 테이블 헤더 -->
                <div class="mk_t_header">
                  <table class="basic_mk mk_t_content">
                    <colgroup>
                      <col style="width: 5%;">
                      <col style="width: 23%;">
                      <col style="width: auto;">
                      <col style="width: 20%;">
                      <col style="width: 23%;">
                    </colgroup>
                    <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">{{ $t('message.coinName') }}</th>
                      <th scope="col">{{ $t('message.latest') }}</th>
                      <th scope="col">{{ $t('message.netChange') }}</th>
                      <th scope="col">{{ $t('message.transactionAmount') }}</th>
                    </tr>
                    </thead>
                  </table>
                </div>
                <!-- 코인 모음 -->
                <div class="mk_t_content mk_fix_h_01">
                  <table class="basic_mk_con coin_list">
                    <colgroup>
                      <col style="width: 5%;">
                      <col style="width: 20%;">
                      <col style="width: auto;">
                      <col style="width: 22%;">
                      <col style="width: 23%;">
                    </colgroup>
                    <tbody>
                      <tr :class="{ focuse: marketItem.marketPair === pair }"
                          class="point"
                          v-for="(marketItem, index) in marketList"
                          :key="'marketList' + index"
                          @click="focusRow(marketItem.marketPair)"
                          v-show="!(selectedSecond === 'favorite' && !marketItem.fav)">
                        <td @click.stop="setFav(marketItem.marketPair, marketItem.fav)">
                          <button v-show="marketItem.fav">
                            <i class="fas fa-star favorite"></i>
                          </button>
                          <button v-show="!marketItem.fav">
                            <i class="fas fa-star"></i>
                          </button>
                        </td>
                        <td class="t_left">
                          <span>{{ getCoinFullName(marketItem.marketPair.split('-')[0]) }}</span>
                          <em class="fw400 fs11">{{ marketItem.marketPair.split('-')[0] }}/{{ marketItem.marketPair.split('-')[1] }}</em>
                        </td>
                        <td class="t_center" :class="{ price_red: marketItem.change > 0, price_blue: marketItem.change < 0 }">
                          {{ marketItem.latest ? subDec(marketItem.latest, marketItem.marketPair.split('-')[1]) : subDec(marketItem.present, marketItem.marketPair.split('-')[1]) | numberWithCommas }}
                        </td>
                        <td class="t_center">
                          <span :class="{ price_red: marketItem.change > 0, price_blue: marketItem.change < 0 }">
                            {{ marketItem.change > 0 ? '+' : '' }}
                            {{ subDec(marketItem.changeRatio) }}%
                          </span>
                        </td>
                        <td class="t_center">
                          <span>{{ marketItem.tradeCost ? marketItem.tradeCost : 0 }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="no_list" v-show="marketList && !marketList.length">{{ $t('message.noData') }}</p>
              </div>
            </div>
          </div>
          <div class="mk_section2">
            <!-- 코인 게시판 -->
            <div class="coin_board">
              <div class="coin_board_tit width-auto">
                <em>
                  <img :src="isCoinImg ? getCoinImg(pair.split('-')[0]) : '/img/coin-lcp.png'" :alt="pair.split('-')[0]" @error="isCoinImg = false">
                </em>
                <p>
                  <strong style="word-break: keep-all">{{ getCoinFullName(pair.split('-')[0]) }}</strong>
                  <span>{{ pair.split('-')[0] }}/{{ pair.split('-')[1] }}</span>
                </p>
              </div>
              <div class="coin_board_cont">
                <div class="ty01">
                  <span :class="{ price_blue: change < 0, price_red: change > 0 }">{{ subDec(currentPrice, pair.split('-')[1]) | numberWithCommas }}</span>
                  <p>
                    <em class="c_tit">{{ $t('message.changeRate') }}</em>
                    <strong :class="{ price_blue: change < 0, price_red: change > 0 }">
                      {{ change > 0 ? '+' : '' }}{{ changePerc }}%
                    </strong>
                    <strong :class="{ price_blue: change < 0, price_red: change > 0 }">
                      {{ change > 0 ? '+' : '' }}{{ change | numberWithCommas }}
                    </strong>
                  </p>
                </div>
                <div class="ty02">
                  <dl class="first">
                    <dt>{{ $t('message.high') }}</dt>
                    <dd class="price_red">{{ subDec(high, pair.split('-')[1]) | numberWithCommas }}</dd>
                    <dt>{{ $t('message.low') }}</dt>
                    <dd class="price_blue">{{ subDec(low, pair.split('-')[1]) | numberWithCommas }}</dd>
                  </dl>
                  <dl>
                    <dt>{{ $t('message.prevDay') }}</dt>
                    <dd>{{ subDec(previousPrice, pair.split('-')[1]) | numberWithCommas }}</dd>
                    <dt>{{ $t('message.vol') }}</dt>
                    <dd>{{ subDec(volume, pair.split('-')[0]) | numberWithCommas }}</dd>
                  </dl>
                </div>
              </div>
            </div>
            <!-- 그래프 -->
            <div class="coin_chart">
              <div id="chart" :class="{ inactive: !showChart }"></div>
            </div>
            <!-- 호가창 / 매수창 -->
            <div class="coin_deal">
              <div class="deal_box_book">
                <div class="mk_deal_list">
                  <div class="tab_buy">
                    <a class="point" :class="{'on_n' : showCurrentOrder}" @click="showCurrentOrder = true">{{ $t('message.currentOrders') }}</a>
                    <a class="point" :class="{'on_n' : !showCurrentOrder}" @click="showCurrentOrder = false">{{ $t('message.marketTrades') }}</a>
                  </div>
                  <div v-show="showCurrentOrder">
                    <div class="mk_t_header">
                      <table class="basic_mk">
                        <colgroup>
                          <col style="width: auto;">
                          <col style="width: 40%;">
                        </colgroup>
                          <thead>
                            <tr>
                              <th scope="col" class="t_left pl-1">{{ $t('message.price') }}({{ pair.split('-')[1] }})</th>
                              <th scope="col" class="t_right pr-1">{{ $t('message.qty') }}({{ pair.split('-')[0] }})</th>
<!--                              <th scope="col" class="t_right pr-1">{{ $t('message.qty') }}/{{ $t('message.cumulAmount') }}({{ pair.split('-')[0] }})</th>-->
                            </tr>
                          </thead>
                      </table>
                    </div>
                    <div class="mk_t_content mk_fix_h_02" id="orderBook-con">
                      <table class="basic_mk_con">
                        <colgroup>
                          <!--                        <col style="width: 23%;">-->
                          <col style="width: auto;">
                          <col style="width: 40%;">
                        </colgroup>
                        <tbody class="orderBook-body" id="orderBook-body">
                          <tr class="down blank point" v-for="(_, index) in orderSell.length < orderBookLength ? (orderBookLength - orderSell.length) : 0" :key="'sellGuide' + index">
                            <td class="t_center"></td>
                            <td class="t_center"></td>
                          </tr>
                          <tr class="down point" v-for="(sellItem, index) in orderSell" :key="'orderSell' + index" @click="setPrice(sellItem.price)">
                            <td class="t_left">
                              <div>
                                <span :class="{ price_red: sellItem.perc > 0, price_blue: sellItem.perc < 0 }">{{ subDec(sellItem.price, pair.split('-')[1]) | numberWithCommas }}</span>
                                <span :class="{ price_red: sellItem.perc > 0, price_blue: sellItem.perc < 0 }">{{ sellItem.perc > 0 ? '+' + subDec(sellItem.perc) : subDec(sellItem.perc) }}%</span>
                              </div>
                            </td>
                            <td class="t_right">{{ sellItem.fills | numberWithCommas }}</td>
                          </tr>
                          <tr class="up point" v-show="index < orderBookLength" v-for="(buyItem, index) in orderBuy" :key="'orderBuy' + index" @click="setPrice(buyItem.price)">
                            <td class="t_left">
                              <div>
                                <span :class="{ price_red: buyItem.perc > 0, price_blue: buyItem.perc < 0 }">{{ subDec(buyItem.price, pair.split('-')[1]) | numberWithCommas }}</span>
                                <span :class="{ price_red: buyItem.perc > 0, price_blue: buyItem.perc < 0 }">{{ buyItem.perc > 0 ? '+' + subDec(buyItem.perc) : subDec(buyItem.perc) }}%</span>
                              </div>
                            </td>
                            <td class="t_right">{{ buyItem.fills | numberWithCommas }}</td>
                          </tr>
                          <tr class="up blank point" v-for="(_, index) in orderBuy.length < orderBookLength ? (orderBookLength - orderBuy.length) : 0" :key="'buyGuide' + index">
                            <td class="t_center"></td>
                            <td class="t_center"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div v-show="!showCurrentOrder">
                    <!-- 테이블 헤더 -->
                    <div class="mk_t_header">
                      <table class="basic_mk basic_line">
                        <colgroup>
                          <col style="width: 20%;">
                          <col style="width: 40%;">
                          <col style="width: 40%;">
                        </colgroup>
                        <thead>
                        <tr>
                          <th rowspan="2" style="border-right : 1px solid #E0E0E0;" scope="col">{{ $t('message.tradedTime') }}</th>
                          <th scope="col" style="border-bottom : 1px solid #E0E0E0;">{{ $t('message.price') }}({{ pair.split('-')[1] }})</th>
                          <th rowspan="2" style="border-left : 1px solid #E0E0E0;" scope="col">{{ $t('message.transactionPrice') }}</th>
                        </tr>
                        <tr>
                          <th scope="col">{{ $t('message.quantity') }}({{ pair.split('-')[0] }})</th>
                        </tr>
                        </thead>
                      </table>
                    </div>
                    <!-- 리스트 -->
                    <div class="mk_t_content mk_fix_h_04" style="height: 410px;">
                      <table class="basic_mk_con basic_line">
                        <colgroup>
                          <col style="width: 20%;">
                          <col style="width: 40%;">
                          <col style="width: 40%;">
                        </colgroup>
                        <tbody v-for="(tradeItem, index) in traded.slice().reverse()" :key="'tradedLive' + index">
                        <tr :class="{ 'bg-red': tradeItem.isBuy, 'bg-blue': !tradeItem.isBuy }">
                          <td rowspan="2" style="border-bottom : 1px solid #E0E0E0;" class="t_center">{{ tradeItem.date }}</td>
                          <td class="t_right">{{ tradeItem.price | numberWithCommas }}</td>
                          <td rowspan="2" :class="{ price_red: previousPrice < tradeItem.price, price_blue: previousPrice > tradeItem.price }" style="border-bottom : 1px solid #E0E0E0;" class="t_right">
                            {{ subDec((tradeItem.price * tradeItem.tradedAmount), pair.split('-')[1]) | numberWithCommas }}
                          </td>
                        </tr>
                        <tr :class="{ 'bg-red': tradeItem.isBuy, 'bg-blue': !tradeItem.isBuy }">
                          <td class="t_right" style="border-bottom : 1px solid #E0E0E0;">{{ tradeItem.tradedAmount | numberWithCommas }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 매수/매도 -->
              <div class="deal_box_order">
                <div class="mk_deal_buy">
                  <div class="tab_buy">
                    <a class="point" :class="{ 'on_r': openTradeTab === 'buy' }" @click="openTradeTab = 'buy'">{{ $t('message.buy') }}</a>
                    <a class="point" :class="{ 'on_b': openTradeTab === 'sell' }" @click="openTradeTab = 'sell'">{{ $t('message.sell') }}</a>
                  </div>
                  <div class="mk_fix_h_03" v-show="openTradeTab === 'buy'">
                    <div class="form_top">
                      <div class="tab_type">
                        <a class="point" :class="{'on_r' : buyType === 'limit'}" @click="buyType = 'limit'">{{ $t('message.limit') }}</a>
                        <a class="point" :class="{'on_r' : buyType === 'market'}" @click="buyType = 'market'">{{ $t('message.marketPrice') }}</a>
                        <a class="point" :class="{'on_r' : buyType === 'reserve'}" @click="buyType = 'reserve'">{{ $t('message.StopLimit') }}</a>
                      </div>
                      <dl class="form_group" v-show="buyType === 'reserve'">
                        <dd>
                          <div class="form_box">
                            <div class="inp_num_box">
                              <input type="text" v-model="buyWatchPrice" :placeholder="$t('message.stop')" @focusout="setBuyWatchPrice()"/>
                              <span class="fix_txt" style="margin-right: 20px;">{{ pair.split('-')[1] }}</span>
                              <div class="btn_box">
                                <a class="btn_inc" @click="increaseBuyWatchPrice">
                                  <i class="ico_ar_up_g"></i>
                                </a>
                                <a class="btn_inc" @click="decreaseBuyWatchPrice">
                                  <i class="ico_ar_down_g"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </dd>
                      </dl>
                      <dl class="form_group" v-show="buyType === 'limit' || buyType === 'reserve'">
                        <dd>
                          <div class="inp_num_box">
                            <input type="text" :key="rerenderKey" v-model="buyPrice" :placeholder="$t('message.price')" @keyup="changeBuyPrice()" @focusout="setBuyPrice()"/>
                            <span class="fix_txt" style="margin-right: 20px;">{{ pair.split('-')[1] }}</span>
                            <div class="btn_box">
                              <a class="btn_inc" @click="increaseBuyPrice">
                                <i class="ico_ar_up_g"></i>
                              </a>
                              <a class="btn_inc" @click="decreaseBuyPrice">
                                <i class="ico_ar_down_g"></i>
                              </a>
                            </div>
                          </div>
                        </dd>
                      </dl>
                      <dl class="form_group" v-show="buyType !== 'market'">
                        <dd>
                          <div class="inp_num_box inp35">
                            <input type="text" :key="rerenderKey" v-model="buyQuantity" :placeholder="$t('message.quantity')" @keyup="changeBuyQuantity()" @focusout="setBuyPrice()"/>
                            <span class="fix_txt">{{ pair.split('-')[0] }}</span>
                          </div>
                        </dd>
                      </dl>
                      <dl class="form_group pl-0">
                        <dt>
                          <span class="title" v-show="buyType === 'market'">{{ $t('message.estiBuyAmt') }}</span>
                        </dt>
                        <div class="btn_set_quick red_set">
                          <button type="button" class="set_quick" :class="{ active: buyQuick === 100 }" @click="setPriceQuick('buy', 100)">
                            <span>100</span>
                            <span>%</span>
                          </button>
                          <button type="button" class="set_quick" :class="{ active: buyQuick === 50 }" @click="setPriceQuick('buy', 50)">
                            <span>50</span>
                            <span>%</span>
                          </button>
                          <button type="button" class="set_quick" :class="{ active: buyQuick === 25 }" @click="setPriceQuick('buy', 25)">
                            <span>25</span>
                            <span>%</span>
                          </button>
                          <button type="button" class="set_quick" :class="{ active: buyQuick === 10 }" @click="setPriceQuick('buy', 10)">
                            <span>10</span>
                            <span>%</span>
                          </button>
                          <button type="button" class="set_quick" :class="{ active: buyQuick === 0 }" @click="setPriceQuick('buy', 0)">
                            <span>0</span>
                            <span>%</span>
                          </button>
                        </div>
                      </dl>
                    </div>
                    <div class="form_middle">
                      <dl class="form_group">
                        <dd>
                          <div class="inp_num_box inp60">
                            <input type="text" :key="rerenderKey" v-model="buyTotal" :placeholder="$t('message.transactionPrice')" @keyup="changeBuyTotal()" @focusout="setBuyPrice()"/>
                            <span class="fix_txt">{{ pair.split('-')[1] }}</span>
                          </div>
                        </dd>
                      </dl>
                      <dl class="form_group pl-0 small">
                        <dt>
                          <span class="title">{{ $t('message.balance') }}: </span>
                        </dt>
                        <dd>
                          <span>{{ subDec(mySecondBal, pair.split('-')[1]) | numberWithCommas }} {{ pair.split('-')[1] }}</span>
                        </dd>
                      </dl>
                      <dl class="form_group pl-0 small">
                        <dt>{{ $t('message.minOrderPrice') }}: </dt>
                        <dd>{{ minBuyPrice | numberWithCommas }} {{ pair.split('-')[1] }}</dd>
                      </dl>
                      <dl class="form_group pl-0 small">
                        <dt>{{ $t('message.minOrderAmt') }}: </dt>
                        <dd>{{ minBuyAmount | numberWithCommas }} {{ pair.split('-')[0] }}</dd>
                      </dl>
                    </div>
                    <div class="form_bottom" @click="doTrade('buy')">
                      <button class="deal_total red">
                        <a>{{ pair.split('-')[0] }} {{ $t('message.buy') }}</a>
                      </button>
                    </div>
                  </div>
                  <div class="mk_fix_h_03" v-show="openTradeTab === 'sell'">
                    <div class="form_top">
                      <div class="tab_type">
                        <a class="point" :class="{'on_b' : sellType === 'limit'}" @click="sellType = 'limit'">{{ $t('message.limit') }}</a>
                        <a class="point" :class="{'on_b' : sellType === 'market'}" @click="sellType = 'market'">{{ $t('message.marketPrice') }}</a>
                        <a class="point" :class="{'on_b' : sellType === 'reserve'}" @click="sellType = 'reserve'">{{ $t('message.StopLimit') }}</a>
                      </div>
                      <dl class="form_group" v-show="sellType === 'reserve'">
                        <dd>
                          <div class="form_box">
                            <div class="inp_num_box">
                              <input type="text" v-model="sellWatchPrice" :placeholder="$t('message.stop')" @focusout="setSellWatchPrice()"/>
                              <span class="fix_txt" style="margin-right: 20px;">{{ pair.split('-')[1] }}</span>
                              <div class="btn_box">
                                <a class="btn_inc" @click="increaseSellWatchPrice">
                                  <i class="ico_ar_up_g"></i>
                                </a>
                                <a class="btn_inc" @click="decreaseSellWatchPrice">
                                  <i class="ico_ar_down_g"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </dd>
                      </dl>
                      <dl class="form_group" v-show="sellType === 'limit' || sellType === 'reserve'">
                        <dd>
                          <div class="inp_num_box">
                            <input type="text" :key="rerenderKey" v-model="sellPrice" :placeholder="$t('message.price')" @keyup="changeSellPrice()" @focusout="setSellPrice()"/>
                            <span class="fix_txt" style="margin-right: 20px;">{{ pair.split('-')[1] }}</span>
                            <div class="btn_box">
                              <a class="btn_inc" @click="increaseSellPrice">
                                <i class="ico_ar_up_g"></i>
                              </a>
                              <a class="btn_inc" @click="decreaseSellPrice">
                                <i class="ico_ar_down_g"></i>
                              </a>
                            </div>
                          </div>

                        </dd>
                      </dl>
                      <dl class="form_group" v-show="sellType !== 'market'">
                        <dd>
                          <div class="inp_num_box inp35">
                            <input type="text" :key="rerenderKey" v-model="sellQuantity" :placeholder="$t('message.quantity')" @keyup="changeSellQuantity()" @focusout="setSellPrice()"/>
                            <span class="fix_txt">{{ pair.split('-')[0] }}</span>
                          </div>
                        </dd>
                      </dl>
                      <dl class="form_group pl-0">
                        <dt>
                          <span class="title" v-show="sellType === 'market'">{{ $t('message.estiSellAmt') }}</span>
                        </dt>
                        <div class="btn_set_quick blue">
                          <button type="button" class="set_quick" :class="{ active: sellQuick === 100 }" @click="setPriceQuick('sell', 100)">
                            <span>100</span>
                            <span>%</span>
                          </button>
                          <button type="button" class="set_quick" :class="{ active: sellQuick === 50 }" @click="setPriceQuick('sell', 50)">
                            <span>50</span>
                            <span>%</span>
                          </button>
                          <button type="button" class="set_quick" :class="{ active: sellQuick === 25 }" @click="setPriceQuick('sell', 25)">
                            <span>25</span>
                            <span>%</span>
                          </button>
                          <button type="button" class="set_quick" :class="{ active: sellQuick === 10 }" @click="setPriceQuick('sell', 10)">
                            <span>10</span>
                            <span>%</span>
                          </button>
                          <button type="button" class="set_quick" :class="{ active: sellQuick === 0 }" @click="setPriceQuick('sell', 0)">
                            <span>0</span>
                            <span>%</span>
                          </button>
                        </div>
                      </dl>
                    </div>
                    <div class="form_middle">
                      <dl class="form_group">
                        <dd>
                          <div class="inp_num_box inp60">
                            <input type="text" :key="rerenderKey" v-model="sellTotal" v-show="sellType !== 'market'" :placeholder="$t('message.transactionPrice')" @keyup="changeSellTotal()" @focusout="setSellPrice()"/>
                            <input type="text" v-model="sellQuantity" v-show="sellType === 'market'" :placeholder="$t('message.quantity')" @keyup="changeSellQuantity()" @focusout="setSellPrice()"/>
                            <span class="fix_txt">{{ sellType === 'market' ? pair.split('-')[0] : pair.split('-')[1] }}</span>
                          </div>
                        </dd>
                      </dl>
                      <dl class="form_group pl-0 small">
                        <dt>
                          <span class="title">{{ $t('message.balance') }}: </span>
                        </dt>
                        <dd>
                          <span>{{ subDec(myPrimaryBal, pair.split('-')[0]) | numberWithCommas }} {{ pair.split('-')[0] }}</span>
                        </dd>
                      </dl>
                      <dl class="form_group pl-0 small">
                        <dt>{{ $t('message.minOrderPrice') }}: </dt>
                        <dd>{{ minSellPrice | numberWithCommas }} {{ pair.split('-')[1] }}</dd>
                      </dl>
                      <dl class="form_group pl-0 small">
                        <dt>{{ $t('message.minOrderAmt') }}: </dt>
                        <dd>{{ minSellAmount | numberWithCommas }} {{ pair.split('-')[0] }}</dd>
                      </dl>
                    </div>
                    <div class="form_bottom" @click="doTrade('sell')">
                      <button class="deal_total blue">
                        <a>{{ pair.split('-')[0] }} {{ $t('message.sell') }}</a>
                      </button>
                    </div>
                  </div>
                  <div class="mk_fix_h_06 market" v-show="openTradeTab === 'traded'">
                    <!-- 테이블 헤더 -->
                    <div class="mk_t_header">
                      <table class="basic_mk basic_line">
                        <colgroup>
                          <col style="width: 20%;">
                          <col style="width: 40%;">
                          <col style="width: 40%;">
                        </colgroup>
                        <thead>
                        <tr>
                          <th rowspan="2" style="border-right : 1px solid #E0E0E0;" scope="col">{{ $t('message.tradedTime') }}</th>
                          <th scope="col" style="border-bottom : 1px solid #E0E0E0;">{{ $t('message.price') }}({{ pair.split('-')[1] }})</th>
                          <th rowspan="2" style="border-left : 1px solid #E0E0E0;" scope="col">{{ $t('message.transactionPrice') }}</th>
                        </tr>
                        <tr>
                          <th scope="col">{{ $t('message.quantity') }}({{ pair.split('-')[0] }})</th>
                        </tr>
                        </thead>
                      </table>
                    </div>
                    <!-- 리스트 -->
                    <div class="mk_t_content mk_fix_h_07">
                      <table class="basic_mk_con basic_line">
                        <colgroup>
                          <col style="width: 20%;">
                          <col style="width: 40%;">
                          <col style="width: 40%;">
                        </colgroup>
                        <tbody v-for="(tradeItem, index) in traded.slice().reverse()" :key="'tradedList' + index">
                          <tr>
                            <td rowspan="2" style="border-bottom : 1px solid #E0E0E0;" class="t_center">{{ tradeItem.date }}</td>
                            <td class="t_right">{{ tradeItem.price | numberWithCommas }}</td>
                            <td rowspan="2" :class="{ price_red: tradeItem.isBuy, price_blue: !tradeItem.isBuy }" style="border-bottom : 1px solid #E0E0E0;" class="t_right">
                              {{ subDec((tradeItem.price * tradeItem.tradedAmount), pair.split('-')[1]) | numberWithCommas }}
                            </td>
                          </tr>
                          <tr>
                            <td class="t_right" style="border-bottom : 1px solid #E0E0E0;">{{ tradeItem.tradedAmount | numberWithCommas }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- // (768px 부터) 체결창 -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content_box mt20">
          <div class="mk_section4">
            <div class="mk_deal_list">
              <div class="tab_oder_history">
                <a :class="{ 'tab_on': !showMyTraded }" @click="tabTrade( 0)">{{ $t('message.openOrders') }}</a>
                <a :class="{ 'tab_on': showMyTraded }" @click="tabTrade(1)">{{ $t('message.recentHistory') }}</a>
              </div>
              <span class="point" @click="refresh"><i class="fas fa-redo-alt"></i><span class="m_left5 refresh">{{ $t('message.refresh') }}</span></span>
              <div class="min_w_530 mk_t_content" v-show="showMyTraded">
                <!-- 테이블 해더 -->
                <div class="mk_t_header mk_t_content">
                  <table class="basic_mk">
                    <colgroup>
                      <col style="width: 25%;">
                      <col style="width: auto;">
                      <col style="width: 25%;">
                      <col style="width: 23%;">
                      <col style="width: 18%;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('message.time') }}</th>
                        <th scope="col">{{ $t('message.sort') }}</th>
                        <th scope="col">{{ $t('message.price') }}</th>
<!--                        <th scope="col">{{ $t('message.orderQuantity') }}</th>-->
<!--                        <th scope="col">{{ $t('message.fees') }}</th>-->
                        <th scope="col">{{ $t('message.tradedAmt') }}</th>
                        <th scope="col">{{ $t('message.tradedPrice') }}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <!-- 리스트 -->
                <div class="mk_t_content mk_fix_h_05">
                  <table class="basic_mk_con fw400" v-show="$store.getters.getLoginState">
                    <colgroup>
                      <col style="width: 25%;">
                      <col style="width: auto;">
                      <col style="width: 25%;">
                      <col style="width: 23%;">
                      <col style="width: 18%;">
<!--                      <col style="width: 17%;">-->
                    </colgroup>
                    <tbody>
                    <tr v-for="(tradedList, index) in myTradedList" :key="'myTradedList' + index">
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ tradedList.date }}</td>
                      <td class="t_center" :class="{price_red: tradedList.isBuy === 'buy', price_blue: tradedList.isBuy === 'sell'}" style="border-bottom : 1px solid #E0E0E0;">{{ $t(`message.${tradedList.isBuy}`) }}</td>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ subDec(tradedList.price, pair.split('-')[1]) | numberWithCommas }} {{ pair.split('-')[1] }}</td>
<!--                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ subDec(tradedList.initAmount, pair.split('-')[0]) |  numberWithCommas }} {{ pair.split('-')[0] }}</td>-->
<!--                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ tradedList.feeAmount }}</td>-->
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ subDec(tradedList.tradedAmount, pair.split('-')[0]) | numberWithCommas }} {{pair.split('-')[0] }}</td>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ subDec(getTotalTraded(tradedList.tradedAmount, tradedList.price), pair.split('-')[1]) | numberWithCommas }} {{ pair.split('-')[1] }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <p v-show="$store.getters.getLoginState && !myTradedList.length">{{ $t('message.noRecentHis') }}</p>
                  <p v-show="!$store.getters.getLoginState">{{ $t('message.doLogin') }}</p>
                </div>
              </div>
              <div class="min_w_530 mk_t_content" v-show="!showMyTraded">
                <div class="mk_t_header mk_t_content">
                  <table class="basic_mk">
                    <colgroup>
                      <col style="width: 12%;">
                      <col style="width: 13%;">
                      <col style="width: auto;">
                      <col style="width: 16%;">
                      <col style="width: 20%;">
                      <col style="width: 18%;">
                    </colgroup>
                    <thead>
                    <tr>
                      <th scope="col">{{ $t('message.sort') }}</th>
                      <th scope="col">{{ $t('message.orderPrice') }}</th>
                      <th scope="col">{{ $t('message.orderQuantity') }}</th>
                      <th scope="col">{{ $t('message.remaining') }}</th>
                      <th scope="col">{{ $t('message.orderTime') }}</th>
                      <th scope="col">{{ $t('message.cancel') }}
                        <button v-show="myTradeWaitList.length > 0" class="outline_btn0" @click="cancelAll()" style="margin-left: 10px;">{{ $t('message.cancelAll') }}</button>
                      </th>
                    </tr>
                    </thead>
                  </table>
                </div>
                <!-- 리스트 -->
                <div class="mk_t_content mk_fix_h_05">
                  <table class="basic_mk_con fw400" v-show="$store.getters.getLoginState">
                    <colgroup>
                      <col style="width: 12%;">
                      <col style="width: 13%;">
                      <col style="width: auto;">
                      <col style="width: 16%;">
                      <col style="width: 20%;">
                      <col style="width: 18%;">
                    </colgroup>
                    <tbody>
                    <tr v-for="(waitList, index) in myTradeWaitList" :key="'myTradeWaitList' + index">
                      <td class="t_center" :class="{price_red: waitList.isBuy === 'buy', price_blue: waitList.isBuy === 'sell'}" style="border-bottom : 1px solid #E0E0E0;">{{ $t(`message.${waitList.isBuy}`) }}</td>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ waitList.price | numberWithCommas3 }}</td>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ waitList.initAmount }}</td>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ waitList.remainAmount }}</td>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ waitList.date }}</td>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">
                        <button class="outline_btn0" @click="cancelOrder(waitList.exid)">{{ $t('message.cancel') }}</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <p v-show="!$store.getters.getLoginState">{{ $t('message.noData') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/cmp/modal/modal'
import Datafeed from '@/commonjs/datafeed'
import Socket from '../commonjs/socket'
import Big from 'big-js'

export default {
  name: "GeneralMarket",
  data() {
    return {
      moveStep: 1,
      marketFullList: [],
      isLoading: null,
      showChart: false,
      isCoinImg: true,
      showMyTraded: false,
      showNotify: true,
      star: true,
      tvScript: null,
      openTradeTab: 'buy',
      modalTrade: 'buy',
      high: 0,
      low: 0,
      previousPrice: 0,
      volume: 0,
      price: 0,
      data: {
        time: 0,
        pair: '',
        price: 0,
        volume: 0
      },
      pairs: [],
      coinSearch: '',
      orderBuy: [],
      orderSell: [],
      traded: [],
      tradedTemp: [],
      buyType: 'limit',
      sellType: 'limit',
      pair: '',
      // orderBookTotal: 0,
      buyPrice: '',
      sellPrice: '',
      currentPrice: '0',
      buyQuantity: '',
      buyTotal: '',
      sellQuantity: '',
      sellTotal: '',
      sellMarketPrice: 0,
      buyMarketPrice: 0,
      buyWatchPrice: '',
      sellWatchPrice: '',
      myTradeWaitList: [],
      myTradedList: [],
      secondCurr: [],
      marketList: [],
      tempBuy: [],
      tempSell: [],
      myPrimaryBal: 0,
      mySecondBal: 0,
      buyFee: '',
      sellFee: '',
      orderBookLength: 10,
      rerenderKey: 0,
      minBuyPrice: 0,
      minSellPrice: 0,
      minBuyAmount: 0,
      minSellAmount: 0,
      showCurrentOrder: true,

      code : 'coin-notice', // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, transfer : 입출금, faq : 자주 묻는 질문, qna : 문의
      codeLocale : '',
      coinNoticeList : [],
      coinNoticeThreeList : [],
      coinNoticeTwoList : [],
      coinNoticeOneList : [],
      selectedSecond: '',
      sellQuick: 0,
      buyQuick: 0,
      isFav: false,
      renderKey: 0,
      dataToSend: {
        type: '',
        pair: [],
        here: '',
        message: 0,
      },
      rgx: /^[0-9]*\.?[0-9]*$/, // 정수 및 소수점 형식만 허용
      loaded: true,
      interval: null,
    }
  },

  components: {
    'Modal': Modal,
  },

  computed: {
    change() {
      return this.subDec((this.currentPrice - this.previousPrice), this.pair.split('-')[1])
    },

    changePerc() {
      let currentPrice = Number(this.currentPrice)
      let previousPrice = Number(this.previousPrice)

      if(!currentPrice || !previousPrice) {
        return 0
      } else {
        return this.subDec((currentPrice - previousPrice) / previousPrice * 100)
      }
    },
  },

  created() {
    if(this.$route.params.pair) {
      sessionStorage.setItem('currentPair', this.$route.params.pair)
    }

    let currentPair = sessionStorage.getItem('currentPair')
    if(!currentPair) {
      currentPair = 'BTC-UBIT'
      sessionStorage.setItem('currentPair', currentPair)
    }

    this.pair = currentPair

    this.selectedSecond = this.pair.split('-')[1]
    Socket.$on("message", this.handleMessage)
    this.$store.state.isSubscribed = true

    this.interval = setInterval(() => {
      this.loadBalance()
    }, 1000)
  },

  beforeDestroy() {
    Datafeed.unsubscribeBars(null)
    Socket.$off("message", this.handleMessage)

    this.coinNoticeThreeList = []
    this.coinNoticeTwoList = []
    this.coinNoticeOneList = []

    clearInterval(this.interval)
  },

  mounted() {
    if (this.$root.$i18n.locale !== 'ko') {
      this.codeLocale = '_' + this.$root.$i18n.locale
    } else {
      this.codeLocale = ''
    }

    this.makeMarketList()

    this.getNoticeList(1)

    // 호가창 스크롤 항상 중간 위치
    let resizeObserver = new ResizeObserver(function() {
      if(document.getElementById('orderBook-con')) {
        let orderBook = document.getElementById('orderBook-con')
        orderBook.scrollTop = orderBook.scrollHeight / 2 - orderBook.clientHeight / 2
      }
    })
    resizeObserver.observe(document.getElementById('orderBook-body'))
    // this.getMyTradedList()
    this.getMyTradeWaitList()
  },

  methods: {
    increaseBuyWatchPrice() {
      if(!this.buyWatchPrice) {
        this.buyWatchPrice = 0
      }
      this.buyWatchPrice = new Big(this.buyWatchPrice)
      this.buyWatchPrice = this.buyWatchPrice.plus(this.moveStep)
    },

    decreaseBuyWatchPrice() {
      if(this.buyWatchPrice > this.moveStep) {
        this.buyWatchPrice = new Big(this.buyWatchPrice)
        this.buyWatchPrice = this.buyWatchPrice.minus(this.moveStep)
      }
    },

    increaseBuyPrice() {
      if(!this.buyPrice) {
        this.buyPrice = 0
      }
      this.buyPrice = new Big(this.buyPrice)
      this.buyPrice = this.buyPrice.plus(this.moveStep)

      this.changeBuyPrice()
    },

    decreaseBuyPrice() {
      if(this.buyPrice > this.moveStep) {
        this.buyPrice = new Big(this.buyPrice)
        this.buyPrice = this.buyPrice.minus(this.moveStep)
      }

      this.changeBuyPrice()
    },

    increaseSellWatchPrice() {
      if(!this.sellWatchPrice) {
        this.sellWatchPrice = 0
      }
      this.sellWatchPrice = new Big(this.sellWatchPrice)
      this.sellWatchPrice = this.sellWatchPrice.plus(this.moveStep)
    },

    decreaseSellWatchPrice() {
      if (this.sellWatchPrice > this.moveStep) {
        this.sellWatchPrice = new Big(this.sellWatchPrice)
        this.sellWatchPrice = this.sellWatchPrice.minus(this.moveStep)
      }
    },

    increaseSellPrice() {
      if(!this.sellPrice) {
        this.sellPrice = 0
      }
      this.sellPrice = new Big(this.sellPrice)
      this.sellPrice = this.sellPrice.plus(this.moveStep)

      this.changeSellPrice()
    },

    decreaseSellPrice() {
      if(this.sellPrice > this.moveStep) {
        this.sellPrice = new Big(this.sellPrice)
        this.sellPrice = this.sellPrice.minus(this.moveStep)
      }

      this.changeSellPrice()
    },

    changeBuyQuantity() {
      if(this.buyType === 'market' || !this.buyQuantity || (!this.buyPrice && !this.buyTotal)) {
        return
      }

      let q = Big(this.buyQuantity)
      if(q.cmp(Big(0)) <= 0) {
        return
      }

      let p = Big(this.buyPrice)
      if(p.cmp(Big(0)) <= 0) {
        return
      }

      this.buyTotal = this.subDec(q.mul(p).toFixed(30), this.pair.split('-')[1])
    },

    changeSellQuantity() {
      if(this.sellType === 'market' || !this.sellQuantity || (!this.sellPrice && !this.sellTotal)) {
        return
      }

      let q = Big(this.sellQuantity)
      if(q.cmp(0) <= 0) {
        return
      }

      let p = Big(this.sellPrice)
      if(p.cmp(0) <= 0) {
        return
      }

      this.sellTotal = this.subDec(q.mul(p).toFixed(30), this.pair.split('-')[1])
    },

    changeBuyPrice() {
      if(this.buyType === 'market' || !this.buyPrice || (!this.buyTotal && !this.buyQuantity)) {
        return
      }

      let p = Big(this.buyPrice)
      if(p.cmp(Big(0)) <= 0) {
        return
      }

      let q = Big(this.buyQuantity)
      if(q.cmp(Big(0)) <= 0) {
        return
      }

      this.buyTotal = this.subDec(q.mul(p).toFixed(30), this.pair.split('-')[1])
    },

    changeSellPrice() {
      if(this.sellType === 'market' || !this.sellPrice || (!this.sellTotal && !this.sellQuantity)) {
        return
      }

      let p = Big(this.sellPrice)
      if(p.cmp(0) <= 0) {
        return
      }

      let q = Big(this.sellQuantity)
      if(q.cmp(0) <= 0) {
        return
      }

      this.sellTotal = this.subDec(q.mul(p).toFixed(30), this.pair.split('-')[1])
    },

    changeBuyTotal() {
      if(this.buyType === 'market') {
        this.buyQuantity = this.subDec(Big(this.buyTotal).toFixed(30), this.pair.split('-')[1])
        return
      }

      let p = Big(this.buyPrice)
      if(p.cmp(Big(0)) <= 0) {
        return
      }

      let t = Big(this.buyTotal)
      if(t.cmp(Big(0)) <= 0) {
        return
      }

      this.buyQuantity = this.subDec(t.div(p).toFixed(30), this.pair.split('-')[1])
    },

    changeSellTotal() {
      if(this.sellType === 'market') {
        this.sellQuantity = this.subDec(Big(this.buyTotal).toFixed(30), this.pair.split('-')[1])
        return
      }

      let p = Big(this.sellPrice)
      if(p.cmp(Big(0)) <= 0) {
        return
      }

      let t = Big(this.sellTotal)
      if(t.cmp(Big(0)) <= 0) {
        return
      }

      this.sellQuantity = this.subDec(t.div(p).toFixed(30), this.pair.split('-')[1])
    },

    searchCoin() {
      let result = []
      if(this.selectedSecond === 'favorite') {
        for(const markets of Object.values(this.marketFullList)) {
          markets.forEach((market) => {
            if(market.marketPair.indexOf(this.coinSearch.toUpperCase()) !== -1) {
              result.push(market)
            }
          })
        }
      } else {
        let tmp = this.marketFullList[this.pair.split('-')[1]]
        tmp.forEach((market) => {
          if(market.marketPair.indexOf(this.coinSearch.toUpperCase()) !== -1) {
            result.push(market)
          }
        })
      }
      this.marketList = result
    },

    setFav(pair, fav) {
      if(!this.$store.getters.getLoginState) {
        this.notify('error', '로그인을 해주세요')
        return
      }

      let formData = new FormData
      formData.append('marketPair', pair)
      formData.append('marketPairSave', !fav)

      this.$post(this.$USER_MYPAGE_FAV, 'setFav', formData, true, () => {
        if(this.marketFullList[pair.split('-')[1]]) {
          for (let i = 0; i < this.marketFullList[pair.split('-')[1]].length; i++) {
            if (this.marketFullList[pair.split('-')[1]][i].marketPair === pair) {
              this.marketFullList[pair.split('-')[1]][i].fav = !fav
            }
          }
        }

        for (let i = 0; i < this.marketList.length; i++) {
          if(this.marketList[i].marketPair === pair) {
            this.marketList[i].fav = !fav
          }
        }
      }, (result) => {
        console.log(result)
      })
    },

    initSwiper() {
      // swiper
      new window.Swiper('.swiper-container', {
        autoplay: {
          delay: 3000,
        },
        loop: true,
        loopFillGroupWithBlank : true, // 공백일 때 그 공간 채울지
        // slidesPerView : 1, // 한 번에 보여줄 갯수
        // slidesPerGroup : 3, // 한 번에 보여줄 갯수 묶은 것
        // spaceBetween: 30, // 간격
        autoHeight : true,
        direction: 'vertical',
      })
    },
    getNoticeList(page) {
      this.coinNoticeOneList = []
      this.coinNoticeTwoList = []
      this.coinNoticeThreeList = []

      let limit = 12
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.coinNoticeList = result.data

        for(let i=0; i<this.coinNoticeList.length; i+=3) {
          this.coinNoticeThreeList.push(this.coinNoticeList.slice(i, i+3))
        }
        for(let i=0; i<this.coinNoticeList.length; i+=2) {
          this.coinNoticeTwoList.push(this.coinNoticeList.slice(i, i+2))
        }
        this.coinNoticeOneList = [...this.coinNoticeList]

        this.pageLast = Math.ceil(result.total / limit)
        setTimeout(() => {
          this.initSwiper()
        }, 1000)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    refresh() {
      if(this.showMyTraded) {
        this.tabTrade(1)
      } else {
        this.tabTrade(0)
      }
    },

    focusRow(marketPair, force = false) {
      if(!force && this.pair === marketPair) {
        return
      }

      if(this.isLoading) {
        clearTimeout(this.isLoading)
        this.tradedTemp = []
      }

      this.showCurrentOrder = true

      this.pair = marketPair
      sessionStorage.setItem('currentPair', this.pair)

      this.moveStep = 1/(Math.pow(10, this.getMaxDecimal(this.pair.split('-')[1])))

      this.orderSell = []
      this.orderBuy = []
      this.traded = []
      this.tradedTemp = []

      for(const markets of Object.values(this.marketFullList)) {
        markets.forEach((market) => {
          if (market.marketPair === this.pair) {
            this.high = this.subDec(market.high, this.pair.split('-')[1])
            this.low = this.subDec(market.low, this.pair.split('-')[1])
            this.previousPrice = this.subDec(market.lastPrice, this.pair.split('-')[1])
            this.currentPrice = this.subDec(market.latest ? market.latest : market.present, this.pair.split('-')[1])
            this.sendData()
          }
        })
      }

      this.showMyTraded = false
      this.getMyTradeWaitList()

      this.isCoinImg = true

      this.getMinPrice()
      this.getFee()

      this.setDealInit()
    },

    cancelOrder(orderNo) {
      this.$get(`${this.$ORDER_SPOT_CANCEL}?exid=${orderNo}`, 'genaralMarketOrderCancel', true, (result) => {
        this.notify('success', result.message)
        this.getMyTradeWaitList()
      }, (result) => {
        console.log(result)
      })
    },

    cancelAll() {
      let _orderNoList = '['
      for (let i=0;i<this.myTradeWaitList.length;i++) {
        let _orderNo = this.myTradeWaitList[i].exid
        if (this.myTradeWaitList.length - 1 > i) {
          _orderNoList = _orderNoList + '"' + _orderNo + '"' + ','
        } else {
          _orderNoList = _orderNoList + '"' + _orderNo + '"' + ']'
        }
      }

      this.$postJson(this.$ORDER_SPOT_CANCEL_ALL, 'genaralMarketOrderCancelAll', _orderNoList, () => {
        this.getMyTradeWaitList()
      }, (result) => {
        if (result.message !== '') {
          this.notify('error', result.message)
        }
      })
    },

    setPriceQuick(type, percentage) {
      if(type === 'buy') {
        this.buyQuick = percentage

        if(this.buyType !== 'market' && !this.buyPrice) {
          this.notify('error', this.$t('message.enterOrderPrice'))
          return
        }

        if(this.mySecondBal) {
          if(percentage === 100) {
            this.buyTotal = this.mySecondBal
          } else if(percentage === 50) {
            this.buyTotal = this.mySecondBal / 2
          } else if(percentage === 25) {
            this.buyTotal = this.mySecondBal / 4
          } else if(percentage === 10) {
            this.buyTotal = this.mySecondBal / 10
          } else {
            this.buyTotal = 0
          }
        }

        this.buyTotal = this.subDec(this.buyTotal, this.pair.split('-')[1])
        if(!this.buyTotal) {
          this.buyTotal = ''
        }
        this.buyQuantity = this.subDec(Number(this.buyTotal) / Number(this.buyPrice), this.pair.split('-')[1])
        if(!this.buyQuantity) {
          this.buyQuantity = ''
        }
      }

      if(type === 'sell') {
        this.sellQuick = percentage

        if(this.sellType !== 'market' && !this.sellPrice) {
          this.notify('error', this.$t('message.enterOrderPrice'))
          return
        }

        if(this.myPrimaryBal) {
          if(percentage === 100) {
            this.sellQuantity = this.myPrimaryBal
          } else if (percentage === 50) {
            this.sellQuantity = this.myPrimaryBal / 2
          } else if (percentage === 25) {
            this.sellQuantity = this.myPrimaryBal / 4
          } else if (percentage === 10) {
            this.sellQuantity = this.myPrimaryBal / 10
          } else {
            this.sellQuantity = 0
          }
        }

        this.sellQuantity = this.subDec(this.sellQuantity, this.pair.split('-')[1])
        if(!this.sellQuantity) {
          this.sellQuantity = ''
        }
        this.sellTotal = this.subDec(Number(this.sellPrice) * Number(this.sellQuantity), this.pair.split('-')[1])
        if(!this.sellTotal) {
          this.sellTotal = ''
        }
      }

      this.$forceUpdate()
    },

    sendData() {
      Datafeed.unsubscribeBars(null)
      let pairDataToSend = []

      for (const markets of Object.values(this.marketFullList)) {
        markets.forEach(market => {
          pairDataToSend.push(market.marketPair)
        })
      }

      this.dataToSend = {
        type: 'set',
        pair: pairDataToSend,
        here: this.pair,
        message: this.orderBookLength.toString(),
      }
      let uint8array = new TextEncoder("utf-8").encode(JSON.stringify(this.dataToSend))
      Socket.send(uint8array)

      if(this.$root.$i18n.locale !== 'cn') {
        this.createChart(this.$root.$i18n.locale)
      } else {
        this.createChart('zh')
      }

      this.isLoading = setTimeout(() => {
        this.isLoading = null
        this.traded = this.tradedTemp
        this.tradedTemp = []
      }, 1000)
    },
    getFavoriteMarket(callback = () => {}) {
      if(this.$store.getters.getLoginState) {
        this.$get(this.$USER_MYPAGE_FAV, 'getFav', true, (result) => {
          callback(result.data)
        }, (result) => {
          console.log(result)
        })
      } else {
        callback([])
      }
    },
    makeMarketList() {
      let currentTab = sessionStorage.getItem('currentTab')
      if(!currentTab) {
        currentTab = this.pair.split('-')[1]
      }
      this.getFavoriteMarket((favoriteMarkets) => {
        this.$get(this.$HOME_SECONDARY, 'MarketGetHomeSecond', false, (result) => {
          this.secondCurr = []
          let _count = 0
          for (let i = 0; i < result.data.length; i++) {
            this.$get(this.$HOME_MARKET + '?secondary=' + result.data[i], 'GeneralGetHomeMarket' + i, false, (result2) => {
              if(result2.data.length > 0) {
                this.secondCurr.push(result.data[i])
              }
              for (let j = 0; j < result2.data.length; j++) {
                result2.data[j].fav = false
                for (let k = 0; k < favoriteMarkets.length; k++) {
                  if (favoriteMarkets[k] === result2.data[j].marketPair) {
                    result2.data[j].fav = true

                    break
                  }
                }

                if (result2.data[j].present !== '0') {
                  result2.data[j].change = result2.data[j].present - result2.data[j].lastPrice
                  result2.data[j].changeRatio = (result2.data[j].present - result2.data[j].lastPrice) / result2.data[j].lastPrice * 100
                  if (result2.data[j].changeRatio === undefined || isNaN(result2.data[j].changeRatio)) {
                    result2.data[j].changeRatio = 0
                  }
                }
              }

              this.marketFullList[result.data[i]] = result2.data

              _count++

              if(_count >= result.data.length) {
                this.activeTab(currentTab, true)
              }
            }, (result) => {
              console.log(result)
            })
          }
        }, (result) => {
          console.log(result)
        })
      })
    },
    getSecondCurrMarket(force = false) {
      if(!this.marketList) {
        return
      }
      if(!Array.isArray(this.marketList)) {
        return
      }

      let sessionPair = sessionStorage.getItem('currentPair')
      if(sessionPair === "") {
        this.focusRow(this.marketList[0].marketPair, force)

        return
      }

      if(sessionPair.split('-')[1] === this.pair.split('-')[1]) {
        this.focusRow(sessionPair, force)
      } else {
        this.focusRow(this.marketList[0].marketPair, force)
      }
    },

    numberToLang(number) {
      let format = {
        ko: {
          4: {slice: [1, 3], name: '천'},
          5: {slice: [1, 3], name: '만'},
          6: {slice: [2, 4], name: '만'},
          7: {slice: [3, 5], name: '만'},
          8: {slice: [1, 3], name: '백만'},
          9: {slice: [1, 3], name: '억'},
          10: {slice: [2, 4], name: '억'},
          11: {slice: [1, 3], name: '백억'},
          12: {slice: [1, 3], name: '천억'},
          13: {slice: [1, 3], name: '조'}
        },
        en: {
          4: {slice: [1, 3], name: 'K'},
          5: {slice: [1, 3], name: 'K'},
          6: {slice: [2, 4], name: 'K'},
          7: {slice: [3, 5], name: 'M'},
          8: {slice: [1, 3], name: 'M'},
          9: {slice: [1, 3], name: 'M'},
          10: {slice: [2, 4], name: 'B'},
          11: {slice: [1, 3], name: 'B'},
          12: {slice: [1, 3], name: 'B'},
          13: {slice: [1, 3], name: 'T'}
        },
      }

      let lang = this.$root.$i18n.locale
      if(!lang || (lang !== 'ko' && lang !== 'en')) {
        lang = 'en'
      }

      if(number.includes('.')) {
        number = number.split('.')[0]
      }

      if(number.length < 4) {
        number = this.subDec(number)
        return number
      }

      return [number.slice(0, format[lang][number.length].slice[0]), '.', number.slice(format[lang][number.length].slice[0], format[lang][number.length].slice[1])].join('') + format[lang][number.length].name
    },

    activeTab(ticker, force = false) {
      this.coinSearch = ''
      this.selectedSecond = ticker

      sessionStorage.setItem('currentTab', ticker)

      if(ticker === 'favorite') {
        this.isFav = true
        if(!this.$store.getters.getLoginState) {
          this.notify('error', this.$t('message.doLogin'))
          return
        }

        let _marketList = []

        for (const markets of Object.values(this.marketFullList)) {
          for (let i = 0; i < markets.length; i++) {
            if(markets[i].fav) {
              _marketList.push(markets[i])
            }
          }
        }

        this.marketList = _marketList
      } else {
        this.isFav = false

        this.marketList = this.marketFullList[ticker]

        this.getSecondCurrMarket(ticker, force)
      }
    },

    loadBalance() {
      if(!this.$store.getters.getLoginState) {
        return
      }

      if(!this.loaded) {
        return
      }

      this.loaded = false

      this.getMyBalance((result) => {
        if(result.success) {
          this.myPrimaryBal = result.myBalance.available
        }
      }, this.pair.split('-')[0])

      this.getMyBalance((result) => {
        if(result.success) {
          this.mySecondBal = result.myBalance.available
        }
      }, this.pair.split('-')[1])

      this.loaded = true
    },

    doTrade(trade) {
      if(!this.$store.getters.getLoginState) {
        this.notify('error', this.$t('message.doLogin'))
        return
      }

      if(trade === 'buy' && !this.mySecondBal) {
        this.notify('error', this.$t('message.noBalForBuy'))
        return
      } else if (trade === 'sell' && !this.myPrimaryBal) {
        this.notify('error', this.$t('message.noBalForSell'))
        return
      }

      if(trade === 'buy' && this.buyType !== 'market' && (!Number(this.buyTotal) || !Number(this.buyPrice) || !Number(this.buyQuantity))) {
        this.notify('error', this.$t('message.chkPriceAndAmt'))
        return
      } else if(trade === 'sell' && this.sellType !== 'market' && (!Number(this.sellTotal) || !Number(this.sellPrice) || !Number(this.sellQuantity))) {
        this.notify('error', this.$t('message.chkPriceAndAmt'))
        return
      }

      if(trade === 'buy') {
        if(this.buyTotal === '') {
          this.notify('error', this.$t('message.chkPriceAndAmt'))
          return
        }
        if(Big(this.minBuyPrice).cmp(Big(this.buyTotal)) > 0) {
          this.notify('error', this.$t('message.chkMinPrice'))
          return
        } else if(this.buyType !== 'market' && Big(this.minBuyAmount).cmp(Big(this.buyQuantity)) > 0) {
          this.notify('error', this.$t('message.chkMinAmt'))
          return
        }
      }

      if(trade === 'sell') {
        if(this.sellQuantity === '') {
          this.notify('error', this.$t('message.chkPriceAndAmt'))
          return
        }
        if(this.sellType !== 'market' && Big(this.minSellPrice).cmp(Big(this.sellTotal)) > 0) {
          this.notify('error', this.$t('message.chkMinPrice'))
          return
        } else if(Big(this.minSellAmount).cmp(Big(this.sellQuantity)) > 0) {
          this.notify('error', this.$t('message.chkMinAmt'))
          return
        }
      }

      this.modalTrade = trade

      this.$refs.Modal.showDialog(this.$t('message.orderConfirm'), this.$t(`message.${trade}`), this.$t('message.close'), () => {
        let isBuy
        let price
        let amount
        let type
        let stopPrice
        if(trade === 'buy') {
          isBuy = true
          price = this.buyType === 'market' ? '' : this.buyPrice
          amount = this.buyType === 'market' ? this.buyTotal : this.buyQuantity
          type = this.buyType
          stopPrice = this.buyType === 'reserve' ? this.buyWatchPrice : ''
        } else {
          isBuy = false
          price = this.sellType === 'market' ? '' : this.sellPrice
          amount = this.sellQuantity
          type = this.sellType
          stopPrice = this.sellType === 'reserve' ? this.sellWatchPrice : ''
        }

        let formData = new FormData()
        formData.append('marketPair', this.pair)
        formData.append('isBuy', isBuy)
        formData.append('price', price.toString())
        formData.append('initAmount', amount.toString())
        formData.append('orderType', type.toUpperCase())
        formData.append('stopPrice', stopPrice.toString())

        this.$post(this.$ORDER_SPOT, 'generalMarketOrderSpotSell', formData, true, (result) => {
          this.notify('success', result.message)
          this.showMyTraded ? this.getMyTradedList() : this.getMyTradeWaitList()
          this.buyPrice = ''
          this.sellPrice = ''
          this.sellQuantity = ''
          this.buyQuantity = ''
          this.buyTotal = ''
          this.sellTotal = ''
          this.buyWatchPrice = ''
          this.sellWatchPrice = ''
        }, (result) => {
          console.log(result)
          if(result.message === 'order.fail.qualified') {
            this.notify('error', this.$t('message.orderBlocked'))
          } else {
            this.notify('error', result.message)
          }

        })
      }, ()=>{}, true, trade === 'buy' ? 'red' : '')
    },

    tabTrade(tabNo) {
      if(tabNo === 1) {
        this.myTradedList = []
        this.showMyTraded = true
        this.getMyTradedList()
      } else {
        this.myTradeWaitList = []
        this.showMyTraded = false
        this.getMyTradeWaitList()
      }
    },

    getMyTradeWaitList() {
      let accessToken = localStorage.getItem('accessToken')
      if(accessToken) {

        this.$get(`${this.$ORDER_SPOT_LIST}?size=50&marketPair=${this.pair}`, 'generalMarketGetSpotList', true, (result) => {
          result.data.map(list => {
            list.date = this.formatDate(list.date, 'full')
            list.isBuy ? list.isBuy = 'buy' : list.isBuy = 'sell'
            list.initAmount = this.toFloat(list.initAmount)
            list.price = this.toFloat(list.price)
            list.remainAmount = this.toFloat(list.remainAmount)
          })
          this.myTradeWaitList = result.data
        }, (result) => {
          console.log(result)
        })
      }
    },

    getMyTradedList() {
      let accessToken = localStorage.getItem('accessToken')
      if(accessToken) {
        this.$get(`${this.$ORDER_SPOT_LIST_TRADED}?size=50&marketPair=${this.pair}`, 'generalMarketGetSpotListTraded', true, (result) => {
          result.data.map(list => {
            // let date = list.exid.split(':')[5]
            // list.date = this.formatDate(Number(date), 'full')
            list.date = this.formatDate(list.createdAt, 'full')
            list.isBuy ? list.isBuy = 'buy' : list.isBuy = 'sell'
            list.initAmount = list.exid.split(':')[4]
            list.feeAmount = list.feeAmount ? list.feeAmount : 0
          })
          this.myTradedList = result.data
        }, (result) => {
          console.log(result)
        })
      }
    },

    getPriceData(pair, price) {
      if(this.isLoading) {
        return
      }

      if(this.pair === pair) {
        this.currentPrice = price
      }

      for(const markets of Object.values(this.marketFullList)) {
        markets.forEach((market) => {
          if(market.marketPair === pair) {
            market.latest = price
            market.present = '0'
            market.change = market.latest - market.lastPrice
            market.changeRatio = (market.latest - market.lastPrice) / market.lastPrice * 100
            if(market.changeRatio === undefined || isNaN(market.changeRatio)) {
              market.changeRatio = 0
            }
          }
        })
      }

      this.marketList.forEach((market) => {
        if(market.marketPair === pair) {
          market.latest = price
          market.present = '0'
          market.change = market.latest - market.lastPrice
          market.changeRatio = (market.latest - market.lastPrice) / market.lastPrice * 100
          if(market.changeRatio === undefined || isNaN(market.changeRatio)) {
            market.changeRatio = 0
          }
          this.$forceUpdate()
        }
      })

      let idx = this.pairs.findIndex(i => i.pair === pair)
      if (idx === -1) {
        this.pairs.push({
          pair: pair,
          price: price
        })
      } else {
        this.pairs.forEach((p) => {
          if (p.pair === pair) {
            p.price = price
          }
        })
      }
    },

    setPrice(price) {
      let priceBig = new Big(price)
      let priceWithDec = this.subDec(priceBig, this.pair.split('-')[1])

      this.buyWatchPrice = priceWithDec
      this.buyPrice = priceWithDec
      this.sellWatchPrice = priceWithDec
      this.sellPrice = priceWithDec
    },

    getAcc(_isBuy, _price) {
      let acc = new Big(0)
      let price = new Big(_price)

      if (_isBuy) {
        for (let i=0; i < this.orderBuy.length; i++) {
          let orderPrice = new Big(this.orderBuy[i].price)
          let orderAmount = new Big(this.orderBuy[i].fills)
          if (price.cmp(orderPrice) <= 0) {
            acc = acc.plus(orderAmount)
          }
        }
      } else {
        for (let i=this.orderSell.length - 1; i >= 0; i--) {
          let orderPrice = new Big(this.orderSell[i].price)
          let orderAmount = new Big(this.orderSell[i].fills)
          if (orderPrice.cmp(price) <= 0) {
            acc = acc.plus(orderAmount)
          }
        }
      }

      acc = this.subDec(acc, this.pair.split('-')[1])
      return acc
    },

    getOrderBookData(jsonData) {
      if(jsonData.p !== this.pair) {
        this.orderBuy = []
        this.orderSell = []
        return
      }

      let currentPrice = new Big(jsonData.m[0].p)

      let previousPrice = new Big(this.previousPrice)
      let calculated = 0
      try{
        calculated = (currentPrice.minus(previousPrice)).div(previousPrice) * 100
      } catch (e) {
        console.log(e)
      }

      let orderInfo = {
        fills: jsonData.m[0].f,
        isBuy: jsonData.m[0].i,
        price: jsonData.m[0].p,
        perc: calculated
      }

      if (orderInfo.fills === '0') {
        if (orderInfo.isBuy === 'true') {
          let tempOrderBuy = [...this.orderBuy]
          for (let i=0; i< this.orderBuy.length; i++) {
            let currentOrder = this.orderBuy[i]
            if (orderInfo.price === currentOrder.price) {
              tempOrderBuy.splice(i, 1)
            }
          }
          this.orderBuy = tempOrderBuy
        } else {
          let tempOrderSell = [...this.orderSell]
          for (let i=0; i< this.orderSell.length; i++) {
            let currentOrder = this.orderSell[i]
            if (orderInfo.price === currentOrder.price) {
              tempOrderSell.splice(i, 1)
            }
          }
          this.orderSell = tempOrderSell
        }
      } else if(orderInfo.isBuy === 'true') {
        let isAdd = false
        for (let i=0; i< this.orderBuy.length; i++) {
          let currentOrder = this.orderBuy[i]
          if (orderInfo.price === currentOrder.price) {
            this.orderBuy[i].fills = orderInfo.fills
            isAdd = true
          }
        }
        if (!isAdd) {
          this.orderBuy.push(orderInfo)
        }
        this.orderBuy = this.sortByPrice(this.orderBuy)
        // if (this.orderBuy.length > this.orderBookLength) {
          // this.orderBuy = this.orderBuy.slice(0, this.orderBookLength)
        // }
      } else {
        let isAdd = false
        for (let i=0; i< this.orderSell.length; i++) {
          let currentOrder = this.orderSell[i]
          if (orderInfo.price === currentOrder.price) {
            this.orderSell[i].fills = orderInfo.fills
            isAdd = true
          }
        }
        if (!isAdd) {
          this.orderSell.push(orderInfo)
        }
        this.orderSell = this.sortByPrice(this.orderSell)
        if (this.orderSell.length > this.orderBookLength) {
          this.orderSell = this.orderSell.slice(this.orderSell.length - this.orderBookLength, this.orderBookLength + (this.orderSell.length - this.orderBookLength))
          // this.orderSell = this.orderSell.slice(this.orderSell.length - this.orderBookLength - 1, this.orderBookLength)
        }
      }

      // 주문전략 합계
      // let totalBuy = 0
      // let totalSell = 0
      // this.orderBuy.forEach(buy => {
      //   totalBuy += Number(buy.fills)
      // })
      // this.orderSell.forEach(sell => {
      //   totalSell += Number(sell.fills)
      // })
      // this.orderBookTotal = (totalBuy + totalSell)
    },

    getTradedData(jsonData) {
      if(jsonData.p !== this.pair) {
        this.traded = []
        return
      }

      jsonData.m[0].d = this.formatDate(jsonData.m[0].d, 'time')

      let data = jsonData.m[0];

      if(this.isLoading) {
        this.tradedTemp.push({
          exid: data.e,
          marketPair: data.m,
          isBuy: data.i,
          price: data.p,
          tradedAmount: data.ta,
          feeAmount: data.fa,
          date: data.d
        })

        return
      }

      if(this.traded.length > 50) {
        this.traded.splice(0, 1)
      }

      this.traded.push({
        exid: data.e,
        marketPair: data.m,
        isBuy: data.i,
        price: data.p,
        tradedAmount: data.ta,
        feeAmount: data.fa,
        date: data.d
      })
    },

    handleMessage(msg) {
      let promise = new Promise(resolve => {
        resolve(msg.text())
      })
      promise.then((value) => {
        let jsonData = JSON.parse(value)

        if (jsonData.t === 'p') {
          // price
          this.getPriceData(jsonData.p, jsonData.m[0])
        } else if(jsonData.t === 'o') {
          // orderBook
          this.getOrderBookData(jsonData)
        } else if (jsonData.t === 't') {
          // traded
          this.getTradedData(jsonData)
        } else if(jsonData.t === 'v') {
          //거래대금
          this.getTradeCost(jsonData.m[0])
          // 거래량
          this.getVolume(jsonData.m[0])
        }
      })
    },

    getVolume(jsonMsg) {
      let pairsData = Object.keys(jsonMsg)

      pairsData.forEach(pair => {
        if(pair === this.pair) {
          this.volume = jsonMsg[pair].volume
        }
      })
    },

    getTradeCost(jsonMsg) {
      let pairsData = Object.keys(jsonMsg)

      for(const markets of Object.values(this.marketFullList)) {
        markets.forEach(market => {
          pairsData.forEach(pair => {
            if(pair === market.marketPair) {
              let tradeCost = jsonMsg[pair].value
              market.tradeCostFull = tradeCost
              market.tradeCost = this.numberToLang(tradeCost)
            }
          })
        })
      }

      this.marketList.forEach(market => {
        pairsData.forEach(pair => {
          if(pair === market.marketPair) {
            let tradeCost = jsonMsg[pair].value
            market.tradeCostFull = tradeCost
            market.tradeCost = this.numberToLang(tradeCost)
          }
        })
      })
    },

    getMinPrice() {
      let marketPairs = JSON.parse(sessionStorage.getItem('marketPairs'))
      if(!marketPairs) {
        return
      }
      marketPairs.forEach(market => {
        if(market.pair === this.pair) {
          this.minBuyPrice = this.toFloat(market.buyMinValue)
          this.minSellPrice = this.toFloat(market.sellMinValue)
          this.minBuyAmount = this.toFloat(market.minBuyAmount)
          this.minSellAmount = this.toFloat(market.minSellAmount)
        }
      })
    },

    getFee() {
      if(!this.$store.state.isLoggedIn) {
        this.buyFee = this.$t('message.chkAfterLogin')
        this.sellFee = this.$t('message.chkAfterLogin')
        return
      }

      this.$get(`${this.$USER_MYPAGE_FEE}?marketType=SPOT&primary=${this.pair.split('-')[0]}&secondary=${this.pair.split('-')[1]}`, 'getUserFee', true, (result) => {
        let data = result.data[0]
        this.buyFee = Number(data.feeRateBuy) * 100 + '%'
        this.sellFee = Number(data.feeRateSell) * 100 + '%'
      }, (result) => {
        console.log(result)
      })
    },

    getTotalTraded(amount, price) {
      let amountN = new Big(amount)
      let priceN = new Big(price)

      return amountN.mul(priceN).toFixed(20)
    },

    sortByPrice(arr) {
      arr.sort(function (a, b) {
        if (Number(a.price) > Number(b.price)) {
          return -1
        }
        if (Number(a.price) < Number(b.price)) {
          return 1
        }
        return 0
      })
      return arr
    },

    createChart(locale) {
      let res = sessionStorage.getItem('chartResolution')
      if(!res) {
        res = '1'
      }

      this.showChart = false
      this.$store.state.requestedTime = Date.now()

      Datafeed.onReady(() => {
        (window.tvWidget = new window.TradingView.widget({
          symbol: `ubit:${this.pair}`,
          datafeed: Datafeed,
          interval: res,
          // time_frames: [
          //   {text: "1y", resolution: "1W"},
          //   {text: "6m", resolution: "3D"},
          //   {text: "3m", resolution: "1D"},
          //   {text: "1m", resolution: "1D"},
          //   {text: "1w", resolution: "30"},
          //   {text: "3d", resolution: "30"},
          //   {text: "1d", resolution: "30"},
          //   {text: "6h", resolution: "15"},
          //   {text: "1h", resolution: "1"}],
          container_id: "chart",
          library_path: "/js/trading_view/charting_library/",
          locale: locale,
          // disabled_features: ["use_localstorage_for_settings"],
          chartsStorageUrl: "https://saveload.tradingview.com",
          chartsStorageApiVersion: "1.1",
          client_id: 'tradingview.com',
          user_id: 'public_user_id',
          fullscreen: false,
          autosize: true,
          disabled_features: [
            "header_compare",
          ],
          enabled_features: [
            "dont_show_boolean_study_arguments",
            "use_localstorage_for_settings",
            "remove_library_container_border",
            "save_chart_properties_to_local_storage",
            "side_toolbar_in_fullscreen_mode",
            "hide_last_na_study_output",
            "constraint_dialogs_movement",
            // "study_templates"
          ],
          studies_overrides: {
            "volume.volume.color.0": "#fe4761",
            "volume.volume.color.1": "#3fcfb4",
            "volume.volume.transparency": 75,
          },
          // loading_screen:{ backgroundColor: "#00ff00",foregroundColor: "#000000", },
          // timeframe:'',
          // toolbar_bg: "#20334d",
          // allow_symbol_change: false,
          timezone: 'UTC+9',
          debug: false,
          overrides: {
            "mainSeriesProperties.showCountdown": true,
            "paneProperties.background": "#fff",
            "paneProperties.vertGridProperties.color": "#E6EFF4",
            "paneProperties.horzGridProperties.color": "#E6EFF4",
            "symbolWatermarkProperties.transparency": 90,
            "scalesProperties.textColor": "#AAA",
            "mainSeriesProperties.candleStyle.wickUpColor": "#336854",
            "mainSeriesProperties.candleStyle.wickDownColor": "#7f323f"
          }
        }))

        this.showChart = true
      })
    },

    setDealInit() {
      this.buyQuantity = ''
      this.sellQuantity = ''
      this.buyTotal = ''
      this.sellTotal = ''
      this.sellQuick = 0
      this.buyQuick = 0
      this.buyPrice = ''
      this.sellPrice = ''
      this.buyWatchPrice = ''
      this.sellWatchPrice = ''
    },

    setBuyPrice() {
      if(this.buyPrice) {
        this.buyPrice = this.subDec(this.buyPrice, this.pair.split('-')[1])
      }

      if(this.buyQuantity) {
        this.buyQuantity = this.subDec(this.buyQuantity, this.pair.split('-')[1])
      }

      if(this.buyTotal) {
        this.buyTotal = this.subDec(this.buyTotal, this.pair.split('-')[1])
      }

      this.changeBuyQuantity()

    },

    setSellPrice() {
      if(this.sellPrice) {
        this.sellPrice = this.subDec(this.sellPrice, this.pair.split('-')[1])
      }

      if(this.sellQuantity) {
        this.sellQuantity = this.subDec(this.sellQuantity, this.pair.split('-')[1])
      }

      if(this.sellTotal) {
        this.sellTotal = this.subDec(this.sellTotal, this.pair.split('-')[1])
      }

      this.changeSellQuantity()
    },

    setBuyWatchPrice() {
      if(this.buyWatchPrice) {
        this.buyWatchPrice = this.subDec(this.buyWatchPrice, this.pair.split('-')[1])
      }
    },

    setSellWatchPrice() {
      if(this.sellWatchPrice) {
        this.sellWatchPrice = this.subDec(this.sellWatchPrice, this.pair.split('-')[1])
      }
    },

  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        if(this.$root.$i18n.locale !== 'cn') {
          this.createChart(this.$root.$i18n.locale)
        } else {
          this.createChart('zh')
        }

        for(const markets of Object.values(this.marketFullList)) {
          markets.forEach(market => {
            market.tradeCost = this.numberToLang(market.tradeCostFull)
          })
        }

        this.marketList.forEach(market => {
          market.tradeCost = this.numberToLang(market.tradeCostFull)
        })

        if (this.$root.$i18n.locale !== 'ko') {
          this.codeLocale = '_' + this.$root.$i18n.locale
        } else {
          this.codeLocale = ''
        }

        this.getNoticeList(1)
      }
    },

    'buyType': {
      handler() {
        this.setDealInit()
      }
    },

    'sellType': {
      handler() {
        this.setDealInit()
      }
    },

  },
  filters: {
    coinImg: (val) => {
      if (val.indexOf('비트코인') !== -1 || val.toLowerCase().indexOf('bitcoin') !== -1
          || val.toLowerCase().indexOf('btc') !== -1) {
        return '/img/btc_coin.png'
      } else if (val.indexOf('이더리움') !== -1 || val.toLowerCase().indexOf('ethereum') !== -1
          || val.toLowerCase().indexOf('eth') !== -1 || val.toLowerCase().indexOf('etc') !== -1) {
        return '/img/eth_coin.png'
      } else if (val.indexOf('클레이튼') !== -1 || val.toLowerCase().indexOf('klay') !== -1) {
        return '/img/klay_coin.png'
      } else if (val.indexOf('트론') !== -1 || val.toLowerCase().indexOf('tron') !== -1
          || val.toLowerCase().indexOf('trx') !== -1) {
        return '/img/trx_coin.png'
      } else if (val.indexOf('위키') !== -1 || val.toLowerCase().indexOf('wiki') !== -1
          || val.toLowerCase().indexOf('wiki') !== -1) {
        return '/img/wiki_coin.png'
      } else {
        return '/img/ubit_coin.svg'
      }
    }
  }
}
</script>

<style scoped>
.width-auto {
  width: auto;
  min-width: 200px;
}
</style>
