<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="sub_container">
        <OtcHistoryMenu ref="OtcHistoryMenu" />
        <p class="c5_wrap">
          <span class="c5_title">{{ $t('message.myOfferTrades') }}<span>[ {{ totalItem }} ]</span></span>
        </p>
        <div class="scroll">
          <table class="table_i">
            <tbody>
            <colgroup>
              <col style="width: 15%;">
              <col style="width: auto;">
              <col style="width: auto;">
              <col style="width: 17%;">
              <col style="width: 15%;">
              <col style="width: 15%;">
            </colgroup>
            <tr>
              <th>{{ $t('message.nickname') }}</th>
              <th>{{ $t('message.tradeAmt') }}</th>
<!--              <th>{{ $t('message.totalTradePrice') }}</th>-->
              <th>{{ $t('message.tradeTimeLeft') }}</th>
              <th>{{ $t('message.state') }}</th>
              <th>{{ $t('message.detail') }}</th>
            </tr>
            <tr v-for="trade in tradeList" :key="trade.tradeIdx">
              <td class="t_center" :key="rerenderKey">{{ trade.nickname }}</td>
              <td class="t_center">{{ trade.amount }} {{ trade.marketPair.split('-')[0] }}</td>
<!--              <td class="t_center">{{ trade.totalPrice | numberWithCommas }} {{ trade.marketPair.split('-')[1] }}</td>-->
              <td class="t_center dblue_01 fwb">{{ trade.otcTradeState === 'ACCEPT_READY' ? timeDesc : '' }}</td>
              <td class="t_center">{{ getOtcState(trade.otcTradeState) }}</td>
              <td class="t_center outline_btn2">
                <span class="blue_f" @click="$router.push({ name: 'OtcProcess', params: { tradeIdx: trade.tradeIdx }})">{{ $t('message.viewDetail') }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 내 문의영역/ 테이블 하단 버튼 페이징-->
        <div class="buttonwrap t_center">
          <paginate
            :page-count="pageLast"
            :page-range="3"
            :margin-pages="2"
            :click-handler="getTradeList"
            :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :first-last-button=true
            :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import OtcHistoryMenu from '@/components/trade_history/history_menu/otc_menu_pc'
import moment from "moment";

export default {
  name: "OtcDetailList",

  components: {
    'OtcHistoryMenu': OtcHistoryMenu
  },

  data() {
    return {
      tradeList: [],
      pageLast: 0,
      timeLimit: 0,
      offerIdx: this.$route.params.offerIdx,
      rerenderKey: 0,
      totalItem: 0,
    }
  },

  computed: {
    timeDesc: function() {
      let timeFormat = ''
      if(this.timeLimit) (
          timeFormat = this.timeLimit.format('mm:ss')
      )
      return timeFormat
    },
  },

  created() {
    if(this.offerIdx) {
      sessionStorage.setItem('offerIdx', this.offerIdx)
    } else {
      this.offerIdx = sessionStorage.getItem('offerIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('offerIdx')
  },

  mounted() {
    this.getTradeList(1)
  },

  methods: {
    getTradeList(page) {
      let formData = new FormData()
      let limit = 10

      formData.append('offerIdx', this.offerIdx)
      formData.append('limit', limit)
      formData.append('page', page)

      this.$post(this.$OTC_TRADE_LIST, 'HistoryOtcSellOfferPostList', formData, true, (result) => {
        this.tradeList = result.data
        this.totalItem = result.total
        this.pageLast = Math.ceil(result.total / limit)

        this.tradeList.forEach((trade) => {
          this.getNickname((result) => {
            if(result.success) {
              trade.nickname = result.nickname
              this.rerenderKey += 1
            } else {
              console.log(result.nickname)
            }
          }, trade.userIdx)

          trade.totalPrice = Number(trade.amount) * Number(trade.priceToGo)

          if(trade.otcTradeState === 'WAITING') {
            let date = trade.createdAt.split('T')[0]
            let time = trade.createdAt.split('T')[1].split('.')[0]

            let dateSplit = date.split('-')
            let timeSplit = time.split(':')
            let milliSec = trade.createdAt.split('T')[1].split('.')[1]
            let newDate = new Date(dateSplit[0], dateSplit[1] - 1 , dateSplit[2], timeSplit[0], timeSplit[1], timeSplit[2], milliSec)
            let timeStamp = newDate.getTime()
            let timeEnd = timeStamp + (15 * 60 * 1000)
            this.timeLimit = moment(timeEnd - Date.now())
            let timeInterval = setInterval(() => {
              this.timeLimit = moment(this.timeLimit.subtract(1, 'seconds'))
              if(this.timeLimit.format('mm:ss') === '00:00') {
                clearInterval(timeInterval)
              }
            }, 1000)
          }
        })
      }, (result) => {
        console.log(result)
      })
    }
  },


}
</script>

<style scoped>

</style>
