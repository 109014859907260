<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="sub_container">
        <OtcSearch></OtcSearch>
        <div class="otc_purchase_wrap">
          <div class="otc_purchase_left" v-show="showDesc">
            <div class="desc_top">
              <h6>{{ $t('message.tradeDesc') }}</h6>
              <div class="desctxt">
                <p class="p1" v-html="$t('message.tradeDesc1')"></p>
                <p class="p2" v-html="$t('message.tradeDesc2')"></p>
                <p class="p3" v-html="$t('message.tradeDesc3')"></p>
              </div>
            </div>
            <div class="desc_bottom">
              <h6>{{ $t('message.note') }}</h6>
              <p>{{ $t('message.noteDesc1') }}</p>
              <p>{{ $t('message.bankAcc') }}: xxxx</p>
              <p>PPS{{ $t('message.acc') }}: xxxxxxx</p>
            </div>
          </div>

          <div class="otc_purchase_left_btn" @click="toggleDesc($event)">
            <span>{{ $t('message.tradeDesc') }} {{ $t('message.open') }}</span>
            <span class="otc_btn_on">{{ $t('message.tradeDesc') }} {{ $t('message.close') }}</span>
          </div>

          <div class="otc_purchase_right">
            <div class="table scroll">
              <div class="mk_fix_h_06">
                <div class="mk_t_header">
                  <table class="basic_mk basic_line">
                    <colgroup>
                      <col style="width: 11%">
                      <col style="width: 11%">
                      <col style="width: 9%">
                      <col style="width: 15%">
                      <col style="width: 11%">
<!--                      <col style="width: auto">-->
                      <col style="width: 11.5%">
                      <col style="width: 11.5%">
                    </colgroup>
                    <thead>
                    <tr>
                      <th rowspan="2" style="border-left : 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;" scope="col">{{ $t('message.tradingNum') }}</th>
                      <th scope="col" style="border-bottom : 1px solid #E0E0E0;">{{ $t('message.postedBy') }}</th>
                      <th rowspan="2" style="border-right : 1px solid #E0E0E0; border-left : 1px solid #E0E0E0;" scope="col">{{ $t('message.coin') }}</th>
                      <th rowspan="2" scope="col" style="border-right : 1px solid #E0E0E0; border-bottom : 1px solid #E0E0E0;">{{ $t('message.tradeAmt') }}</th>
                      <th rowspan="2" scope="col">{{ $t('message.fees') }}<br>({{ trade.feeRate }}%)</th>
<!--                      <th rowspan="2" style="border-left : 1px solid #E0E0E0;" scope="col">{{ $t('message.totalTradePrice') }}</th>-->
                      <th rowspan="2" style="border-right : 1px solid #E0E0E0; border-left : 1px solid #E0E0E0;" scope="col">{{ $t('message.tradeTime') }}</th>
                      <th rowspan="2" scope="col" style="border-right : 1px solid #E0E0E0;">{{ $t('message.state') }}</th>
                    </tr>
                    <tr>
                      <th scope="col">{{ $t('message.tradedBy') }}</th>
<!--                      <th scope="col" style="border-right : 1px solid #E0E0E0;">{{ $t('message.tradeUnitPrice') }}</th>-->
                    </tr>
                    </thead>
                  </table>
                </div>
                <!-- 리스트 -->
                <div class="mk_t_content mk_fix_h_07 otc_t_desc">
                  <table class="basic_mk_con basic_line">
                    <colgroup>
                      <col style="width: 11%">
                      <col style="width: 11%">
                      <col style="width: 9%">
                      <col style="width: 15%">
                      <col style="width: 11%">
<!--                      <col style="width: auto">-->
                      <col style="width: 11.5%">
                      <col style="width: 11.5%">
                    </colgroup>
                    <tbody>
                    <tr>
                      <td rowspan="2" style="border-bottom : 1px solid #E0E0E0; border-right : 1px solid #E0E0E0; border-left : 1px solid #E0E0E0;" class="t_center">{{ tradeIdx }}</td>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ offerNickname }}</td>
                      <td rowspan="2" style="border-bottom : 1px solid #E0E0E0; border-right : 1px solid #E0E0E0; border-left : 1px solid #E0E0E0;" class="t_center">{{ trade.marketPair.split('-')[0] }}</td>
                      <td rowspan="2" style="border-bottom : 1px solid #E0E0E0;" class="t_center">{{ trade.amount }} {{ trade.marketPair.split('-')[0] }}</td>
                      <td rowspan="2" class="t_center" style="border-bottom : 1px solid #E0E0E0; border-left : 1px solid #E0E0E0; border-right: 1px solid rgb(224, 224, 224);">{{ subDec(trade.amount * (trade.feeRate / 100), trade.marketPair.split('-')[0]) }} {{ trade.marketPair.split('-')[0] }}</td>
<!--                      <td rowspan="2" style="border-bottom : 1px solid #E0E0E0; border-left : 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;" class="t_center">{{ subDec(trade.totalPrice, trade.marketPair.split('-')[1]) | numberWithCommas }} {{ trade.marketPair.split('-')[1] }}</td>-->
                      <td rowspan="2" class="t_center" style="border-bottom : 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;">{{ trade.createdData }}</td>
                      <td rowspan="2" style="border-bottom : 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;" class="t_center">
                        {{ trade.otcTradeState === 'WAITING' ?
                          $t('message.waiting')
                          : trade.otcTradeState === 'DEPOSITED' ?
                          $t('message.deposited')
                          : trade.otcTradeState === 'TRADED' ?
                          $t('message.traded')
                          : trade.otcTradeState === 'TIMEOUT' ?
                          $t('message.timeout')
                          :  $t('message.timeout') }}
                        <br>
                        <span class="price_red" v-show="timeDesc !== '00:00' && trade.otcTradeState === 'WAITING'">{{ timeDesc }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ myNickname }}</td>
<!--                      <td class="t_center" style="border-bottom : 1px solid #E0E0E0;">{{ trade.priceToGo | numberWithCommas }} {{ trade.marketPair.split('-')[1] }}</td>-->
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
<!--            <div class="otc_payment_con" v-if="payDetail && payDetail.state">-->
<!--              <div class="pay_method">-->
<!--                <img :src="trade.otcPayType === 'aliPay' ?-->
<!--                          '/img/Icon_alipay.svg'-->
<!--                          : trade.otcPayType === 'bankTrans' ?-->
<!--                          '/img/Icon_bank.svg'-->
<!--                          : trade.otcPayType === 'ppsBank' ?-->
<!--                          '/img/Icon_pps.svg'-->
<!--                          : '/img/Icon_wechat.svg'"-->
<!--                     alt="거래방법">-->
<!--                <span>{{ $t(`message.${trade.otcPayType}`) }}</span>-->
<!--              </div>-->
<!--              <div class="pay_desc" v-if="trade.otcPayType === 'aliPay'">-->
<!--                <p><span>Alipay ID</span><span>{{ payDetail.alipayId }}</span></p>-->
<!--                <p><span>{{ $t('message.holder') }}</span><span>{{ payDetail.holder }}</span></p>-->
<!--              </div>-->
<!--              <div class="pay_desc" v-else-if="trade.otcPayType === 'bankTrans'">-->
<!--                <p><span>{{ $t('message.accNum') }}</span><span>{{ payDetail.accountNumber }}</span></p>-->
<!--                <p><span>{{ $t('message.holder') }}</span><span>{{ payDetail.holder }}</span></p>-->
<!--                <p><span>{{ $t('message.bankName') }}</span><span>{{ payDetail.bankName }}</span></p>-->
<!--              </div>-->
<!--              <div class="pay_desc" v-else-if="trade.otcPayType === 'ppsBank'">-->
<!--                <p><span>VPL {{ $t('message.accNum') }}</span><span>{{ payDetail.vpl }}</span></p>-->
<!--                <p><span>PPS ID</span><span>{{ payDetail.ppsId }}</span></p>-->
<!--              </div>-->
<!--              <div class="pay_desc" v-else>-->
<!--                <p><span>Wechat ID</span><span>{{ payDetail.wechatId }}</span></p>-->
<!--                <p><span>{{ $t('message.holder') }}</span><span>{{ payDetail.holder }}</span></p>-->
<!--              </div>-->
<!--            </div>-->
            <div class="chatting_con">
              <div class="chat_tit">
                <div>
                  <i class="fas fa-comment"></i><span>{{ $t('message.chat') }}</span>
                </div>
                <div class="right">
                  <div class="img_wrap"><img :src="trade.userImg ? trade.userImg : '/img/profile-none.jpg'" alt="profile" /></div>
                  <span v-show="showChat" @click="toggleArrow"><i class="fas fa-chevron-up"></i></span>
                  <span v-show="!showChat" @click="toggleArrow"><i class="fas fa-chevron-down"></i></span>
                </div>
              </div>
              <div v-show="showChat">
                <div class="warn">
                  <i class="text-danger fas fa-exclamation-triangle price_red fs16"></i>
                  <span class="m_left5">{{ $t('message.safeTrade') }}</span>
                </div>
                <div class="chatting scroll">
                  <div class="msg_con">
                    <p class="fs12">{{ trade.createdData }}</p>
                    <p class="fs16 fwb t_center" v-html="$t('message.escrowed')"></p>
                  </div>
                  <div class="msg_con" v-show="timeDesc === '00:00' && trade.otcTradeState === 'TIMEOUT'">
                    <p class="fs12">{{ trade.timeEnd }}</p>
                    <p class="fs16 fwb">{{ $t('message.tradeTimeout') }}</p>
                  </div>
                  <div class="msg_balls">
                    <div class="msg_sent" v-for="(msg, msgIdx) in msgAll" :key="msgIdx" :class="{ flex_end: msg.userIdx === myIdx, flex_start: msg.userIdx !== myIdx}">
                      <p class="msg" v-show="msg.comment">{{ msg.comment }}</p>
                      <img v-show="msg.fileHash" :src="msg.fileLink" :alt="msg.filename" :key="rerenderkey" />
                      <div v-show="msg.fileHash"></div>
                      <p class="fs12" :class="{ time_r: msg.userIdx === myIdx, time_l: msg.userIdx !== myIdx}">{{ formatDate(msg.timestamp * 1000, 'full') }}</p>
                    </div>
                  </div>
                </div>
                <div class="msg_tit">
                  <i class="fas fa-comment-dots"></i>
                  <span class="m_left5">{{ $t('message.msg') }}</span>
                </div>
                <div class="txt_con">
                  <textarea :placeholder="$t('message.enterMsg')" v-model="msg" @keyup.enter="sendTxt"></textarea>
                </div>
                <div class="upload_con" :class="{flex_col: fileName}">
                  <div class="left">
                    <div class="upload_btn">
                      <input type="file" class="custom-input" @change="upload($event)" accept="*">
                      <i class="fas fa-paperclip"></i>
                      <a class="m_left5">{{ $t('message.fileAttach') }}</a>
                    </div>
                    <div v-show="fileName" class="input_con">
                      <input type="text" class="input_file" v-model="fileName" disabled>
                      <span @click="deleteFile"><i class="fas fa-times"></i></span>
                    </div>
                  </div>
                  <div class="middle_btn1" @click="sendMsg"><a>{{ $t('message.send') }}</a></div>
                </div>
              </div>

            </div>
            <div class="state_con">
              <span class="refresh" v-show="trade.otcTradeState === 'WAITING' || trade.otcTradeState === 'DEPOSITED'">
                <i class="fas fa-redo-alt"></i>
                <span class="m_left5" @click="getTradeInfo(true)">{{ $t('message.refresh') }}</span>
              </span>
              <div class="time_con" v-show="trade.otcTradeState === 'WAITING'">
                <div class="time_tick">
                  <p>{{ $t('message.otcTime') }} <span class="price_red fwb">{{ timeDesc }}</span> {{ $t('message.timeLeft') }}</p>
                  <p>{{ $t('message.depositInTime') }}</p>
                </div>
              </div>
              <div class="state_msg">
                <i class="fas fa-info-circle price_red"></i>
                <span class="m_left5 price_red" v-show="trade.otcTradeState === 'WAITING'">{{ $t('message.waitDeposit') }}</span>
                <span class="m_left5 price_red" v-show="trade.otcTradeState === 'TIMEOUT' || trade.otcTradeState === 'CANCEL'">{{ $t('message.timeoutTrade') }}</span>
                <span class="m_left5 price_red" v-show="trade.otcTradeState === 'DEPOSITED'">{{ $t('message.depositedTrade') }}</span>
                <span class="m_left5 price_red" v-show="trade.otcTradeState === 'TRADED'">{{ $t('message.tradeSuccess') }}</span>
              </div>
            </div>
            <div class="btn_con_grid outline_btn2" v-show="trade.isBuy === true && trade.otcTradeState === 'WAITING'">
              <span class="t_center" @click="payDone">{{ $t('message.deposited') }}</span>
              <span class="t_center red" @click="cancelTrade">{{ $t('message.tradeCancel') }}</span>
              <span class="t_center red_f" @click="extendTime">{{ $t('message.tradeExtended') }}</span>
              <span class="t_center blue_f" @click="goToList">{{ $t('message.seeTradeHis') }}</span>
            </div>
            <div class="btn_con_grid outline_btn2" v-show="trade.isBuy === false && trade.otcTradeState === 'DEPOSITED'">
              <span class="t_center red_f" @click="sendCoin">{{ $t('message.sendCoin') }}</span>
              <span class="t_center blue_f" @click="goToList">{{ $t('message.seeTradeHis') }}</span>
            </div>
            <div class="btn_con" v-show="trade.otcTradeState === 'TRADED' || trade.otcTradeState === 'TIMEOUT' || (trade.isBuy === false && timeDesc !== '00:00')">
              <button class="btn_100 blue_f" @click="goToList">{{ $t('message.seeTradeHis') }}</button>
            </div>
            <div class="eval_con" v-show="showEval">
              <div class="eval_tit">
                <p class="fwb">{{ $t('message.starTrade') }}</p>
              </div>
              <div class="eval_chk">
                <span>
                  <label class="label_checkradio">
                  <input type="radio" value="1" v-model="evaluation" @change="changeEval($event)">
                  <span class="radio_icon"></span>
                  <span class="radio_text dblue_01"><i class="fas fa-grin"></i><span class="m_left5">{{ $t('message.veryGood') }}</span></span>
                  </label>
                  <label class="label_checkradio">
                    <input type="radio" value="2" v-model="evaluation" @change="changeEval($event)">
                    <span class="radio_icon"></span>
                    <span class="radio_text"><i class="fas fa-smile"></i><span class="m_left5">{{ $t('message.good') }}</span></span>
                  </label>
                </span>
                <span>
                  <label class="label_checkradio">
                  <input type="radio" value="3" v-model="evaluation" @change="changeEval($event)">
                  <span class="radio_icon"></span>
                  <span class="radio_text"><i class="fas fa-frown"></i><span class="m_left5">{{ $t('message.worse') }}</span></span>
                </label>
                <label class="label_checkradio">
                  <input type="radio" value="4" v-model="evaluation" @change="changeEval($event)">
                  <span class="radio_icon"></span>
                  <span class="radio_text"><i class="fas fa-angry"></i><span class="m_left5">{{ $t('message.worst') }}</span></span>
                </label>
                </span>
              </div>
              <div class="eval_txt">
                <textarea :placeholder="$t('message.enterEval')" v-model="evalTxt"></textarea>
                <div class="outline_btn2"><span @click="evelConfirm">{{ $t('message.confirm') }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcSearch from "./cmp/otc_search"
import moment from "moment"

export default {
  name: "OtcProcess",

  components: {
    'OtcSearch': OtcSearch
  },

  data() {
    return {
      evaluation: '1',
      timeLimit: 0,
      tradeIdx: this.$route.params.tradeIdx,
      showChat: true,
      msg: '',
      fileName: '',
      imgFile: null,
      evalTxt: '',
      trade: {
        amount: 0,
        otcPayType: '',
        nickname: '',
        otcTradeState: '',
        marketPair: '',
        priceToGo: 0,
        totalPrice: 0,
        userIdx: 0,
        createdData: '',
        feeRate: '',
        offerIdx: '',
        tradeIdx: '',
        isBuy: true,
        userImg: '',
      },
      payDetail: {},
      myNickname: '',
      offerNickname: '',
      myIdx: 0,
      showEval: true,
      chatInterval: null,
      lastTimestamp: 0,
      msgAll: [],
      tmpMsg: [],
      rerenderkey: 0,
      showDesc: true,
      extended: false,
    }
  },

  computed: {
    timeDesc: function() {
      let timeFormat = ''
      if(this.timeLimit) (
          timeFormat = this.timeLimit.format('mm:ss')
      )
      return timeFormat
    },

    fileChanged: {
      get: function() {
        return this.fileName
      },
      set: function(newValue) {
        this.fileName = newValue
      }
    },
  },

  created() {
    this.getUserProfile((result) => {
      if(result.success) {
        this.myIdx = result.userProfile.userIdx
      } else {
        console.log(result.userProfile)
      }
    })

    this.chatInterval = setInterval(() => {
      this.getChatHistory()
    }, 60 * 1000)
  },

  beforeDestroy() {
    clearInterval(this.chatInterval)
  },

  mounted() {
    if(this.tradeIdx) {
      sessionStorage.setItem('otcTradeIdx', this.tradeIdx)
    } else {
      this.tradeIdx = sessionStorage.getItem('otcTradeIdx')
    }

    this.$get(`${this.$OTC_TRADE_PAY}?tradeIdx=${this.tradeIdx}`, 'OtcProcessGetTradePay', true, (result) => {
      this.payDetail = result.data[0]
    }, (result) => {
      console.log(result)
    })

    this.getTradeInfo()
    this.getChatHistory()
  },

  methods: {
    deleteFile() {
      this.imgFile = []
      this.fileChanged = ''
    },

    toggleDesc(e) {
      e.currentTarget.querySelectorAll('span').forEach((ele, idx) => {
        if(ele.classList.contains('otc_btn_on')) {
          ele.classList.remove('otc_btn_on')
          if(idx === 0) {
            this.showDesc = true
          }
        } else {
          ele.classList.add('otc_btn_on')
          if(idx === 0) {
            this.showDesc = false
          }
        }
      })
    },

    sendCoin() {
      let formData = new FormData()
      formData.append('tradeIdx', this.tradeIdx)

      this.$post(this.$OTC_TRADE_TRANSFER, 'OtcProcessTransfer', formData, true, (result) => {
        this.notify('success', result.message)
        this.getTradeInfo()
      }, (result) => {
        console.log(result)
      })
    },

    modifyChatData(msgList) {
      this.tmpMsg = [...this.tmpMsg, ...msgList]
      this.msgAll = [...this.tmpMsg]

      this.msgAll.forEach((chat, idx) => {
        if(chat.fileHash) {
          if(!chat.fileLink) {
            this.$download(`${this.$OTC_TRADE_CHAT_DOWNLOAD}?fileHash=${chat.fileHash}&tradeIdx=${this.tradeIdx}`, 'ChatDownLoad_' + Math.random(), true, (result) => {
              chat.fileLink = this.$fileDownload(result)[0]
              chat.filename = this.$fileDownload(result)[1]
              if(!document.getElementsByClassName('msg_sent')[idx].querySelector('div').hasChildNodes()) {
                document.getElementsByClassName('msg_sent')[idx].querySelector('div').appendChild(chat.fileLink)
              }
              this.rerenderkey += 1
            }, (result) => {
              this.notify('error', result.message)
            })
          }
        }
      })

      this.msgAll.sort(function(a, b) {
        return a.timestamp - b.timestamp
      })


      this.lastTimestamp = this.msgAll[this.msgAll.length - 1].timestamp
    },

    getChatHistory() {
      this.$get(`${this.$OTC_TRADE_CHAT}?tradeIdx=${this.tradeIdx}&timestamp=${this.lastTimestamp+1}`, 'OtpProcessTradeChat', true, (result) => {
        let msgList = result.data
        if(msgList.length) {
          this.modifyChatData(msgList)
        }
      }, (result) => {
        console.log(result)
      })
    },

    getTradeInfo(isRefresh = false) {
      this.$get(`${this.$OTC_TRADE}?tradeIdx=${this.tradeIdx}`, 'OtcProcessGetTrade', true, (result) => {
        this.trade = result.data[0]

        this.extended = this.trade.extended

        let isCommented
        if(this.trade.userIdxOffer === this.myIdx) {
          this.trade.isBuy = !this.trade.isBuy
          isCommented = this.trade.isOfferCommented
        } else {
          isCommented = this.trade.isTraderCommented
        }

        if(this.trade.otcTradeState === 'TRADED') {
          this.showEval = !isCommented
        }

        this.trade.totalPrice = (this.trade.amount + (this.trade.amount * (this.trade.feeRate / 100))) * this.trade.priceToGo

        let date = this.trade.createdAt.split('T')[0]
        let time = this.trade.createdAt.split('T')[1].split('.')[0]
        this.trade.createdData = `${date} ${time}`

        this.$get(`${this.$USER_MYPAGE_PROFILE_IMAGE_USERS}?userIds=${this.myIdx}`, 'GetUserImgByIdx', true, (result) => {
          if(result.data.length) {
            this.trade.userImg = result.data[0].profileImage
          }
        }, (result) => {
          console.log(result)
        })

        if(this.trade.otcPayType === 'ALIPAY') {
          this.trade.otcPayType = 'aliPay'
        } else if (this.trade.otcPayType === 'BANK') {
          this.trade.otcPayType = 'bankTrans'
        } else if (this.trade.otcPayType === 'PPS') {
          this.trade.otcPayType = 'ppsBank'
        } else {
          this.trade.otcPayType = 'wechatPay'
        }

        if(!isRefresh) {
          let dateSplit = date.split('-')
          let timeSplit = time.split(':')
          let milliSec = this.trade.createdAt.split('T')[1].split('.')[1]
          let newDate = new Date(dateSplit[0], dateSplit[1], dateSplit[2], timeSplit[0], timeSplit[1], timeSplit[2], milliSec)
          let timeStamp = newDate.getTime()
          this.trade.timeEnd = this.formatDate(timeStamp + (15 * 60 * 1000), 'full')

          if(this.trade.otcTradeState === 'WAITING') {
            this.timeLimit = moment(timeStamp + (15 * 60 * 1000) - Date.now())
            let timeInterval = setInterval(() => {
              this.timeLimit = moment(this.timeLimit.subtract(1, 'seconds'))
              if(this.timeLimit.format('mm:ss') === '00:00') {
                clearInterval(timeInterval)
                this.getTradeInfo(true)
              }
            }, 1000)
          }

          this.getNickname((result) => {
            if(result.success) {
              this.offerNickname = result.nickname

            } else {
              console.log(result.nickname)
            }
          }, this.trade.userIdxOffer)

          this.getNickname((result) => {
            if(result.success) {
              this.myNickname = result.nickname
              console.log(this.myNickname)
            } else {
              console.log(result.nickname)
            }
          }, this.trade.userIdx)
        }
      }, (result) => {
        console.log(result)
      })
    },

    goToList() {
      // if(this.trade.isBuy) {
      //   this.$router.replace({ name: 'TradeHistoryOtcBuyState' })
      //   localStorage.removeItem('otcTradeIdx')
      // } else {
      this.$router.replace({ name: 'TradeHistoryOtcSellState' })
      sessionStorage.removeItem('otcTradeIdx')
      // }
    },

    extendTime() {
      if(this.extended) {
        this.notify('error', this.$t('message.timeExtended'))
        return
      }

      let formData = new FormData()
      formData.append('tradeIdx', this.tradeIdx)

      this.$post(this.$OTC_TRADE_EXTEND, 'OtcProcessPostTradeExtend', formData, true, (result) => {
        this.getTradeInfo()
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    cancelTrade() {
      let formData = new FormData()
      formData.append('tradeIdx', this.tradeIdx)

      this.$post(this.$OTC_TRADE_CANCEL, 'OtcProcessPostTradeCancel', formData, true, (result) => {
        console.log(result)
        this.getTradeInfo()
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    payDone() {
      let formData = new FormData()
      formData.append('tradeIdx', this.tradeIdx)

      this.$post(this.$OTC_TRADE_DEPOSIT, 'OtcProcessPostTradeDeposit', formData, true, (result) => {
        console.log(result)
        this.getTradeInfo()
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    evelConfirm() {
      if(this.trade.otcTradeState !== 'TRADED') {
        this.notify('error', this.$t('message.writeAfterEval'))
        return
      }

      if(this.trade.otcTradeState === 'TRADED') {
        let formData = new FormData()
        formData.append('tradeIdx', this.tradeIdx)
        formData.append('score', Number(this.evaluation))
        formData.append('comment', this.evalTxt)

        this.$post(this.$OTC_TRADE_COMMENT, 'OtcProcessPostEval', formData, true, (result) => {
          this.evalTxt = ''
          this.evaluation = '1'
          this.notify('success', result.message)
          this.showEval = false
        }, (result) => {
          console.log(result)
          this.notify('error', result.message)
        })
      }
    },

    changeEval(e) {
      document.querySelector('.eval_chk').querySelectorAll('.radio_text').forEach((ele) => {
        ele.classList.remove('dblue_01')
      })
      e.currentTarget.parentNode.querySelector('.radio_text').classList.add('dblue_01')
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.fileChanged = file[0].name
      this.imgFile = [...file]
    },

    sendMsg() {
      this.sendTxt()
      this.sendImg()
    },

    sendImg() {
      if(!this.imgFile || this.imgFile.length === 0) {
        return
      }

      let formData = new FormData()
      formData.append('tradeIdx', this.tradeIdx)
      formData.append('multipartFile', this.imgFile[0])

      this.$post(this.$OTC_TRADE_CHAT_UPLOAD, 'OtcProcessPostTradeChatUpload', formData, true, () => {
        this.imgFile = []
        this.fileChanged = ''
        this.getChatHistory()
      }, (result) => {
        console.log(result)
      })
    },

    sendTxt() {
      if(this.msg.trim() === '') {
        this.msg = ''
        return
      }

      let formData = new FormData()
      formData.append('tradeIdx', this.tradeIdx)
      formData.append('score', '')
      formData.append('comment', this.msg)

      this.$post(this.$OTC_TRADE_CHAT, 'OtcProcessPostChat', formData, true, (result) => {
        console.log(result)
        this.getChatHistory()
        this.msg = ''
      }, (result) => {
        console.log(result)
      })
    },

    toggleArrow() {
      this.showChat = !this.showChat
    }
  },

  watch: {
    'msgAll': {
      handler() {
        this.$nextTick(function () {
          let scrollBox = document.querySelector('.scroll')
          scrollBox.scrollTop = scrollBox.scrollHeight
        })
      },
    },
  }
}
</script>

<style scoped>

</style>
