<template>
  <div class="body_wrap">
    <MyPageMenuMobile ref="MyPageMenuMobile"></MyPageMenuMobile>
    <div class="pagew">
      <div class="subcontainer1">
        <MyPageMenuPC ref="MyPageMenuPC"></MyPageMenuPC>
        <ul class="c5_wrap">
          <li class="c5_title">{{ $t('message.recentActivity') }}</li>
        </ul>
        <div class="mk_t_content scroll">
          <table class="table_i">
            <colgroup>
              <col style="width: 33%;">
              <col style="width: 33%;">
              <col style="width: auto;">
<!--              <col style="width: auto;">-->
            </colgroup>
            <thead>
              <tr class="columns">
                <th>{{ $t('message.date') }}</th>
                <th>{{ $t('message.action') }}</th>
                <th>IP</th>
<!--                <th>{{ $t('message.moreInfo') }}</th>-->
              </tr>
            </thead>
            <tbody>
            <tr v-for="(activity, idx) in activityList" :key="idx">
              <td class="t_center">{{ activity.date }}</td>
              <td class="t_center">{{ activity.browser }}</td>
              <td class="t_center">{{ activity.address }}</td>
<!--              <td class="t_center outline_btn2"><span><a href="https://xn&#45;&#45;c79as89aj0e29b77z.xn&#45;&#45;3e0b707e/kor/whois/whois.jsp" target="_blank" style="color: #1E6AD0">WHOIS {{ $t('message.lookup') }}</a></span></td>-->
            </tr>
            </tbody>
          </table>
        </div>
        <div class="buttonwrap t_center" v-show="activityList.length !== 0">
          <paginate v-show="activityList.length"
            :page-count="pageLast"
            :page-range="3"
            :margin-pages="2"
            :click-handler="getActList"
            :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :first-last-button=true
            :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPageMenuPC from './my_page_menu/menu_pc'
import MyPageMenuMobile from './my_page_menu/menu_mobile'

export default {
  name: "RecentActivity",
  components: {
    'MyPageMenuPC': MyPageMenuPC,
    'MyPageMenuMobile': MyPageMenuMobile
  },

  data() {
    return {
      activityList: [],
      pageLast: 0,
    }
  },

  mounted() {
    this.$refs.MyPageMenuPC.focusTab(3)
    this.$refs.MyPageMenuMobile.selectMenu(this.$t('message.recentActivity'))

    this.getActList(1)
  },

  methods: {
    getActList(page) {
      let limit = 10
      this.$get(`${this.$USER_MYPAGE_LOGIN_LOG}?page=${page}&limit=${limit}`, 'myPageRecentActivity', true, (result) => {
        this.activityList = result.data

        this.activityList.map(act => {
          act.date = this.formatDate(act.createdAt, 'full')
        })

        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
