<template>
  <div class="body_wrap">
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container otc_list_progress">
        <OtcMenuPC ref="OtcMenuPC"></OtcMenuPC>
        <div class="wkx_content mt">
          <div class="wkx_right w100">
            <div class="checks otc_offer_ckh">
              <p class="title">{{ $t('message.otcChat') }}</p>
            </div>
            <div class="scroll">
              <table class="table_i">
                <colgroup>
                  <col style="width: 13%;">
                  <col style="width: auto;">
                  <col style="width: 55%;">
                  <col style="width: 15%;">
                </colgroup>
                <thead>
                <tr class="columns">
                  <th scope="col">{{ $t('message.sort') }}</th>
                  <th scope="col">{{ $t('message.date') }}</th>
                  <th scope="col">{{ $t('message.content') }}</th>
                  <th scope="col">{{ $t('message.oppoNick') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(chat, idx) in chatList" :key="idx" @click="goToChatroom(chat)" class="point">
                  <td scope="row" class="t_center">{{ !chat.coin.includes('UNLISTED') ? chat.coin : chat.coin.split('-')[1] + '(' + $t('message.directTran') + ')' }}
                    <span :class="{ 'price_red': chat.boardIsBuy, 'price_blue': !chat.boardIsBuy }">{{ chat.boardIsBuy ? $t('message.doBuy') : $t('message.doSell') }}</span>
                  </td>
                  <td scope="row" class="t_center">{{ chat.createdAt }}</td>
                  <td scope="row" class="t_center">{{ chat.content !== 'file' ? chat.content : $t('message.fileAttach') }}</td>
                  <td scope="row" class="t_center">{{ chat.oppositeNickname }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="no_list mt20" v-show="!chatList.length">{{ $t('message.noData') }}</p>
            <div class="buttonwrap t_center">
              <paginate
                  :page-count="pageLast"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="getList"
                  :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :first-last-button=true
                  :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :container-class="'pagination'">
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcMenuPC from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_pc'
import OtcMenuMobile from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_mobile'

export default {
  name: "OtcSuggestionChatList",

  data() {
    return {
      pageLast: 0,
      chatList: [],
      limit: 10,
    }
  },

  components: {
    'OtcMenuPC': OtcMenuPC,
    'OtcMenuMobile': OtcMenuMobile,
  },

  created() {
    this.getList(1)
  },

  mounted() {
    this.$refs.OtcMenuPC.focusTab(2)
    this.$refs.OtcMenuMobile.selectMenu(this.$t('message.otcChat'))
  },

  methods: {
    goToChatroom(chat) {
      this.$router.push({ name: 'OtcSuggestionChatDetail',
        params: {
          'chatroomIdx': chat.chatroomIdx,
          'post': chat,
          'tabNo': 2,
        }
      })
    },

    getList(page) {
      let formData = new FormData()
      formData.append('limit', this.limit)
      formData.append('page', page)

      this.$post(this.$OTC_TEMP_CHATROOM_LIST, 'otcTempMyListForChat', formData, true, (result) => {
        this.pageLast = Math.ceil(result.total / this.limit)
        this.chatList = result.data

        this.chatList.forEach((chat) => {
          chat.createdAt = this.formatDate(chat.createdAt, 'full')
        })
      }, (result) => {
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>

</style>
