<template>
  <div class="body_wrap">
    <WalletMenuMobile ref="WalletMenuMobile"></WalletMenuMobile>
    <Modal ref="Modal">
      <div>
        <dl class="wallet_form">
          <dt class="wallet_form_tit lh50">TXID</dt>
          <dd class="wallet_form_text t_left dblue_02 lh50">{{ transfer.txid === 'safe-withdraw-pending' ? $t('message.waitForSafeWith') : transfer.txid }}</dd>
        </dl>
        <dl class="wallet_form">
          <dt class="wallet_form_tit lh50">{{ $t('message.curr') }}</dt>
          <dd class="wallet_form_text t_left lh50">{{ transfer.tickerName.charAt(0).toUpperCase() + transfer.tickerName.slice(1) }}</dd>
        </dl>
      </div>
      <div>
        <h6 class="modal_sub_tit left price_blue">From</h6>
<!--        <dl class="wallet_form">
          <dt class="wallet_form_tit lh50">ID</dt>
          <dd class="wallet_form_text t_left lh50">{{ transfer.type === $t('message.exWithdrawal') ? userEmail : $t('message.exWallet') }}</dd>
        </dl>-->
        <dl class="wallet_form">
          <dt class="wallet_form_tit lh50">ID</dt>
          <dd class="wallet_form_text t_left lh50">{{  transfer.tx_type.indexOf('IN') >= 0 ? id : userEmail }}</dd>
        </dl>
        <dl class="wallet_form">
          <dt class="wallet_form_tit lh50">{{ $t('message.quantity') }}</dt>
          <dd class="wallet_form_text t_left lh50">{{ toFloat(transfer.amount) }} {{ transfer.tickerName }}</dd>
        </dl>
      </div>
      <div>
        <h6 class="modal_sub_tit left price_blue">To</h6>
        <dl class="wallet_form">
          <dt class="wallet_form_tit lh50">ID</dt>
          <dd class="wallet_form_text t_left lh50">{{ transfer.tx_type.indexOf('OUT') >= 0 ? id : userEmail }}</dd>
        </dl>
        <dl class="wallet_form">
          <dt class="wallet_form_tit lh50">{{ $t('message.quantity') }}</dt>
          <dd class="wallet_form_text t_left lh50">{{ Number(transfer.amount) - Number(transfer.feeAmount) }} {{ transfer.tickerName }}</dd>
        </dl>
        <dl class="wallet_form">
          <dt class="wallet_form_tit lh50">{{ $t('message.fees') }}</dt>
          <dd class="wallet_form_text t_left lh50">{{ toFloat(transfer.feeAmount) }} {{ transfer.tickerName }}</dd>
        </dl>
      </div>
    </Modal>
    <div class="pagew">
      <div class="sub_container">
        <WalletMenuPC ref="WalletMenuPC"></WalletMenuPC>
        <div class="wallet_tit mt50" style="flex-direction: row; align-items: center">
          <h4 class="sub_tit">{{ $t('message.statements') }}</h4>
        </div>
        <div class="wallet_content1 mk_t_content">
          <table class="basic">
            <colgroup>
              <col style="width: 13%;">
              <col style="width: 24%;">
              <col style="width: 24%;">
              <col style="width: 24%;">
              <col style="width: 15%;">
              <col>
            </colgroup>
            <thead>
            <tr>
              <th>{{ $t('message.sort') }}</th>
              <th>{{ $t('message.quantity') }}</th>
              <th>{{ $t('message.fees') }}</th>
              <th>{{ $t('message.time') }}</th>
              <th>{{ $t('message.moreInfo') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(transfer, idx) in transferList" :key="idx">
              <td class="t_center" :class="{ price_blue: transfer.type.slice(-2) === $t('message.withdrawal'), price_red: transfer.type.slice(-2) === $t('message.deposit') }">{{ transfer.type }}</td>
              <td class="t_center">{{ toFloat(transfer.amount) }} {{ fullnameToTicker(transfer.tickerName) }}</td>
              <td class="t_center">{{ toFloat(transfer.feeAmount) }} {{ fullnameToTicker(transfer.tickerName) }}</td>
              <td class="t_center">{{ transfer.date }}</td>
              <td>
                <a class="outline_s_btn" v-show="transfer.tx_type === 'TX_IN' || transfer.tx_type === 'TX_OUT' || transfer.tx_type === 'HOUSE_IN' || transfer.tx_type === 'HOUSE_OUT'" @click="openDetail(transfer)">{{ $t('message.blockChain') }}</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p class="no_list" v-show="!transferList.length">{{ $t('message.noData') }}</p>
        <div class="buttonwrap t_center" v-show="transferList.length">
          <paginate
              :page-count="pageLast"
              :page-range="3"
              :margin-pages="2"
              :click-handler="getTransferList"
              :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :first-last-button=true
              :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '../cmp/modal/modal'
import WalletMenuPC from "@/components/asset_management/wallet_menu/wallet_menu_pc"
import WalletMenuMobile from "@/components/asset_management/wallet_menu/wallet_menu_mobile"

export default {
  name: "AssetManagementWalletHistory",

  components: {
    'Modal': Modal,
    'WalletMenuPC': WalletMenuPC,
    'WalletMenuMobile': WalletMenuMobile
  },

  data() {
    return {
      transferList: [],
      transfer: {
        amount: '',
        date: 0,
        feeAmount: '',
        opposite: '',
        tickerName: '',
        txid: '',
        type: '',
        tx_type: '',
        fromSpot: true,
      },
      pageLast: 0,
      transferAmount: 0,
      userEmail: '',
      modalTitle: '',
      id: ''
    }
  },

  created() {
    this.getTransferList(1)

    this.getUserProfile((result) => {
      if(result.success) {
        this.userEmail = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.$refs.WalletMenuPC.focusTab(1)
    this.$refs.WalletMenuMobile.selectMenu(this.$t('message.coinDepWith'))
  },

  methods: {
    openDetail(transfer) {
      this.transfer = transfer
      this.checkModalTitle(this.transfer.tx_type)
      this.checkId(this.transfer.tx_type)
      console.log(JSON.stringify(this.transfer))
      this.$refs.Modal.showDialog(this.modalTitle, '', this.$t('message.close'), () => {}, ()=>{})
    },

    checkId(tx_type) {
      if (tx_type.indexOf('HOUSE') >= 0) {
        this.id = this.$t('message.inWallet')
      } else {
        this.id = this.$t('message.exWallet')
      }
    },

    checkModalTitle(tx_type) {
      if ('TX_IN' === tx_type || 'HOUSE_IN' === tx_type) {
        this.modalTitle = this.$t('message.walletDeposit')
      } else if ('TX_OUT' === tx_type || 'HOUSE_OUT' === tx_type) {
        this.modalTitle = this.$t('message.walletWithdrawal')
      }
    },

    getTransferList(page) {
      this.transferList = []
      let formData = new FormData()
      let limit = 10

      this.$get(`${this.$WALLET_TRANSFER_COIN}?limit=${limit}&page=${page}`, 'AssetWalletTotalTransfer', formData, (result) => {
        this.pageLast = Math.ceil(result.total / limit)
        this.transferList = result.data

        this.transferList.forEach((transfer) => {
          transfer.tx_type = transfer.type;
          transfer.type = this.getTransferType(transfer.type, transfer.txid)
          transfer.date = this.formatDate(transfer.date * 1000, 'full')
        })
      }, (result) => {
        console.log(result)
      })
    },

    getTransferType(type, txid) {
      let transferType = type

      if(type === 'HOUSE_IN') {
        transferType = this.$t('message.inDeposit')
      } else if (type === 'HOUSE_OUT') {
        transferType = this.$t('message.inWithdrawal')
      } else if (type === 'TX_IN') {
        transferType = this.$t('message.exDeposit')
      } else if(type === 'TX_OUT') {
        transferType = this.$t('message.exWithdrawal')
      } else if (type === 'ESCROW_IN') {
        transferType = this.$t('message.escrowIn')
      } else if(type === 'ESCROW_OUT'){
        transferType = this.$t('message.escrowOut')
      } else if(type === 'ADMIN_IN') {
        transferType = this.$t('message.inDeposit')
      } else if(type === 'ADMIN_OUT') {
        if(txid === 'internal') {
          transferType = this.$t('message.inWithdrawal')
        } else {
          transferType = this.$t('message.exWithdrawal')
        }
      }
      return transferType
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.getTransferList(1)
      }
    },
  }
}
</script>

<style scoped>

</style>
