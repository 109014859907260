<template>
  <div class="body_wrap">
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container history_o">
        <OtcHistoryMenu ref="OtcHistoryMenu" />
        <p class="c5_wrap">
          <span class="c5_title">{{ $t('message.buyOrderSum') }}<span>[ {{ totalItem }} ]</span></span>
          <select v-model="tradeState" @change="getBuyList">
            <option value="all">-- {{ $t('message.fullTrade') }} --</option>
            <option value="waiting">{{ $t('message.wait') }}</option>
            <option value="deposited">{{ $t('message.deposited') }}</option>
            <option value="traded">{{ $t('message.completed') }}</option>
            <option value="timeout">{{ $t('message.tradeDone') }}</option>
            <option value="cancel">{{ $t('message.orderCancelled') }}</option>
          </select>
        </p>
        <div class="scroll">
          <table class="table_i row_two">
            <colgroup>
              <col style="width: 9%;">
              <col style="width: 9%;">
              <col style="width: 7%;">
              <col style="width: auto;">
              <col style="width: auto;">
              <col style="width: auto;">
              <col style="width: 9%;">
              <col style="width: auto;">
              <col style="width: 11%;">
            </colgroup>
            <thead>
            <tr>
              <th rowspan="2" style="border-right : 1px solid #E0E0E0;">{{ $t('message.tradingNum') }}</th>
              <th>{{ $t('message.postedBy') }}</th>
              <th rowspan="2" style="border-left: 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;">{{ $t('message.coin') }}</th>
              <th>{{ $t('message.tradeAmt') }}</th>
              <th rowspan="2" style="border-left: 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;">{{ $t('message.fees') }}</th>
              <th rowspan="2">{{ $t('message.totalTradePrice') }}</th>
              <th rowspan="2" style="border-left: 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;">{{ $t('message.tradeTime') }}</th>
              <th rowspan="2">{{ $t('message.state') }}</th>
              <th rowspan="2" style="border-left: 1px solid #E0E0E0">{{ $t('message.detail') }}</th>
            </tr>
            <tr>
              <th>{{ $t('message.tradedBy') }}</th>
              <th>{{ $t('message.tradeUnitPrice') }}</th>
            </tr>
            </thead>
            <tbody v-for="trade in tradeList" :key="trade.tradeIdx">
            <tr>
              <td rowspan="2" class="t_center" style="border-right: 1px solid #E0E0E0">{{ trade.tradeIdx }}</td>
              <td class="t_center" :key="rerenderKey">{{ trade.offerNickname }}</td>
              <td rowspan="2" class="t_center" style="border-left: 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;">{{ trade.marketPair.split('-')[0] }}</td>
              <td class="t_right p_right10">{{ trade.amount }} {{ trade.marketPair.split('-')[0] }}</td>
              <td rowspan="2" class="t_right p_right10" style="border-left: 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;">
                0 {{ trade.marketPair.split('-')[0] }}</td>
              <td rowspan="2" class="t_right p_right10">{{ trade.priceToGo * trade.amount | numberWithCommas }} {{ trade.marketPair.split('-')[1] }}</td>
              <td rowspan="2" class="t_center" style="border-left: 1px solid #E0E0E0; border-right : 1px solid #E0E0E0;">
                {{ trade.createdAt }}</td>
              <td rowspan="2" class="t_center">{{ getOtcState(trade.otcTradeState) }}</td>
              <td rowspan="2" class="t_center outline_btn2" style="border-left: 1px solid #E0E0E0">
                <span class="blue_f" @click="$router.push({ name: 'OtcProcess', params: { tradeIdx: trade.tradeIdx }})">{{ $t('message.viewDetail') }}</span>
              </td>
            </tr>
            <tr>
              <td class="t_center">{{ trade.myNickname }}</td>
              <td class="t_right p_right10">{{ trade.priceToGo | numberWithCommas }} {{ trade.marketPair.split('-')[1] }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <p class="no_list" v-show="!tradeList.length">{{ $t('message.noData') }}</p>
        <paginate v-show="tradeList.length"
            :page-count="pageLast"
            :page-range="3"
            :margin-pages="2"
            :click-handler="getBuyList"
            :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :first-last-button=true
            :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :container-class="'pagination'">
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import OtcHistoryMenu from '@/components/trade_history/history_menu/otc_menu_pc'
import OtcMenuMobile from '@/components/trade_history/history_menu/otc_menu_mobile'

export default {
  name: "BuyState",

  data() {
    return {
      tradeState: 'all',
      tradeList: [],
      rerenderKey: 0,
      pageLast: 0,
      totalItem: 0,
    }
  },

  components: {
    'OtcHistoryMenu': OtcHistoryMenu,
    'OtcMenuMobile': OtcMenuMobile
  },

  mounted() {
    this.$refs.OtcHistoryMenu.focusTab(3)
    this.$refs.OtcMenuMobile.selectMenu(this.$t('message.buyOrderSum'))

    this.getBuyList(1)
  },

  methods: {
    getBuyList(page) {
      let formData = new FormData()
      let limit = 10

      formData.append('isBuy', true)
      formData.append('limit', limit)
      if(typeof page !== 'number') {
        page = 1
      }
      formData.append('page', page)

      if(this.tradeState !== 'all' ) {
        formData.append('tradeState', this.tradeState.toUpperCase())
      }

      this.$post(this.$OTC_TRADE_LOG, 'HistoryOtcSellStateGetTradeList', formData,true, (result) => {
        this.tradeList = result.data
        this.totalItem = result.total
        this.pageLast = Math.ceil(result.total / limit)

        this.tradeList.map((trade) => {
          let date = trade.createdAt.split('T')[0]
          let time = trade.createdAt.split('T')[1]
          let timeSplit = time.split('.')[0]
          trade.createdAt = `${date} ${timeSplit}`

          this.getNickname((result) => {
            if(result.success) {
              trade.myNickname = result.nickname
              this.rerenderKey += 1
            } else {
              console.log(result.nickname)
            }
          }, trade.userIdx)

          this.getNickname((result) => {
            if(result.success) {
              trade.offerNickname = result.nickname
              this.rerenderKey += 1
            } else {
              console.log(result.nickname)
            }
          }, trade.userIdxOffer)
        })
      }, (result) => {
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>

</style>
