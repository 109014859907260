<template>
  <div class="header" v-if="show">
    <div class="pagew-header">
      <router-link :to="{ name: 'Main' }" title="cocoin" class="loge left"><img src="/img/logo_header.svg" :alt="$t('message.logo')"></router-link>
      <div class="gnb_menu">
        <div class="gnb left">
          <ul class="mainmenu">
            <li><a class="point" @click="goToMarket">{{ $t('message.exchange') }}</a></li>
            <li>
              <router-link :to="{ name: 'TradeHistoryGeneralWait' }">{{ $t('message.myTradingHistory') }}</router-link>
            </li>
            <li><router-link :to="{ name: 'OtcSuggestionMarketList' }">OTC {{ $t('message.market') }}</router-link></li>
            <li>
              <router-link :to="{ name: 'AssetManagementWallet' }">{{ $t('message.manageMyAsset') }}</router-link>
            </li>
<!--            <li class="asset">-->
<!--              <a @click="dropDown">{{ $t('message.manageMyAsset') }}</a>-->
<!--              <ul class="asset_list" style="display: none;">-->
<!--                <li><router-link :to="{ name: 'AssetManagementWallet' }">{{ $t('message.myWallet') }}</router-link></li>-->
<!--                <li><router-link :to="{ name: 'AssetManagementConvertToWkx' }">{{ $t('message.convertToWKX') }}</router-link></li>-->
<!--                <li><router-link :to="{ name: 'AssetManagementConvertToUsdpay' }">{{ $t('message.convertToUSDPAY') }}</router-link></li>-->
<!--              </ul>-->
<!--            </li>-->
          </ul>
        </div>
        <div class="etc right">
          <ul class="customer_list" v-show="showPopover.customer">
            <li @click="showPopover.customer = false"><router-link :to="{ name: 'CustomerSupportList' }">{{ $t('message.announcement') }}</router-link></li>
            <li @click="showPopover.customer = false"><router-link :to="{ name: 'CustomerSupportCenter' }">{{ $t('message.userGuide') }}</router-link></li>
            <li @click="showPopover.customer = false"><router-link :to="{ name: 'CustomerSupportFee' }">{{ $t('message.fees') }}</router-link></li>
            <li @click="showPopover.customer = false"><router-link :to="{ name: 'MyPageQnaRegist' }">{{ $t('message.inquiry') }}</router-link></li>
            <li @click="showPopover.customer = false"><router-link :to="{ name: 'CustomerSupportCoinRequest' }">{{ $t('message.requestNewCoin') }}</router-link></li>
          </ul>
          <ul class="etc_list">
            <li>
              <a @click="showPopover.customer=!showPopover.customer">{{ $t('message.helpCenter') }}</a>
            </li>
            <li v-if="$store.getters.getLoginState"><router-link :to="{ name: 'MyPageInfo' }">{{ $t('message.myPage') }}</router-link></li>
            <li v-if="$store.getters.getLoginState" @click="logout"><a>{{ $t('message.logout') }}</a></li>
            <li v-if="!$store.getters.getLoginState"><router-link :to="{ name: 'Login' }">{{ $t('message.login') }}</router-link></li>
            <li v-if="!$store.getters.getLoginState"><router-link :to="{ name: 'Signup' }">{{ $t('message.signup') }}</router-link></li>
            <LanguageVerti></LanguageVerti>
          </ul>
        </div>
      </div>
    </div>
    <div class="mobile_btn" :class="{ active: isMenuOpen }" id="mobileBtn" @click="mobileDropDown">
      <span class="top"></span>
      <span class="middle"></span>
      <span class="bottom"></span>
    </div>
    <div class="gnb_mb" v-show="isMenuOpen">
      <ul class="mainmenu_mb" :class="{ hovered: isMenuOpen }">
        <li @click="closeMobileMenu"><a @click="goToMarket">{{ $t('message.exchange') }}</a></li>
        <li @click="closeMobileMenu"><router-link :to="{ name: 'TradeHistoryGeneralWait' }">{{ $t('message.myTradingHistory') }}</router-link></li>
        <li @click="closeMobileMenu"><router-link :to="{ name: 'OtcSuggestionMarketList' }">OTC {{ $t('message.market') }}</router-link></li>
        <li @click="closeMobileMenu"><router-link :to="{ name: 'AssetManagementWallet' }">{{ $t('message.manageMyAsset') }}</router-link></li>
        <li @click="closeMobileMenu"><router-link :to="{ name: 'CustomerSupportCenter' }">{{ $t('message.customerCenter') }}</router-link></li>
        <li class="etc_mb">
          <div class="my_area" @click="closeMobileMenu">
            <div class="login_before">
              <button class="etc_s_btn" v-if="!this.$store.getters.getLoginState">
                <router-link :to="{ name: 'Login' }">{{ $t('message.login') }}</router-link>
              </button>
              <button class="etc_s_btn" v-if="!this.$store.getters.getLoginState">
                <router-link :to="{ name: 'Signup' }">{{ $t('message.signup') }}</router-link>
              </button>
            </div>
            <div class="login_after">
              <button class="etc_s_btn" v-if="this.$store.getters.getLoginState">
                <router-link :to="{ name: 'MyPageInfo' }">{{ $t('message.myPage') }}</router-link>
              </button>
              <button class="etc_s_btn" v-if="this.$store.getters.getLoginState" @click="logout">
                <a>{{ $t('message.logout') }}</a>
              </button>
            </div>
          </div>
          <div class="lang_mb">
            <LanguageHorizon @closeMobileMenu="mobileDropDown"/>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LanguageVerti from './language/lang_verti'
import LanguageHorizon from './language/lang_horizon'
import Socket from "@/commonjs/socket";

export default {
  name: "Header",
  components: {
    'LanguageVerti': LanguageVerti,
    'LanguageHorizon': LanguageHorizon
  },
  data() {
    return {
      isMenuOpen: false,
      display: 'vertical',
      show: true,
      showPopover: {
        customer: false,
      },
    }
  },

  methods : {
    // toggleSubMenu(e) {
    //   let subMenuClass = e.currentTarget.parentNode.querySelector('.submenu_mb').classList
    //   let arrowClass = e.currentTarget.querySelector('.mbtn').classList
    //
    //   if(subMenuClass.contains('active')) {
    //     subMenuClass.remove('active')
    //     arrowClass.remove('btn_up')
    //   } else {
    //     subMenuClass.add('active')
    //     arrowClass.add('btn_up')
    //   }
    // },

    mobileDropDown() {
      // let mbGnbMenu = window.$('.gnb_mb > ul > li > a.sub_mb')
      let myPageSub = window.$('#mobile-sub')
      myPageSub.hide()

      this.isMenuOpen = !this.isMenuOpen

      if (!this.isMenuOpen) {
        // window.$('.submenu_mb').hide()

        // let subMenu = document.getElementsByClassName('submenu_mb')
        // subMenu.forEach(menu => {
        //   if(menu.classList.contains('active')) {
        //     menu.classList.remove('active')
        //   }
        // })

        // let arrowBtns = document.getElementsByClassName('mbtn')
        // arrowBtns.forEach(arrow => {
        //   if(arrow.classList.contains('btn_up')) {
        //     arrow.classList.remove('btn_up')
        //   }
        // })
      }

      // mbGnbMenu.off().on('click', function (event) {
      //   window.$('#mobileBtn').next('.submenu_mb').slideToggle(300)
      //   event.preventDefault()
      // })
    },

    // dropDown() {
    //   let sBt = window.$('.status')
    //   let sLi = window.$('.status_list')
    //
    //   let aBt = window.$('.asset')
    //   let aLi = window.$('.asset_list')
    //
    //   sBt.click(function() {
    //     sLi.slideDown(200)
    //     sBt.addClass('motion')
    //     aLi.slideUp(100)
    //     aBt.removeClass('motion')
    //   })
    //   sLi.mouseleave(function() {
    //     sLi.slideUp(100)
    //     sBt.removeClass('motion')
    //   })
    //
    //   aBt.click(function() {
    //     aLi.slideDown(200)
    //     aBt.addClass('motion')
    //     sLi.slideUp(100)
    //     sBt.removeClass('motion')
    //   })
    //   aLi.mouseleave(function() {
    //     aLi.slideUp(100)
    //     aBt.removeClass('motion')
    //   })
    // },

    showHeader(bool) {
      this.show = bool
    },

    closeMobileMenu() {
      this.isMenuOpen = false
    },

    logout() {
      this.$get(this.$USER_LOGIN_LOGOUT, 'Logout', true, (result) => {
        this.notify('success', result.message)
        this.$store.state.isLoggedIn = false
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        this.$router.push({name:'Main'})
      }, (result) => {
        console.log(result)
      })
    },

    goToMarket() {
      let defaultSecondary = sessionStorage.getItem('defaultSecondary')
      this.$router.push({ name: 'GeneralMarket', params: { pair: `BTC-${defaultSecondary}` }}).catch(()=> {})
    }
  },

  beforeDestroy(){
    Socket.$off("message", this.handleMessage)
  },
}
</script>

<style scoped>

</style>
