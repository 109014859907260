<template>
  <div class="body_wrap">
    <audio id="audio" src="/data/chat.mp3"></audio>
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container">
        <OtcMenuPC ref="OtcMenuPC"></OtcMenuPC>
        <div class="otc_purchase_wrap">
          <div class="otc_purchase_right otc_s_chat">
            <div class="chatting_con">
              <div class="chat_tit">
                <div>
                  <i class="fas fa-comment"></i><span>{{ $t('message.chat') }}</span>
                </div>
              </div>
              <div>
                <div ref="Scroll" class="chatting scroll">
                  <div class="msg_con">
                    <p class="fs12">{{ formatDate(new Date(), 'full') }}</p>
                    <p class="fs16 fwb t_center" v-if="post">{{ $t('message.writing') }}: {{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '(직접거래)' }} {{ post.boardIsBuy ? $t('message.doBuy') : $t('message.doSell') }}
                      | {{ $t('message.writer') }}: {{ post.writerNickname }}
                    </p>
                    <p class="fs16 fwb t_center">{{ $t('message.doConference') }}</p>
                  </div>
                  <div class="msg_balls">
                    <div class="msg_sent" v-for="(msg, msgIdx) in msgAll" :key="msgIdx" :class="{ flex_end: msg.userIdx === myIdx, flex_start: msg.userIdx !== myIdx}">
                      <p class="msg m-0" v-show="msg.comment">{{ msg.comment }}</p>
                      <img v-if="msg.fileHash && msg.fileImage && msg.fileLoaded" :src="msg.fileLink" :alt="msg.fileName" />
                      <p v-if="msg.fileHash && !msg.fileImage" class="msg">{{ msg.fileName }}</p>
                      <div v-if="msg.fileHash && msg.fileLoaded">
                        <a :download="msg.fileName" :href="$DOMAIN + $OTC_TEMP_CHAT_IMAGE + '?chatroomIdx=' + chatroomIdx + '&fileHash=' + msg.fileHash + '&comment=' + chatKey" target="hidden_frame" class="btn btn-point btn-block">{{ $t('message.download') }}</a>
                      </div>
                      <p class="fs12" :class="{ time_r: msg.userIdx === myIdx, time_l: msg.userIdx !== myIdx}">{{ formatDate(msg.timestamp, 'full') }}</p>
                    </div>
                  </div>
                </div>
                <div class="msg_tit">
                  <i class="fas fa-comment-dots"></i>
                  <span class="m_left5">{{ $t('message.msg') }}</span>
                </div>
                <div class="txt_con">
                  <textarea :placeholder="$t('message.enterMsg')" v-model="msg" @keyup.enter="sendTxt"></textarea>
                </div>
                <div class="upload_con" :class="{flex_col: fileName}">
                  <div class="left">
                    <div class="upload_btn">
                      <input type="file" class="custom-input" @change="upload($event)" accept="*">
                      <i class="fas fa-paperclip"></i>
                      <a class="m_left5">{{ $t('message.fileAttach') }}</a>
                    </div>
                    <div v-show="fileName" class="input_con">
                      <input type="text" class="input_file" v-model="fileName" disabled>
                      <span @click="deleteFile"><i class="fas fa-times"></i></span>
                    </div>
                  </div>
                  <div class="middle_btn1" @click="sendMsg"><a>{{ $t('message.send') }}</a></div>
                </div>
              </div>
            </div>
            <iframe src="about:blank" name="hidden_frame" id="hidden_frame" style="height: 1px;width: 100%; opacity: 0"></iframe>
            <div class="p_btn_wrap mt20">
              <a @click="$router.back()" class="p_btn_blue">{{ $t('message.goBack') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcMenuPC from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_pc'
import OtcMenuMobile from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_mobile'

export default {
  name: "OtcSuggestionChatDetail",

  data() {
    return {
      msg: '',
      post: this.$route.params.post,
      chatroomIdx: this.$route.params.chatroomIdx,
      tabNo: this.$route.params.tabNo,
      msgAll: [],
      tmpMsg: [],
      chatInterval: null,
      chatIntervalLoaded: false,
      lastTimestamp: 0,
      imgFile: null,
      fileName: '',
      myIdx: 0,
      ifImgError: false,
      loaded: false,
      chatKey: '',
      busy: false,
    }
  },

  components: {
    'OtcMenuPC': OtcMenuPC,
    'OtcMenuMobile': OtcMenuMobile,
  },

  created() {
    if(!this.post) {
      this.tabNo = 2
      this.$get(`${this.$OTC_TEMP_CHATROOM_BOARDIDX}?chatroomIdx=${this.chatroomIdx}`, 'getChatroomBoardIdx', true, (result) => {
        this.post = result.data[0]
        this.init()
      }, () => {
        this.$router.back()
      })
    } else {
      this.init()
    }
  },

  computed: {
    fileChanged: {
      get: function() {
        return this.fileName
      },
      set: function(newValue) {
        this.fileName = newValue
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.chatInterval)
  },

  mounted() {
    this.$refs.OtcMenuPC.focusTab(this.tabNo)
    let tabStr = this.tabNo === 0 ? this.$t('message.otcMarket') : this.tabNo === 1 ? this.$t('message.myTradingHistory') : this.tabNo === 2 ? this.$t('message.otcChat') : this.tabNo === 3 ? this.$t('message.myPost') : ''
    this.$refs.OtcMenuMobile.selectMenu(tabStr)
  },

  methods: {
    init() {
      this.getChatHistory()

      this.chatInterval = setInterval(() => {
        if(!this.loaded) {
          return
        }
        this.getChatHistory()
      }, 1000)

      this.getUserProfile((result) => {
        if(result.success) {
          this.myIdx = result.userProfile.userIdx
        } else {
          console.log(result.userProfile)
        }
      })
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.fileChanged = file[0].name
      this.imgFile = [...file]
    },

    deleteFile() {
      this.imgFile = []
      this.fileChanged = ''
    },

    sendMsg() {
      if(!this.busy) {
        this.sendTxt()
        this.sendImg()
      } else {
        this.notify('error', this.$t('message.btnBusy'))
      }
    },

    sendTxt() {
      if(this.msg.trim() === '') {
        this.msg = ''
        return
      }

      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('chatroomIdx', this.chatroomIdx)
      formData.append('comment', this.msg)

      this.$post(this.$OTC_TEMP_CHAT_ADD, 'chatAdd', formData, true, (result) => {
        console.log(result)
        this.msg = ''
      }, (result) => {
        console.log(result)
      })
    },

    sendImg() {
      if(!this.imgFile || this.imgFile.length === 0) {
        return
      }

      this.busy = true
      this.notify('success', this.$t('message.imageUploading'))

      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('chatroomIdx', this.chatroomIdx)
      formData.append('file', this.imgFile[0])

      this.$post(this.$OTC_TEMP_CHAT_UPLOAD, 'OtcChattingUpload', formData, true, () => {
        this.busy = false

        this.imgFile = []
        this.fileChanged = ''
      }, (result) => {
        console.log(result)
      })
    },

    getChatHistory() {
      if(!this.chatroomIdx) {
        return
      }

      let formData = new FormData()
      formData.append('chatroomIdx', this.chatroomIdx)
      formData.append('timestamp', this.lastTimestamp + 1)

      this.$post(this.$OTC_TEMP_CHAT_LIST, 'OtpProcessTradeChatList', formData, true, (result) => {
        let msgList = result.data
        if(msgList.length) {
          if(this.chatIntervalLoaded) {
            for (let i = 0; i < msgList.length; i++) {
              if (msgList[i].userIdx !== this.myIdx) {
                document.getElementById('audio').play()
                break
              }
            }
          }

          this.modifyChatData(msgList)
          this.chatIntervalLoaded = true
        }
      }, (result) => {
        console.log(result)
      })
    },

    modifyChatData(msgList) {
      msgList.forEach((chat) => {
        if(this.chatKey === '' && chat.chatKey) {
          this.chatKey = chat.chatKey
        }

        chat.fileImage = false
        chat.fileLoaded = false

        if(chat.fileHash !== null) {
          if(!chat.fileLink) {
            let formData = new FormData()
            formData.append('idx', this.post.idx)
            formData.append('chatroomIdx', this.chatroomIdx)
            formData.append('fileHash', chat.fileHash)

            this.$downloadPost(this.$OTC_TEMP_CHAT_DOWNLOAD, 'ChatDownLoad_' + Math.random(), formData, true, (result) => {
              let file = this.$fileDownload(result)

              chat.fileLink = ''
              chat.fileName = file[1]

              let reader = new FileReader()
              reader.readAsDataURL(result.data)
              reader.onloadend = function () {
                chat.fileLink = reader.result
                chat.fileLoaded = true
              }

              if(chat.fileName.lastIndexOf('.') !== -1) {
                let filetype = chat.fileName.substring(chat.fileName.lastIndexOf('.') + 1)
                if(filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png' || filetype === 'gif') {
                  chat.fileImage = true
                }
              }
            }, (result) => {
              this.notify('error', result.message)
            })
          }
        }
      })

      let msgAll = [...this.msgAll, ...msgList]
      msgAll.sort(function(a, b) {
        return a.timestamp - b.timestamp
      })

      this.msgAll = msgAll

      this.lastTimestamp = this.msgAll[this.msgAll.length - 1].timestamp

      this.loaded = true

      setTimeout(() => {
        let scrollBox = this.$refs.Scroll
        scrollBox.scrollTop = scrollBox.scrollHeight
      }, 1000)
    },
  },
}
</script>

<style scoped>

</style>
