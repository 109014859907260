<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="subcontainer1">
        <h2 class="support_title fwm dblue_02">{{ $t('message.faq') }}</h2>
        <div class="support_viewrap">
          <div class="view_top">
            <div class="view_title">
              <h5 class="fwm">{{ faqDetail.title }}</h5>
            </div>
            <div class="view_date">
              <span class="fwm">{{ $t('message.createdTime') }}</span>
              <span class="fwm">{{ faqDetail.date }}</span>
            </div>
          </div>
          <div class="view_content">
            <p v-html="escapeTag(faqDetail.contents)"></p>
            <img v-show="faqDetail.file" :src="faqDetail.file" :alt="$t('message.file')" />
          </div>
          <div class="p_btn_wrap">
            <router-link :to="{ name: 'CustomerSupportCenter' }" class="p_btn_blue">{{ $t('message.customerCenter') }}</router-link>
            <router-link :to="{ name: 'CustomerSupportFAQList' }" class="p_btn_blue">{{ $t('message.list') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faqView",
  data() {
    return {
      faqIdx: this.$route.params.articleIdx,
      faqDetail: {},
    }
  },

  created() {
    if(this.faqIdx) {
      sessionStorage.setItem('faqIdx', this.faqIdx)
    } else {
      this.faqIdx = sessionStorage.getItem('faqIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('faqIdx')
  },

  mounted() {
    this.$get(`${this.$BOARD_DETAIL}/${this.faqIdx}`, this.$options.name + '_mountedDetail', false, (result) => {
      this.faqDetail = result.data[0]
      this.faqDetail.date = this.formatDate(this.faqDetail.createdAt, 'full')
    }, (result) => {
      this.notify('error', result.message)
    })
  },
}
</script>

<style scoped>

</style>
