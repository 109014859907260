<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="sub_container">
        <OtcSearch
            @changeOfferType="changeOfferType"
            @getCurrencyArr="getCurrencyArr"
            @getCoinArr="getCoinArr">
        </OtcSearch>
        <Modal ref="Modal">
          <div v-show="showAddDesc">
            <span v-html="$t('message.extraNoteOtcOffer')"></span>
            <div class="descbox">
              <em>{{ $t('message.bankAcc') }} : xxxx xxxx xxxx xxxx<br>
                {{ $t('message.aliPay') }} : xxxxxxx<br>
                {{ $t('message.wechatPay') }} : xxxxx</em>
            </div>
          </div>
          <div v-show="showConfirmOffer">
            <div>
              <dl class="wallet_form2">
                <dt class="wallet_form_tit">{{ $t('message.coin') }}</dt>
                <dd class="wallet_form_text t_left">{{ selectedCoin }}</dd>
              </dl>
<!--              <dl class="wallet_form2">-->
<!--                <dt class="wallet_form_tit">{{ $t('message.tradeType') }}</dt>-->
<!--                <dd class="wallet_form_text t_left">{{ tradeType === 'splitTrade' ? $t('message.split') : $t('message.full') }}</dd>-->
<!--              </dl>-->
              <dl class="wallet_form2">
                <dt class="wallet_form_tit">{{ $t('message.quantity') }}</dt>
                <dd class="wallet_form_text t_left">{{ amount | numberWithCommas }} {{ selectedCoin }}</dd>
              </dl>
              <dl class="wallet_form2" v-show="offerType === 'sell'">
                <dt class="wallet_form_tit">{{ $t('message.fees') }}({{ fee }}%)</dt>
                <dd class="wallet_form_text t_left">{{ Number(amount) * fee }} {{ selectedCoin }}</dd>
              </dl>
              <dl class="wallet_form2" v-show="offerType === 'sell'">
                <dt class="wallet_form_tit">{{ $t('message.ActualTransaction') }}</dt>
                <dd class="wallet_form_text t_left">{{ Number(amount) + (Number(amount) * fee) | numberWithCommas }} {{ selectedCoin }}</dd>
              </dl>
<!--              <dl class="wallet_form2" v-show="tradeType === 'splitTrade'">-->
<!--                <dt class="wallet_form_tit">{{ $t('message.minAmount') }}</dt>-->
<!--                <dd class="wallet_form_text t_left">{{ minAmount | numberWithCommas }} {{ selectedCoin }}</dd>-->
<!--              </dl>-->
              <dl class="wallet_form2">
                <dt class="wallet_form_tit">{{ $t('message.unitPrice') }}</dt>
<!--                <dd class="wallet_form_text t_left">{{ subDec(Number(price), selectedCurrency) | numberWithCommas }} {{ selectedCurrency }}</dd>-->
                <dd class="wallet_form_text t_left">{{ selectedExchange === 'NONE' ? '협상가' : selectedExchange }} {{ selectedExchange === 'NONE' ? '' : (markUp >= 0 ? '기준 +' : '기준 ') + markUp + '%' }}</dd>
              </dl>
<!--              <dl class="wallet_form2">-->
<!--                <dt class="wallet_form_tit">{{ $t('message.totalTradeAmount') }}</dt>-->
<!--                <dd class="wallet_form_text t_left">{{ subDec((price * Number(amount)), selectedCurrency) | numberWithCommas }} {{ selectedCurrency }}</dd>-->
<!--              </dl>-->
              <dl class="wallet_form2">
                <dt class="wallet_form_tit">{{ $t('message.myBalance') }}</dt>
                <dd class="wallet_form_text t_left">{{ myBalance | numberWithCommas }} {{ selectedCoin }}</dd>
              </dl>
              <dl class="wallet_form2" v-show="offerType === 'sell'">
                <dt class="wallet_form_tit">{{ $t('message.balanceAfterPosting') }}</dt>
                <dd class="wallet_form_text t_left">{{ myBalance | numberWithCommas }}{{ selectedCoin }} – {{ Number(amount) + (Number(amount) * fee) | numberWithCommas }} {{ selectedCoin }} =
                  <em class="dblue_02">{{ myBalance - (Number(amount) + (Number(amount) * fee)) | numberWithCommas }} {{ selectedCoin }}</em>
                </dd>
              </dl>
            </div>
            <div class="checks otc_offer_ckh">
              <input type="checkbox" id="check_03" v-model="confirmChk" @change="toggleChk" />
              <label for="check_03">{{ $t('message.confirmChk') }}</label>
            </div>
          </div>
        </Modal>
        <h5 class="dblue_02 offer_title" v-if="offerType === 'buy'">{{ $t('message.postBuyOffer')}}</h5>
        <h5 class="dblue_02 offer_title" v-else>{{ $t('message.postSellOffer')}}</h5>
        <div class="purchase_offer">
          <dl class="table_line">
            <dt class="table_line_title">
              <span>{{ $t('message.coin') }}</span></dt>
            <dd class="table_line_content">
              <select class="currency1" v-model="selectedCoin" @change="changeCoin">
                <option v-for="coin in coinArr" :key="coin" :value="coin">{{ coin }}</option>
              </select>
<!--              <div class="i_change">-->
<!--                <img src="/img/icon_reverse.svg" alt="change">-->
<!--              </div>-->
<!--              <select class="currency1" v-model="selectedCurrency">-->
<!--                <option v-for="currency in currencyArr" :value="currency" :key="currency">{{ currency }}</option>-->
<!--              </select>-->
            </dd>
          </dl>

<!--          <dl class="table_line">-->
<!--            <dt class="table_line_title">-->
<!--              <span>{{ $t('message.tradeType')}}</span></dt>-->
<!--            <dd class="table_line_content">-->
<!--              <div class="check_wrap">-->
<!--                <label class="label_checkradio">-->
<!--                  <input type="radio" value="splitTrade" v-model="tradeType">-->
<!--                  <span class="radio_icon"></span>-->
<!--                  <span class="radio_text">{{ $t('message.split')}}</span>-->
<!--                </label>-->
<!--                <label class="label_checkradio">-->
<!--                  <input type="radio" value="fullTrade" v-model="tradeType">-->
<!--                  <span class="radio_icon"></span>-->
<!--                  <span class="radio_text">{{ $t('message.full')}}</span>-->
<!--                </label>-->
<!--              </div>-->
<!--            </dd>-->
<!--          </dl>-->
          <dl class="table_line">
            <dt class="table_line_title">
              <span>{{ $t('message.quantity')}}</span></dt>
            <dd>
              <div class="amount">
                <div class="inputwrap2">
                  <input type="number" v-model="amount">
                  <span>{{ selectedCoin }}</span>
                </div>
              </div>
            </dd>
          </dl>
<!--          <dl class="table_line" v-show="tradeType === 'splitTrade'">-->
<!--            <dt class="table_line_title">-->
<!--              <span>{{ $t('message.minAmount')}}</span></dt>-->
<!--            <dd>-->
<!--              <div class="amount">-->
<!--                <div class="inputwrap2">-->
<!--                  <input type="number" v-model="minAmount">-->
<!--                  <span>{{ selectedCoin }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </dd>-->
<!--          </dl>-->
          <dl class="table_line">
            <dt class="table_line_title">
              <span>{{ $t('message.tradePrice')}}</span></dt>
            <dd>
<!--              <div class="price_1">-->
<!--                <label class="label_checkradio">-->
<!--                  <input type="radio" value="floatingPrice" v-model="priceType" @change="changePriceType">-->
<!--                  <span class="radio_icon"></span>-->
<!--                  <span class="radio_text">{{ $t('message.dynamicPrice')}}</span>-->
<!--                </label>-->
<!--                <label class="label_checkradio">-->
<!--                  <input type="radio" value="fixedPrice" v-model="priceType" @change="changePriceType">-->
<!--                  <span class="radio_icon"></span>-->
<!--                  <span class="radio_text">{{ $t('message.staticPrice')}}</span>-->
<!--                </label>-->
<!--              </div>-->
              <div class="price_2">
                <p>{{ $t('message.exchanges')}}</p>
                <select class="currency1" @change="changeExchange($event)" v-model="selectedExchange">
                  <option v-for="exchange in exchangeList" :key="exchange" :value="exchange">{{ exchange === 'NONE' ? '협상가' : exchange }}</option>
                </select>

              </div>
<!--              시세:-->
<!--              <div class="price_3">-->
<!--                <div class="price_3_wrap">-->
<!--                  <p>{{ $t('message.price')}}</p>-->
<!--                  <div class="inputwrap2 red_price grey_back">-->
<!--                    <input type="number" class="price_red" v-model="price" :disabled="priceType === 'floatingPrice'">-->
<!--                    <span>{{ selectedCurrency }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="price_3_wrap">-->
<!--                  <p>{{ $t('message.minMarketPrice')}}</p>-->
<!--                  <div class="inputwrap2 grey_back">-->
<!--                    <input type="text" placeholder="" disabled="disabled">-->
<!--                    <span>{{ selectedCurrency }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              :시세-->
<!--              마크업:-->
<!--              <div class="price_4" v-show="priceType === 'floatingPrice'">-->
              <div class="price_4" v-show="selectedExchange !== 'NONE'">
                <div class="price_4_wrap">
                  <p>{{ $t('message.markUp')}}(%)</p>
                  <div class="amount">
                    <div class="inputwrap2">
                      <input type="number" style="width: 98%;" placeholder="0.000" v-model="markUp">
                    </div>
                    <!-- 버튼 -->
                    <div class="btnwrap">
                      <div class="btn_minus" @click="markUp -= 0.001"></div>
                      <div class="btn_plus" @click="markUp += 0.001"></div>
                    </div>
                  </div>
                  <span class="t_left">* {{ $t('message.markUpDesc')}}</span>
                </div>
              </div>
            </dd>
          </dl>
<!--          <dl class="table_line">-->
<!--            <dt class="table_line_title">-->
<!--              <span>{{ $t('message.totalTradePrice')}}</span></dt>-->
<!--            <dd>-->
<!--              <div class="amount">-->
<!--                <div class="inputwrap2 grey_back">-->
<!--                  <input type="text" disabled="disabled" v-model="totalPrice">-->
<!--                  <span>{{ selectedCurrency }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </dd>-->
<!--          </dl>-->

    <!--      <dl class="table_line">-->
    <!--        <dt class="table_line_title">-->
    <!--          <span>거래가능시간</span></dt>-->
    <!--        <dd class="time_wrap">-->
    <!--          <div class="time">-->
    <!--            <select class="time_select">00</select>시-->
    <!--            <select class="time_select">00</select>분-->
    <!--          </div>-->
    <!--          <span class="wave">~</span>-->
    <!--          <div class="time">-->
    <!--            <select class="time_select">23</select>시-->
    <!--            <select class="time_select">59</select>분-->
    <!--          </div>-->
    <!--        </dd>-->
    <!--      </dl>-->
          <dl class="table_line">
            <dt class="table_line_title">
              <span class="ex_icon" @click="openAddDesc">{{ $t('message.extraNote')}}</span>
            </dt>
            <dd>
              <textarea
                  class="explain"
                  cols="30"
                  rows="10"
                  :placeholder="$t('message.offerMemo')"
                  v-model="memo">
              </textarea>
            </dd>
          </dl>

          <dl class="table_line">
            <dt class="table_line_title">
              <span>{{ $t('message.myBalance')}}</span></dt>
            <dd>
              <div class="amount">
                <div class="inputwrap2 grey_back">
                  <input type="text" style="width: 98%" :value="myBalance | numberWithCommas" disabled>
                  <span>{{ selectedCoin }}</span>
                </div>
              </div>
            </dd>
          </dl>
<!--          <dl class="table_line" v-show="payOptions.BANK.state || payOptions.PPS.state || payOptions.ALIPAY.state || payOptions.WECHAT.state">-->
<!--            <dt class="table_line_title">-->
<!--              <span>{{ $t('message.payMethod')}}</span></dt>-->
<!--            <dd class="checks payment">-->
<!--              <div v-show="payOptions.BANK.state">-->
<!--                <input type="checkbox" v-model="bank" id="bank"/>-->
<!--                <label for="bank">{{ $t('message.bankTrans')}}</label>-->
<!--              </div>-->
<!--              <div v-show="payOptions.PPS.state">-->
<!--                <input type="checkbox" v-model="pps" id="pps"/>-->
<!--                <label for="pps">PPS{{ $t('message.bankTrans')}}</label>-->
<!--              </div>-->
<!--              <div v-show="payOptions.ALIPAY.state">-->
<!--                <input type="checkbox" v-model="alipay" id="alipay"/>-->
<!--                <label for="alipay">{{ $t('message.aliPay')}}</label>-->
<!--              </div>-->
<!--              <div v-show="payOptions.WECHAT.state">-->
<!--                <input type="checkbox" v-model="wechat" id="wechat"/>-->
<!--                <label for="wechat">{{ $t('message.wechatPay')}}</label>-->
<!--              </div>-->
<!--            </dd>-->
<!--          </dl>-->

<!--          <dl class="table_line" v-show="!payOptions.BANK.state && !payOptions.PPS.state && !payOptions.ALIPAY.state && !payOptions.WECHAT.state">-->
<!--            <dt class="table_line_title">-->
<!--              <span>{{ $t('message.tradeType')}}</span></dt>-->
<!--            <dd>-->
<!--              <div class="pay_method">-->
<!--                <em>{{ $t('message.noPayMethodDesc')}}</em>-->
<!--                <span class="btn_enroll">-->
<!--                  <keep-alive>-->
<!--                    <router-link :to="{ name: 'MyPageAdvanced', params: { route: 'fromOtcOffer' }}">{{ $t('message.registration')}}</router-link>-->
<!--                  </keep-alive>-->
<!--                </span>-->
<!--              </div>-->
<!--            </dd>-->
<!--          </dl>-->
        </div>
        <!--매수오퍼_ 공지 -->
        <div class="offer_notice">
          <p>{{ $t('message.offerRegistDesc1')}}</p>
          <p>{{ $t('message.offerRegistDesc2')}}</p>
        </div>
        <div class="p_btn_wrap">
          <a class="p_btn_blue" @click="confirmOffer">{{ $t('message.confirm') }}</a>
          <a class="p_btn cancel" @click="$emit('goToOtcHome')">{{ $t('message.cancel')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/cmp/modal/modal'
import OtcSearch from "./cmp/otc_search"

export default {
  name: "OtcOffer",

  data() {
    return {
      showAddDesc: false,
      showConfirmOffer: false,
      confirmChk: false,
      tradeType: 'splitTrade',
      amount: 0,
      minAmount: '',
      priceType: 'floatingPrice',
      markUp: 0.000,
      exchangeList: [],
      selectedCoin: '',
      selectedCurrency: '',
      selectedExchange: '',
      price: 'NONE',
      memo: '',
      bank: false,
      pps: false,
      alipay: false,
      wechat: false,
      fee: 0,
      myBalance: 0,
      offerType: this.$route.params.offerType,
      currencyArr: [],
      coinArr: [],
      payOptions: {
        WECHAT: {
          state: false
        },
        BANK: {
          state: false
        },
        PPS: {
          state: false
        },
        ALIPAY: {
          state: false
        }
      },
      totalPrice: 0,
    }
  },

  components: {
    'Modal': Modal,
    'OtcSearch': OtcSearch
  },

  computed: {
    modalTitle() {
      if(this.offerType === 'buy') {
        return this.$t('message.postBuyOffer')
      } else {
        return this.$t('message.postSellOffer')
      }
    }
  },

  activated() {
    this.offerType = this.$route.params.offerType

    this.$get(`${this.$USER_MYPAGE_OPTION_PAY}?method=BANK&method=PPS&method=ALIPAY&method=WECHAT`, 'OtcOfferGetOptionPay', true, (result) => {
      if(Object.keys(result.data[0]).length !== 0) {
        this.payOptions = result.data[0]
      }
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$get(this.$OTC_LIST_EXCHANGES, 'otcOfferGetExchangeList', true, (result) => {
      this.exchangeList = result.data
      this.selectedExchange = this.exchangeList[0]
      this.changeExchange()
    }, (result) => {
      console.log(result)
    })
  },

  methods: {
    changeCoin() {
      this.getMyOtcBalance()
    },

    getCurrencyArr(currencyArr) {
      this.currencyArr = currencyArr
      this.selectedCurrency = this.currencyArr[0]
    },

    getCoinArr(coinArr) {
      this.coinArr = coinArr
      this.selectedCoin = this.coinArr[0]
      this.getMyOtcBalance()
    },

    changeOfferType(offerType) {
      this.offerType = offerType
    },

    changePriceType() {
      if(this.priceType === 'floatingPrice') {
        this.changeExchange()
      }
    },

    getMyOtcBalance() {
      this.getMyBalance((result) => {
        if(result.success) {
          this.myBalance = result.myBalance.available
        }
      }, this.selectedCoin)
    },

    //가격은 무조건 시세가 아닌 협상가 또는 선택된 거래소의 마크업 스트링으로만 보이게 처리 : 20211019 pk
    changeExchange() {
      // if(this.selectedExchange === 'BITFINEX') {
      //   let marketPair = `${this.selectedCoin}usd`
      //   marketPair = marketPair.toLowerCase()
      //
      //   this.$get(`${this.$OTC_DISPLAY_TICKER_BITFINEX}/${marketPair}`, 'otcOfferGetBitfinex', true, (result) => {
      //     let usdPrice = result.data[0].mid
      //
      //     if(this.selectedCurrency === 'KRW') {
      //       this.$get(`${this.$OTC_DISPLAY_USDKRW}`, 'otcOfferGetUSDKRW', true, (result) => {
      //         let rate = result.data[0].rate
      //         this.price = this.subDec(rate * usdPrice, this.selectedCurrency)
      //       }, (result) => {
      //         console.log(result)
      //       })
      //     } else {
      //       // 환율계산하는 api 화폐당 더 나와야함
      //     }
      //   }, (result) => {
      //     console.log(result)
      //     this.notify('error', result.message)
      //   })
      // } else if(this.selectedExchange === 'UPBIT') {
      //   let marketPair = `${this.selectedCurrency}-${this.selectedCoin}`
      //   marketPair = marketPair.toUpperCase()
      //
      //   this.$get(`${this.$OTC_DISPLAY_TICKER_UPBIT}/${marketPair}`, 'otcOfferGetUpbit', true, (result) => {
      //     let midPrice = result.data[0].mid
      //
      //     if(midPrice && this.selectedCurrency === 'KRW') {
      //       this.price = this.subDec(midPrice, this.selectedCurrency)
      //     } else {
      //       // 환율계산하는 api 화폐당 더 나와야함
      //     }
      //   }, (result) => {
      //     this.notify('error', result.message)
      //   })
      // } else if(this.selectedExchange === 'BINANCE') {
      //   let marketPair = `${this.selectedCoin}${this.selectedCurrency}`
      //
      //   this.$get(`${this.$OTC_DISPLAY_TICKER_BINANCE}/${marketPair}`, 'otcOfferGetBinance', true, (result) => {
      //     let midPrice = result.data[0].price
      //     console.log(midPrice)
      //   }, (result) => {
      //     this.notify('error', result.message)
      //   })
      // } else {
      //   this.price = 0
      // }

      if (this.selectedExchange === 'NONE') {
        this.price = 'NONE'
      } else {
        this.price = this.selectedExchange + this.markUp.toString()
      }
    },

    openAddDesc() {
      this.showAddDesc = true
      this.showConfirmOffer = false
      this.$refs.Modal.showDialog(this.$t('message.extraNote'), null, this.$t('message.close'), ()=>{}, ()=>{})
    },

    confirmOffer() {
      if(!this.selectedCurrency || !this.selectedCoin || !this.amount || !this.price) {
        this.notify('error', this.$t('message.warningEmptyBlank'))
        return
      }
      // if(!this.bank && !this.pps && !this.alipay && !this.wechat) {
      //   this.notify('error', this.$t('message.selectPayMethod'))
      //   return
      // }

      this.showAddDesc = false
      this.showConfirmOffer = true
      this.confirmChk = false
      this.$refs.Modal.showDialog(this.modalTitle, null, this.$t('message.cancel'), ()=>{}, ()=>{})
    },

    toggleChk() {
      if(this.confirmChk) {
        this.$refs.Modal.showDialog(this.modalTitle, this.$t('message.confirm'), this.$t('message.cancel'), () => {
          let formData = new FormData()

          let isBuy
          this.offerType === 'buy' ? isBuy = true : isBuy = false

          let isSplit
          this.tradeType === 'splitTrade' ? isSplit = true : isSplit = false

          let isPriceRef
          this.priceType === 'floatingPrice' ? isPriceRef = true : isPriceRef = false

          formData.append('marketPair', `${this.selectedCoin}-${this.selectedCurrency}`)
          formData.append('isBuy', isBuy)
          formData.append('isSplit', isSplit)
          if(isSplit) {
            formData.append('minUnit', this.minAmount.toString())
          }
          formData.append('totalAmount', this.amount.toString())
          formData.append('isPriceRef', isPriceRef)
          if(!isPriceRef) {
            formData.append('price', this.price.toString())
          }
          if(isPriceRef) {
            formData.append('markUp', this.markUp.toString())
            formData.append('exchanges', this.selectedExchange)
          }
          formData.append('memo', this.memo.toString())
          formData.append('bank ', this.bank)
          formData.append('pps ', this.pps)
          formData.append('alipay', this.alipay)
          formData.append('wechat', this.wechat)

          this.$post(this.$OTC_OFFER, 'otcOfferPostOffer', formData, true, () => {
            this.getUserProfile((result) => {
              if(result.success) {
                let nickname = result.userProfile.nickName
                this.$router.push({ name: 'OtcConfirm', params: { 'nickname': nickname, 'offerType': this.offerType }})

                this.amount = 0
                this.minAmount = 0
                this.selectedExchange = this.exchangeList[0]
                this.markUp = 0
                this.price = 'NONE'
                this.memo = ''
                this.bank = false
                this.pps = false
                this.alipay = false
                this.wechat = false
              } else {
                console.log(result.userProfile)
              }
            })
          }, (result) => {
            console.log(result)
          })
        }, ()=>{})
      } else {
        this.$refs.Modal.showDialog(this.modalTitle, null, this.$t('message.cancel'), ()=>{}, ()=>{})
      }
    }
  },

  watch: {
    'markUp': {
      handler() {
        this.markUp = Math.round(this.markUp * 1000) / 1000
      }
    },

    // 'amount': {
    //   handler() {
    //     this.totalPrice = Number(this.amount) * this.price
    //   }
    // },
    //
    // 'price': {
    //   handler() {
    //     this.totalPrice = Number(this.amount) * this.price
    //   }
    // },
  }

}
</script>

<style scoped>

</style>
