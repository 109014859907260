<template>
  <div class="tabmenu mypage">
    <router-link :to="{ name: 'OtcSuggestionMarketList' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 0 }">{{ $t('message.otcMarket') }}</router-link>
    <router-link :to="{ name: 'OtcSuggestionHistoryList' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 1 }">{{ $t('message.myTradingHistory') }}</router-link >
    <router-link :to="{ name: 'OtcSuggestionChatList' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 2 }">{{ $t('message.otcChat') }}</router-link>
    <router-link :to="{ name: 'OtcSuggestionMypostList'}" class="content_tab" :class="{ 'tab_focus1': tabNo === 3 }">{{ $t('message.myPost') }}</router-link>
  </div>
</template>

<script>
export default {
  name: "otcSuggestionMenuPc",

  data() {
    return {
      tabNo: 0
    }
  },

  methods: {
    focusTab(tabNo) {
      this.tabNo = tabNo
    }
  }
}
</script>

<style scoped>

</style>
