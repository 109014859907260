<template>
  <div class="body_wrap">
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container otc_list_all">
        <OtcMenuPC ref="OtcMenuPC"></OtcMenuPC>
        <div class="wkx_content">
          <div class="wkx_left">
            <div class="btn_otc_wrap">
              <div class="btn_otc" @click="switchOtcType(undefined)" :class="{ active: isAll && !isBuy}">
                <a>{{ $t('message.all') }}</a>
              </div>
              <div class="btn_otc" @click="switchOtcType(false)" :class="{ active: !isAll && !isBuy }">
                <a>{{ $t('message.doSell') }}</a>
              </div>
              <div class="btn_otc" @click="switchOtcType(true)" :class="{ active: !isAll && isBuy }">
                <a>{{ $t('message.doBuy') }}</a>
              </div>
            </div>
            <div class="tablewrapper">
              <div class="table_wkx_box">
                <table class="table_wkx">
                  <caption></caption>
                  <colgroup>
                    <col style="width: 20%;">
                    <col style="width: 80%;">
                  </colgroup>
                  <thead>
                  <tr>
                    <th class="t_left">{{ $t('message.coinName') }}</th>
                    <th class="t_right">{{ $t('message.count') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="cnt in cntByCoin" :key="cnt.coin" @click="selectRow(cnt)" :class="{ active: cnt.active }">
                    <td class="t_left">{{ cnt.coin === 'ALL' ? $t('message.all') : cnt.coin.includes('UNLISTED') ? cnt.coin.split('-')[1] + '('+ $t('message.directTran') +')' : cnt.coin }}</td>
                    <td class="t_right">{{ cnt.count }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="wkx_right mobile">
            <p>{{ isAll ? '' : isBuy ? $t('message.doBuy') : $t('message.doSell') }} > {{ selectedCoin === 'ALL' ? $t('message.all') : selectedCoin.includes('UNLISTED') ? selectedCoin.split('-')[1] + '('+ $t('message.directTran') +')' : selectedCoin }}</p>
            <div class="table_top">
              <div class="btn_link point" @click="checkRegistrationOtcOffer">
                <span>{{ $t('message.registration') }}</span>
              </div>
              <div style="display: flex">
                <div>
                  <select class="point" style="width: 120px; height: 40px;" v-model="searchFilter" @change="searchFilter === 'all' ? keyword = '' : ''">
                    <option value="all">{{ $t('message.all') }}</option>
                    <option value="title">{{ $t('message.title') }}</option>
                    <option value="nickname">{{ $t('message.nickname') }}</option>
                    <option value="safe_trade">{{ $t('message.safeTran') }}</option>
                    <option value="trade_limit">{{ $t('message.tradeLimit') }}</option>
                  </select>
                </div>
                <p class="coin_search_c_wrap">
                  <input class="search_c" type="text" v-model="keyword" :disabled="searchFilter === 'all'" @keyup.enter="getPostList(1)"/>
                  <em class="coin_search_c_icon"><i class="fas fa-search"></i></em>
                </p>
                <div class="btn_link point" @click="getPostList(1)">
                  <span>{{ $t('message.search') }}</span>
                </div>
              </div>
            </div>
            <div class="scroll mt20">
              <table class="table_i">
                <colgroup>
                  <col style="width: 17%;">
                  <col style="width: auto;">
                  <col style="width: 15%;">
                  <col style="width: 15%;">
                  <col style="width: 15%;">
                </colgroup>
                <thead>
                <tr class="columns">
                  <th scope="col"></th>
                  <th scope="col">{{ $t('message.title') }}</th>
                  <th scope="col">
                    <input type="checkbox" class="point" v-model="isTrustUser" @change="getPostList(1)"/>
                    <em class="m_left5">Trust User</em>
                    <span>T</span>
                  </th>
                  <th scope="col">{{ $t('message.nickname') }}</th>
                  <th scope="col">{{ $t('message.time') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="post in postList" :key="post.idx" @click="goToDetail(post)" class="point">
                  <td scope="row">{{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}
                    <span :class="{
                      'price_red': post.state !== 'SOLDOUT' && post.stateVal === 'doBuy',
                      'price_blue': post.state !== 'SOLDOUT' && post.stateVal === 'doSell' }"
                    >
                      {{ post.state === 'SOLDOUT' ? $t('message.endOfSale') : $t(`message.${post.stateVal}`) }}
                    </span>
                  </td>
                  <td scope="row">{{ post.title }}</td>
                  <td class="t_right"><span class="trust-mark" v-show="post.writerStateTrust">T</span></td>
                  <td scope="row" class="t_center">{{ post.writerNickname }}</td>
                  <td scope="row" class="t_center">{{ post.timeGap }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="no_list mt20" v-show="!postList.length">{{ $t('message.noData') }}</p>
            <div class="buttonwrap t_center">
              <paginate
                  :page-count="pageLast"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="getPostList"
                  :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :first-last-button=true
                  :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :container-class="'pagination'">
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcMenuPC from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_pc'
import OtcMenuMobile from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_mobile'

export default {
  name: "OtcSuggestionMarketList",

  data() {
    return {
      userInfo: {
        userIdx: 0,
        email: '',
        nickName: '',
        phone: '',
        level: 'LV1',
        profileImage: ''
      },
      pageLast: 0,
      searchListPageLast: 0,
      isBuy: false,
      cntByCoin: [],
      selectedCoin: 'ALL',
      postList: [],
      searchFilter: 'all',
      keyword: '',
      limit: 10,
      isTrustUser: false,
      page: 1,
      isAll: false,
      otcList: [],
    }
  },

  components: {
    'OtcMenuPC': OtcMenuPC,
    'OtcMenuMobile': OtcMenuMobile,
  },

  created() {
    this.isBuy = sessionStorage.getItem('otcSuggestionListType') === 'true'

    let coinFilter = sessionStorage.getItem('otcCoinFilter')
    if(coinFilter) {
      this.selectedCoin = coinFilter
    }

    this.$get(this.$OTC_TEMP_TICKERS + '?isEscrow=1', 'getOTCTempTickers', true, (result) => {
      this.otcList = result.data

      this.getPostList(1)
      this.getCoinList()
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$refs.OtcMenuPC.focusTab(0)
    this.$refs.OtcMenuMobile.selectMenu(this.$t('message.otcMarket'))
  },

  methods: {
    checkRegistrationOtcOffer() {
      this.getUserProfile((result) => {
        if(result.success) {
          this.userInfo = result.userProfile
          let level = Number(this.userInfo.level.substring(2, 3));
          if (level < 4) {
            this.notify('error', this.$t('message.levelLimit4'));
          }
        } else {
          console.log(result.userProfile)
        }
      });

      this.$router.push({ name: 'OtcSuggestionWrite', params: { isBuy : this.isBuy.toString() } });
    },

    async switchOtcType(isBuy) {
      if (isBuy === undefined) {
        this.isAll = true;
        this.isBuy = false;
      } else {
        this.isAll = false;
        this.isBuy = isBuy;
      }
      this.selectedCoin = 'ALL'
      await this.getCoinList()
      await this.getPostList(1)

      sessionStorage.setItem('otcSuggestionListType', isBuy)
      this.selectRow(this.cntByCoin[0], true)
    },

    goToDetail(post) {
      this.$router.push({ name: 'OtcSuggestionMarketDetail', params: { postIdx: post.idx, tabNo: 0 } })
    },

    selectRow(cnt, fromSwitchOtcType = false) {
      sessionStorage.setItem('otcCoinFilter', cnt.coin)

      if(!fromSwitchOtcType) {
        if(window.innerWidth < 480) {
          this.$router.push({ name: 'OtcSuggestionMarketListMobile' })
          return
        }
      }

      this.selectedCoin = cnt.coin
      this.cntByCoin.forEach(coinObj => {
        if(coinObj.coin === this.selectedCoin) {
          coinObj.active = true
        } else {
          coinObj.active = false
        }
      })

      this.getPostList(1)
    },

    getPostList(page = 1) {
      this.page = page
      let formData = new FormData()

      formData.append('all', this.isAll);
      formData.append('isBuy', this.isBuy)

      this.selectedCoin !== 'ALL' ? formData.append('coin', this.selectedCoin) : ''
      formData.append('filter', this.searchFilter.toUpperCase())
      formData.append('keyword', this.keyword)
      formData.append('limit', this.limit)
      formData.append('page', this.page)
      // 마켓 리스트에는 팝니다, 삽니다, 매매종료 세가지만 표시
      formData.append('state', 'OPEN')
      formData.append('state', 'TRADING')
      //formData.append('state', 'SOLDOUT')
      this.isTrustUser ? formData.append('stateTrust', this.isTrustUser) : ''

      this.$post(this.$OTC_TEMP_LIST, 'otcTempList', formData, false, (result) => {
        this.postList = result.data
        this.pageLast = Math.ceil(result.total / this.limit)

        let currentDate = new Date()
        let timestamp = currentDate.getTime() / 1000

        this.postList.forEach(post => {
          let timeGap = timestamp - post.createdAt / 1000

          // 24시간 = 86400초
          if(timeGap < 1) {
            post.timeGap = this.$t('message.before0')
          } else if(timeGap < 86400) {
            post.timeGap = this.secondsToHms(timeGap)
          } else if(timeGap >= 86400) {
            post.timeGap = this.secondsToDay(timeGap)
          }

          if(post.boardIsBuy) {
            post.stateVal = 'doBuy'
          } else {
            post.stateVal = 'doSell'
          }

          if(post.state === 'SOLDOUT') {
            post.stateVal = 'endOfSale'
          }
        })
      }, (result) => {
        console.log(result)
      })
    },

    secondsToHms(timeGap) {
      let h = Math.floor(timeGap % (3600*24) / 3600)
      let m = Math.floor(timeGap % 3600 / 60)
      let s = Math.floor(timeGap % 60)

      let hDisplay = h > 0 ? h + this.$t('message.time') + ' ' : ''
      let mDisplay = m > 0 ? m + this.$t('message.min') + ' ' : ''
      let sDisplay = s > 0 ? s + this.$t('message.sec') + ' ' : ''
      return hDisplay + mDisplay + sDisplay + this.$t('message.before')
    },

    secondsToDay(timeGap) {
      let d = Math.floor(timeGap / (3600*24))

      let dDisplay = d > 0 ? d + this.$t('message.day') + ' ' : ''
      return dDisplay + this.$t('message.before')
    },

    getCoinList() {
      this.$get(`${this.$OTC_TEMP_COUNT}?isBuy=${this.isBuy}&isAll=${this.isAll}`, 'otcSuggestionOtcListAll', false, (result) => {
        this.cntByCoin = []
        for (let i = 0; i < this.otcList.length; i++) {
          let found = false
          for (let j = 0; j < result.data.length; j++) {
            if(this.otcList[i] === result.data[j].coin) {
              this.cntByCoin.push(result.data[j])
              found = true

              break
            }
          }

          if(found === false) {
            this.cntByCoin.push({
              coin: this.otcList[i],
              count: 0,
            })
          }
        }
        let cntAll = 0
        this.cntByCoin.forEach(coinObj => {
          for (let j = 0; j < result.data.length; j++) {
            if('UNLISTED-' + coinObj.coin === result.data[j].coin) {
              coinObj.count += result.data[j].count
              break;
            }
          }
          cntAll += coinObj.count
        })

        this.cntByCoin.unshift({
          coin: 'ALL',
          count: cntAll,
        })

        let coinFilter = sessionStorage.getItem('otcCoinFilter')
        if(coinFilter) {
          this.cntByCoin.forEach(coinObj => {
            if(coinFilter === coinObj.coin) {
              coinObj.active = true
            } else {
              coinObj.active = false
            }
          })
        } else {
          this.cntByCoin.forEach(coinObj => {
            if(coinObj.coin === 'ALL') {
              coinObj.active = true
              sessionStorage.setItem('otcCoinFilter', 'ALL')
            } else {
              coinObj.active = false
            }
          })
        }


      }, (result) => {
        console.log(result)
      })
    }
  }
}
</script>

<style scoped>

</style>
