<template>
  <div class="body_wrap">
    <Modal ref="Modal">
      <p>{{ title }}</p>
      <table class="table_m">
        <tr>
          <th class="t_left">{{ $t('message.contact') }}</th>
          <td><input type="text" v-model="userPhone" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.email') }}</th>
          <td><input type="text" v-model="userEmail" disabled></td>
        </tr>
        <tr v-for="qna in qnaInfo" :key="qna.title" v-show="qna.content">
          <th class="t_left">{{ qna.title }}</th>
          <td v-show="qna.title !== 'file'"><textarea class="scroll" :placeholder="qna.content" disabled></textarea></td>
          <td v-show="qna.title === 'file'"><div><img :src="qna.content" :alt="$t('message.file')"/></div></td>
        </tr>
        <tr v-for="(answer, answerIdx) in answers" :key="answerIdx" v-show="answer.comment">
          <th class="t_left">{{ $t('message.answer') }}</th>
          <td><textarea class="scroll" :placeholder="answer.comment" disabled></textarea></td>
        </tr>
      </table>
    </Modal>

    <div class="pagew">
      <div class="subcontainer1">
        <div class="cs_wrap">
          <h2 class="fs24 dblue_02 fwm">{{ $t('message.customerCenter') }}</h2>
          <div class="cs_search">
            <div class="search_form">
              <h6 class="fs18">{{ $t('message.helpComment') }}</h6>
              <div class="inputwrap3">
                <input type="text" :placeholder="$t('message.enterKeyword')" v-model="keyword" @keyup.enter="search"/>
                <span class="btn_search" @click="search">
                  <i class="fas fa-search"></i>
                </span>
              </div>
            </div>
          </div>
          <!-- 초보자 가이드영역 -->
<!--          <div class="cs_guide">-->
<!--            <h6 class="dblue_02 fwm">{{ $t('message.beginnerGuide') }}</h6>-->
<!--            &lt;!&ndash; 초보자 가이드 PC &ndash;&gt;-->
<!--            <div class="cs_card_pc">-->
<!--              <div class="card_img" @click="$router.push({ name: 'Signup' })">-->
<!--                <img src="/img/cs_card1.svg" alt="회원가입방법">-->
<!--                <div class="card_content">-->
<!--                  <div class="cardleft">-->
<!--                    <img src="/img/card_icon1.svg" alt="회원가입방법">-->
<!--                  </div>-->
<!--                  <div class="cardright">-->
<!--                    <div class="logowrap"><img src="/img/white_logo.svg" alt="logo"></div>-->
<!--                    <p>{{ $t('message.howToSignup') }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card_img" @click="$router.push({ name: 'OtcSuggestionListAll' })">-->
<!--                <img src="/img/cs_card2.svg" alt="C2C 거래">-->
<!--                <div class="card_content">-->
<!--                  <div class="cardleft">-->
<!--                    <img src="/img/card_icon2.svg" alt="C2C 거래">-->
<!--                  </div>-->
<!--                  <div class="cardright">-->
<!--                    <div class="logowrap"><img src="/img/white_logo.svg" alt="logo"></div>-->
<!--                    <p>{{ $t('message.otc') }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card_img" @click="goToMobile">-->
<!--                <img src="/img/cs_card3.svg" alt="PC/ Mobile">-->
<!--                <div class="card_content">-->
<!--                  <div class="cardleft">-->
<!--                    <img src="/img/card_icon3.svg" alt="PC/ Mobile">-->
<!--                  </div>-->
<!--                  <div class="cardright">-->
<!--                    <div class="logowrap"><img src="/img/white_logo.svg" alt="logo"></div>-->
<!--                    <p>PC / Mobile</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card_img" @click="$router.push({ name: 'GeneralMarket' })">-->
<!--                <img src="/img/cs_card4.svg" alt="암호화폐 거래">-->
<!--                <div class="card_content">-->
<!--                  <div class="cardleft">-->
<!--                    <img src="/img/card_icon4.svg" alt="암호화폐 거래">-->
<!--                  </div>-->
<!--                  <div class="cardright">-->
<!--                    <div class="logowrap"><img src="/img/white_logo.svg" alt="logo"></div>-->
<!--                    <p>{{ $t('message.cryptoTrade') }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; 초보자 가이드 mobile &ndash;&gt;-->
<!--            <div class="coin_graph_mobile">-->
<!--              <div class="swiper-container coin_graph_slide">-->
<!--                <div class="swiper-wrapper">-->
<!--                  <div class="swiper-slide graph_coin" @click="$router.push({ name: 'Signup' })">-->
<!--                    <div class="slide_con">-->
<!--                      <div class="coin_graph">-->
<!--                        <img src="/img/cs_card1.svg" alt="card1">-->
<!--                        <div class="card_content">-->
<!--                          <div class="cardleft">-->
<!--                            <img src="/img/card_icon1.svg" alt="회원가입방법">-->
<!--                          </div>-->
<!--                          <div class="cardright">-->
<!--                            <div class="logowrap"><img src="/img/white_logo.svg" alt="logo"></div>-->
<!--                            <p>{{ $t('message.howToSignup') }}</p>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="swiper-slide graph_coin" @click="$router.push({ name: 'OtcSuggestionListAll' })">-->
<!--                    <div class="slide_con">-->
<!--                      <div class="coin_graph">-->
<!--                        <img src="/img/cs_card2.svg" alt="card2">-->
<!--                        <div class="card_content">-->
<!--                          <div class="cardleft">-->
<!--                            <img src="/img/card_icon2.svg" alt="C2C 거래">-->
<!--                          </div>-->
<!--                          <div class="cardright">-->
<!--                            <div class="logowrap"><img src="/img/white_logo.svg" alt="logo"></div>-->
<!--                            <p>{{ $t('message.otc') }}</p>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="swiper-slide graph_coin" @click="goToMobile">-->
<!--                    <div class="slide_con">-->
<!--                      <div class="coin_graph">-->
<!--                        <img src="/img/cs_card3.svg" alt="card3">-->
<!--                        <div class="card_content">-->
<!--                          <div class="cardleft">-->
<!--                            <img src="/img/card_icon3.svg" alt="PC/ Mobile">-->
<!--                          </div>-->
<!--                          <div class="cardright">-->
<!--                            <div class="logowrap"><img src="/img/white_logo.svg" alt="logo"></div>-->
<!--                            <p>PC / Mobile</p>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="swiper-slide graph_coin" @click="$router.push({ name: 'GeneralMarket' })">-->
<!--                    <div class="slide_con">-->
<!--                      <div class="coin_graph">-->
<!--                        <img src="/img/cs_card4.svg" alt="card4">-->
<!--                        <div class="card_content">-->
<!--                          <div class="cardleft">-->
<!--                            <img src="/img/card_icon4.svg" alt="암호화폐 거래">-->
<!--                          </div>-->
<!--                          <div class="cardright">-->
<!--                            <div class="logowrap"><img src="/img/white_logo.svg" alt="logo"></div>-->
<!--                            <p>{{ $t('message.cryptoTrade') }}</p>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="swiper-button-next coin_graph_next"></div>-->
<!--              <div class="swiper-button-prev coin_graph_prev"></div>-->
<!--            </div>-->
<!--          </div>-->
          <!-- cs / 게시판영역 -->
          <div class="cs_board">

            <div class="notice" v-show="isSearched">
              <h5 class="help_tit">{{ $t('message.searchResult') }}</h5>
              <ul v-if="!searchList.length">
                <li class="t_center">{{ $t('message.noData') }}</li>
              </ul>
              <ul v-for="search in searchList" :key="search.idx">
                <li>
                  <a class="point t_left" @click="goToDetail(search.code, search.idx)">{{ search.title }}</a>
                </li>
              </ul>
            </div>

            <div v-show="!isSearched">
              <div class="help">
                <div class="help_box1">
                  <div class="notice">
                    <h5 class="help_tit">{{ $t('message.announcement') }}</h5>
                    <ul v-if="noticeList.length === 0">
                      <li>
                        {{ $t('message.noData') }}
                      </li>
                    </ul>
                    <ul v-for="notice in noticeList" :key="notice.idx">
                      <li>
                        <a class="point" @click="goToDetail('notice', notice.idx)">{{ notice.title }}</a>
                      </li>
                    </ul>
                    <router-link :to="{ name: 'CustomerSupportList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
                  </div>
                </div>
                <div class="help_box2">
                  <div class="notice">
                    <h5 class="help_tit">{{ $t('message.faq') }}</h5>
                    <ul v-if="faqList.length === 0">
                      <li>
                        {{ $t('message.noData') }}
                      </li>
                    </ul>
                    <ul v-for="faq in faqList" :key="faq.idx">
                      <li>
                        <a class="point" @click="goToDetail('faq', faq.idx)">{{ faq.title }}
                        </a>
                      </li>
                    </ul>
                    <router-link :to="{ name: 'CustomerSupportFAQList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
                  </div>
                </div>
              </div>
              <div class="help">
                <div class="help_box1">
                  <div class="notice">
                    <h5 class="help_tit">{{ $t('message.deposit') }} / {{ $t('message.withdrawal') }}</h5>
                    <ul v-if="transferList.length === 0">
                      <li>
                        {{ $t('message.noData') }}
                      </li>
                    </ul>
                    <ul v-for="transfer in transferList" :key="transfer.idx">
                      <li>
                        <a class="point" @click="goToDetail('transfer', transfer.idx)">{{ transfer.title }}
                        </a>
                      </li>
                    </ul>
                    <router-link :to="{ name: 'CustomerSupportTransferList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
                  </div>
                </div>
                <div class="help_box2">
                  <div class="notice">
                    <h5 class="help_tit">{{ $t('message.trading') }}</h5>
                    <ul v-if="tradeList.length === 0">
                      <li>
                        {{ $t('message.noData') }}
                      </li>
                    </ul>
                    <ul v-for="trade in tradeList" :key="trade.idx">
                      <li>
                        <a class="point" @click="goToDetail('trade', trade.idx)">{{ trade.title }}
                        </a>
                      </li>
                    </ul>
                    <router-link :to="{ name: 'CustomerSupportTradeList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
                  </div>
                </div>
              </div>
              <div class="help">
                <div class="help_box1">
                  <div class="notice">
                    <h5 class="help_tit">{{ $t('message.auth') }} / OTP</h5>
                    <ul v-if="authList.length === 0">
                      <li>
                        {{ $t('message.noData') }}
                      </li>
                    </ul>
                    <ul v-for="auth in authList" :key="auth.idx">
                      <li>
                        <a class="point" @click="goToDetail('auth', auth.idx)">{{ auth.title }}
                        </a>
                      </li>
                    </ul>
                    <router-link :to="{ name: 'CustomerSupportAuthList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
                  </div>
                </div>
                <div class="help_box2">
                  <div class="notice">
                    <h5 class="help_tit">{{ $t('message.goInquiry') }}</h5>
                    <ul v-if="qnaList.length === 0">
                      <li>
                        {{ $t('message.noData') }}
                      </li>
                    </ul>
                    <ul v-for="qna in qnaList" :key="qna.idx">
                      <li>
                        <a class="point" @click="openQnaDetail(qna.idx)">{{ qna.title }}
                        </a>
                      </li>
                    </ul>
                    <ul>
                      <li><router-link :to="{ name: 'MyPageQnaRegist' }" class="btn_inquiry">{{ $t('message.goInquiry') }}</router-link></li>
                    </ul>
                    <router-link :to="{ name: 'MyPageQnaList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/cmp/modal/modal'

export default {
  name: "CustomerSupportCenter",
  components: {
    'Modal': Modal
  },
  data() {
    return {
      codeLocale : '',
      showMenuBtns: true,
      code : '', // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, transfer : 입출금, faq : 자주 묻는 질문, qna : 문의
      noticeList : [],
      faqList : [],
      qnaList : [],
      tradeList : [],
      authList : [],
      transferList : [],
      qnaInfo: [],
      answers: [],
      title : '',
      userPhone: '',
      userEmail: '',
      rerenderKey: 0,
      pageLast: 0,
      keyword: '',
      isSearched: false,
      searchList: [],
    }
  },

  mounted() {
    if (this.$root.$i18n.locale !== 'ko') {
      this.codeLocale = '_' + this.$root.$i18n.locale
    } else {
      this.codeLocale = ''
    }

    new window.Swiper(".coin_graph_slide", {
      slidesPerView: 1,
      spaceBetween: 0,
      breakpoints: {
        1024 : {
          slidesPerView: 3,
        },
        768 : {
          slidesPerView: 2,
        },
        360 : {
          slidesPerView: 1,
        }
      },
      loop: true,
      navigation: {
        nextEl: ".coin_graph_next",
        prevEl: ".coin_graph_prev",
      },
    })

    this.getAnnounceList(1)
    if(this.$store.getters.getLoginState) {
      this.getQnaList(1)
    }
  },

  methods: {
    search() {
      this.$get(`${this.$BOARD_SEARCH}?search=${this.keyword}&page=1&limit=10`, 'searchBoard', false, (result) => {
        this.isSearched = true
        this.searchList = result.data
      }, (result) => {
        console.log(result)
      })
    },

    goToMobile() {
      if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPod/i)) {
        window.location.href = process.env.VUE_APP_MOBILE_DOMAIN
      }
    },

    getAnnounceList(page) {
      let limit = 5
      // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, transfer : 입출금, faq : 자주 묻는 질문, qna : 문의

      this.code = 'notice'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.noticeList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })

      this.code = 'trade'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.tradeList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })

      this.code = 'auth'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.authList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })

      this.code = 'transfer'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.transferList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })

      this.code = 'faq'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.faqList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    getQnaList(page) {
      let limit = 5
      this.$get(`${this.$USER_MYPAGE_QNA}?page=${page}&limit=${limit}`, 'myPageQnaGetList', true, (result) => {
        this.qnaList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    goToDetail(code, idx) {
      if(code === 'notice') {
        this.$router.push({ name: 'CustomerSupportView', params: { articleIdx : idx }} )
      } else if(code === 'faq') {
        this.$router.push({ name: 'CustomerSupportFAQView', params: { articleIdx : idx }} )
      } else if(code === 'transfer') {
        this.$router.push({ name: 'CustomerSupportTransferView', params: { articleIdx : idx }} )
      } else if(code === 'trade') {
        this.$router.push({ name: 'CustomerSupportTradeView', params: { articleIdx : idx }} )
      } else if(code === 'auth') {
        this.$router.push({ name: 'CustomerSupportAuthView', params: { articleIdx : idx }} )
      }
    },

    goToQnaDetail(qnaIdx) {
      this.$router.push({ name: 'MyPageQnaDetail', params: { qnaIdx: qnaIdx }} )
    },

    async openQnaDetail(idx) {
      this.$get(`${this.$USER_MYPAGE_QNA_DETAIL}/${idx}`, 'MyPageQnaListDetail', true, (result) => {
        this.qnaInfo = []
        this.answers = []

        let qnaDetail = result.data[0]
        let qnaKeys = Object.keys(qnaDetail)
        let qnaValues = Object.values(qnaDetail)

        this.getUserProfile((result) => {
          if(result.success) {
            this.userPhone = result.userProfile.phone
            this.userEmail = result.userProfile.email
          } else {
            console.log(result.userProfile)
          }
        })

        qnaKeys.forEach((qna, idx) => {
          if(qna === 'title') {
            this.title = qnaValues[idx]
          } else if (qna === 'comments'){
            this.answers = qnaValues[idx]
          } else if(qna !== 'state'){
            this.qnaInfo.push({
              title: qna,
              content: qnaValues[idx]
            })
          }
        })
      }, (result) => {
        console.log(result)
      })

      this.$refs.Modal.showDialog(this.$t('message.myInquiries'), '', this.$t('message.close'), () => {}, ()=>{})
    },
  },

  watch: {
    'keyword': {
      handler() {
        if(!this.keyword) {
          this.isSearched = false
        }
      }
    },
    '$root.$i18n.locale': {
      handler() {
        if (this.$root.$i18n.locale !== 'ko') {
          this.codeLocale = '_' + this.$root.$i18n.locale
        } else {
          this.codeLocale = ''
        }

        this.getAnnounceList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>
