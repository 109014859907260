<template>
  <div class="body_wrap otc_s_detail otc_his_detail">
    <audio id="audio" src="/data/chat.mp3"></audio>
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container">
        <OtcMenuPC ref="OtcMenuPC"></OtcMenuPC>
        <div class="title_con mt20">
          <h2 class="support_title">
            <span :class="{ active: trade.otcTradeState === 'ACCEPT_READY' || trade.otcTradeState === 'DECLINED' }">{{ $t('message.tradeRequest') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
            <span :class="{ active: trade.otcTradeState === 'WAITING' }">{{ $t('message.tradeApp') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
            <span :class="{ active: trade.otcTradeState === 'DEPOSITED' }">{{ $t('message.depositAccept') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
            <span :class="{ active: trade.otcTradeState === 'TRADED' }">{{ $t('message.traded') }}</span>
          </h2>
          <div>
            <a class="outline_s_btn m_left10" @click="getTradeDetail(false)">{{ $t('message.refresh') }}<i class="fas fa-redo-alt m_left10"></i></a>
            <a class="outline_s_btn m_left10" @click="goToMarketDetail">{{ $t('message.goToPostDetail') }}</a>
          </div>
        </div>
        <div class="support_viewrap">
          <div class="view_top">
            <div class="view_title">
              <h5 class="fwm">{{ post.writerNickname }}<span class="m_left25">({{ $t('message.postTrade') }} {{ writerInfo.allCnt }} | {{ $t('message.traded') }} {{ writerInfo.soldoutCnt }})</span></h5>
            </div>
          </div>
          <div class="view_content his-detail">
            <div>
              <p>{{ $t('message.tradingNum') }}: {{ trade.tradeIdx }}</p>
              <p>{{ $t('message.tradeDate') }}: {{ formatDate(trade.timestamp, 'full') }}</p>
              <p>{{ $t('message.trading') }}{{ $t('message.nickname') }}: {{ post.oppositeNickname }}</p>
              <p>{{ $t('message.market') }}: {{ !trade.marketPair.includes('UNLISTED') ? trade.marketPair.split('-')[0] : trade.marketPair.split('-')[1] + '(' + $t('message.directTran') + ')' }}</p>
            </div>
            <div>
              <p>{{ $t('message.tradeAmt') }}: {{ trade.amountView | numberWithCommas }} <span v-show="(trade.otcTradeState === 'WAITING' || trade.otcTradeState === 'DEPOSITED') && post.tradeMethod === 0">({{ $t('message.coinEscrowed') }})</span></p>
              <p>{{ $t('message.transactionPrice') }}: {{ trade.priceToGo | numberWithCommas }}</p>
              <p>{{ $t('message.tradeState') }}: {{ $t(`message.${tradeStateInLang(trade.otcTradeState)}`) }}</p>
            </div>
          </div>

          <div class="view_content" v-show="trade.otcTradeState === 'ACCEPT_READY'" v-html="$t('message.guideWhenAcceptReady')"></div>
          <div class="view_content" v-show="trade.otcTradeState === 'DECLINED'" v-html="$t('message.guideWhenDeclined')"></div>
          <div class="view_content" v-show="trade.otcTradeState === 'WAITING'" v-html="$t('message.guideWhenWaiting')"></div>
          <div class="view_content" v-show="trade.otcTradeState === 'DEPOSITED'" v-html="$t('message.guideWhenDeposited')"></div>
          <div class="view_content" v-show="trade.otcTradeState === 'TRADED'" v-html="$t('message.guideWhenTraded')"></div>
          <div class="view_content" v-show="trade.otcTradeState === 'CANCEL'" v-html="$t('message.guideWhenCancel')"></div>

          <div class="view_content" v-show="trade.otcTradeState === 'ACCEPT_READY' || trade.otcTradeState === 'WAITING' || trade.otcTradeState === 'DEPOSITED'">
            <div class="btn_wrap" :key="rerenderBtn">
              <a class="outline_s_btn t_center m_left10" v-show="cancelBtn.show" :class="{ inactive: cancelBtn.disable }"
                 @click="!cancelBtn.disable ? cancelTrade() : ''">{{ $t('message.tradeCancel') }}
              </a>
              <a class="outline_s_btn t_center m_left10" v-show="acceptBtn.show" :class="{ inactive: acceptBtn.disable }"
                 @click="!acceptBtn.disable ? acceptTrade(true) : ''">{{ $t('message.tradeApp') }}
              </a>
              <a class="outline_s_btn t_center m_left10" v-show="acceptBtn.show" :class="{ inactive: acceptBtn.disable }"
                 @click="!acceptBtn.disable ? acceptTrade(false) : ''">{{ $t('message.tradeRefuse') }}
              </a>
              <a class="outline_s_btn t_center m_left10" v-show="depositedBtn.show" :class="{ inactive: depositedBtn.disable }"
                 @click="!depositedBtn.disable ? didDeposit() : ''">{{ $t('message.deposited') }}
              </a>
              <a class="outline_s_btn t_center m_left10" v-show="doneBtn.show" :class="{ inactive: doneBtn.disable }"
                 @click="!doneBtn.disable ? tradeDone() : ''">{{ $t('message.sendCoin') }}
              </a>
              <a class="outline_s_btn t_center m_left10" v-show="claimBtn.show" :class="{ inactive: claimBtn.disable }"
                 @click="!claimBtn.disable ? doClaim() : ''">{{ $t('message.claim') }}
              </a>
            </div>
          </div>
          <div class="chatting_con">
            <div class="chat_tit">
              <div>
                <i class="fas fa-comment"></i><span>{{ $t('message.chat') }}</span>
              </div>
            </div>
            <div>
              <div ref="Scroll" class="chatting scroll">
                <div class="msg_con">
                  <p class="fs12">{{ formatDate(new Date(), 'full') }}</p>
                  <p class="fs16 fwb t_center">
                    {{ $t('message.writing') }}:
                    {{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '(' + $t('message.directTran') + ')' }}
                    {{ post.boardIsBuy ? $t('message.doBuy') : $t('message.doSell') }}
                    | {{ $t('message.writer') }}: {{ post.writerNickname }}
                  </p>
                  <p class="fs16 fwb t_center">{{ $t('message.doConference') }}</p>
                </div>
                <div class="msg_balls">
                  <div class="msg_sent" v-for="(msg, msgIdx) in msgAll" :key="msgIdx" :class="{ flex_end: msg.userIdx === myIdx, flex_start: msg.userIdx !== myIdx}">
                    <p class="msg m-0" v-show="msg.comment">{{ msg.comment }}</p>
                    <img v-if="msg.fileHash && msg.fileImage && msg.fileLoaded" :src="msg.fileLink" :alt="msg.fileName" />
                    <p v-if="msg.fileHash && !msg.fileImage" class="msg">{{ msg.fileName }}</p>
                    <div v-if="msg.fileHash && msg.fileLoaded">
                      <a :download="msg.fileName" :href="$DOMAIN + $OTC_TEMP_CHAT_IMAGE + '?chatroomIdx=' + post.chatroomIdx + '&fileHash=' + msg.fileHash + '&comment=1' + chatKey" target="hidden_frame" class="btn btn-point btn-block">{{ $t('message.download') }}</a>
                    </div>
                    <p class="fs12" :class="{ time_r: msg.userIdx === myIdx, time_l: msg.userIdx !== myIdx}">{{ formatDate(msg.timestamp, 'full') }}</p>
                  </div>
                </div>
              </div>
              <div class="msg_tit">
                <i class="fas fa-comment-dots"></i>
                <span class="m_left5">{{ $t('message.msg') }}</span>
              </div>
              <div class="txt_con">
                <textarea :placeholder="$t('message.enterMsg')" v-model="msg" @keyup.enter="sendTxt"></textarea>
              </div>
              <div class="upload_con" :class="{flex_col: fileName}">
                <div class="left">
                  <div class="upload_btn">
                    <input type="file" class="custom-input" @change="upload($event)" accept="*">
                    <i class="fas fa-paperclip"></i>
                    <a class="m_left5">{{ $t('message.fileAttach') }}</a>
                  </div>
                  <div v-show="fileName" class="input_con">
                    <input type="text" class="input_file" v-model="fileName" disabled>
                    <span @click="deleteFile"><i class="fas fa-times"></i></span>
                  </div>
                </div>
                <div class="middle_btn1" @click="sendMsg"><a>{{ $t('message.send') }}</a></div>
              </div>
            </div>
          </div>
          <iframe src="about:blank" name="hidden_frame" id="hidden_frame" style="height: 1px;width: 100%; opacity: 0"></iframe>
          <div class="p_btn_wrap mt20">
            <a @click="$router.back(); clearInterval(this.chatInterval)" class="p_btn_blue">{{ $t('message.list') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcMenuPC from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_pc'
import OtcMenuMobile from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_mobile'

export default {
  name: "OtcSuggestionHistoryDetail",

  data() {
    return {
      fileName: '',
      msg: '',
      msgAll: [],
      post: {
        idx: 0,
        state: '',
        boardIsBuy: true,
        coin: '',
        title: '',
        writerUserIdx: 0,
        createdAt: 0,
        writerNickname: '',
        tradeMethod: 0,
        chatroom: [],
        content: '',
        exchanges: '',
        markUp: 0,
        offersList: [],
        chatroomIdx: null,
      },
      writerInfo: {
        allCnt: 0,
        soldoutCnt: 0
      },

      ifImgError: false,

      cancelBtn: {
        show: false,
        disable: false,
      },
      acceptBtn: {
        show: false,
        disable: false,
      },
      depositedBtn: {
        show: false,
        disable: false,
      },
      doneBtn: {
        show: false,
        disable: false,
      },
      claimBtn: {
        show: false,
        disable: false,
      },


      tradeIdx: this.$route.params.tradeIdx,
      tabNo: this.$route.params.tabNo,
      myIdx: null,

      trade: {
        amount: 0,
        createdAt: '',
        feeRateBuy: 0,
        feeRateSell: 0,
        feeTicker: '',
        tradeIsBuy: true,
        marketPair: '',
        nickname: '',
        offerUserIdx: null,
        oppositeNickname: null,
        oppositeStateTrust: null,
        otcBoardIdx: null,
        otcTradeState: '',
        priceToGo: '',
        sellerUserIdx: null,
        stateTrust: true,
        timestamp: 0,
        tradeIdx: null,
        userIdx: null,
        writerUserIdx: null,
      },
      chatInterval: null,
      chatIntervalLoaded: false,
      lastTimestamp: 0,
      imgFile: null,
      rerenderBtn: 0,
      tradeInterval: false,
      autoMsg: {
        opening: false,
        ending: false,
      },
      loaded: false,
      chatKey: '',
      busy: false,
    }
  },

  components: {
    'OtcMenuPC': OtcMenuPC,
    'OtcMenuMobile': OtcMenuMobile,
  },

  created() {
    if(!this.tradeIdx) {
      this.$router.back()
      return
    }
    if(!this.tabNo) {
      this.tabNo = 1
    }

    this.getUserProfile((result) => {
      if(result.success) {
        this.myIdx = result.userProfile.userIdx
      } else {
        console.log(result.userProfile)
      }
    })
  },

  beforeDestroy() {
    clearInterval(this.chatInterval)
    this.tradeInterval = false;
  },

  computed: {
    fileChanged: {
      get: function() {
        return this.fileName
      },
      set: function(newValue) {
        this.fileName = newValue
      }
    },
  },

  mounted() {
    this.$refs.OtcMenuPC.focusTab(this.tabNo)
    let tabStr = this.tabNo === 0 ? this.$t('message.otcMarket') : this.tabNo === 1 ? this.$t('message.myTradingHistory') : this.tabNo === 2 ? this.$t('message.otcChat') : this.tabNo === 3 ? this.$t('message.myPost') : ''
    this.$refs.OtcMenuMobile.selectMenu(tabStr)

    this.getTradeDetail(true)
    this.tradeInterval = true
    this.callTradeDetail()
  },

  methods: {
    callTradeDetail() {
      if(!this.tradeInterval) {
        return
      }
      setTimeout(() => {
        this.getTradeDetail(false, () => {
          this.callTradeDetail()
        });
      }, 5000)
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.fileChanged = file[0].name
      this.imgFile = [...file]
    },

    deleteFile() {
      this.imgFile = []
      this.fileChanged = ''
    },

    sendMsg() {
      if(!this.busy) {
        this.sendTxt()
        this.sendImg()
      } else {
        this.notify('error', this.$t('message.btnBusy'))
      }
    },

    sendTxt() {
      if(this.msg.trim() === '') {
        this.msg = ''
        return
      }

      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('tradeIdx', this.tradeIdx)
      formData.append('chatroomIdx', this.post.chatroomIdx)
      formData.append('comment', this.msg)

      this.$post(this.$OTC_TEMP_CHAT_ADD, 'chatAdd', formData, true, (result) => {
        console.log(result)
        this.msg = ''
      }, (result) => {
        console.log(result)
      })
    },

    sendImg() {
      if(!this.imgFile || this.imgFile.length === 0) {
        return
      }

      this.busy = true
      this.notify('success', this.$t('message.imageUploading'))

      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('chatroomIdx', this.post.chatroomIdx)
      formData.append('file', this.imgFile[0])

      this.$post(this.$OTC_TEMP_CHAT_UPLOAD, 'OtcChattingUpload', formData, true, () => {
        this.busy = false

        this.imgFile = []
        this.fileChanged = ''
      }, (result) => {
        console.log(result)
      })
    },

    getChatHistory() {
      if(!this.post.chatroomIdx) {
        return
      }

      let formData = new FormData()
      formData.append('chatroomIdx', this.post.chatroomIdx)
      formData.append('timestamp', this.lastTimestamp + 1)

      this.$post(this.$OTC_TEMP_CHAT_LIST, 'OtcProcessTradeChat', formData, true, (result) => {
        let msgList = result.data
        if(msgList.length) {
          if(this.chatIntervalLoaded) {
            for (let i = 0; i < msgList.length; i++) {
              if (msgList[i].userIdx !== this.myIdx) {
                document.getElementById('audio').play()
                break
              }
            }
          }

          this.modifyChatData(msgList)
          this.chatIntervalLoaded = true
        }
      }, (result) => {
        console.log(result)
      })
    },

    modifyChatData(msgList) {
      msgList.forEach((chat) => {
        if(this.chatKey === '' && chat.chatKey) {
          this.chatKey = chat.chatKey
        }

        chat.fileImage = false
        chat.fileLoaded = false

        if(chat.fileHash !== null) {
          if(!chat.fileLink) {
            let formData = new FormData()
            formData.append('idx', this.post.idx)
            formData.append('chatroomIdx', this.post.chatroomIdx)
            formData.append('fileHash', chat.fileHash)

            this.$downloadPost(this.$OTC_TEMP_CHAT_DOWNLOAD, 'ChatDownLoad_' + Math.random(), formData, true, (result) => {
              let file = this.$fileDownload(result)

              chat.fileLink = ''
              chat.fileName = file[1]

              let reader = new FileReader()
              reader.readAsDataURL(result.data)
              reader.onloadend = function () {
                chat.fileLink = reader.result
                chat.fileLoaded = true
              }

              if(chat.fileName.lastIndexOf('.') !== -1) {
                let filetype = chat.fileName.substring(chat.fileName.lastIndexOf('.') + 1)
                if(filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png' || filetype === 'gif') {
                  chat.fileImage = true
                }
              }
            }, (result) => {
              if(result) {
                this.notify('error', result.message)
              }
            })
          }
        }
      })

      let msgAll = [...this.msgAll, ...msgList]
      msgAll.sort(function(a, b) {
        return a.timestamp - b.timestamp
      })

      this.msgAll = msgAll

      this.lastTimestamp = this.msgAll[this.msgAll.length - 1].timestamp

      this.loaded = true

      setTimeout(() => {
        let scrollBox = this.$refs.Scroll
        scrollBox.scrollTop = scrollBox.scrollHeight
      }, 1000)
    },

    goToMarketDetail() {
      if(this.post.state === 'DELETED') {
        this.notify('error', '삭제된 글입니다.')
        return
      }

      clearInterval(this.chatInterval)
      this.$router.push({ name: 'OtcSuggestionMarketDetail', params: { postIdx: this.post.idx, tabNo: 0 } })
    },

    getAutoMsg(timing) {
      this.$get(`${this.$HOME_EXTRA_AUTO}/${this.post.writerUserIdx}`, 'getHomeExtraAutoOtc', false, (result) => {
        let data = result.data[0]
        if(data.using) {
          if(timing === 0 && data.opening) {
            this.notify('success', data.opening)
          } else if(timing === 1 && data.ending) {
            this.notify('success', data.ending)
          }
        }
      }, (result) => {
        console.log(result)
      })
    },

    getTradeDetail(firstCall, cb = null) {
      this.$get(`${this.$OTC_TEMP_TRADE}/${this.tradeIdx}`, 'OtcDetailGetListHistory', true, (result) => {
        if(this.tradeInterval === false) {
          return
        }

        this.post = result.data[0]
        this.trade = this.post.tradeDtoList[0]

        if(this.post.chatroom.length) {
          this.post.chatroomIdx = this.post.chatroom[0].chatroomIdx
        }

        if(firstCall) {
          this.getWriterInfo()
          if ((this.post.state === 'OPEN' || this.post.state === 'TRADING') && !this.post.chatroom.length) {
            this.createChatroom()
          }

          this.getChatHistory()
          this.chatInterval = setInterval(() => {
            if(!this.loaded) {
              return
            }
            this.getChatHistory()
          }, 1000)
        }

        this.whatAmI()

        if(this.post.tradeState === 'TRADED' && this.autoMsg.ending === false) {
          this.autoMsg.ending = true
          this.getAutoMsg(1)
        }

        if(this.post.tradeState === 'ACCEPT_READY' && this.autoMsg.opening === false) {
          this.autoMsg.opening = true
          this.getAutoMsg(0)
        }

        if(cb != null) {
          cb();
        }
      }, (result) => {
        console.log(result)
      })
    },

    createChatroom() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)
      this.$post(this.$OTC_TEMP_CHATROOM, 'otcCreateChat', formData, true, (result) => {
        this.post.chatroomIdx = result.data[0].chatroomIdx
      }, (result) => {
        console.log(result)
      })
    },

    getWriterInfo() {
      let formData = new FormData()
      formData.append('writerIdx', this.post.writerUserIdx)
      this.$post(this.$OTC_TEMP_COUNT, 'otcDetailCountHistory', formData, true, (result) => {
        this.writerInfo = result.data[0]
      }, (result) => {
        console.log(result)
      })
    },

    doClaim() {
      clearInterval(this.chatInterval)
      this.$router.push({ name: 'OtcSuggestionClaim', params: { tradeIdx: this.trade.tradeIdx }})
    },

    tradeDone() {
      let formData = new FormData()
      formData.append('tradeIdx', this.trade.tradeIdx)

      this.$post(this.$OTC_TRADE_TRANSFER, 'OtcSuggestionTransfer', formData, true, (result) => {
        this.doneBtn.disable = true
        this.notify('success', result.message)
        this.getTradeDetail(false)
      }, (result) => {
        console.log(result)
      })
    },

    didDeposit() {
      let formData = new FormData()
      formData.append('tradeIdx', this.trade.tradeIdx)

      this.$post(this.$OTC_TRADE_DEPOSIT, 'OtcSuggestionTradeDeposit', formData, true, (result) => {
        this.depositedBtn.disable = true
        this.notify('success', result.message)
        this.getTradeDetail(false)
      }, (result) => {
        console.log(result)
      })
    },


    acceptTrade(isAccept) {
      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('tradeIdx', this.trade.tradeIdx)
      formData.append('isAccept', isAccept)

      this.$post(this.$OTC_TEMP_TRADE_ACCEPT, 'OtcTempTradeAccept', formData, true, () => {
        this.acceptBtn.disable = true
        if(isAccept) {
          this.notify('success', this.$t('message.tradeApproved'))
        } else {
          this.notify('success', this.$t('message.tradeRefused'))
        }
        this.getTradeDetail(false)
      }, (result) => {
        console.log(result)
      })
    },

    cancelTrade() {
      let formData = new FormData()
      formData.append('tradeIdx', this.trade.tradeIdx)

      this.$post(this.$OTC_TRADE_CANCEL, 'OtcProcessTradeCancel', formData, true, (result) => {
        this.cancelBtn.disable = true
        this.notify('success', result.message)
        this.getTradeDetail(false)
      }, (result) => {
        console.log(result)
      })
    },

    showBtnIfAcceptReady() {
      this.cancelBtn.show = true
      this.depositedBtn.show = false
      this.doneBtn.show = false
      this.claimBtn.show = false
      if(this.post.amIWriter) {
        this.acceptBtn.show = true
      } else {
        this.acceptBtn.show = false
      }

      this.rerenderBtn += 1
    },

    showBtnIfWaiting() {
      this.claimBtn.show = true
      this.acceptBtn.show = false
      this.cancelBtn.show = true
      this.doneBtn.show = false
      if(!this.trade.amIBuyer) {
        this.depositedBtn.show = false
      } else {
        this.depositedBtn.show = true
      }

      this.rerenderBtn += 1
    },

    showBtnIfDeposited() {
      this.claimBtn.show = true
      this.depositedBtn.show = false
      this.acceptBtn.show = false
      if(!this.trade.amIBuyer) {
        this.doneBtn.show = true
        this.cancelBtn.show = false
      } else {
        this.doneBtn.show = false
        this.cancelBtn.show = true
      }

      this.rerenderBtn += 1
    },

    showBtnIfNoTrade() {
      this.claimBtn.show = false
      this.cancelBtn.show = false
      this.doneBtn.show = false
      this.depositedBtn.show = false
      this.acceptBtn.show = false

      this.rerenderBtn += 1
    },

    showBtns() {
      if(this.trade.otcTradeState === 'ACCEPT_READY') {
        this.showBtnIfAcceptReady()
      } else if(this.trade.otcTradeState === 'WAITING') {
        this.showBtnIfWaiting()
      } else if(this.trade.otcTradeState === 'DEPOSITED') {
        this.showBtnIfDeposited()
      } else if(this.trade.otcTradeState === 'CANCEL' || this.trade.otcTradeState === 'DECLINED' || this.trade.otcTradeState === 'TRADED') {
        this.showBtnIfNoTrade()
      }
    },

    whatAmI() {
      this.post.amIWriter = false
      if(this.myIdx === this.post.writerUserIdx) {
        this.post.amIWriter = true
      }

      this.trade.amIBuyer = false
      if((this.post.boardIsBuy && this.post.amIWriter) || (!this.post.boardIsBuy && !this.post.amIWriter)) {
        this.trade.amIBuyer = true
      }

      this.showBtns()
    },
  },
}
</script>

<style scoped>

</style>
