import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Main from "../components/main/main"
import Login from "../components/login/login"
import OtpLogin from "../components/login/otp_login"
import PinLogin from "../components/login/pin_login"
import PhoneLogin from "../components/login/phone_login"
import GeneralMarket from "../components/general_market"
import OtcMain from "../components/otc/otc_main"
import OtcDetail from "../components/otc/otc_detail"
import OtcOffer from "../components/otc/otc_offer"
import OtcProcess from "../components/otc/otc_process"
import OtcConfirm from "../components/otc/otc_confirm"
import TradeHistoryGeneral from "../components/trade_history/general/general"
import TradeHistoryGeneralWait from "../components/trade_history/general/general_wait"
import TradeHistoryOtcSellOffers from "../components/trade_history/otc/sell_offers"
import TradeHistoryOtcBuyOffers from "../components/trade_history/otc/buy_offers"
import TradeHistoryOtcOfferDetailList from "../components/trade_history/otc/offer_detail_list"
import TradeHistoryOtcSellState from "../components/trade_history/otc/sell_state"
import TradeHistoryOtcBuyState from "../components/trade_history/otc/buy_state"
import MyPageInfo from "../components/my_page/info"
import MyPageAdvanced from "../components/my_page/advanced"
import MyPageLevel from "../components/my_page/level"
import MyPageRecentActivity from "../components/my_page/recent_activity"
import MyPageQnaList from "../components/my_page/qna/list"
import MyPageQnaRegist from "../components/my_page/qna/regist"
import MyPageQnaDetail from "../components/my_page/qna/detail"
import MyPageSecurityMain from "../components/my_page/security/main"
import MyPageSecurityOTP from "../components/my_page/security/otp_setting"
import Signup from "../components/signup"
import NewPwd from "../components/find_pwd/new_pwd"
import AssetManagementWallet from "../components/asset_management/wallet"
import AssetManagementWalletDetail from "../components/asset_management/wallet_detail"
import AssetManagementWalletHistory from "../components/asset_management/wallet_history"
import AssetManagementConvertToUsdpay from "../components/asset_management/convert_to_usdpay"
import AssetManagementConvertToWkx from "../components/asset_management/convert_to_wkx"
import CompanyAbout from "../components/company/about"
import CompanyAgree from "../components/company/agree"
import CompanyPrivacy from "../components/company/privacy"
import DocumentsApiDoc from "../components/documents/api_doc"
import DocumentsAppDownload from "../components/documents/app_download"
import CustomerSupportCenter from "../components/customer_support/center/main"
import CustomerSupportList from "../components/customer_support/center/list"
import CustomerSupportView from "../components/customer_support/center/view"
import CustomerSupportCoinView from "../components/customer_support/center/coin_notice/view"
import CustomerSupportCoinRequest from "../components/customer_support/coin_request"
import CustomerSupportFee from "../components/customer_support/fee"
import CustomerSupportUseInfo from "../components/customer_support/use_info"

import CustomerSupportFAQList from "../components/customer_support/center/faq/list"
import CustomerSupportFAQView from "../components/customer_support/center/faq/view"
import CustomerSupportAuthList from "../components/customer_support/center/auth/list"
import CustomerSupportAuthView from "../components/customer_support/center/auth/view"
import CustomerSupportTradeList from "../components/customer_support/center/trade/list"
import CustomerSupportTradeView from "../components/customer_support/center/trade/view"
import CustomerSupportTransferList from "../components/customer_support/center/transfer/list"
import CustomerSupportTransferView from "../components/customer_support/center/transfer/view"

//otc 제안
import OtcSuggestionMarketList from "../components/otc_suggestion/otc_suggestion_market/otc_suggestion_market_list"
import OtcSuggestionMarketListMobile from "../components/otc_suggestion/otc_suggestion_market/otc_suggestion_market_list_mobile"
import OtcSuggestionHistoryList from "../components/otc_suggestion/otc_suggestion_history/otc_suggestion_history_list"
import OtcSuggestionHistoryDetail from "../components/otc_suggestion/otc_suggestion_history/otc_suggestion_history_detail"
import OtcSuggestionMypostList from "../components/otc_suggestion/otc_suggestion_mypost/otc_suggestion_mypost_list"
import OtcSuggestionChatList from "../components/otc_suggestion/otc_suggestion_chat/otc_suggestion_chat_list"
import OtcSuggestionMarketDetail from "../components/otc_suggestion/otc_suggestion_market/otc_suggestion_market_detail"
import  OtcSuggestionChatDetail from "../components/otc_suggestion/otc_suggestion_chat/otc_suggestion_chat_detail"
import OtcSuggestionWrite from "../components/otc_suggestion/otc_suggestion_write"
import OtcSuggestionClaim from "../components/otc_suggestion/otc_suggestion_claim"

export default new Router({
    routes: [
        {
          path: '/',
          name: 'Main',
          component: Main
        },
        {
          path: '/login',
          name: 'Login',
          component: Login
        },
        {
          path: '/login/pin',
          name: 'PinLogin',
          component: PinLogin
        },
        {
          path: '/login/otp',
          name: 'OtpLogin',
          component: OtpLogin
        },
        {
          path: '/login/phone',
          name: 'PhoneLogin',
          component: PhoneLogin
        },
        {
          path: '/generalmarket',
          name: 'GeneralMarket',
          component: GeneralMarket
        },
        {
            path: '/otc/main',
            name: 'OtcMain',
            component: OtcMain
        },
        {
            path: '/otc/detail',
            name: 'OtcDetail',
            component: OtcDetail
        },
        {
            path: '/otc/offer',
            name: 'OtcOffer',
            component: OtcOffer,
            meta: { keepAlive: true }
        },
        {
            path: '/otc/process',
            name: 'OtcProcess',
            component: OtcProcess
        },
        {
            path: '/otc/confirm',
            name: 'OtcConfirm',
            component: OtcConfirm
        },
        {
          path: '/history/general',
          name: 'TradeHistoryGeneral',
          component: TradeHistoryGeneral
        },
        {
            path: '/history/general/wait',
            name: 'TradeHistoryGeneralWait',
            component: TradeHistoryGeneralWait
        },
        {
          path: '/history/otc/sell/offers',
          name: 'TradeHistoryOtcSellOffers',
          component: TradeHistoryOtcSellOffers
        },
        {
            path: '/history/otc/buy/offers',
            name: 'TradeHistoryOtcBuyOffers',
            component: TradeHistoryOtcBuyOffers
        },
        {
            path: '/history/otc/offer/list',
            name: 'TradeHistoryOtcOfferDetailList',
            component: TradeHistoryOtcOfferDetailList
        },
        {
            path: '/history/otc/sell/state',
            name: 'TradeHistoryOtcSellState',
            component: TradeHistoryOtcSellState
        },
        {
            path: '/history/otc/buy/state',
            name: 'TradeHistoryOtcBuyState',
            component: TradeHistoryOtcBuyState
        },
        {
          path: '/mypage/info',
          name: 'MyPageInfo',
          component: MyPageInfo
        },
        {
          path: '/mypage/advanced',
          name: 'MyPageAdvanced',
          component: MyPageAdvanced
        },
        {
          path: '/mypage/level',
          name: 'MyPageLevel',
          component: MyPageLevel
        },
        {
          path: '/mypage/recentactivity',
          name: 'MyPageRecentActivity',
          component: MyPageRecentActivity
        },
        {
          path: '/mypage/qna/list',
          name: 'MyPageQnaList',
          component: MyPageQnaList
        },
        {
          path: '/mypage/qna/regist',
          name: 'MyPageQnaRegist',
          component: MyPageQnaRegist
        },
        {
          path: '/mypage/qna/detail',
          name: 'MyPageQnaDetail',
          component: MyPageQnaDetail
        },
        {
          path: '/mypage/security/main',
          name: 'MyPageSecurityMain',
          component: MyPageSecurityMain
        },
        {
          path: '/mypage/security/otp',
          name: 'MyPageSecurityOTP',
          component: MyPageSecurityOTP
        },
        {
          path: '/signup',
          name: 'Signup',
          component: Signup
        },
        {
          path: '/findpwd/newpwd',
          name: 'NewPwd',
          component: NewPwd
        },
        {
          path: '/asset/wallet',
          name: 'AssetManagementWallet',
          component: AssetManagementWallet
        },
        {
          path: '/asset/wallet/detail',
          name: 'AssetManagementWalletDetail',
          component: AssetManagementWalletDetail
        },
        {
            path: '/asset/wallet/history',
            name: 'AssetManagementWalletHistory',
            component: AssetManagementWalletHistory
        },
        {
          path: '/asset/convertUsdpay',
          name: 'AssetManagementConvertToUsdpay',
          component: AssetManagementConvertToUsdpay
        },
        {
          path: '/asset/convertWkx',
          name: 'AssetManagementConvertToWkx',
          component: AssetManagementConvertToWkx
        },
        {
          path: '/company/about',
          name: 'CompanyAbout',
          component: CompanyAbout
        },
        {
          path: '/company/agree',
          name: 'CompanyAgree',
          component: CompanyAgree
        },
        {
          path: '/company/privacy',
          name: 'CompanyPrivacy',
          component: CompanyPrivacy
        },
        {
          path: '/documents/api',
          name: 'DocumentsApiDoc',
          component: DocumentsApiDoc
        },
        {
          path: '/documents/app',
          name: 'DocumentsAppDownload',
          component: DocumentsAppDownload
        },
        {
          path: '/customer/center/main',
          name: 'CustomerSupportCenter',
          component: CustomerSupportCenter
        },
        {
            path: '/customer/center/list',
            name: 'CustomerSupportList',
            component: CustomerSupportList
        },
        {
            path: '/customer/center/view',
            name: 'CustomerSupportView',
            component: CustomerSupportView,
        },
        {
            path: '/customer/center/coin/view',
            name: 'CustomerSupportCoinView',
            component: CustomerSupportCoinView,
        },
        {
            path: '/customer/center/faq/list',
            name: 'CustomerSupportFAQList',
            component: CustomerSupportFAQList,
        },
        {
            path: '/customer/center/faq/view',
            name: 'CustomerSupportFAQView',
            component: CustomerSupportFAQView,
        },
        {
            path: '/customer/center/auth/list',
            name: 'CustomerSupportAuthList',
            component: CustomerSupportAuthList,
        },
        {
            path: '/customer/center/auth/view',
            name: 'CustomerSupportAuthView',
            component: CustomerSupportAuthView,
        },
        {
            path: '/customer/center/trade/list',
            name: 'CustomerSupportTradeList',
            component: CustomerSupportTradeList,
        },
        {
            path: '/customer/center/trade/view',
            name: 'CustomerSupportTradeView',
            component: CustomerSupportTradeView,
        },
        {
            path: '/customer/center/transfer/list',
            name: 'CustomerSupportTransferList',
            component: CustomerSupportTransferList,
        },
        {
            path: '/customer/center/transfer/view',
            name: 'CustomerSupportTransferView',
            component: CustomerSupportTransferView,
        },
        {
          path: '/customer/coinrequest',
          name: 'CustomerSupportCoinRequest',
          component: CustomerSupportCoinRequest
        },
        {
          path: '/customer/fee',
          name: 'CustomerSupportFee',
          component: CustomerSupportFee
        },
        {
          path: '/customer/use',
          name: 'CustomerSupportUseInfo',
          component: CustomerSupportUseInfo
        },

  //      otc 제안
        {
            path: '/otc/suggestion/markets',
            name: 'OtcSuggestionMarketList',
            component: OtcSuggestionMarketList
        },
        {
            path: '/otc/suggestion/markets/mobile',
            name: 'OtcSuggestionMarketListMobile',
            component: OtcSuggestionMarketListMobile
        },
        {
            path: '/otc/suggestion/historys',
            name: 'OtcSuggestionHistoryList',
            component: OtcSuggestionHistoryList
        },
        {
            path: '/otc/suggestion/history/:tradeIdx',
            name: 'OtcSuggestionHistoryDetail',
            component: OtcSuggestionHistoryDetail
        },
        {
            path: '/otc/suggestion/myposts',
            name: 'OtcSuggestionMypostList',
            component: OtcSuggestionMypostList
        },
        {
            path: '/otc/suggestion/chats',
            name: 'OtcSuggestionChatList',
            component: OtcSuggestionChatList
        },
        {
            path: '/otc/suggestion/market/:postIdx',
            name: 'OtcSuggestionMarketDetail',
            component: OtcSuggestionMarketDetail,
        },
        {
            path: '/otc/suggestion/chat/:chatroomIdx',
            name: 'OtcSuggestionChatDetail',
            component: OtcSuggestionChatDetail
        },
        {
            path: '/otc/suggestion/write',
            name: 'OtcSuggestionWrite',
            component: OtcSuggestionWrite
        },
        {
            path: '/otc/suggestion/claim',
            name: 'OtcSuggestionClaim',
            component: OtcSuggestionClaim
        },
  ],
    scrollBehavior() {
        document.getElementById('app').scrollIntoView()
    }
})
