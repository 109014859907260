<template>
  <div class="body_wrap">
    <div class="content">
      <div class="c5_wrap_b">
        <h6><a>{{ $t('message.claim') }}</a></h6>
      </div>
      <table class="table_inquiry mt50">
        <colgroup>
          <col style="width: 14%;">
          <col style="width: 86%;">
        </colgroup>
        <tr>
          <th class="t_left">{{ $t('message.writing') }}
            <p class="fs11 price_red">{{ $t('message.required') }}</p>
          </th>
          <td>
            <textarea :placeholder="$t('message.enterContentClaim')" v-model="claimInput.contents" v-show="!claimInfo.contents"></textarea>
            <textarea :placeholder="$t('message.enterContentClaim')" v-html="claimInfo.contents" v-show="claimInfo.contents" disabled></textarea>
          </td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.file') }}</th>
          <td>
            <div class="file">
              <div v-show="!claimInfo.contents">
                <input type="text" :placeholder="$t('message.doAttach')" v-model="claimInput.fileName" disabled/>
                <div class="middle_btn upload_btn m_left10">
                  <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
                  <a>{{ $t('message.attach') }}</a>
                </div>
              </div>
              <img style="max-width: 700px" v-show="claimInfo.fileLink" :src="claimInfo.fileLink" alt="claim">
            </div>
          </td>
        </tr>
      </table>
      <div class="p_btn_wrap">
        <a class="p_btn_blue" @click="claim()">{{ $t('message.registration') }}</a>
        <a class="p_btn cancel" @click="$router.back()">{{ $t('message.cancel')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtcClaim",

  data() {
    return {
      tradeIdx: this.$route.params.tradeIdx,
      claimInfo: {
        contents: '',
        fileHash: '',
        fileLink: ''
      },
      claimInput: {
        contents: '',
        fileName: '',
        fileList: {}
      },
      didClaim: false,
    }
  },

  created() {
    if(!this.tradeIdx) {
      this.$router.back()
      return
    }

    this.$get(`${this.$OTC_TEMP_CLAIM}?tradeIdx=${this.tradeIdx}`, 'OtcTempClaimGet', true, (result) => {
      if(result.data[0]) {
        this.claimInfo = result.data[0]
        if(this.claimInfo.fileHash) {
          this.getFile()
        }
        this.didClaim = true
      }
    }, (result) => {
      console.log(result)
    })
  },

  methods: {
    getFile() {
      this.$download(`${this.$IMAGE}?key=${this.claimInfo.fileHash}`, 'GetClaimImg_' + Math.random(), false, (result) => {
        this.claimInfo.fileLink = result.config.url
        this.$forceUpdate()
      }, (result) => {
        console.log(result)
      })
    },

    upload(e) {
      let file = e.target.files
      if(!file.length) {
        return
      }
      this.claimInput.fileName = file[0].name
      this.claimInput.fileList = file[0]
    },

    claim() {
      if(this.didClaim) {
        this.notify('error', this.$t('message.alreadyClaim'))
        return
      }

      if(!this.claimInput.contents) {
        this.notify('error', this.$t('message.enterContent'))
        return
      }

      let formData = new FormData()
      this.claimInput.fileList ? formData.append('file', this.claimInput.fileList) : ''
      formData.append('contents', this.claimInput.contents)
      formData.append('tradeIdx', this.tradeIdx)

      this.$post(this.$OTC_TEMP_CLAIM, 'OtcTempClaim', formData, true, (result) => {
        console.log(result)
        this.notify('error', this.$t('message.doneClaim'))
        this.$router.push({ name: 'OtcSuggestionHistoryList' })
      }, (result) => {
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>

</style>
