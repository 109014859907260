<template>
  <div class="tabmenu" id="tabmenu">
<!--    <router-link :to="{ name: 'TradeHistoryOtcSellOffers' }" class="content_tab">{{ $t('message.sellOfferSum') }}</router-link>-->
<!--    <router-link :to="{ name: 'TradeHistoryOtcBuyOffers' }" class="content_tab">{{ $t('message.buyOfferSum') }}</router-link>-->
<!--    <router-link :to="{ name: 'TradeHistoryOtcSellState' }" class="content_tab">{{ $t('message.sellOrderSum') }}</router-link>-->
<!--    <router-link :to="{ name: 'TradeHistoryOtcBuyState'}" class="content_tab">{{ $t('message.buyOrderSum') }}</router-link>-->

<!--    <a :class="{'tab_focus1' : selectedTab === 0}" @click="selectTab(0)" class="content_tab point">{{ $t('message.openOrderHistory') }}</a>-->
<!--    <a :class="{'tab_focus1' : selectedTab === 1}" @click="selectTab(1)" class="content_tab point">{{ $t('message.orderHistory') }}</a>-->

<!--    <a :class="{'tab_focus1' : selectedTab === 2}" @click="selectTab(2)" class="content_tab point">OTC {{ $t('message.inOrdersQuantity') }}</a>-->
<!--    <a :class="{'tab_focus1' : selectedTab === 3}" @click="selectTab(3)" class="content_tab point">OTC {{ $t('message.orderHistory') }}</a>-->
  </div>
</template>

<script>
export default {
  name: "OtcMenu",
  // props: ['selectedTab'],
  // methods: {
  //   selectTab(_tab) {
  //     if (_tab === 0) {
  //       this.$router.replace({name:'TradeHistoryGeneralWait'})
  //     } else if (_tab === 1) {
  //       this.$router.replace({name:'TradeHistoryGeneral'})
  //     } else if (_tab === 2) {
  //       this.$router.replace({ name: 'TradeHistoryOtcSellOffers' })
  //     } else if (_tab === 3) {
  //       this.$router.replace({ name: 'TradeHistoryOtcSellState' })
  //     }
  //   },
  //   focusTab(tabNo) {
  //     document.getElementById('tabmenu').children.forEach((tab, idx) => {
  //       if(idx === tabNo) {
  //         tab.classList.add('tab_focus1')
  //       } else {
  //         tab.classList.remove('tab_focus1')
  //       }
  //     })
  //   }
  // }
}
</script>

<style scoped>

</style>
