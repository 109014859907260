<template>
  <div class="body_wrap">
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container otc_list_done">
        <OtcMenuPC ref="OtcMenuPC"></OtcMenuPC>
        <div class="wkx_content">
          <div class="wkx_right w100">
            <div class="checks otc_offer_ckh">
              <p class="title">{{ $t('message.myTradingHistory') }}</p>
              <div>
                <div>
                  <i class="fa fa-user" aria-hidden="true"></i> :
                  <span class="m_right10"> {{ $t('message.myPost') }}</span>
                  <i class="fa fa-users" aria-hidden="true"></i> :
                  <span class="m_right10"> {{ $t('message.postIApplied') }}</span>
                </div>
                <div>
                  <input type="checkbox" id="check_01" v-model="filter.chk1" @change="getList(1 )"/>
                  <label for="check_01" class="orange">{{ $t('message.waitingAccept') }}</label>
                  <input type="checkbox" id="check_02" v-model="filter.chk2" @change="getList(1)" />
                  <label for="check_02" class="price_red">{{ $t('message.waiting') }}</label>
                  <input type="checkbox" id="check_03" v-model="filter.chk3" @change="getList(1)" />
                  <label for="check_03" class="blue_01">{{ $t('message.deposited') }}</label>
                </div>
                <div>
                  <input type="checkbox" id="check_04" v-model="filter.chk4" @change="getList(1)" />
                  <label for="check_04" class="blue_04">{{ $t('message.traded') }}</label>
                  <input type="checkbox" id="check_05" v-model="filter.chk5" @change="getList(1)" />
                  <label for="check_05" class="green">{{ $t('message.tradeCancel') }}</label>
                  <input type="checkbox" id="check_06" v-model="filter.chk6" @change="getList(1)" />
                  <label for="check_06" class="grey">{{ $t('message.tradeRefuse') }}</label>
                </div>
              </div>
            </div>
            <div class="scroll">
              <table class="table_i">
                <colgroup>
                  <col style="width: 7%;">
                  <col style="width: 7%;">
                  <col style="width: auto;">
                  <col style="width: 15%;">
                  <col style="width: 14%;">
                  <col style="width: 14%;">
                  <col style="width: 14%;">
                  <col style="width: 14%;">
                </colgroup>
                <thead>
                <tr class="columns">
                  <th scope="col">글</th>
                  <th scope="col">{{ $t('message.tradingNum') }}</th>
                  <th scope="col">{{ $t('message.market') }}</th>
                  <th scope="col">{{ $t('message.tradeAmt') }}</th>
                  <th scope="col">{{ $t('message.transactionPrice') }}</th>
                  <th scope="col">{{ $t('message.trading') }}{{ $t('message.nickname') }}</th>
                  <th scope="col">{{ $t('message.tradeDate') }}</th>
                  <th scope="col">{{ $t('message.tradeState') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(trade, idx) in tradeList" :key="idx" @click="goToDetail(trade)" class="point">
                  <td scope="row" class="t_center">
                    <i class="fa fa-user" aria-hidden="true" v-show="trade.writerUserIdx === myIdx"></i>
                    <i class="fa fa-users" aria-hidden="true" v-show="trade.writerUserIdx !== myIdx"></i>
                  </td>
                  <td scope="row" class="t_center">{{ trade.tradeIdx }}</td>
                  <td scope="row" class="t_center">
                    {{ !trade.marketPair.includes('UNLISTED') ? trade.marketPair.split('-')[0] : trade.marketPair.split('-')[1] + '(' + $t('message.directTran') +')'  }}
                    <span :class="{ 'price_blue': trade.tradeIsBuy, 'price_red': !trade.tradeIsBuy }">{{ trade.tradeIsBuy ? $t('message.doSell') : $t('message.doBuy') }}</span>
                  </td>
                  <td scope="row" class="t_center">{{ trade.amountView | numberWithCommas }}</td>
                  <td scope="row" class="t_center">{{ trade.priceToGo | numberWithCommas }}</td>
                  <td scope="row" class="t_center">{{ trade.oppositeNickname }}</td>
                  <td scope="row" class="t_center">{{ trade.timestamp }}</td>
                  <td scope="row" class="t_center"
                      :class="{
                        'orange': trade.otcTradeState === 'ACCEPT_READY',
                        'price_red': trade.otcTradeState === 'WAITING',
                        'blue_01': trade.otcTradeState === 'DEPOSITED',
                        'blue_04': trade.otcTradeState === 'TRADED',
                        'green': trade.otcTradeState === 'CANCEL',
                        'grey': trade.otcTradeState === 'DECLINED'
                      }"
                  >
                    {{ $t(`message.${tradeStateInLang(trade.otcTradeState)}`) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="no_list mt20" v-show="!tradeList.length">{{ $t('message.noData') }}</p>
            <div class="buttonwrap t_center">
              <paginate
                  :page-count="pageLast"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="getList"
                  :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :first-last-button=true
                  :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                  :container-class="'pagination'">
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcMenuPC from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_pc'
import OtcMenuMobile from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_mobile'

export default {
  name: "OtcSuggestionHistoryList",

  data() {
    return {
      pageLast: 0,
      tradeList: [],
      limit: 10,
      myIdx: null,
      filter: {
        chk1: false,
        chk2: false,
        chk3: false,
        chk4: false,
        chk5: false,
        chk6: false,
      }
    }
  },

  components: {
    'OtcMenuPC': OtcMenuPC,
    'OtcMenuMobile': OtcMenuMobile,
  },

  created() {
    this.getUserProfile((result) => {
      if(result.success) {
        this.myIdx = result.userProfile.userIdx
        this.getList(1)
      } else {
        console.log(result.userProfile)
      }
    })

    let filter = JSON.parse(sessionStorage.getItem('otcHistoryFilter'))
    if(filter) {
      this.filter = filter
    }
  },

  mounted() {
    this.$refs.OtcMenuPC.focusTab(1)
    this.$refs.OtcMenuMobile.selectMenu(this.$t('message.myTradingHistory'))
  },

  methods: {
    goToDetail(trade) {
      this.$router.push({ name: 'OtcSuggestionHistoryDetail',
        params: {
          tradeIdx: trade.tradeIdx,
          tabNo: 1,
        }})
    },

    getList(page) {
      let formData = new FormData()
      formData.append('limit', this.limit)
      formData.append('page', page)
      this.filter.chk1 ? formData.append('stateList', 'ACCEPT_READY') : ''
      this.filter.chk2 ? formData.append('stateList', 'WAITING') : ''
      this.filter.chk3 ? formData.append('stateList', 'DEPOSITED') : ''
      this.filter.chk4 ? formData.append('stateList', 'TRADED') : ''
      this.filter.chk5 ? formData.append('stateList', 'CANCEL') : ''
      this.filter.chk6 ? formData.append('stateList', 'DECLINED') : ''

      this.$post(this.$OTC_TEMP_TRADE_LIST, 'otcTempMyTradeList', formData, true, (result) => {
        this.pageLast = Math.ceil(result.total / this.limit)
        this.tradeList = result.data

        this.tradeList.forEach(trade => {
          trade.timestamp = this.formatDate(trade.timestamp, 'full')
        })

        sessionStorage.setItem('otcHistoryFilter', JSON.stringify(this.filter))
      }, (result) => {
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>

</style>
