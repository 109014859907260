<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="sub_container">
        <OtcSearch
            @changeOfferType="changeOfferType"
            @changeCurrency="changeCurrency"
            @changeCoin="changeCoin">
        </OtcSearch>
        <div class="offer_list_con">
          <h5 class="buying_title" v-if="offerType === 'buy'">{{ $t('message.buy') }} {{ $t('message.offer') }} {{ $t('message.list') }}</h5>
          <h5 class="buying_title" v-else>{{ $t('message.sell') }} {{ $t('message.offer') }} {{ $t('message.list') }}</h5>
          <div class="mk_t_content scroll">
            <table class="table_i">
              <colgroup>
                <col style="width: 3%;">
                <col style="width: 14%;">
                <col style="width: 15%;">
                <col style="width: 16%;">
                <col style="width: 16%;">
                <col style="width: 18%;">
                <col style="width: 16%;">
              </colgroup>
              <tr class="columns">
                <th></th>
                <th>{{ $t('message.country') }}</th>
                <th>{{ $t('message.nickname') }}</th>
                <th class="th3">{{ $t('message.coin') }} <!-- 거래내역-> 코인으로 변경함. 관련된 부분도 변경하기* -->
                  <!-- 화살표 클릭시 색 변경 적용해야함 -->
<!--                  <div class="triangle_wrap">-->
<!--                    <span class="tri_up active" @click="doSort($event, 'credit', 'desc')"></span>-->
<!--                    <span class="tri_down" @click="doSort($event, 'credit', 'asc')"></span>-->
<!--                  </div>-->
                </th>
<!--                <th>{{ $t('message.payMethod') }}</th>-->
                <th>{{ $t('message.tradeLimit') }}
                  <div class="triangle_wrap">
                    <span class="tri_up" @click="doSort($event, 'amount', 'desc')"></span>
                    <span class="tri_down" @click="doSort($event, 'amount', 'asc')"></span>
                  </div>
                </th>
                <th>{{ $t('message.unitPrice') }}
<!--                  <div class="triangle_wrap">-->
<!--                    <span class="tri_up" @click="doSort($event, 'price', 'desc')"></span>-->
<!--                    <span class="tri_down" @click="doSort($event, 'price', 'asc')"></span>-->
<!--                  </div>-->
                </th>
                <th>{{ $t('message.state') }}</th>
              </tr>
              <tr v-for="(offer, offerIdx) in offerList" :key="offerIdx">
                <td class="td1 t_center"><img :src="offer.profileImage ? offer.profileImage : '/img/s_profile_outline.png'" alt="profile" :key="rerenderKey"></td>
                <td class="t_center">{{ offer.countryCode | setFullCountryName }}</td>
                <td class="t_center">{{ offer.nickname }}</td>
                <td class="t_center">{{ selectedCoin }}</td>
<!--                <td class="t_center">-->
<!--                  <div class="payicon">-->
<!--                    <img v-show="offer.bank" src="/img/Icon_bank.svg" alt="icon_bank">-->
<!--                    <img v-show="offer.pps" src="/img/Icon_pps.svg" alt="icon_pps">-->
<!--                    <img v-show="offer.wechat" src="/img/Icon_wechat.svg" alt="icon_wechat">-->
<!--                    <img v-show="offer.alipay" src="/img/Icon_alipay.svg" alt="Icon_alipay">-->
<!--                  </div>-->
<!--                </td>-->
                <td class="t_center">{{ offer.remainAmount }} {{ selectedCoin }}</td>
<!--                <td class="t_center">{{ subDec(offer.price, selectedCurrency) | numberWithCommas }}</td>-->
                <td class="t_center">{{ offer.exchanges === 'NONE' ? '협상가' : offer.exchanges }} {{ offer.exchanges === 'NONE' ? '' : (offer.markUp >= 0 ? '기준 +' : '기준 ') + (offer.markUp * 100) + '%' }}</td>
                <td>
                  <div class="outline_btn2 t_center">
                    <span class="t_center" @click="goOtcDetail(offer)">{{ $t('message.doTrade') }}</span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <p class="no_list" v-show="!offerList.length">{{ $t('message.noData') }}</p>
          <paginate v-show="offerList.length"
              :page-count="pageLast"
              :page-range="3"
              :margin-pages="2"
              :click-handler="getAvailableList"
              :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :first-last-button=true
              :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcSearch from "./cmp/otc_search"

export default {
  name: "OtcMain",
  components: {
    'OtcSearch': OtcSearch
  },

  data() {
    return {
      offerType: this.$route.params.offerType ? this.$route.params.offerType : 'buy',
      offerList: [],
      selectedCurrency: '',
      selectedCoin: '',
      rerenderKey: 0,
      pageLast: 0,
      sort: 'credit',
      direction: 'desc',
      page: 1,
    }
  },

  filters: {
    setFullCountryName: (value) => {
      if (value === 'KR') {
        return '대한민국'
      } else if(value === 'EN') {
        return '미국'
      } else if(value === 'CN') {
        return '중국'
      } else  {
        return ''
      }
    },
  },

  methods: {
    doSort(e, sort, direction) {
      this.sort = sort
      this.direction = direction

      document.getElementsByClassName('tri_up').forEach((ele) => {
        ele.classList.remove('active')
      })
      document.getElementsByClassName('tri_down').forEach((ele) => {
        ele.classList.remove('active')
      })

      e.currentTarget.classList.add('active')
      this.getAvailableList(this.page)
    },

    // getExchangePrice(offer) {
    //   if(offer.exchanges === 'BITFINEX') {
    //     let marketPair = `${this.selectedCoin}usd`
    //     marketPair = marketPair.toLowerCase()
    //     this.$get(`${this.$OTC_DISPLAY_TICKER_BITFINEX}/${marketPair}`, 'otcListGetBitfinex_' + offer.offerIdx, true, (result) => {
    //       let usdPrice = result.data[0].mid
    //       if(usdPrice && this.selectedCurrency === 'KRW') {
    //         this.$get(`${this.$OTC_DISPLAY_USDKRW}`, 'otcOfferGetUSDKRW_' + offer.offerIdx, true, (result) => {
    //           let rate = result.data[0].rate
    //           offer.price = this.subDec((rate * usdPrice), this.selectedCurrency)
    //
    //           if(offer.markUp !== '0' && offer.markUp !== null) {
    //             offer.markUp = Number(offer.markUp)
    //             offer.price = Number(offer.price)
    //             offer.price = offer.price + (offer.price * offer.markUp)
    //           }
    //         }, (result) => {
    //           console.log(result)
    //         })
    //       } else {
    //         // 환율계산하는 api 화폐당 더 나와야함
    //       }
    //     }, (result) => {
    //       this.notify('error', result.message)
    //     })
    //   } else if (offer.exchanges === 'UPBIT') {
    //     let marketPair = `${this.selectedCurrency}-${this.selectedCoin}`
    //     this.$get(`${this.$OTC_DISPLAY_TICKER_UPBIT}/${marketPair}`, 'otcListGetUpbit_' + offer.offerIdx, true, (result) => {
    //       let midPrice = result.data[0].mid
    //       if(midPrice && this.selectedCurrency === 'KRW') {
    //         offer.price = this.subDec(midPrice, this.selectedCurrency)
    //
    //         if(offer.markUp !== '0' && offer.markUp !== null) {
    //           offer.markUp = Number(offer.markUp)
    //           offer.price = Number(offer.price)
    //           offer.price = offer.price + (offer.price * offer.markUp)
    //         }
    //       } else {
    //         // 환율계산하는 api 화폐당 더 나와야함
    //       }
    //     }, (result) => {
    //       this.notify('error', result.message)
    //     })
    //   } else if(offer.exchanges === 'BINANCE') {
    //     let marketPair = `${this.selectedCoin}${this.selectedCurrency}`
    //     this.$get(`${this.$OTC_DISPLAY_TICKER_BINANCE}/${marketPair}`, 'otcListGetBinance_' + offer.offerIdx, true, (result) => {
    //       let midPrice = result.data[0].price
    //       console.log(midPrice)
    //     }, (result) => {
    //       this.notify('error', result.message)
    //     })
    //   } else {
    //     offer.price = 0
    //   }
    // },

    goOtcDetail(offer) {
      offer.pair = `${this.selectedCoin}-${this.selectedCurrency}`
      offer.offerType = this.offerType
      this.$router.push({ name: 'OtcDetail', params: { 'offer': offer }})
    },

    changeOfferType(offerType) {
      this.offerType = offerType
      this.getAvailableList(1)
    },

    changeCurrency(selectedCurrency) {
      this.selectedCurrency = selectedCurrency
      this.getAvailableList(1)
    },

    changeCoin(selectedCoin) {
      this.selectedCoin = selectedCoin
      this.getAvailableList(1)
    },

    getUserImg(offer) {
      this.$get(`${this.$USER_MYPAGE_PROFILE_IMAGE_USERS}?userIds=${offer.userIdx}`, 'OtcMainGetUserImg_' + offer.offerIdx, true, (result) => {
        if(result.data.length) {
          offer.profileImage = result.data[0].profileImage
        }
        this.rerenderKey += 1
      }, (result) => {
        console.log(result)
      })
    },

    getAvailableList(page) {
      this.page = page
      if(!this.selectedCurrency || !this.selectedCoin) {
        return
      }

      let offerType
      this.offerType === 'buy' ? offerType = true : offerType = false

      let formData = new FormData()
      let limit = 10

      formData.append('isBuy', offerType)
      formData.append('limit', limit)
      formData.append('page', page)
      formData.append('marketPair', `${this.selectedCoin}-${this.selectedCurrency}`)
      formData.append('sort', this.sort.toUpperCase())
      formData.append('direction', this.direction.toUpperCase())

      this.$post(this.$OTC, 'otcGetList', formData,true, (result) => {
        this.offerList = result.data
        this.pageLast = Math.ceil(result.total / limit)

        this.offerList.forEach((offer) => {
          if(!offer.price) {
            offer.price = 0
            // this.getExchangePrice(offer)
          }
          this.getUserImg(offer)
        })
      }, () => {
        this.offerList = []
      })
    },
  },
}
</script>

<style scoped>

</style>
