<template>
  <div class="mobile_menu">
    <ul>
      <li>
        <a class="mobile_tit" @click="slideMenu">{{ selectedMenu }}<i class="mbtn" :class="{ open: open }"></i></a>
        <ul class="mobile_sub" id="mobile-sub">
          <li v-show="selectedMenu !== $t('message.account')">
            <router-link :to="{ name: 'MyPageInfo' }">{{ $t('message.account') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.membershipLevel')">
            <router-link :to="{ name: 'MyPageLevel' }">{{ $t('message.membershipLevel') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.security')">
            <router-link :to="{ name: 'MyPageSecurityMain' }">{{ $t('message.security') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.recentActivity')">
            <router-link :to="{ name: 'MyPageRecentActivity'}">{{ $t('message.recentActivity') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.myInquiries')">
            <router-link :to="{ name: 'MyPageQnaList' }">{{ $t('message.myInquiries') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.advancedOptions')">
            <router-link :to="{ name: 'MyPageAdvanced' }">{{ $t('message.advancedOptions') }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "myPageMenuMobile",
  data() {
    return {
      selectedMenu: '',
      open: false,
    }
  },

  methods: {
    slideMenu() {
      window.$(".mobile_sub").slideToggle("300")
      this.open = !this.open
    },

    selectMenu(selectedMenu) {
      this.selectedMenu = selectedMenu
    },
  }
}
</script>

<style scoped>

</style>
