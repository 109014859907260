<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="sub_container history_g">
        <div class="tabmenu">
          <a :class="{'tab_focus1' : selectedTab === 0}" @click="selectTab(0)" class="content_tab point">{{ $t('message.openOrderHistory') }}</a>
          <a :class="{'tab_focus1' : selectedTab === 1}" @click="selectTab(1)" class="content_tab point">{{ $t('message.orderHistory') }}</a>
        </div>
        <div v-show="selectedTab === 0">
          <div class="search_set mt20">
            <div class="date_search">
              <p class="date_search_set">
                <span class="date_input">
                  <label for="start">{{ $t('message.start') }}</label>
                  <date-picker id="start" v-model="startDate" :key="rerenderKey" :config="calendarOptions()" @dp-change="getList(1)"></date-picker>
                </span>
                  <span class="date_input">
                  <label for="end">{{ $t('message.end') }}</label>
                  <date-picker id="end" v-model="endDate" :key="rerenderKey" :config="calendarOptions()" @dp-change="getList(1)"></date-picker>
                </span>
              </p>
            </div>
            <div class="option_search">
              <select v-model="selectedPair" @change="getList(1)">
                <option value="all">{{ $t('message.tradePair') }}</option>
                <option v-for="marketPair in marketPairs" :key="marketPair" :value="marketPair">{{ marketPair }}</option>
              </select>
            </div>
            <div class="option_search">
              <select v-model="buyOrSell" @change="getList(1)">
                <option value="all">{{ $t('message.buy') }}/{{ $t('message.sell') }}</option>
                <option value="buy">{{ $t('message.buy') }}</option>
                <option value="sell">{{ $t('message.sell') }}</option>
              </select>
            </div>
            <div class="option_search">
              <select v-model="orderMethod" @change="getList(1)">
                <option value="all">{{ $t('message.orderMethod') }}</option>
                <option value="limit">{{ $t('message.limit') }}</option>
                <option value="market">{{ $t('message.marketPrice') }}</option>
                <option value="reserve">{{ $t('message.StopLimit') }}</option>
              </select>
            </div>
            <button @click="getList(1)">{{ $t('message.search') }}</button>
          </div>
          <div class="scroll">
            <table class="table_i">
              <colgroup>
                <col style="width: 15%;">
                <col style="width: 15%;">
                <col style="width: 15%;">
                <col style="width: 15%;">
                <col style="width: 15%;">
                <col style="width: 15%;">
                <col style="width: auto;">
              </colgroup>
              <thead>
              <tr class="columns">
                <th scope="col">{{ $t('message.orderTime') }}</th>
                <th scope="col">{{ $t('message.exchange') }}</th>
                <th scope="col">{{ $t('message.sort') }}</th>
                <th scope="col">{{ $t('message.orderMethod') }}</th>
                <th scope="col">{{ $t('message.orderPrice') }}</th>
                <th scope="col">{{ $t('message.orderQuantity') }}</th>
                <th scope="col">{{ $t('message.remaining') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(trade, tradeIdx) in tradeList" :key="tradeIdx">
                <td scope="row" class="t_center">{{ trade.date }}</td>
                <td class="t_center">{{ trade.marketPair.replace('-', ' / ') }}</td>
                <td class="t_center">{{ trade.orderType }}</td>
                <td class="t_center" :class="{ price_red: trade.isBuy === 'buy', price_blue: trade.isBuy === 'sell' }" >{{ $t(`message.${trade.isBuy}`) }}</td>
                <td class="t_center" >{{ trade.price | numberWithCommas }}</td>
                <td class="t_center" >{{ trade.total | numberWithCommas }}</td>
                <td class="t_center" >{{ trade.remainAmount | numberWithCommas }}</td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="no_list" v-show="!tradeList.length">{{ $t('message.noData') }}</p>
        <div class="buttonwrap t_center" v-show="tradeList.length">
          <paginate
              :page-count="pageLast"
              :page-range="3"
              :margin-pages="2"
              :click-handler="getList"
              :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :first-last-button=true
              :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
              :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TradeHistoryGeneralWait",
  data() {
    return {
      tradeType: 'all',
      selectedPair: 'all',
      orderMethod: 'all',
      buyOrSell: 'all',
      marketPairs: [],
      pageLast: 0,
      tradeList: [],
      rerenderKey: 1,
      selectedTab: 0,
      limit: 10,
      startDate: '',
      endDate: '',
    }
  },

  created() {
    if (this.$route.params.selectedTab === '1') {
      this.selectedTab = this.$route.params.selectedTab * 1
    }
    let d = new Date()
    d.setMonth(d.getMonth() - 1)
    this.startDate = this.formatDate(d.getTime(), 'date')
    this.endDate = this.formatDate(Date.now(), 'date')

    let pairs = JSON.parse(sessionStorage.getItem('marketPairs'))
    if (pairs) {
      pairs.forEach((p) => {
        this.marketPairs.push(p.pair.replace('-', ' / '))
      })
      this.marketPairs = [...new Set(this.marketPairs)]
    }

    this.getList(1)
  },

  methods: {
    selectTab(_tab) {
      this.selectedTab = _tab
      this.tradeList = []
      this.selectedPair = 'all'
      this.orderMethod = 'all'
      this.buyOrSell = 'all'
      this.getList(1)
    },

    getList(page) {
      if (this.selectedTab === 0) {
        this.getWaitList(page)
      } else if (this.selectedTab === 1) {
        this.$router.replace({ name: 'TradeHistoryGeneral' })
      }
    },

    getWaitList(page) {
      let formData = new FormData()

      let startDateArr = this.startDate.split('-')
      let endDateArr = this.endDate.split('-')
      let startInSecond = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2], 0, 0, 0)
      let endInSecond = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2], 23, 59, 59)

      let timeGap = endInSecond - startInSecond
      // 조회가능기간: 최대 3개월(7884000밀리초)
      if(timeGap > 7884000000) {
        this.notify('error', this.$t('message.max3'))
        return
      }

      formData.append('start', startInSecond.getTime())
      formData.append('end', endInSecond.getTime())
      formData.append('limit', this.limit)
      formData.append('page', page)

      if(this.selectedPair !== 'all') {
        let marketPair = this.selectedPair.replace(' / ', '-')
        formData.append('marketPair', marketPair)
      }

      if(this.buyOrSell !== 'all') {
        let isBuy = this.buyOrSell === 'buy'
        formData.append('isBuy', isBuy)
      }

      if(this.orderMethod !== 'all') {
        let orderType = this.orderMethod.toUpperCase()
        formData.append('orderType', orderType)
      }

      this.$post(this.$ORDER_SPOT_HISTORY_OPEN, 'generalMarketHistoryList', formData,true, (result) => {
        this.tradeList = result.data
        this.tradeList.map(list => {
          list.date = this.formatDate(list.createdAt, 'full')
          list.isBuy ? list.isBuy = 'buy' : list.isBuy = 'sell'
          list.initAmount = this.toFloat(list.initAmount)
          list.price = this.toFloat(list.orderPrice)
          list.remainAmount = this.toFloat(list.remainAmount)
          list.orderType = this.getOrderType(list.orderType)
        })
        this.pageLast = Math.ceil(result.total / this.limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    getOrderType(orderType) {
      // LIMIT, LIMIT_M, LIMIT_R, TRADED_LIMIT
      if(orderType.includes('LIMIT')) {
        return this.$t('message.limit')
      } else if(orderType === 'MARKET') {
        return this.$t('message.marketPrice')
      } else if(orderType === 'RESERVE') {
        return this.$t('message.StopLimit')
      } else {
        return orderType
      }
    },
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.selectedPair = 'all'
        this.orderMethod = 'all'
        this.buyOrSell = 'all'
        this.rerenderKey += 1
      }
    },
  },


}
</script>

<style scoped>

</style>
