<template>
  <div class="modal" v-if="show">
    <div class="modal_con">
      <div class="modal_header" :class="{ red: color === 'red' }">
        <h6 v-html="title"></h6>
        <div class="iconbox">
          <a class="modal_close" @click="close"></a>
        </div>
      </div>
      <div class="modal_contents">
        <slot></slot>
        <div class="modal_btn" v-show="textOk || textNo">
          <a class="modal_buy_btn" @click="ok" v-show="textOk" :class="{ red: color === 'red' }">{{ textOk }}</a>
          <a class="modal_cancel_btn" @click="no" v-show="textNo">{{ textNo }}</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "modal",
  data() {
    return {
      show: false,
      callback: null,
      cancel: null,
      textOk: '',
      textNo: '',
      title: '',
      isChecked: true,
      color: '',
      onlyDisplay: true,
    }
  },

  methods: {
    showDialog(title, textOk, textNo, callback, cancel, isChecked = true, color = '', onlyDisplay = true) {
      this.title = title
      this.textOk = textOk
      this.textNo = textNo
      this.callback = callback
      this.cancel = cancel
      this.isChecked = isChecked
      this.color = color
      this.onlyDisplay = onlyDisplay

      this.show = true
    },
    close() {
      this.show = false
    },
    ok() {
      if(!this.isChecked) {
        this.notify('error', this.$t('message.checkForTransaction'))
        return
      }
      this.callback()

      if(this.onlyDisplay) {
        this.close()
      }
    },

    no() {
      this.close()
      this.cancel()
    }
  }
}
</script>

<style scoped>

</style>
