<template>
  <div class="otc_top">
    <div class="top_title">
      <h5><router-link :to="{ name: 'OtcMain' }" class="dblue_02">{{ $t('message.otc') }}</router-link></h5>
      <div class="btn_otc_wrap">
        <div class="btn_otc" @click="goToOtcOffer('sell')">
          <a>{{ $t('message.postSellOffer') }}</a>
        </div>
        <div class="btn_otc" @click="goToOtcOffer('buy')">
          <a>{{ $t('message.postBuyOffer') }}</a>
        </div>
      </div>
    </div>
    <div class="table_top">
      <div>
        <span>{{ $t('message.coin') }} {{ $t('message.search') }}</span>
        <p class="coin_search_c_wrap">
          <input class="search_c" type="text" :placeholder="$t('message.search')" disabled="disabled">
          <em class="coin_search_c_icon"><i class="fas fa-search"></i></em>
        </p>
      </div>
      <div>
        <select class="buy" @change="changeOfferType($event)" v-model="offerType">
          <option value="buy">{{ $t('message.buy') }}</option>
          <option value="sell">{{ $t('message.sell') }}</option>
        </select>
        <select class="sort" @change="changeCoin($event)" v-model="selectedCoin">
          <option v-for="coin in coinArr" :key="coin" :value="coin">{{ coin }}</option>
        </select>
      </div>
      <div class="btn_otc_m">
        <div class="point" :class="{ btn_active: offerType === 'buy' }" @click="changeOfferTypeMobile($event, 'buy')">
          <a>{{ $t('message.buy') }}</a>
        </div>
        <div class="point" :class="{ btn_active: offerType === 'sell' }" @click="changeOfferTypeMobile($event, 'sell')">
          <a>{{ $t('message.sell') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OtcCmpSearch",

  data() {
    return {
      selectedCoin: '',
      offerType: this.$route.params.offerType ? this.$route.params.offerType : 'buy',
      currencyArr: [],
      coinArr: [],
      offerList: [],
      marketPairList: [],
    }
  },

  mounted() {
    this.$get(this.$HOME_LIST_MARKET, 'otcGetListMarket', false, (result) => {
      this.marketPairList = result.data
      console.log(this.marketPairList)

      this.$emit('getPairList', this.marketPairList)

      let tempCoinArr = []
      this.marketPairList.forEach(marketPair => {
        if(marketPair.marketType !== 'OTC') {
          return
        }
        tempCoinArr.push(marketPair.marketPair.split('-')[0])
      })
      this.coinArr = [...new Set(tempCoinArr)]
      this.$emit('getCoinArr', this.coinArr)

      let tempCurrencyArr = []
      this.marketPairList.forEach(marketPair => {
        if(marketPair.marketType !== 'OTC') {
          return
        }
        tempCurrencyArr.push(marketPair.marketPair.split('-')[1])
      })
      this.currencyArr = [...new Set(tempCurrencyArr)]
      this.$emit('getCurrencyArr', this.currencyArr)

      this.selectedCoin = this.coinArr[0]
      let selectedCurrency = this.currencyArr[0]
      this.$emit('changeCoin', this.selectedCoin)
      this.$emit('changeCurrency', selectedCurrency)
    }, () => {

    })
  },

  methods: {
    goToOtcHome(offerType) {
      if(offerType) {
        this.$router.push({ name: 'OtcMain', params: { offerType: offerType }})
      } else {
        this.$router.push({ name: 'OtcMain' })
      }
      document.querySelector('.body_wrap').scrollIntoView()
    },

    goToOtcOffer(offerType) {
      if(this.$route.path !== '/otc/offer') {
        this.$router.push({ name: 'OtcOffer', params: { offerType: offerType }})
      } else {
        this.$emit('changeOfferType', offerType)
      }
    },

    changeCoin(e) {
      this.selectedCoin = e.currentTarget.value
      if(this.$route.path !== '/otc/main') {
        this.goToOtcHome()
      }
      this.$emit('changeCoin', this.selectedCoin)
    },

    changeOfferType(e) {
      this.offerType = e.currentTarget.value
      if(this.$route.path !== '/otc/main') {
        this.goToOtcHome(this.offerType)
      } else {
        this.$emit('changeOfferType', this.offerType)
      }
    },

    changeOfferTypeMobile(e, type) {
      this.offerType = type
      if(this.$route.path !== '/otc/main') {
        this.goToOtcHome(this.offerType)
      } else {
        this.$emit('changeOfferType', this.offerType)
      }

      if(!e.currentTarget.classList.contains('btn_active')) {
        e.currentTarget.parentNode.getElementsByClassName('btn_active')[0].classList.remove('btn_active')
        e.currentTarget.classList.add('btn_active')
      }
    },
  }
}
</script>

<style scoped>

</style>
