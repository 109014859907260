<template>
  <div class="body_wrap">
    <div class="main m-0">
      <div class="pagew p-0">
        <div class="popup">
          <div class="popup-item" v-for="(popup, index) in popupList" v-bind:key="index" v-show="popup.show">
            <div class="popup-title">{{ popup.title }}</div>
            <div class="popup-contents ck-content" v-html="escapeTag(popup.contents)"></div>
            <button class="popup-close" @click="closePopup(index)">[ 닫기 ]</button>
          </div>
        </div>
      </div>
    </div>
    <div class="slide">
      <div class="swiper-container main_banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide banner_01">
            <div class="pagew">
              <div class="text_box">
                <img src="/img/logo_header.svg" :alt="$t('message.logo')">
                <span class="dblue_01">{{ $t('message.mainSlide1') }}</span>
                <span class="blue_01">{{ $t('message.mainSlide2') }}</span>
                <p>{{ $t('message.mainSlide3') }}</p>
              </div>
            </div>
          </div>
          <div class="swiper-slide banner_02">
            <div class="pagew">
              <div class="text_box">
                <img src="/img/logo_header.svg" :alt="$t('message.logo')">
                <span class="dblue_01">{{ $t('message.mainSlide4') }}</span>
                <span class="blue_01">{{ $t('message.mainSlide5') }}</span>
                <p>{{ $t('message.mainSlide6') }}</p>
              </div>
            </div>
          </div>
          <div class="swiper-slide banner_03">
            <div class="pagew">
              <div class="text_box">
                <img src="/img/logo_header.svg" :alt="$t('message.logo')">
                <span class="dblue_01">{{ $t('message.mainSlide7') }}<small>({{ $t('message.mainSlide8') }})</small></span>
                <span class="blue_01">{{ $t('message.mainSlide9') }}</span>
                <p>{{ $t('message.mainSlide10') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination slide_bn"></div>
      </div>
    </div>
    <!-- 메인 컨텐츠 영역 -->
    <div class="main">
      <div class="pagew">
        <!-- 주요 시세 -->
        <div class="coin_contents">
          <!-- 주요 시세 모바일 슬라이드 -->
          <div class="coin_graph_mobile">
            <div class="swiper-container coin_graph_slide">
              <div class="swiper-wrapper">
                <div class="swiper-slide graph_coin" v-for="(miniChart) in miniCharts" :key="miniChart.marketPair">
                  <div class="slide_con">
                    <div class="coin_graph">
                      <div :class="miniChart.marketPair"></div>
                    </div>
                    <div class="coin_box">
                      <span class="coin_box_01">
                        <strong class="fs16">{{ getCoinFullName(miniChart.marketPair.split('-')[0]) }}</strong>
                        <em class="fs12">{{ miniChart.marketPair }}</em>
                      </span>
                      <span class="coin_box_02">
                        <em class="fwb fs24" :class="{ price_red: miniChart.changeRatio > 0, price_blue: miniChart.changeRatio < 0 }">
                          {{ miniChart.price | numberWithCommas }} <span class="fs11">{{ miniChart.marketPair.split('-')[1] }}</span>
                        </em>
                        <span :class="{ price_red: miniChart.change > 0, price_blue: miniChart.change < 0 }">
                          <i class="fas fa-caret-up" v-if="miniChart.change >= 0"></i>
                          <i class="fas fa-caret-down" v-else-if="miniChart.change < 0"></i>
                          {{ subDec(miniChart.change, miniChart.marketPair.split('-')[1]) | numberWithCommas }}
                        </span>
                      </span>
                      <span class="coin_box_03">
                        <em>{{ $t('message.change') }}(24H)</em>
                        <strong :class="{ price_red: miniChart.changeRatio > 0, price_blue: miniChart.changeRatio < 0 }">
                          {{ Number(miniChart.changeRatio) > 0 ? '+' : '' }}{{ miniChart.changeRatio }}%
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-next coin_graph_next"></div>
            <div class="swiper-button-prev coin_graph_prev"></div>
          </div>

          <!-- 코인 정보창 -->
          <div class="coin_info_wrap">
            <div class="main_table_tab">
              <span id="tabs">
                <a class="coin_tab" :class="{ tab_focus: curr === secondCurrency && !isFav }" v-for="curr in secondCurr" :key="curr" @click="activeTab(curr)">{{ curr }} {{ $t('message.market') }}</a>
              </span>
              <a class="coin_tab" :class="{ tab_focus: isFav }" @click="activeTab('favorite')">{{ $t('message.favorite') }}</a>
            </div>
            <div class="coin_search right">
              <form class="form">
                <input type="text" placeholder="검색 (예: BTC)" v-model="keyword" @keyup.enter="searchCoin" />
                <span class="coin_search_icon">
                  <i class="fas fa-search"></i>
                </span>
              </form>
            </div>
            <div class="main_table_con w768">
              <table class="basic">
                <colgroup>
                  <col style="width: 3%;">
                  <col style="width: 14%;">
                  <col style="width: 14%;">
                  <col style="width: 14%;">
                  <col style="width: 14%;">
                  <col style="width: 14%;">
                  <col style="width: 14%;">
                  <col style="width: 13%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col" colspan="2">{{ $t('message.coinName') }}</th>
                    <th scope="col">{{ $t('message.price') }}</th>
                    <th class="none" scope="col">{{ $t('message.high') }}</th>
                    <th class="none" scope="col">{{ $t('message.low') }}</th>
                    <th class="none" scope="col">24H {{ $t('message.vol') }}</th>
                    <th scope="col">24H {{ $t('message.changeRate') }}</th>
                    <th class="none" scope="col">{{ $t('message.trading') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(market, idx) in marketList" :key="idx" v-show="!(selectedSecond === 'favorite' && !market.fav)">
                    <td @click="setFav(market.marketPair, market.fav)">
                      <button v-show="market.fav">
                        <i class="fas fa-star favorite"></i>
                      </button>
                      <button v-show="!market.fav">
                        <i class="fas fa-star"></i>
                      </button>
                    </td>
                    <td class="t_left">
                      <span>{{ getCoinFullName(market.marketPair.split('-')[0]) }}</span>
                      <em>{{ market.marketPair.replace('-', ' / ') }}</em>
                    </td>
                    <td class="t_right">{{ subDec(market.present, market.marketPair.split('-')[1]) | numberWithCommas }} {{ getSymbol(market.marketPair.split('-')[1]) }}</td>
                    <td class="t_right none">{{ subDec(market.high, market.marketPair.split('-')[1]) | numberWithCommas }} {{ getSymbol(market.marketPair.split('-')[1]) }}</td>
                    <td class="t_right none">{{ subDec(market.low, market.marketPair.split('-')[1]) | numberWithCommas }} {{ getSymbol(market.marketPair.split('-')[1]) }}</td>
                    <td class="t_right none">{{ subDec(market.volume, market.marketPair.split('-')[1]) | numberWithCommas }} {{ getSymbol(market.marketPair.split('-')[0]) }}</td>
                    <td class="t_right" :class="{ price_red: Number(market.changeRatio) > 0, price_blue: Number(market.changeRatio) < 0 }">{{ Number(market.changeRatio) >= 0 ? '+' : '' }} {{ subDec(Number(market.changeRatio)) }}%
                      <i class="fas fa-caret-up" v-show="Number(market.changeRatio) > 0"></i>
                      <i class="fas fa-caret-down" v-show="Number(market.changeRatio) < 0"></i>
                    </td>
                    <td class="blue_04 fwb none trading" @click="$router.push({ name: 'GeneralMarket', params: { pair: market.marketPair } })">{{ $t('message.trading') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="no_list" v-show="!marketList.length">{{ $t('message.noData') }}</p>
          </div>
          <div class="help">
            <div class="help_box1">
              <div class="notice">
                <h5 class="help_tit">{{ $t('message.announcement') }}</h5>
                <ul v-if="noticeList.length === 0">
                  <li>
                    {{ $t('message.noData') }}
                  </li>
                </ul>
                <ul v-for="notice in noticeList" :key="notice.idx">
                  <li>
                    <a class="point" @click="goToDetail(notice.idx)">{{ notice.title }}
                    </a>
                  </li>
                </ul>
                <router-link :to="{ name: 'CustomerSupportList' }" class="more"><span>{{ $t('message.more') }}</span><i class="fas fa-angle-right"></i></router-link>
              </div>
            </div>
            <div class="help_box2">
              <div class="top_ranking">
                <h5 class="help_tit">{{ $t('message.ranking') }}</h5>
                <table class="w768">
                  <colgroup>
                    <col style="width: auto;">
                    <col style="width: 22%;">
                    <col style="width: 22%;">
                    <col style="width: 18%;">
                  </colgroup>
                  <thead>
                  <tr>
                    <th scope="col">{{ $t('message.coinName') }}</th>
                    <th scope="col">{{ $t('message.price') }}</th>
                    <th scope="col" class="none">24H {{ $t('message.vol') }}</th>
                    <th scope="col">{{ $t('message.changeRate') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!--                5개-->
                  <tr v-for="(market, idx) in topList" :key="'top' + idx">
                    <td class="t_left"><strong class="m_right10">{{ getCoinFullName(market.marketPair.split('-')[0]) }}</strong>
                      <em class="fs11">{{ market.marketPair.replace('-', ' / ') }}</em>
                    </td>
                    <td class="t_right">{{ subDec(market.present, market.marketPair.split('-')[1]) | numberWithCommas }} {{ getSymbol(market.marketPair.split('-')[1]) }}</td>
                    <td class="t_right none">{{ subDec(market.volume, market.marketPair.split('-')[1]) | numberWithCommas }} {{ getSymbol(market.marketPair.split('-')[0]) }}</td>
                    <td class="t_center" :class="{ price_red: market.change >= 0, price_blue: market.change < 0 }">{{ market.change >= 0 ? '+' : '' }} {{ subDec(market.change) }}%
                      <i class="fas fa-caret-up" v-show="market.change > 0"></i>
                      <i class="fas fa-caret-down" v-show="market.change < 0"></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 회사 소개영역 -->
      <div class="company">
        <div class="pagew">
          <div class="com_wrap">
            <div class="com_box">
              <div class="c_img">
                <img src="/img/c_1.svg" alt="icon1">
              </div>
              <p>{{ $t('message.strong1Tit') }}</p>
              <span v-html="$t('message.strong1Desc')"></span>
            </div>
            <div class="com_box">
              <div class="c_img">
                <img src="/img/c_2.svg" alt="icon2">
              </div>
              <p>{{ $t('message.strong2Tit') }}</p>
              <span v-html="$t('message.strong2Desc')"></span>
            </div>
            <div class="com_box">
              <div class="c_img">
                <img src="/img/c_3.svg" alt="icon3">
              </div>
              <p>{{ $t('message.strong3Tit') }}</p>
              <span v-html="$t('message.strong3Desc')"></span>
            </div>
            <div class="com_box">
              <div class="c_img">
                <img src="/img/c_4.svg" alt="icon4">
              </div>
              <p>{{ $t('message.strong4Tit') }}</p>
              <span v-html="$t('message.strong4Desc')"></span>
            </div>
          </div>
          <!-- 회사 소개 모바일 슬라이드 -->
          <div class="com_wrap_mobile">
            <div class="swiper-container com_wrap_slide">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="com_box">
                    <div class="c_img">
                      <img src="/img/c_1.svg" alt="icon1">
                    </div>
                    <p>{{ $t('message.strong1Tit') }}</p>
                    <span v-html="$t('message.strong1Desc')"></span>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="com_box">
                    <div class="c_img">
                      <img src="/img/c_2.svg" alt="icon2">
                    </div>
                    <p>{{ $t('message.strong2Tit') }}</p>
                    <span v-html="$t('message.strong2Desc')"></span>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="com_box">
                    <div class="c_img">
                      <img src="/img/c_3.svg" alt="icon3">
                    </div>
                    <p>{{ $t('message.strong3Tit') }}</p>
                    <span v-html="$t('message.strong3Desc')"></span>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="com_box">
                    <div class="c_img">
                      <img src="/img/c_4.svg" alt="icon4">
                    </div>
                    <p>{{ $t('message.strong4Tit') }}</p>
                    <span v-html="$t('message.strong4Desc')"></span>
                  </div>
                </div>

              </div>
            </div>
            <div class="swiper-button-next com_wrap_next"></div>
            <div class="swiper-button-prev com_wrap_prev"></div>
          </div>
        </div>
      </div>
      <!-- 사이트 링크 영역 -->
      <div class="site_link">
        <div class="pagew">
          <div class="link_wrap">
            <div class="link_left">
              <h5>{{ $t('message.intro1Tit') }}</h5>
              <p v-html="$t('message.intro1Desc')"></p>
              <router-link :to="{ name: 'OtcSuggestionMarketList' }">
                <div class="btn_link">
                  <span>{{ $t('message.goToOtc') }}</span>
                </div>
              </router-link>
            </div>
            <div class="link_right">
              <h5 v-html="$t('message.otcGuide')"></h5>
              <p v-html="$t('message.intro2Desc')"></p>
              <a href="http://zmishop.com" target="_blank">
                <div class="btn_link">
                  <span>{{ $t('message.goToIntro') }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createChart } from 'lightweight-charts'

export default {
  name: 'Main',

  data() {
    return {
      star: false,
      keyword: '',
      markets: [],
      miniCharts: [],
      priceData: [],
      secondCurr: [],
      selectedSecond: '',
      marketList: [],
      topList: [],

      code : '', // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, transfer : 입출금, faq : 자주 묻는 질문, qna : 문의
      codeLocale : '',
      noticeList : [],
      pageLast: 0,
      secondCurrency: '',
      isFav: false,
      popupList: [],
    }
  },

  mounted() {
    if (this.$root.$i18n.locale !== 'ko') {
      this.codeLocale = '_' + this.$root.$i18n.locale
    } else {
      this.codeLocale = ''
    }

    new window.Swiper('.main_banner', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 3000,
      },
      loop: true,
    })

    // ** 회사 소개 (모바일)
    new window.Swiper(".com_wrap_slide", {
      slidesPerView: 1,
      spaceBetween: 0,
      breakpoints: {
        768 : {
          slidesPerView: 3,
        },
        600 : {
          slidesPerView: 2,
        }
      },
      loop: true,
      navigation: {
        nextEl: ".com_wrap_next",
        prevEl: ".com_wrap_prev",
      },
    });

    this.getChartData()

    this.$get(this.$HOME_SECONDARY, 'MainGetHomeSecond', false, (result) => {
      this.secondCurr = result.data
      this.getSecondCurrMarket(this.secondCurr[0], true)
    }, (result) => {
      console.log(result)
    })

    this.getAnnounceList(1)
    this.getPopupList(1)
  },

  methods: {
    setFav(pair, fav) {
      if(!this.$store.getters.getLoginState) {
        this.notify('error', '로그인을 해주세요.')
        return
      }

      let formData = new FormData
      formData.append('marketPair', pair)
      formData.append('marketPairSave', !fav)

      this.$post(this.$USER_MYPAGE_FAV, 'setFav', formData, true, (result) => {
        console.log(result)
        this.getSecondCurrMarket(this.secondCurr[0], false)
      }, (result) => {
        console.log(result)
      })
    },

    getChartData() {
      this.$get(`${this.$HOME_CARD}?count=2`, 'mainCard', false, (result) => {
        this.miniCharts = result.data

        for(let i=0; i<this.miniCharts.length; i++) {
          if(this.miniCharts[i].chart.length) {
            this.miniCharts[i].change = this.miniCharts[i].price - this.miniCharts[i].chart[0]
            let ratio = (Number(this.miniCharts[i].price) - Number(this.miniCharts[i].chart[0])) / Number(this.miniCharts[i].chart[0]) * 100
            this.miniCharts[i].changeRatio = this.subDec(ratio)
            if(!isFinite(this.miniCharts[i].changeRatio)) {
              this.miniCharts[i].changeRatio = 0
            }
          } else {
            this.miniCharts[i].change = 0
            this.miniCharts[i].changeRatio = 0
          }
        }
        this.createMiniChart()
      }, (result) => {
        console.log(result)
      })
    },

    createMiniChart() {
      setTimeout(() => {
        this.miniCharts.forEach((miniChart) => {
          let chartArr = []
          let eleArr = document.getElementsByClassName(`${miniChart.marketPair}`)

          eleArr.forEach((ele, idx) => {
            chartArr[idx] = createChart(ele, option)
          })

          let areaSeriesArr = []

          chartArr.forEach((chart, idx) => {
            areaSeriesArr[idx] = chart.addAreaSeries(areaOption)
          })

          function convertDate(timestamp) {
            timestamp = new Date(timestamp)

            let year = timestamp.getFullYear()
            let month = timestamp.getMonth() + 1
            month = month >= 10 ? month : '0' + month
            let day = timestamp.getDate()
            day = day >= 10 ? day : '0' + day

            timestamp = year + '-' + month + '-' + day
            return timestamp
          }

          let days24 = []
          let dayNow = Date.now()
          days24.push(convertDate(dayNow))
          for(let i=0; i<23; i++) {
            let dayAgo = dayNow - (1000 * 60 * 60 * 24)
            days24.push(convertDate(dayAgo))
            dayAgo = convertDate(dayAgo).split('-')
            dayAgo = new Date(parseInt(dayAgo[0]), dayAgo[1] - 1, parseInt(dayAgo[2]))
            dayNow = dayAgo
          }
          days24 = days24.reverse()

          let seriesData = []
          miniChart.chart.forEach((p, idx) => {
            seriesData.push({ time: `${days24[idx]}`, value: Number(p) })
          })

          areaSeriesArr.forEach(areaSeries => {
            areaSeries.setData(seriesData)
          })
        })

        // ** 주요 시세창 (모바일)
        new window.Swiper(".coin_graph_slide", {
          slidesPerView: 1,
          spaceBetween: 0,
          breakpoints: {
            1024 : {
              slidesPerView: 3,
            },
            768 : {
              slidesPerView: 2,
            },
            360 : {
              slidesPerView: 1,
            }
          },
          loop: true,
          navigation: {
            nextEl: ".coin_graph_next",
            prevEl: ".coin_graph_prev",
          },
        })
      }, 1500)

      let option = {
        width: 278,
        height: 200,
        crosshair: {
          vertLine: {
            visible: false,
            labelVisible: false,
          },
          horzLine: {
            visible: false,
            labelVisible: true,
          },
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        leftPriceScale: {
          visible: false,
        },
        rightPriceScale: {
          visible: false,
        },

        timeScale: {
          barSpacing: 13,
          rightOffset: -0.4,
          visible: false,
        },

        priceScale: {
          // position: 'right',
          // mode: 2,
          autoScale: true,
          invertScale: false,
          alignLabels: false,
          borderVisible: false,
          scaleMargins: {
            top: 0.40,
            bottom: 0.15,
            left: 0,
          },
        },
      }

      let areaOption = {
        topColor: '#f9f9f9',
        bottomColor: '#f9f9f9',
        lineColor: '#dcdcdc',
        lineWidth: 2,
      }
    },

    searchCoin() {
      this.getSecondCurrMarket(this.secondCurrency, false, this.keyword.toUpperCase())
    },

    getSecondCurrMarket(secondCurr, firstCall = false, primaryCurr) {
      let url = `${this.$HOME_MARKET}?secondary=${secondCurr}`
      if(primaryCurr) {
        url += `&primary=${primaryCurr}`
      }

      this.secondCurrency = secondCurr
      this.$get(url, 'MainGetHomeMarket' + secondCurr, false, (result) => {
        this.marketList = result.data

        if(!this.marketList.length) {
          return
        }

        this.marketList.map((market) => {
          if(market.present !== '0' && market.lastPrice !== '0') {
            // 전일대비 변동률
            market.change = (Number(market.present) - Number(market.lastPrice)) / Number(market.lastPrice) * 100
          }
          this.miniCharts.forEach(minichart => {
            if(minichart.marketPair === market.marketPair) {
              // 24H 변동률
              market.changeRatio = minichart.changeRatio
              this.$forceUpdate()
            }
          })
        })

        if(this.$store.getters.getLoginState) {
          this.$get(this.$USER_MYPAGE_FAV, 'getFav', true, (result) => {
            let favList = result.data

            this.marketList.map((market) => {
              favList.forEach(fav => {
                if(fav === market.marketPair) {
                  market.fav = true
                  this.$mount()
                }
              })
            })
          }, (result) => {
            console.log(result)
          })
        }



        if(firstCall) {
          //임시로 작업
          if (this.topList.length === 0) {
            for (let i=0;i<this.marketList.length;i++) {
              if (i<5) {
                this.topList.push(this.marketList[i])
              }
            }
            this.topList.sort((a, b) => {
              return b.change - a.change
            })
          }
        }
      }, (result) => {
        console.log(result)
      })
    },

    getAllMarket(secondCurr) {
      this.$get(`${this.$HOME_MARKET}?secondary=${secondCurr}`, 'MainGetHomeMarket' + secondCurr, false, (result) => {
        this.marketList = [...this.marketList, ...result.data]

        this.marketList.map((market) => {
          if(market.present !== '0' && market.lastPrice !== '0') {
            // 전일대비 변동률
            market.change = (Number(market.present) - Number(market.lastPrice)) / Number(market.lastPrice) * 100
          }
          this.miniCharts.forEach(minichart => {
            if(minichart.marketPair === market.marketPair) {
              // 24H 변동률
              market.changeRatio = minichart.changeRatio
              this.$forceUpdate()
            }
          })
        })

        if(this.$store.getters.getLoginState) {
          this.$get(this.$USER_MYPAGE_FAV, 'getFav', true, (result) => {
            let favList = result.data
            this.marketList.map((market) => {
              favList.forEach(fav => {
                if(fav === market.marketPair) {
                  market.fav = true
                  this.$mount()
                }
              })
            })

          }, (result) => {
            console.log(result)
          })
        }

      }, (result) => {
        console.log(result)
      })
    },

    activeTab(ticker) {
      this.selectedSecond = ticker
      if(ticker === 'favorite') {
        this.isFav = true
        if(!this.$store.getters.getLoginState) {
          this.notify('error', this.$t('message.doLogin'))
          return
        }
        this.marketList = []
        this.secondCurr.forEach((curr) => {
          this.getAllMarket(curr)
        })
      } else {
        this.isFav = false
        this.getSecondCurrMarket(ticker, false)
      }
    },

    getAnnounceList(page) {
      let limit = 5
      // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, transfer : 입출금, faq : 자주 묻는 질문, qna : 문의

      this.code = 'notice'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getNoticeList', false, (result) => {
        this.noticeList = result.data
        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    getPopupList(page) {
      let limit = 3

      this.code = 'popup'
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getPopupList', false, (result) => {
        this.popupList = []
        for (let i = 0; i < result.data.length; i++) {
          let popup = result.data[i]

          this.$get(`${this.$BOARD_DETAIL}/${popup.idx}`, this.$options.name + '_getPopupDetail_' + i, false, (result) => {
            result.data[0].show = true
            this.popupList.push(result.data[0])
          }, (result) => {
            this.notify('error', result.message)
          })
        }
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    goToDetail(idx) {
      this.$router.push({ name: 'CustomerSupportView', params: { articleIdx : idx }} )
    },

    closePopup(index) {
      this.popupList[index].show = false
    }

  },

  watch: {
    email(email) {
      this.$intercom.update({ email })
    },
    '$root.$i18n.locale': {
      handler() {
        if (this.$root.$i18n.locale !== 'ko') {
          this.codeLocale = '_' + this.$root.$i18n.locale
        } else {
          this.codeLocale = ''
        }

        this.getAnnounceList(1)
        this.getPopupList(1)
      }
    },
  }
}
</script>

<style scoped>
.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.popup-item {
  position: fixed;

  margin-top: 100px;
  left: 50%;
  transform: translate(-50%, 0);

  background-color: #fff;
  border: 1px solid #e5e5e5;
  z-index: 9999;
  opacity: 0.95;
}

.popup-title {
  background-color: #e5e5e5;
  padding: 10px;
}

.popup-contents {
  padding: 10px;
}

.popup-close {
  width: 100%;
  font-weight: bold;
  display: block;
  padding: 10px;
}
</style>
