<template>
  <div class="mobile_menu">
    <ul>
      <li>
        <a class="mobile_tit" @click="slideMenu">{{ selectedMenu }}<i class="mbtn" :class="{ open: open }"></i></a>
        <ul class="mobile_sub" id="mobile-sub">
          <li v-show="selectedMenu !== $t('message.otcMarket')">
            <router-link :to="{ name: 'OtcSuggestionMarketList' }">OTC {{ $t('message.market') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.myTradingHistory')">
            <router-link :to="{ name: 'OtcSuggestionHistoryList' }">{{ $t('message.myTradingHistory') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.otcChat')">
            <router-link :to="{ name: 'OtcSuggestionChatList' }">{{ $t('message.otcChat') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.myPost')">
            <router-link :to="{ name: 'OtcSuggestionMypostList'}">{{ $t('message.myPost') }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OtcSuggestionMenuMobile",

  data() {
    return {
      selectedMenu: '',
      open: false,
    }
  },

  methods: {
    slideMenu() {
      window.$(".mobile_sub").slideToggle("300")
      this.open = !this.open
    },

    selectMenu(selectedMenu) {
      this.selectedMenu = selectedMenu
    },
  }
}
</script>

<style scoped>

</style>
