<template>
  <div class="body_wrap otc_s_detail otc_m_detail">
    <Modal ref="Modal">
      <div class="support_viewrap">
        <div class="title_con mt20">
          <h2 class="support_title">
            <span class="active">{{ $t('message.tradeRequest') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
            <span class="grey">{{ $t('message.tradeApp') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
            <span class="grey">{{ $t('message.depositAccept') }}</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
            <span class="grey">{{ $t('message.traded') }}</span>
          </h2>
        </div>
        <div class="view_top">
          <div class="view_title">
            <h5 class="fwm">
              {{ post.writerNickname }}
              <span class="m_left25">({{ $t('message.postTrade') }} {{ writerInfo.allCnt }} | {{ $t('message.traded') }} {{ writerInfo.soldoutCnt }})</span>
            </h5>
          </div>
        </div>
        <div class="purchase_offer">
          <dl class="table_line">
            <div class="notice">
              <p>{{ $t('message.tradeRequest1') }}</p>
              <p>{{ $t('message.tradeRequest2') }}</p>
            </div>
          </dl>

          <dl class="table_line">
            <dt class="table_line_title">
              <span>{{ $t('message.tradeLimit') }}</span>
            </dt>
            <dd>
              <span class="tradeAvailable"> {{ tradeAvailable }}</span>
              <span class="coin">{{ !post.coin.includes('UNLISTED-') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}</span>
            </dd>
          </dl>

          <dl class="table_line">
            <dt class="table_line_title">
              <span>{{ $t('message.tradeAmt') }}</span>
            </dt>
            <dd>
              <input type="text" class="explain pr" v-model="amount"/>
              <span class="coin">{{ !post.coin.includes('UNLISTED-') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}</span>
            </dd>
          </dl>
          <dl class="table_line">
            <dt class="table_line_title">
              <span>{{ $t('message.price') }}</span>
            </dt>
            <dd>
              <input type="text" class="explain" v-model="price"/>
            </dd>
          </dl>
        </div>
      </div>
    </Modal>
    <OtcMenuMobile ref="OtcMenuMobile"></OtcMenuMobile>
    <div class="pagew">
      <div class="sub_container">
        <OtcMenuPC ref="OtcMenuPC"></OtcMenuPC>
        <p class="dblue_02 m_bottom20 mt20">{{ post.boardIsBuy ? $t('message.doBuy') : $t('message.doSell') }} > {{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}</p>
        <div class="title_con">
          <h2 class="support_title">
            [{{ !post.coin.includes('UNLISTED') ? post.coin : post.coin.split('-')[1] + '('+ $t('message.directTran') +')' }}
            <span :class="{ 'price_red': post.stateVal === 'doBuy', 'price_blue': post.stateVal === 'doSell' }">
              {{ $t(`message.${post.stateVal}`) }}
            </span>
            ]
            <span class="fwm">{{ post.title }}</span>
          </h2>
          <div v-show="post.amIWriter" :key="rerenderkey">
            <a class="outline_s_btn m_left10" @click="completeTrade" v-show="post.state === 'OPEN'">{{ $t('message.endOfSale') }}</a>
            <a class="outline_s_btn m_left10" @click="updateTrade" v-show="post.state === 'OPEN'">{{ $t('message.modify') }}</a>
            <a class="outline_s_btn m_left10" @click="deleteTrade" v-show="post.state === 'OPEN' || post.state === 'SOLDOUT'">{{ $t('message.delete') }}</a>
          </div>
        </div>
        <div class="support_viewrap">
          <div class="view_top">
            <div class="view_title">
              <h5 class="fwm">{{ post.writerNickname }}
                <span class="m_left25">({{ $t('message.postTrade') }} {{ writerInfo.allCnt }} | {{ $t('message.traded') }} {{ writerInfo.soldoutCnt }})</span>
              </h5>
            </div>
            <button class="small_btn f_right h30" @click="openModal" :key="rerenderkey" v-show="!post.amIWriter && (post.state === 'OPEN' || post.state === 'TRADING')">
              <a>{{ $t('message.tradeRequest') }}</a>
            </button>
          </div>
          <div class="view_content">
            <p>{{ $t('message.priceWanted') }}: {{ post.exchanges === 'NONE' ? $t('message.negoPrice') : post.exchanges }} {{ post.exchanges === 'NONE' ? '' : (post.markUp >= 0 ? $t('message.standard') + ' +' : $t('message.standard') + ' ') + post.markUp + '%' }}</p>
            <p>{{ $t('message.tradeType') }}: {{ post.tradeMethod === 0 ? $t('message.safeTran') : $t('message.directTran') }}</p>
            <p>{{ $t('message.howToPay') }}</p>
          </div>
          <div class="view_content">
            <p v-html="post.content"></p>
          </div>
          <div class="p_btn_wrap">
            <a @click="$router.back()" class="p_btn_blue">{{ $t('message.list') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtcMenuPC from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_pc'
import OtcMenuMobile from '@/components/otc_suggestion/otc_suggestion_menu/otc_suggestion_menu_mobile'
import Modal from '@/components/cmp/modal/modal'

export default {
  name: "OtcSuggestionMarketDetail",

  data() {
    return {
      fileName: '',
      msg: '',
      price: '',
      amount: '',
      isTradeRequested: false,
      rerenderkey: 0,
      tradeAvailable: 0,
      post: {
        idx: 0,
        state: '',
        boardIsBuy: true,
        coin: '',
        title: '',
        writerUserIdx: 0,
        createdAt: 0,
        writerNickname: '',
        tradeMethod: 0,
        amIWriter: false,
        stateVal: '',
        applicantInputAmount: 0,
        applicantInputPrice: 0,
        applicantUserIdx: 0,
        chatroom: [],
        content: '',
        exchanges: '',
        markUp: 0,
        offersList: [],
        chatroomIdx: null
      },
      writerInfo: {
        allCnt: 0,
        soldoutCnt: 0
      },
      postIdx: this.$route.params.postIdx,
      tabNo: this.$route.params.tabNo,
      myIdx: null,
      chatInterval: null,
      lastTimestamp: 0,
      msgAll: [],
      tmpMsg: [],
      ifImgError: false,
      imgFile: null,
    }
  },

  components: {
    'OtcMenuPC': OtcMenuPC,
    'OtcMenuMobile': OtcMenuMobile,
    'Modal': Modal,
  },

  created() {
    if(!this.postIdx) {
      this.$router.back()
      return
    }

    this.getPostDetail()
  },

  mounted() {
    this.$refs.OtcMenuPC.focusTab(this.tabNo)
    let tabStr = this.tabNo === 0 ? this.$t('message.otcMarket') : this.tabNo === 1 ? this.$t('message.myTradingHistory') : this.tabNo === 2 ? this.$t('message.otcChat') : this.tabNo === 3 ? this.$t('message.myPost') : ''
    this.$refs.OtcMenuMobile.selectMenu(tabStr)
  },

  methods: {
    openModal() {
      let coin = !this.post.coin.includes('UNLISTED') ? this.post.coin : this.post.coin.split('-')[1] + '('+ this.$t('message.directTran') +')'
      let state = this.$t(`message.${this.post.stateVal}`)

      this.$refs.Modal.showDialog(`[${coin} ${state}] ${this.post.title}`, this.$t('message.tradeRequest'), this.$t('message.cancel'), () => {
        if(this.amount * 1 <= 0 || this.price * 1 <= 0 ) {
          this.notify('error', this.$t('message.shouldBeMoreThanZero'))
          return
        }
        this.requestTrade()
      }, ()=>{}, true, '', false)
    },

    createChatroom() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)
      this.$post(this.$OTC_TEMP_CHATROOM, 'otcCreateChat', formData, true, (result) => {
        this.post.chatroomIdx = result.data[0].chatroomIdx
      }, (result) => {
        console.log(result)
      })
    },

    requestTrade() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)
      formData.append('amount', this.amount)
      formData.append('price', this.price)

      this.$post(this.$OTC_TEMP_TRADE_REQUEST, 'PostTradeRequest', formData, true, () => {
        this.createChatroom()
        this.notify('success', this.$t('message.tradeRequestDone'))
        // this.$router.push({ name: 'OtcSuggestionHistoryDetail',
        //   params: {
        //     tradeIdx: result.data[0].tradeIdx,
        //     tabNo: 1,
        // }})
        this.$router.push({ name: 'OtcSuggestionHistoryList', params: { tabNo: 1 } })
      }, (result) => {
        console.log(result)
      })
    },

    getPostDetail() {
      this.$get(`${this.$OTC_TEMP_LIST}/${this.postIdx}`, 'OtcDetailGetLIST', true, (result) => {
        this.post = result.data[0]
        this.tradeAvailable = result.data[0].tradeAvailable;
        this.tradeAvailable = (this.tradeAvailable !== undefined && this.tradeAvailable !== null) ? this.tradeAvailable : 0;
        this.getUserProfile((result) => {
          if(result.success) {
            this.myIdx = result.userProfile.userIdx

            this.whatAmI()
          } else {
            console.log(result.userProfile)
          }
        })

        if(this.post.boardIsBuy) {
          this.post.stateVal = 'doBuy'
        } else {
          this.post.stateVal = 'doSell'
        }

        if(this.post.state === 'SOLDOUT') {
          this.post.stateVal = 'endOfSale'
        }

        this.post.content = this.post.content.replaceAll('\n', '<br/>')

        this.getWriterInfo()
      }, (result) => {
        console.log(result)
      })
    },

    getWriterInfo() {
      let formData = new FormData()
      formData.append('writerIdx', this.post.writerUserIdx)
      this.$post(this.$OTC_TEMP_COUNT, 'otcDetailCount', formData, true, (result) => {
        this.writerInfo = result.data[0]
      }, (result) => {
        console.log(result)
      })
    },

    whatAmI() {
      this.post.amIWriter = this.myIdx === this.post.writerUserIdx
      this.rerenderkey += 1
    },

    completeTrade() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)
      this.$post(this.$OTC_TEMP_TRADE_DONE, 'otcDetailTradeDone', formData, true, (result) => {
        console.log(result)
        this.notify('success', this.$t('message.didTradeDone'))
        this.$router.back()
      }, (result) => {
        console.log(result)
      })
    },

    updateTrade() {
      this.$router.push({ name: 'OtcSuggestionWrite', params: { post: this.post }})
    },

    deleteTrade() {
      let formData = new FormData()
      formData.append('idx', this.post.idx)

      this.$post(this.$OTC_TEMP_DELETE, 'otcDetailDelete', formData, true, (result) => {
        console.log(result)
        this.notify('success', this.$t('message.deleted'))
        this.$router.back()
      }, (result) => {
        console.log(result)
      })
    }
  },

  watch: {
    'amount': {
      handler() {
        if(!this.amount.toString().match(/^[0-9]*\.?[0-9]*$/)) {
          this.amount = this.amount.toString().slice(0, this.amount.length - 1)
        }
      }
    },

    'price': {
      handler() {
        if(!this.price.toString().match(/^[0-9]*\.?[0-9]*$/)) {
          this.price = this.price.toString().slice(0, this.price.length - 1)
        }
      }
    },
  }
}
</script>

<style scoped>
.tradeAvailable {
  position: absolute;
  left: 10px;
  line-height: 40px;
}

</style>
