<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="subcontainer1">
        <h2 class="support_title fwm dblue_02">{{ $t('message.trading') }}</h2>
        <div class="support_viewrap">
          <div class="view_top">
            <div class="view_title">
              <h5 class="fwm">{{ tradeDetail.title }}</h5>
            </div>
            <div class="view_date">
              <span class="fwm">{{ $t('message.createdTime') }}</span>
              <span class="fwm">{{ tradeDetail.date }}</span>
            </div>
          </div>
          <div class="view_content">
            <p v-html="escapeTag(tradeDetail.contents)"></p>
            <img v-show="tradeDetail.file" :src="tradeDetail.file" alt="파일" />
          </div>
          <div class="p_btn_wrap">
            <router-link :to="{ name: 'CustomerSupportCenter' }" class="p_btn_blue">{{ $t('message.customerCenter') }}</router-link>
            <router-link :to="{ name: 'CustomerSupportTradeList' }" class="p_btn_blue">{{ $t('message.list') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tradeView",
  data() {
    return {
      tradeIdx: this.$route.params.articleIdx,
      tradeDetail: {},
    }
  },

  created() {
    if(this.tradeIdx) {
      sessionStorage.setItem('tradeIdx', this.tradeIdx)
    } else {
      this.tradeIdx = sessionStorage.getItem('tradeIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('tradeIdx')
  },

  mounted() {
    this.$get(`${this.$BOARD_DETAIL}/${this.tradeIdx}`, this.$options.name + '_mountedDetail', false, (result) => {
      this.tradeDetail = result.data[0]
      this.tradeDetail.date = this.formatDate(this.tradeDetail.createdAt, 'full')
    }, (result) => {
      this.notify('error', result.message)
    })
  },
}
</script>

<style scoped>

</style>
