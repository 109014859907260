<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="otp_top">
        <p class="fs24 fwb title">OTP {{ $t('message.verificationApplied') }}</p>
        <p>{{ $t('message.otpDesc1') }}</p>
      </div>
      <div class="otp_mid">
        <div class="step">
          <span class="s1">{{ $t('message.step1') }}</span>
          <span class="sub-tit">{{ $t('message.otpDown') }}</span>
          <p>{{ $t('message.otpDesc3') }}</p>
        </div>
        <div class="phone_box">
          <div>
            <p class="fs24 fwb">{{ $t('message.android') }}</p>
            <p>{{ $t('message.searchPlayStore') }}</p>
            <img src="/img/otp_img2.jpg" />
            <a :href="otpData.playStore" class="outline_s_btn">{{ $t('message.playStore') }} OTP</a>
          </div>
          <div>
            <p class="fs24 fwb">{{ $t('message.iPhone') }}</p>
            <p>{{ $t('message.searchAppStore') }}</p>
            <img src="/img/otp_img.jpg" />
            <a :href="otpData.appStore" class="outline_s_btn">{{ $t('message.appStore') }} OTP</a>
          </div>
        </div>
        <div class="ph_screen">
          <div>
            <img src="/img/googleotp01.jpg" />
          </div>
          <div>
            <img src="/img/googleotp02.jpg" />
          </div>
          <div>
            <img src="/img/googleotp03.jpg" />
          </div>
          <div>
            <img src="/img/googleotp04.jpg" />
          </div>
        </div>
      </div>
      <div class="otp_bottom">
        <div class="step">
          <span class="s1">{{ $t('message.step2') }}</span>
          <span class="sub-tit">{{ $t('message.qrAuth') }}</span>
          <p>{{ $t('message.qrDesc1') }}</p>
        </div>
        <div class="qr_code">
          <div class="qr_con">
            <qrcode :value=otpData.url :options="{ width: 200 }"></qrcode>
            <p>{{ $t('message.qrDesc2') }}</p>
            <p class="fs12 price_red">{{ otpData.key }}</p>
          </div>
          <div class="qr_con">
            <p>{{ $t('message.qrDesc3') }}</p>
            <p class="fs12 price_red">ubit ({{ $t('message.yourId') }})</p>
          </div>

          <div class="ad_option">
            <div class="pay_desc">
              <input type="text" class="input_f" :placeholder="$t('message.placeholderOTP')" v-model="optCode">
              <div class="middle_btn upload_btn" @click="confirm">
                <a>{{ $t('message.confirm') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "MyPageSecurityOTP",

  data() {
    return {
      otpData: {
        key: "",
        url: "",
        playStore: "",
        appStore: ""
      },
      optCode: ''
    }
  },

  mounted() {
    this.$get(this.$USER_MYPAGE_SECURITY_OTP, 'myPageSecurityOtpGetOTP', true, (result) => {
      this.otpData = result.data[0]
    }, (result) => {
      console.log(result)
    })

  },

  methods: {
    confirm() {
      let formData = new FormData()
      formData.append('key', this.otpData.key)
      formData.append('code', this.optCode.toString())

      this.$post(this.$USER_MYPAGE_SECURITY_OTP, 'myPageSecurityOptPostCode', formData, true, (result) => {
        this.notify('success', result.message)
        this.$router.push({ name: 'MyPageSecurityMain' })
      }, (result) => {
        this.notify('error', result.message)
      })
    }
  }

}
</script>

<style scoped>

</style>
