<template>
  <div class="tabmenu mypage">
    <router-link :to="{ name: 'AssetManagementWallet' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 0 }">{{ $t('message.assetInfo') }}</router-link>
    <router-link :to="{ name: 'AssetManagementWalletHistory' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 1 }">{{ $t('message.coinDepWith') }}</router-link>
  </div>
</template>

<script>
export default {
  name: "WalletMenuPc",

  data() {
    return {
      tabNo: 0,
    }
  },

  methods: {
    focusTab(tabNo) {
      this.tabNo = tabNo
    }
  }
}
</script>

<style scoped>

</style>
