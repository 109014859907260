<template>
  <div class="user_wrap">
    <div class="container_join scroll">
      <div class="login_top">
        <router-link :to="{ name: 'Main' }">
          <img src="/img/logo_header.svg" :alt="$t('message.logo')">
        </router-link>
      </div>
      <h5 class="dblue_01 t_center">{{ $t('message.signup') }}</h5>
      <div class="join_wrap">
        <input type="text" :placeholder="$t('message.placeholderNickname')" v-model="nickName" v-focus>
<!--        <p class="join_desc dblue_01 fs12">*영어, 숫자를 포함 6자리 이상</p>-->
      </div>
      <div class="join_wrap right-inner-addon">
        <input :type="passwordType" :placeholder="$t('message.placeholderPwd')" v-model="pwd">
        <i class="fa fa-eye fa-lg" @click="hiddenPassword"></i>
        <p class="join_desc dblue_01 fs12">* {{ $t('message.pwdLimit') }}</p>
      </div>
      <div class="join_wrap right-inner-addon">
        <input :type="passwordConfirmType" :placeholder="$t('message.placeholderPwdConfirm')" v-model="pwdConfirm">
        <i class="fa fa-eye fa-lg" @click="hiddenPasswordConfirm"></i>
      </div>
      <div class="join_line"></div>
      <div class="join_wrap flex">
        <input type="email" :placeholder="$t('message.placeholderEmail')" v-model="email">
        <div class="outline_m_btn" @click="sendCodeToEmail">
          <a>{{ $t('message.authCode') }}</a>
        </div>
      </div>
      <div class="join_wrap flex" v-show="showInputEmailVerify">
        <input type="number" :placeholder="$t('message.placeholderPutAuthCode')" v-model="emailVerifyCode"/>
        <div class="outline_m_btn" @click="confirmEmailCode">
          <a>{{ $t('message.confirm') }}</a>
        </div>
      </div>
      <div class="join_wrap flex">
        <vue-country-code @onSelect="onSelect" :preferredCountries="['kr', 'cn', 'us']" defaultCountry="kr"></vue-country-code>
        <input class="phone" :placeholder="$t('message.placeholderPhoneNo')" v-model="phone"/>
        <div class="outline_m_btn" @click="sendCodeToSMS">
          <a>{{ $t('message.authCode') }}</a>
        </div>
      </div>
      <div class="join_wrap flex" v-show="showInputSmsVerify">
        <input type="number" :placeholder="$t('message.placeholderPutAuthCode')" v-model="smsVerifyCode"/>
        <div class="outline_m_btn" @click="confirmSmsCode">
          <a>{{ $t('message.confirm') }}</a>
        </div>
      </div>
      <div class="join_wrap">
        <input type="text" :placeholder="$t('message.placeholderPutAgentCode')" v-model="agentCode"/>
      </div>
      <div class="join_wrap">
        <div class="checks">
          <input type="checkbox" id="chkAgree" v-model="chkAgree">
          <label for="chkAgree">{{ $t('message.agreeChk') }}</label>
        </div>
      </div>
      <div class="join_wrap">
        <a class="btn_user" @click="signup">{{ $t('message.signup') }}</a>
        <div class="join_link">
          <span>{{ $t('message.askAccount') }}</span>
          <router-link :to="{ name: 'Login' }" class="dblue_01">{{ $t('message.login') }}</router-link>
        </div>
        <LanguageHorizon/>
      </div>
    </div>
  </div>

</template>

<script>
import LanguageHorizon from "./cmp/language/lang_horizon"

export default {
  name: "Signup",
    components: {
      'LanguageHorizon': LanguageHorizon
    },
  data() {
    return {
      nickName: '',
      pwd: '',
      pwdConfirm: '',
      email: '',
      phone: '',
      emailVerifyCode: '',
      smsVerifyCode: '',
      agentCode: '',
      countryCode: '',
      showInputEmailVerify: false,
      showInputSmsVerify: false,
      emailHash: '',
      smsHash: '',
      chkAgree: false,
      passwordType: 'password',
      passwordConfirmType: 'password'
    }
  },

  mounted() {
    this.$parent.showHeaderFooter(false)
    this.$intercom.update({
      hide_default_launcher: true
    })
  },

  beforeDestroy() {
    this.$parent.showHeaderFooter(true)
    this.$intercom.update({
      hide_default_launcher: false
    })
  },

  methods: {
    onSelect({name, iso2, dialCode}) {
      console.log(name, iso2, dialCode)
      this.countryCode = iso2
    },

    signup() {
      if(this.chkAgree) {
        let formData = new FormData()
        formData.append('email', this.email)
        formData.append('nickName', this.nickName)
        formData.append('password', this.pwd)
        formData.append('passwordConfirm', this.pwdConfirm)
        formData.append('code', this.agentCode.toString())
        formData.append('countryCode', this.countryCode)
        formData.append('phone', this.phone)
        formData.append('emailVerifyCode', this.emailHash)
        formData.append('phoneVerifyCode', this.smsHash)

        this.$post(this.$USER_SIGNUP, 'signup', formData, false, (result) => {
          console.log('post signup', result.data)
          this.notify('success', result.message)
          this.$router.push({ name: 'Login' })
        }, (result) => {
          console.log('post signup fail', result)
          this.notify('error', result.message)
        })
      } else {
        this.notify('error', this.$t('message.chkTerms'))
      }

    },

    sendCodeToEmail() {
      if(this.email === '') {
        return
      }
      let formData = new FormData()
      formData.append('email', this.email)

      if(this.$root.$i18n.locale === 'ko') {
        formData.append('countryCode', 'KR')
      } else if(this.$root.$i18n.locale === 'cn') {
        formData.append('countryCode', 'CH')
      } else if(this.$root.$i18n.locale === 'en') {
        formData.append('countryCode', 'EN')
      }

      this.$post(this.$USER_SIGNUP_EMAIL_SEND, 'signupEmailSend', formData, false, (result) => {
        this.notify('success', result.message)
        this.showInputEmailVerify = true
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    sendCodeToSMS() {
      if(this.phone === '') {
        return
      }
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_SIGNUP_SMS_SEND, 'signupSmsSend', formData, false, (result) => {
        this.notify('success', result.message)
        this.showInputSmsVerify = true
      }, (result) => {
        console.log(result)
        this.notify('error', result.message)
      })
    },

    confirmEmailCode() {
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('code', this.emailVerifyCode)

      this.$post(this.$USER_SIGNUP_EMAIL_CERT, 'signupEmailCert', formData, false, (result) => {
        this.emailHash = result.data[0].verifyCode
        console.log('this.verifyCode', result.data)
        this.notify('success', this.$t('message.authSuccess'))
      }, (result) => {
        console.log('post email send Cert', result)
        this.notify('error', result.message)
      })
    },

    confirmSmsCode() {
      let formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('code', this.smsVerifyCode.toString())
      formData.append('countryCode', this.countryCode)

      this.$post(this.$USER_SIGNUP_SMS_CERT, 'signupEmailCert', formData, false, (result) => {
        this.smsHash = result.data[0].verifyCode
        console.log('this.verifyCode', this.smsHash)
        this.notify('success', this.$t('message.authSuccess'))
      }, (result) => {
        console.log('post email send Cert', result)
        this.notify('error', result.message)
      })
    },

    hiddenPassword() {
      if ('password' === this.passwordType) {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },

    hiddenPasswordConfirm() {
      if ('password' === this.passwordConfirmType) {
        this.passwordConfirmType = 'text';
      } else {
        this.passwordConfirmType = 'password';
      }
    }
  }

}
</script>

<style scoped>

</style>
