<template>
  <div class="mobile_menu">
    <ul>
      <li>
        <a class="mobile_tit" @click="slideMenu">{{ selectedMenu }}<i class="mbtn" :class="{ open: open }"></i></a>
        <ul class="mobile_sub" id="mobile-sub">
          <li v-show="selectedMenu !== $t('message.assetInfo')">
            <router-link :to="{ name: 'AssetManagementWallet' }">{{ $t('message.assetInfo') }}</router-link>
          </li>
          <li v-show="selectedMenu !== $t('message.coinDepWith')">
            <router-link :to="{ name: 'AssetManagementWalletHistory' }">{{ $t('message.coinDepWith') }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "WalletMenuMobile",

  data() {
    return {
      selectedMenu: '',
      open: false,
    }
  },

  methods: {
    slideMenu() {
      window.$(".mobile_sub").slideToggle("300")
      this.open = !this.open
    },

    selectMenu(selectedMenu) {
      this.selectedMenu = selectedMenu
    },
  }
}
</script>

<style scoped>

</style>
