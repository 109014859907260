<template>
  <div class="body_wrap">
    <MyPageMenuMobile ref="MyPageMenuMobile"></MyPageMenuMobile>
    <div class="pagew">
      <div class="subcontainer1 advanced">
        <MyPageMenuPC ref="MyPageMenuPC"></MyPageMenuPC>
        <ul class="c5_wrap">
          <li class="c5_title">{{ $t('message.smsLang') }}</li>
        </ul>
        <div>
          <div class="ad_con">
            <ul class="ad_list">
              <li>{{ $t('message.lang') }}</li>
            </ul>
            <div class="ad_option">
              <select class="wallet_address_list" v-model="smsLang">
                <option v-if="smsLang === ''" value="">{{ $t('message.unuse') }}</option>
                <option value="kr">{{ $t('message.ko') }}</option>
                <option value="en">{{ $t('message.en') }}</option>
                <option value="cn">{{ $t('message.ch') }}</option>
              </select>
            </div>
          </div>
          <span class="notify-text">※ {{ $t('message.smsLangNotify')}}</span>
          <div class="middle_btn1" @click="setSMSLang"><a>{{ $t('message.option') }}</a></div>
        </div>
        <ul class="c5_wrap">
          <li class="c5_title">{{ $t('message.autoReply') }}</li>
        </ul>
        <div>
          <div class="ad_con">
            <ul class="ad_list">
              <li>{{ $t('message.isUsed') }}</li>
            </ul>
            <div class="ad_option">
              <label class="label_checkradio">
                <input type="radio" value="1" v-model="autoMsg.using"/>
                <span class="radio_icon"></span>
                <span class="radio_text">{{ $t('message.use') }}</span>
              </label>
              <label class="label_checkradio">
                <input type="radio" value="0" v-model="autoMsg.using"/>
                <span class="radio_icon"></span>
                <span class="radio_text">{{ $t('message.unuse') }}</span>
              </label>
            </div>
          </div>
          <div class="ad_con">
            <ul class="ad_list">
              <li>{{ $t('message.greet') }}</li>
            </ul>
            <div class="ad_option">
              <input type="text" class="input_f" :placeholder="$t('message.accExample')" v-model="autoMsg.opening"/>
            </div>
          </div>
          <div class="ad_con">
            <ul class="ad_list">
              <li>{{ $t('message.endMsg') }}</li>
            </ul>
            <div class="ad_option">
              <input type="text" class="input_f" :placeholder="$t('message.endMsgExample')" v-model="autoMsg.ending"/>
            </div>
          </div>
          <div class="middle_btn1" @click="setAutoMsg"><a>{{ $t('message.option') }}</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPageMenuPC from './my_page_menu/menu_pc'
import MyPageMenuMobile from './my_page_menu/menu_mobile'

export default {
  name: "Advanced",
  components: {
    'MyPageMenuPC': MyPageMenuPC,
    'MyPageMenuMobile': MyPageMenuMobile
  },

  data() {
    return {
      bank: 'false',
      pps: 'false',
      alipay: 'false',
      wechat: 'false',
      bankUser: '',
      bankName: '',
      bankNumber: '',
      ppsId: '',
      ppsNumber: '',
      aliUser: '',
      aliId: '',
      wechatUser: '',
      wechatId: '',
      smsLang: '',
      autoMsg: {
        using: '0',
        opening: '',
        ending: '',
      }
    }
  },

  created() {
    this.getSMSLang()
    this.getAutoMsg()
  },

  mounted() {
    this.$refs.MyPageMenuPC.focusTab(5)
    this.$refs.MyPageMenuMobile.selectMenu(this.$t('message.advancedOptions'))
  },

  methods: {
    setAutoMsg() {
      let formData = new FormData()
      formData.append('using', this.autoMsg.using === '1' ? true : false)
      formData.append('opening', this.autoMsg.opening)
      formData.append('ending', this.autoMsg.ending)

      this.$post(this.$USER_MYPAGE_EXTRA_AUTO, 'postHomeExtraAuto', formData, true, (result) => {
        this.getAutoMsg()
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    getAutoMsg() {
      this.getUserProfile((result) => {
        if(result.success) {
          let myIdx = result.userProfile.userIdx

          this.$get(`${this.$HOME_EXTRA_AUTO}/${myIdx}`, 'getHomeExtraAuto', false, (result) => {
            if(result.data.length) {
              let data = result.data[0]
              if(data.using) {
                data.using = '1'
              } else {
                data.using = '0'
              }
              this.autoMsg = data
            }
          }, (result) => {
            console.log(result)
          })
        } else {
          console.log(result.userProfile)
        }
      })
    },

    setSMSLang() {
      let formData = new FormData()
      formData.append('value', this.smsLang)
      formData.append('append', true)

      this.$post(this.$USER_MYPAGE_EXTRA_SMSLANG, 'postExtraSMSLang', formData, true, (result) => {
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    getSMSLang() {
      this.$get(this.$USER_MYPAGE_EXTRA_SMSLANG, 'extraSMSLang', true, (result) => {
        this.smsLang = result.data[0]
        if(!this.smsLang) {
          this.smsLang = ''
        }
      }, (result) => {
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>
.notify-text {
  color: #b6b6b6;
}
</style>
