<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="wkx_wrap">
        <h5 class="dblue_02 fs24 wkx_title">WKX로 전환</h5>
        <div class="wkx_content">
          <div class="wkx_left">
            <div class="tablewrapper">
              <div class="table_wkx_box">
                <table class="table_wkx">
                  <caption></caption>
                  <colgroup>
                    <col style="width: 20%;">
                    <col style="width: 80%;">
                  </colgroup>
                  <thead>
                  <tr>
                    <th class="t_left">잔고</th>
                    <th class="t_right">{{ this.wkxBal.available | numberWithCommas }}</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="coin in balanceList" :key="coin.coin" :class="{ selected_row: coin.coin === selectedCoin }" @click="selectCoin($event, coin.coin, coin.available)">
                      <td class="t_left">{{ coin.coin }}</td>
                      <td class="t_right">{{ coin.available | numberWithCommas }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="wkx_right">
            <h5>WKX로 전환</h5>
            <p class="t_right">{{ timeDesc }}초 후 실시간 시세가 반영됩니다.</p>

            <div class="gray_line"></div>
            <dl class="table_line">
              <dt class="table_title">
                <span>총자산</span></dt>
              <dd class="table_contents">
                <div class="inputwrap">
                  <input type="text" :value="selectedBal" disabled="disabled">
                  <span>{{ selectedCoin }}</span>
                </div>
              </dd>
            </dl>
            <dl class="table_line">
              <dt class="table_title">
                <span>{{ selectedCoin }} 자산</span></dt>
              <dd class="table_contents">
                <div class="inputwrap">
                  <input type="number" placeholder="0" v-model="amount">
                  <span>{{ selectedCoin }}</span>
                </div>
              </dd>
            </dl>
            <dl class="table_line">
              <dt class="table_title">
                <span>수수료({{ selectedFee }}%)</span></dt>
              <dd class="table_contents">
                <div class="inputwrap">
                  <input :key="rerenderKey" type="number" :value="amount * selectedFee" disabled="disabled">
                  <span>{{ selectedCoin }}</span>
                </div>
              </dd>
            </dl>
            <dl class="table_line">
              <dt class="table_title">
                <span>USD 가치</span></dt>
              <dd class="table_contents">
                <div class="inputwrap">
                  <input type="number" :value="usdValue" disabled="disabled">
                  <span>USD</span>
                </div>
              </dd>
            </dl>
            <dl class="table_line">
              <dt class="table_title">
                <span>WKX 수량</span></dt>
              <dd class="table_contents">
                <div class="inputwrap">
                  <input type="text" placeholder="0" disabled="disabled">
                  <span>WKX</span>
                </div>
              </dd>
            </dl>
            <dl class="table_line">
              <dt class="table_title">
                <span>WKX 시세</span></dt>
              <dd class="table_contents">
                <div class="inputwrap">
                  <input type="number" :value="price" disabled="disabled">
                  <span>WKX</span>
                </div>
              </dd>
            </dl>
            <div class="gray_line"></div>
            <div class="wkx_change_btn_wrap">
              <a class="wkx_change_btn" @click="convert">WKX로 전환</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import Socket from "@/commonjs/socket"

export default {
  name: "AssetManagementConvertToWkx",
  data() {
    return {
      balanceList: [],
      wkxBal: {},
      time: null,
      selectedCoin: '',
      selectedBal: null,
      selectedFee: 0,
      amount: 0,
      wkxIdx: 0,
      selectedCoinIdx: 0,
      rerenderKey: 0,
      price: 0,
      usdValue: 0,
      coins: ['WKX'],
      pairInfo: JSON.parse(sessionStorage.getItem('marketPairs'))
    }
  },

  computed: {
    timeDesc: function() {
      if(this.time.format('ss') === '00') {
        this.getBalance()
      }
      return this.time.format('ss')
    }
  },

  created() {
    this.getBalance()

    setInterval(() => {
      this.time = moment(this.time.subtract(1, 'seconds'))
    }, 1000)
  },

  beforeDestroy() {
    Socket.$off("message", this.handleMessage)
  },

  methods: {
    convert() {
      if(this.selectedBal <= 0) {
        this.notify('error', '잔고가 부족합니다.')
        return
      }

      if(Number(this.amount) <= 0) {
        this.notify('error', `전환할 ${this.selectedCoin} 수량을 입력해주세요.`)
        return
      }

      let formData = new FormData()
      formData.append('currencyIdxFrom', this.selectedCoinIdx)
      formData.append('currencyIdxTo', this.wkxIdx)
      formData.append('amount', Number(this.amount))

      this.$post(this.$WALLET_CONVERT, 'ConvertToWkxPost', formData, true, (result) => {
        this.notify('success', result.message)
        this.getBalance()
        this.amount = 0
      }, (result) => {
        console.log(result)
      })
    },

    selectCoin(e, coin, balance) {
      e.currentTarget.parentNode.querySelector('.selected_row').classList.remove('selected_row')
      e.currentTarget.classList.add('selected_row')
      this.selectedCoin = coin
      this.selectedBal = balance
    },

    getConvertInfo() {
      let tickers = JSON.parse(sessionStorage.getItem('tickers'))
      if(!tickers) {
        this.$router.push({ name: 'Main' })
      }
      let wkx = tickers.filter(ticker => (
          ticker.ticker === 'WKX'
      ))
      this.wkxIdx = wkx[0].currencyIdx

      let secondCoin = tickers.filter(ticker => (
          ticker.ticker === this.selectedCoin
      ))
      this.selectedCoinIdx = secondCoin[0].currencyIdx

      this.$get(`${this.$WALLET_CONVERT}?fromIdx=${this.selectedCoinIdx}&toIdx=${this.wkxIdx}`, 'ConvertToWkxGet', true, (result) => {
        let convertInfo = result.data[0]
        this.price = convertInfo.price
        this.usdValue = convertInfo.usdValue
      }, (result) => {
        console.log(result)
      })
    },

    getBalance() {
      this.time = moment(60 * 1000)
      this.balanceList = []
      this.coins = ['WKX']

      this.$get(this.$WALLET_BALANCE, 'ConvertToWkwGetBal', true, (result) => {
        let list = result.data[0]
        let coins = Object.keys(list)

        let wkxPairs = this.pairInfo.filter(pair => (
            pair.pair.split('-')[1] === 'WKX'
        ))

        coins.forEach((coin) => {
          wkxPairs.forEach((pair) => {
            if(pair.pair.split('-')[0] === coin) {
              this.coins.push(coin)
            }
          })
        })

        this.coins.forEach((coin) => {
          if(coin === 'WKX') {
            this.wkxBal = {
              coin: coin,
              available: list[coin].available
            }
          } else {
            this.balanceList.push({
              coin: coin,
              available: list[coin].available
            })
          }
        })

        this.selectedCoin = this.selectedCoin ? this.selectedCoin : this.balanceList[0].coin

        this.pairInfo.forEach((pair) => {
          if(pair.pair === `${this.selectedCoin}-WKX`) {
            this.selectedFee = Number(this.toFloat(pair.sellFeeRatio))
          }
        })
        this.selectedBal = this.selectedBal !== null ? this.selectedBal : this.balanceList[0].available
        this.getConvertInfo()
      }, (result) => {
        console.log(result)
      })
    }
  },
}
</script>

<style scoped>

</style>
