<template>
  <div class="body_wrap">
    <Modal ref="Modal">
      <div v-show="showDetail">
        <div>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">TXID</dt>
            <dd class="wallet_form_text t_left dblue_02 lh50">{{ transfer.txid === 'safe-withdraw-pending' ? $t('message.waitForSafeWith') : transfer.txid }}</dd>
          </dl>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">{{ $t('message.curr') }}</dt>
            <dd class="wallet_form_text t_left lh50">{{ transfer.tickerName.charAt(0).toUpperCase() + transfer.tickerName.slice(1) }}</dd>
          </dl>
        </div>
        <div>
          <h6 class="modal_sub_tit left price_blue">From</h6>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">ID</dt>
            <dd class="wallet_form_text t_left lh50">{{ transfer.tx_type.indexOf('IN') >= 0 ? id : userEmail }}</dd>
          </dl>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">{{ $t('message.quantity') }}</dt>
            <dd class="wallet_form_text t_left lh50">{{ toFloat(transfer.amount) }} {{ getSymbol(coin) }}</dd>
          </dl>
        </div>
        <div>
          <h6 class="modal_sub_tit left price_blue">To</h6>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">ID</dt>
            <dd class="wallet_form_text t_left lh50">{{ transfer.tx_type.indexOf('OUT') >= 0 ? id : userEmail }}</dd>
          </dl>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">{{ $t('message.quantity') }}</dt>
            <dd class="wallet_form_text t_left lh50">{{ Number(transfer.amount) - Number(transfer.feeAmount) }} {{ getSymbol(coin) }}</dd>
          </dl>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">{{ $t('message.fees') }}</dt>
            <dd class="wallet_form_text t_left lh50">{{ toFloat(transfer.feeAmount) }} {{ getSymbol(coin) }}</dd>
          </dl>
        </div>
      </div>
      <div v-show="showWarning">
        <p class="dblue_01">{{ $t('message.warningTit') }}</p>
        <span v-html="$t('message.fraudWarning')"></span>
      </div>

      <div v-show="showTransfer">
        <div>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">{{ $t('message.coin') }}</dt>
            <dd class="wallet_form_text t_left dblue_02 lh50">
              <input type="text" class="wallet_area t_right" :value="coin" disabled/>
            </dd>
          </dl>
        </div>
        <div class="transfer">
          <div>
            <h6 class="modal_sub_tit t_center price_blue">From</h6>
            <p class="flex_p t_center">
              <span class="wallet_form_tit lh50">{{ fromSpot ? $t('message.exchange') : $t('message.otc') }}</span>
            </p>
            <p class="flex_p t_center">
<!--              <span class="lh50">{{ fromSpot ? mySpotBalance : myOtcBalance | numberWithCommas }}{{ getSymbol(coin) }}</span>-->
            </p>
          </div>
          <div><h6 class="modal_sub_tit t_center price_blue point" @click="fromSpot = !fromSpot"><i class="fas fa-arrows-alt-h"></i></h6></div>
          <div>
            <h6 class="modal_sub_tit t_center price_blue">To</h6>
            <p class="flex_p t_center">
              <span class="wallet_form_tit lh50">{{ !fromSpot ? $t('message.exchange') : $t('message.otc') }}</span>
            </p>
            <p class="flex_p t_center">
<!--              <span class="lh50">{{ !fromSpot ? mySpotBalance : myOtcBalance | numberWithCommas }}{{ getSymbol(coin) }}</span>-->
            </p>
          </div>
        </div>
        <div>
          <dl class="wallet_form">
            <dt class="wallet_form_tit lh50">{{ $t('message.exchangeAmo') }}</dt>
            <dd class="wallet_form_text t_left dblue_02 lh50">
              <input type="number" class="wallet_area t_right" v-model="transferAmount"/>
            </dd>
          </dl>
        </div>
      </div>
    </Modal>
    <WalletMenuMobile ref="WalletMenuMobile"></WalletMenuMobile>
    <div class="pagew">
      <div class="sub_container">
        <WalletMenuPC ref="WalletMenuPC"></WalletMenuPC>
        <div class="wallet_tit wv_tit mt50">
          <h4 class="sub_tit">{{ coin }} {{ $t('message.myWallet') }}</h4>
<!--          <div class="wallet_link">-->
<!--            <select v-model="coin" @change="changeCoin">-->
<!--              <option v-for="coin in coinArr" :value="coin" :key="coin">{{ coin }}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div class="middle_btn right m_left10" @click="$router.push({ name: 'GeneralMarket', params: { pair: `${coin}-KRW` }})">-->
<!--            <a>{{ $t('message.doTrade') }}</a>-->
<!--          </div>-->
        </div>
<!--        <div class="wallet_section">-->
<!--          <dl class="wallet_form" v-show="showAddress">-->
<!--            <dt class="wallet_form_tit">-->
<!--              <span>{{ $t('message.address') }}</span>-->
<!--            </dt>-->
<!--            <dd class="wallet_address">-->
<!--              <select class="wallet_address_list" v-model="address">-->
<!--                <option>{{ address }}</option>-->
<!--              </select>-->
<!--            </dd>-->
<!--            <dd class="wallet_btn">-->
<!--              <button type="button" class="outline_m_btn" @click="copyAddress()">{{ $t('message.copy') }}</button>-->
<!--            </dd>-->
<!--          </dl>-->
<!--          <dl class="wallet_form" v-show="!showAddress">-->
<!--            <dt class="wallet_form_tit"><span>{{ $t('message.address') }}</span></dt>-->
<!--            <dd class="wallet_address">-->
<!--              <select class="wallet_address_list">-->
<!--                <option>{{ $t('message.errorWarning') }}</option>-->
<!--              </select>-->
<!--            </dd>-->
<!--            <dd class="wallet_btn">-->
<!--              <button type="button" class="outline_m_btn" @click="makeAddress">{{ $t('message.makeAddress') }}</button>-->
<!--            </dd>-->
<!--          </dl>-->
<!--        </div>-->
        <div class="wallet_section">
          <div class="tab_buy tab_buy2">
            <a class="point" :class="{'on_n' : selectedTab === 0}" @click="selectedTab = 0">{{ $t('message.deposit') }}</a>
            <a class="point" :class="{'on_n' : selectedTab === 1}" @click="selectedTab = 1">{{ $t('message.withdrawal') }}</a>
            <a class="point" :class="{'on_n' : selectedTab === 2}" @click="selectedTab = 2">{{ $t('message.manageBook') }}</a>
            <a class="point" :class="{'on_n' : selectedTab === 3}" @click="selectedTab = 3">{{ $t('message.statements') }}</a>
            <!--                    <a @click="selectTradeTab($event, 'traded')">{{ $t('message.marketTrades') }}</a>-->
          </div>
          <div class="wallet_card">
            <div class="card m-auto mt20" v-show="selectedTab === 0">
              <div class="card_tit wallet_tit h40">
                <h4 class="sub_tit">{{ $t('message.deposit') }}</h4>
<!--                <span class="tip" @click="openDesc">{{ $t('message.guide') }}</span>-->
              </div>
              <div class="card_cont" v-show="showEmpty"></div>
              <div class="card_cont" v-show="showAddress && !showEmpty">
                <dl class="wallet_form">
                  <dt class="wallet_form_tit"><span style="width: auto">{{ $t('message.address') }}</span></dt>
                  <dd class="wallet_form_text">
                    {{ address }}
                    <button type="button" class="outline_m_btn center" @click="copyAddress()">{{ $t('message.copy') }}</button>
                  </dd>
                </dl>
                <dl class="wallet_form" style="justify-content: flex-start">
                  <dt class="wallet_form_tit"><span>QR{{ $t('message.code') }}</span></dt>
                  <dd class="wallet_qrcode">
                    <qrcode :value="address" :options="{ width: 260 }"></qrcode>
                  </dd>
                </dl>
                <div class="card_info" v-show="address !== '0'">{{ $t('message.depositDesc', { coin: getCoinFullName(coin) })}}</div>
                <div class="mt20">
                  <p class="fwb">{{ $t('message.refNote') }}</p>
                  <ul>
                    <li v-show="coin === 'UBIT'">{{ $t('message.depNote1') }}</li>
                    <li>{{ $t('message.depNote2', { coinFull: coinFull() }) }}</li>
                    <li>{{ $t('message.depNote3') }}</li>
                    <li v-show="coin !== 'ETH'">{{ $t('message.depNote4') }}</li>
                    <li v-show="coin === 'ETH'">{{ $t('message.depNote5') }}</li>
                  </ul>
                </div>
              </div>
              <div class="card_cont" v-show="!showAddress && !showEmpty">
                <dl class="wallet_form">
                  <div class="wallet_form_tit">
                    <p>{{ $t('message.depNote6') }}</p>
                  </div>
                </dl>
                <dl class="wallet_form">
                  <div class="wallet_form_tit">
                    <p>{{ $t('message.depNote7') }}</p>
                  </div>
                </dl>
                <div class="card_cont_btn">
                  <div class="middle_btn" @click="makeAddress">
                    <a>{{ $t('message.chkMakeAddress') }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card m-auto mt20" v-show="selectedTab === 1">
              <div class="card_tit wallet_tit h40">
                <h4 class="sub_tit">{{ $t('message.withdrawal') }}</h4>
<!--                <span class="tip" @click="openDesc">{{ $t('message.guide') }}</span>-->
              </div>
              <div class="card_cont">
<!--                <dl class="wallet_form">-->
<!--                  <dt class="wallet_form_tit"><span>일반거래 잔액</span></dt>-->
<!--                  <dd class="wallet_form_text fwb">{{ mySpotBalance | numberWithCommas }} {{ getSymbol(coin) }}</dd>-->
<!--                </dl>-->
<!--                <dl class="wallet_form">-->
<!--                  <dt class="wallet_form_tit"><span>장외거래 잔액</span></dt>-->
<!--                  <dd class="wallet_form_text fwb">{{ myOtcBalance | numberWithCommas }} {{ getSymbol(coin) }}</dd>-->
<!--                </dl>-->
                <dl class="wallet_form">
                  <dt class="wallet_form_tit"><span>{{ $t('message.withdrawal') }} {{ $t('message.address') }}</span></dt>
                  <dd class="wallet_form_area">
                    <input class="wallet_area" name="address" :placeholder="$t('message.enterAddressTo')" type="text" v-model="addressToSend">
                    <div class="wallet_area_btn d-flex">
                      <button type="button" class="outline_m_btn" style="padding: 0" @click="isBookOpen = !isBookOpen">{{ $t('message.withdrawal') }}{{ $t('message.address') }}{{ $t('message.list') }}</button>
                    </div>
                  </dd>
                </dl>

                <div class="wallet_content1 mk_t_content mt-3 book" v-show="isBookOpen">
                  <table class="basic">
                    <colgroup>
                      <col style="width: 80%;">
                      <col style="width: auto;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th class="fs14" colspan="2">{{ $t('message.manageBook') }}</th>
                      </tr>
                    </thead>
                    <tbody :key="rerenderKey">
                      <tr v-for="addressObj in addressBook" :key="addressObj.idx">
                        <td class="t_left fs14"><span class="address-item">{{ addressObj.name }}<span> ({{ addressObj.address }})</span></span></td>
                        <td class="t_center fs14"><a class="outline_s_btn" @click="addressToSend = addressObj.address; isBookOpen = false">{{ $t('message.select') }}</a></td>
                      </tr>
                      <tr v-show="!addressBook.length">
                        <td class="t_center fs14" colspan="2">{{ $t('message.noAddress') }}</td>
                      </tr>
                      <tr class="t_center"><td colspan="2"><a class="outline_s_btn w100" @click="selectedTab = 2">{{ $t('message.manageBook') }}</a></td></tr>
                    </tbody>
                  </table>
                </div>
                <dl class="wallet_form">
                  <dt class="wallet_form_tit"><span>{{ $t('message.balanceSum') }}</span></dt>
                  <dd class="wallet_form_text total_balance">{{ myBalance | numberWithCommas }} {{ getSymbol(coin) }}</dd>
                </dl>
                <dl class="wallet_form">
                  <dt class="wallet_form_tit"><span>{{ $t('message.quantity') }}</span></dt>
                  <dd class="wallet_form_area">
                    <input class="wallet_area" :placeholder="$t('message.enterAmount')" type="number" v-model="amount">
                    <div class="wallet_area_btn">
                      <button type="button" class="outline_m_btn" @click="setMax">{{ $t('message.max') }}</button>
                    </div>
                  </dd>
                </dl>
                <dl class="wallet_form">
                  <dt class="wallet_form_tit"><span>{{ $t('message.fees') }}</span></dt>
                  <dd class="wallet_form_text t_right">{{ percentage ? subDec(fee * 100, getSymbol(coin)) : subDec(fee, getSymbol(coin)) }} {{ percentage ? '%' : getSymbol(coin) }}</dd>
                  <dd class="fee-zero">{{ $t('message.whenInternal') }} 0 {{ percentage ? '%' : getSymbol(coin) }}</dd>
                </dl>
                <dl class="wallet_form">
                  <dt class="wallet_form_tit"><span>{{ $t('message.totalAmount') }}</span></dt>
                  <dd class="wallet_form_text t_right">{{ getTotal() | numberWithCommas }} {{ getSymbol(coin) }}</dd>
                  <dd class="fee-zero">{{ $t('message.whenInternal') }} {{ amount }} {{ getSymbol(coin) }}</dd>
                </dl>
                <dl v-if="security === 'PIN'" class="wallet_form">
                  <dt class="wallet_form_tit"><span>{{ $t('message.pinCode') }}</span></dt>
                  <dd class="wallet_form_area">
                    <input class="wallet_area" :placeholder="$t('message.enterPin')" type="password" v-model="code">
                  </dd>
                </dl>
                <dl v-else-if="security === 'PHONE'" class="wallet_form">
                  <dt class="wallet_form_tit"><span>{{ $t('message.verificationCode') }}</span></dt>
                  <dd class="wallet_form_area">
                    <input class="wallet_area" :placeholder="$t('message.placeholderPutAuthCode')" type="password" v-model="code">
                    <div class="wallet_area_btn">
                      <button type="button" class="outline_m_btn" @click="getCode()">{{ $t('message.authCode') }}</button>
                    </div>
                  </dd>
                </dl>
                <dl v-else-if="security === 'OTP'" class="wallet_form">
                  <dt class="wallet_form_tit"><span>{{ $t('message.optCode') }}</span></dt>
                  <dd class="wallet_form_area">
                    <input class="wallet_area" :placeholder="$t('message.placeholderOTP')" type="password" v-model="code">
                  </dd>
                </dl>
              </div>
              <div class="wallet_form">
                <dt class="wallet_form_tit"><span>{{ $t('message.lv') }} 출금 한도</span></dt>
                <dd class="wallet_form_text">
                  {{ subDec(maxWithdrawalAmounts.dayWithdrawValue, maxWithdrawalAmounts.withdrawCurrency) | numberWithCommas }} / {{maxWithdrawalAmounts.maxDayWithdraw == 0 ? '∞' : subDec(maxWithdrawalAmounts.maxDayWithdraw, maxWithdrawalAmounts.withdrawCurrency) | numberWithCommas }}
                  {{ maxWithdrawalAmounts.withdrawCurrency }}
                </dd>
              </div>
              <div class="card_cont_btn">
                <div class="middle_btn">
                  <a role="button" @click="withdraw">{{ $t('message.withdrawal') }}</a>
                </div>
              </div>
              <div class="mt20 card_cont">
                <p class="fwb mt20">{{ $t('message.refNote') }}</p>
                <ul>
                  <li>{{ $t('message.oneTime') }} {{ $t('message.minLimit') }}: {{ minAmount | numberWithCommas }} {{ this.getSymbol(this.coin) }}</li>
                  <li v-show="maxAmount">{{ $t('message.maxWithdraw') }}: {{ maxAmount | numberWithCommas }} {{ this.getSymbol(this.coin) }}</li>
                  <li v-show="!maxAmount">{{ $t('message.maxWithdraw') }}: {{ $t('message.unlimited') }}</li>
                  <li>{{ $t('message.withNote1') }}</li>
                  <li>{{ $t('message.withNote2') }}</li>
                </ul>
              </div>
            </div>
            <div class="card m-auto mt20" v-show="selectedTab === 2">
              <div class="card_tit wallet_tit h40">
                <h4 class="sub_tit">{{ $t('message.manageBook') }}</h4>
              </div>
              <div class="card_cont">
                <div class="wallet_content1 mk_t_content mt-3 book">
                  <table class="basic">
                    <colgroup>
                      <col style="width: 13%;">
                      <col style="width: auto;">
                      <col style="width: 9%;">
                      <col style="width: 9%;">
                      <col style="width: 9%;">
                    </colgroup>
                    <thead>
                    <tr>
                      <th class="fs14">{{ $t('message.title') }}</th>
                      <th class="fs14">{{ $t('message.address') }}</th>
                      <th class="fs14">{{ $t('message.delete') }}</th>
                      <th class="fs14">{{ $t('message.modify') }}</th>
                      <th class="fs14">{{ $t('message.add') }}</th>
                    </tr>
                    </thead>
                    <tbody :key="rerenderKey">
                    <tr v-for="addressObj in addressBook" :key="addressObj.idx">
                      <td class="t_center fs14"><input type="text" v-model="addressObj.name" :disabled="!addressObj.isInputActive" /></td>
                      <td class="t_center fs14"><input type="text" v-model="addressObj.address" :disabled="!addressObj.isInputActive" /></td>
                      <td class="t_center fs14"><a class="outline_s_btn red" @click="deleteAddressBook(addressObj.idx)"><i class="fas fa-times"></i></a></td>
                      <td class="t_center fs14">
                        <a class="outline_s_btn black" v-show="!addressObj.isInputActive" @click="activeInput(addressObj)"><i class="fas fa-pen"></i></a>
                        <a class="outline_s_btn black" v-show="addressObj.isInputActive" @click="changeAddressBook(addressObj)">{{ $t('message.save') }}</a>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="t_center fs14"><input type="text" :placeholder="$t('message.title')" v-model="addressBookTitle" /></td>
                      <td colspan="3" class="t_center fs14"><input type="text" :placeholder="$t('message.address')" v-model="addressBookAddress" /></td>
                      <td class="t_center fs14"><a class="outline_s_btn block" @click="addAddressBook"><i class="fas fa-plus"></i></a></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wallet_section mb0" v-show="selectedTab === 3">
          <div class="wallet_tit h40">
            <h4 class="sub_tit">{{ $t('message.statements') }}</h4>
          </div>
          <div class="wallet_content1 mk_t_content">
            <table class="basic">
              <colgroup>
                <col style="width: 13%;">
                <col style="width: 24%;">
                <col style="width: 24%;">
                <col style="width: 24%;">
                <col style="width: 15%;">
                <col>
              </colgroup>
              <thead>
              <tr>
                <th>{{ $t('message.sort') }}</th>
                <th>{{ $t('message.quantity') }}</th>
                <th>{{ $t('message.fees') }}</th>
                <th>{{ $t('message.time') }}</th>
                <th>{{ $t('message.moreInfo') }}</th>
              </tr>
              </thead>
              <tbody :key="rerenderKey">
                <tr v-for="(transfer, idx) in transferList" :key="idx">
                  <td class="t_center" :class="{ price_blue: transfer.type.slice(-2) === $t('message.withdrawal'), price_red: transfer.type.slice(-2) === $t('message.deposit') }">{{ transfer.type }}</td>
                  <td class="t_center">{{ toFloat(transfer.amount) }} {{ getSymbol(coin) }}</td>
                  <td class="t_center">{{ toFloat(transfer.feeAmount) }} {{ getSymbol(coin) }}</td>
                  <td class="t_center">{{ transfer.date }}</td>
                  <td>
                    <a class="outline_s_btn" v-show="transfer.tx_type === 'TX_IN' || transfer.tx_type === 'TX_OUT' || transfer.tx_type === 'HOUSE_IN' || transfer.tx_type === 'HOUSE_OUT'" @click="openDetail(transfer)">{{ $t('message.blockChain') }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="no_list" v-show="!transferList.length">{{ $t('message.noData') }}</p>
          <div class="buttonwrap t_center" v-show="transferList.length">
            <paginate
                :page-count="pageLast"
                :page-range="3"
                :margin-pages="2"
                :click-handler="getTransferList"
                :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                :first-last-button=true
                :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                :container-class="'pagination'">
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '../cmp/modal/modal'
import WalletMenuPC from "@/components/asset_management/wallet_menu/wallet_menu_pc"
import WalletMenuMobile from "@/components/asset_management/wallet_menu/wallet_menu_mobile"
import Big from "big-js";

export default {
  name: "AssetManagementWalletDetail",
  components: {
    'Modal': Modal,
    'WalletMenuPC': WalletMenuPC,
    'WalletMenuMobile': WalletMenuMobile
  },

  data() {
    return {
      address: '0',
      coin: this.$route.params.coin,
      showAddress: false,
      showEmpty: true,
      showDetail: false,
      showWarning: false,
      showTransfer: false,
      fromSpot: true,
      myBalance: 0,
      amount: 0,
      fee: 0,
      minAmount: 0,
      maxAmount: 0,
      addressToSend: '',
      transferList: [],
      transfer: {
        amount: '',
        date: 0,
        feeAmount: '',
        opposite: '',
        tickerName: '',
        txid: '',
        type: '',
        tx_type: ''
      },
      pageLast: 0,
      transferAmount: 0,
      userEmail: '',
      modalTitle: '',
      id: '',
      selectedTab: 0,
      isBookOpen: false,
      rerenderKey: 0,
      addressBook: [],
      addressBookTitle: '',
      addressBookAddress: '',
      percentage: true,
      maxWithdrawalAmounts: [],
      security: 'PIN',
      code: '',
    }
  },

  created() {
    if(this.coin) {
      sessionStorage.setItem('walletCoin', this.coin)
    } else {
      this.coin = sessionStorage.getItem('walletCoin')
    }

    this.getAddress()
    this.getBalance()
    this.getTransferList(1)

    this.$get(`${this.$WALLET_FEE}?ticker=${this.coin}`, 'AssetWalletDetailGetFee', true, (result) => {
      this.fee = result.data[0].fee
      this.percentage = result.data[0].percentage
      this.minAmount = result.data[0].minWithdrawAmount
      this.maxAmount = result.data[0].maxWithdrawAmount
    }, (result) => {
      console.log(result)
    })

    this.getUserProfile((result) => {
      if(result.success) {
        this.userEmail = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })

    this.$get(this.$USER_MYPAGE_SECURITY, 'myPageGetSecurityMethod', true, (result) => {
      this.security = result.data[0].security
    }, (result) => {
      console.log(result)
    })

    this.getAddressBook()

    this.$get(`${this.$USER_MYPAGE_POLICY_WITHDRAW}?ticker=${this.coin}`, 'UserMyPageMaxWithdrawalAmount', true, (result) => {
      this.maxWithdrawalAmounts = result.data[0];
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$refs.WalletMenuPC.focusTab(0)
    this.$refs.WalletMenuMobile.selectMenu(this.$t('message.assetInfo'))
  },

  methods: {

    checkId(tx_type) {
      if (tx_type.indexOf('HOUSE') >= 0) {
        this.id = this.$t('message.inWallet')
      } else {
        this.id = this.$t('message.exWallet')
      }
    },

    checkModalTitle(tx_type) {
      if ('TX_IN' === tx_type || 'HOUSE_IN' === tx_type) {
        this.modalTitle = this.$t('message.walletDeposit')
      } else if ('TX_OUT' === tx_type || 'HOUSE_OUT' === tx_type) {
        this.modalTitle = this.$t('message.walletWithdrawal')
      }
    },

    coinFull() {
      if(this.coin === 'ETH') {
        return 'ETH(ERC20)'
      } else if(this.coin === 'BTC') {
        return 'Bitcoin'
      } else if(this.coin === 'USDT') {
        return 'USDT(ERC20)'
      } else if(this.coin === 'TRX') {
        return 'Tron(TRX)'
      } else if(this.coin === 'UBIT') {
        return 'UBIT'
      } else {
        return this.coin
      }
    },

    activeInput(addressObj) {
      this.addressBook.forEach(address => {
        address.isInputActive = false
      })
      addressObj.isInputActive = true
      this.rerenderKey += 1
    },

    getAddressBook() {
      this.$get(`${this.$WALLET_ADDRESS_WITHDRAW}?ticker=${this.coin}`, 'walletDetailGetAddress', true, (result) => {
        this.addressBook = result.data
        this.addressBook.forEach(addressObj => {
          addressObj.isInputActive = false
        })
      }, (result) => {
        console.log(result)
      })
    },

    deleteAddressBook(idx) {
      this.$axios_delete(`${this.$WALLET_ADDRESS_WITHDRAW}/${idx}`, 'walletDetailDeleteAddress', true, (result) => {
        console.log(result)
        this.getAddressBook()
      }, (result) => {
        console.log(result)
      })
    },

    changeAddressBook(addressObj) {
      console.log(addressObj)
      console.log(addressObj)
      let formData = new FormData()
      formData.append('address', addressObj.address)
      formData.append('ticker', this.coin)
      formData.append('name', addressObj.name)
      this.$put(`${this.$WALLET_ADDRESS_WITHDRAW}/${addressObj.idx}`, 'walletDetailPutAddress', formData, true, (result) => {
        console.log(result)
        this.getAddressBook()
      }, (result) => {
        console.log(result)
      })
    },

    addAddressBook() {
      if(!this.addressBookAddress || !this.addressBookTitle) {
        this.notify('error', this.$t('message.warningEmptyBlank'))
        return
      }

      let formData = new FormData()
      formData.append('address', this.addressBookAddress)
      formData.append('ticker', this.coin)
      formData.append('name', this.addressBookTitle)
      this.$post(this.$WALLET_ADDRESS_WITHDRAW, 'walletDetailPostAddress', formData, true, () => {
        this.addressBookTitle = ''
        this.addressBookAddress = ''
        this.getAddressBook()
      }, (result) => {
        console.log(result)
      })
    },

    getBalance() {
      this.getMyBalance((result) => {
        if(result.success) {
          this.myBalance = result.myBalance.available
        }
      }, this.coin)
    },

    getTransferList(page) {
      this.transferList = []
      let formData = new FormData()
      let limit = 10

      formData.append('limit', limit)
      formData.append('page', page)
      formData.append('ticker', this.coin)

      this.$post(this.$WALLET_TRANSFER, 'AssetWalletDetailPostTransfer', formData, true, (result) => {
        this.pageLast = Math.ceil(result.total / limit)
        this.transferList = result.data

        this.transferList.forEach((transfer) => {
          transfer.tx_type = transfer.type;
          transfer.type = this.getTransferType(transfer.type, transfer.txid)
          transfer.date = this.formatDate(transfer.date * 1000, 'full')
        })
      }, (result) => {
        console.log(result)
      })
    },

    getTransferType(type, txid) {
      let transferType = type

      if(type === 'HOUSE_IN') {
        transferType = this.$t('message.inDeposit')
      } else if (type === 'HOUSE_OUT') {
        transferType = this.$t('message.inWithdrawal')
      } else if (type === 'TX_IN') {
        transferType = this.$t('message.exDeposit')
      } else if(type === 'TX_OUT') {
        transferType = this.$t('message.exWithdrawal')
      } else if (type === 'ESCROW_IN') {
        transferType = this.$t('message.escrowIn')
      } else if(type === 'ESCROW_OUT'){
        transferType = this.$t('message.escrowOut')
      } else if(type === 'ADMIN_IN') {
        transferType = this.$t('message.inDeposit')
      } else if(type === 'ADMIN_OUT') {
        if(txid === 'internal') {
          transferType = this.$t('message.inWithdrawal')
        } else {
          transferType = this.$t('message.exWithdrawal')
        }
      }
      return transferType
    },

    setMax() {
      Big.RM = 0
      let myBalance = new Big(this.myBalance)
      let fee = new Big(this.fee)

      if(this.percentage) {
        this.amount = myBalance.div(fee.plus(1))
      } else {
        this.amount = myBalance.minus(fee)
      }

      if(this.amount < 0 || (this.myBalance < this.minAmount)) {
        this.amount = this.subDec(0, this.coin)
      }
    },

    getTotal() {
      Big.RM = 0

      let amount = this.amount

      if(typeof amount === 'string') {
        if(amount.includes(',')) {
          amount = amount.replaceAll(',', '')
        }
      }

      if(amount) {
        amount = new Big(amount)
      } else {
        amount = new Big(0)
      }

      let fee = new Big(this.fee)
      let total = new Big(0)

      if(this.percentage) {
        total = amount.plus(amount.times(fee))
      } else {
        total = amount.plus(fee)
      }

      return total
    },

    getCode() {
      let formData = new FormData()

      this.$post(this.$WALLET_SMS, 'AssetWalletDetailPostSMS', formData, true, (result) => {
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    withdraw() {
      if(!this.amount || !this.addressToSend || !this.code) {
        this.notify('error', this.$t('message.enterAll'))
        return
      }

      let formData = new FormData()
      formData.append('ticker', this.coin)
      formData.append('amount', this.amount)
      formData.append('addressTo', this.addressToSend)
      formData.append('code', this.code)

      this.$post(this.$WALLET_WITHDRAW, 'AssetWalletDetailPostWithdraw', formData, true, (result) => {
        this.notify('success', result.message)
        this.getBalance()
        this.amount = 0
        this.addressToSend = ''
        this.code = ''
      }, (result) => {
        console.log(result)
      })
    },

    getAddress() {
      this.$get(`${this.$WALLET}?ticker=${this.coin}`, 'AssetDetailGetWallet',true, (result) => {
        this.showEmpty = false

        if(result.data[0]) {
          this.showAddress = true
          if(result.data[0].RPC) {
            this.address = result.data[0].RPC.address
          } else if(result.data[0].RPCSUB) {
            this.address = result.data[0].RPCSUB.address
          } else {
            this.address = '0'
          }
        } else {
          this.showAddress = false
        }
      }, (result) => {
        console.log(result)
      })
    },

    makeAddress() {
      let formData = new FormData()
      formData.append('ticker', this.coin)

      this.$post(this.$WALLET, 'AssetPostMakeWallet', formData, true, () => {
        this.showAddress = true
        this.showEmpty = false
        this.getAddress()
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    openDetail(transfer) {
      this.transfer = transfer

      this.showDetail = true
      this.showWarning = false
      this.showTransfer = false

      this.checkModalTitle(this.transfer.tx_type)
      this.checkId(this.transfer.tx_type)

      this.$refs.Modal.showDialog(this.modalTitle, '', this.$t('message.close'), () => {}, ()=>{})
    },

    copyAddress() {
      let tempInput = document.createElement('input')
      tempInput.value = this.address
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      this.notify('success', this.$t('message.copied'))
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler() {
        this.getTransferList(1)
      }
    },
  }
}
</script>

<style scoped>
</style>
