<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="subcontainer1">
        <h2 class="support_title fwm dblue_02">{{ $t('message.trading') }}</h2>
        <div class="mk_t_content scroll">
          <table class="table_i center_list">
            <colgroup>
              <col style="width: auto;">
              <col style="width: 30%;">
            </colgroup>
            <thead>
            <tr class="tr_title">
              <th>{{ $t('message.title') }}</th>
              <th>{{ $t('message.date') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="trade in tradeList" :key="trade.idx" @click="goToDetail(trade.idx)">
              <td class="td1">{{ trade.title }}</td>
              <td class="t_center">{{ trade.date }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <p class="no_list" v-show="!tradeList.length">{{ $t('message.noData') }}</p>
        <div class="buttonwrap t_center">
          <paginate v-show="tradeList.length" :key="rerenderKey"
                    :page-count="pageLast"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="getTradeList"
                    :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :first-last-button=true
                    :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tradeList",
  data() {
    return {
      code : 'trade', // notice : 공지사항, coin-notice : 코인 공지사항, trade : 거래, auth : 인증보안, 입출금 : transfer
      codeLocale : '',
      tradeList: [],
      tradeInfo: [],
      title : '',
      rerenderKey: 0,
      pageLast: 0,
    }

  },
  mounted() {
    if (this.$root.$i18n.locale !== 'ko') {
      this.codeLocale = '_' + this.$root.$i18n.locale
    } else {
      this.codeLocale = ''
    }

    this.getTradeList(1)
  },
  methods: {
    getTradeList(page) {
      let limit = 10
      this.$get(`${this.$BOARD_LIST}?code=${this.code}${this.codeLocale}&page=${page}&limit=${limit}`, this.$options.name + '_getTradeList', false, (result) => {
        this.tradeList = result.data

        this.tradeList.map(trade => {
          trade.date = this.formatDate(trade.createdAt, 'full')
        })

        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    goToDetail(tradeIdx) {
      this.$router.push({ name: 'CustomerSupportTradeView', params: { articleIdx : tradeIdx }} )
    },
  },
  watch: {
    '$root.$i18n.locale': {
      handler() {
        if (this.$root.$i18n.locale !== 'ko') {
          this.codeLocale = '_' + this.$root.$i18n.locale
        } else {
          this.codeLocale = ''
        }

        this.getTradeList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>
