<template>
  <div class="tabmenu mypage">
    <router-link :to="{ name: 'MyPageInfo' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 0 }">{{ $t('message.account') }}</router-link>
    <router-link :to="{ name: 'MyPageLevel' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 1 }">{{ $t('message.membershipLevel') }}</router-link >
    <router-link :to="{ name: 'MyPageSecurityMain' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 2 }">{{ $t('message.security') }}</router-link>
    <router-link :to="{ name: 'MyPageRecentActivity'}" class="content_tab" :class="{ 'tab_focus1': tabNo === 3 }">{{ $t('message.recentActivity') }}</router-link>
    <router-link :to="{ name: 'MyPageQnaList' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 4 }">{{ $t('message.myInquiries') }}</router-link>
    <router-link :to="{ name: 'MyPageAdvanced' }" class="content_tab" :class="{ 'tab_focus1': tabNo === 5 }">{{ $t('message.advancedOptions') }}</router-link>
  </div>
</template>

<script>
export default {
  name: "MyPageMenuPC",

  data() {
    return {
      tabNo: 0,
    }
  },

  methods: {
    focusTab(tabNo) {
      this.tabNo = tabNo
    }
  }
}
</script>

<style scoped>

</style>
