<template>
  <div class="body_wrap">
    <Modal ref="Modal">
      <p>{{ title }}</p>
      <table class="table_m">
        <tr>
          <th class="t_left">{{ $t('message.contact') }}</th>
          <td><input type="text" v-model="userPhone" disabled></td>
        </tr>
        <tr>
          <th class="t_left">{{ $t('message.email') }}</th>
          <td><input type="text" v-model="userEmail" disabled></td>
        </tr>
        <tr v-for="qna in qnaInfo" :key="qna.title" v-show="qna.content">
          <th class="t_left">{{ qna.title }}</th>
          <td v-show="qna.title !== 'file'"><textarea class="scroll" :placeholder="qna.content" disabled></textarea></td>
          <td v-show="qna.title === 'file'"><div><img :src="qna.content" :alt="$t('message.file')"/></div></td>
        </tr>
        <tr v-for="(answer, answerIdx) in answers" :key="answerIdx" v-show="answer.comment">
          <th class="t_left">{{ $t('message.answer') }}</th>
          <td><textarea class="scroll" :placeholder="answer.comment" disabled></textarea></td>
        </tr>
      </table>
    </Modal>
    <MyPageMenuMobile ref="MyPageMenuMobile"></MyPageMenuMobile>
    <div class="pagew">
      <div class="subcontainer1">
        <MyPageMenuPC ref="MyPageMenuPC"></MyPageMenuPC>
        <ul class="c5_wrap">
          <li class="c5_title">{{ $t('message.myInquiries') }}</li>
          <li class="middle_btn1">
            <router-link :to="{ name: 'MyPageQnaRegist' }">1:1 {{ $t('message.doInquiry') }}</router-link>
          </li>
        </ul>
        <!-- 내 문의내역 / 테이블 -->
        <div class="scroll">
          <table class="table_i">
            <colgroup>
              <col style="width: auto;">
              <col style="width: 18%;">
              <col style="width: 8%;">
              <col style="width: 20%;">
              <col style="width: 15%;">
            </colgroup>
            <thead>
            <tr>
              <th>{{ $t('message.title') }}</th>
              <th>{{ $t('message.date') }}</th>
              <th>{{ $t('message.inquiryStatus') }}</th>
              <th>{{ $t('message.moreInfo') }}</th>
              <th>{{ $t('message.delete') }}</th>
            </tr>
            </thead>
            <tbody v-for="qna in qnaList" :key="qna.idx">
              <tr>
                <td class="td1 t_center">{{ qna.title }}</td>
                <td class="t_center">{{ qna.date }}</td>
                <td class="t_center" :class="{ gray_02: !qna.state }">{{ qna.state ? $t('message.answered') : $t('message.answering') }}</td>
                <td class="t_center">
                  <a class="outline_btn1" @click="openDetail(qna.idx)">{{ $t('message.moreInfo') }}</a>
                </td>
                <td class="t_center">
                  <a class="outline_btn1" @click="deleteQna(qna.idx)">{{ $t('message.delete') }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p class="no_list" v-show="!qnaList.length">{{ $t('message.noData') }}</p>
        <!-- 내 문의영역/ 테이블 하단 버튼 페이징-->
        <div class="buttonwrap t_center">
          <paginate v-show="qnaList.length" :key="rerenderKey"
            :page-count="pageLast"
            :page-range="3"
            :margin-pages="2"
            :click-handler="getQnaList"
            :prev-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M9 11L4 6.5L9 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :next-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4 2L9 6.5L4 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :first-last-button=true
            :first-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M6 11L1 6.5L6 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M12 11L7 6.5L12 2\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :last-button-text="'<svg width=\'13\' height=\'13\' viewbox=\'0 0 13 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 2L12 6.5L7 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/><path d=\'M1 2L6 6.5L1 11\' stroke=\'#828282\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/></svg>'"
            :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPageMenuPC from '@/components/my_page/my_page_menu/menu_pc'
import MyPageMenuMobile from '@/components/my_page/my_page_menu/menu_mobile'
import Modal from '@/components/cmp/modal/modal'

export default {
  name: "QNA",
  components: {
    'MyPageMenuPC': MyPageMenuPC,
    'MyPageMenuMobile': MyPageMenuMobile,
    'Modal': Modal
  },

  data() {
    return {
      qnaList: [],
      qnaInfo: [],
      answers: [],
      title : '',
      userPhone: '',
      userEmail: '',
      rerenderKey: 0,
      pageLast: 0,
    }
  },

  mounted() {
    this.$refs.MyPageMenuPC.focusTab(4)
    this.$refs.MyPageMenuMobile.selectMenu(this.$t('message.myInquiries'))

    this.getQnaList(1)
  },

  methods: {
    getQnaList(page) {
      let limit = 10
      this.$get(`${this.$USER_MYPAGE_QNA}?page=${page}&limit=${limit}`, 'myPageQnaGetList', true, (result) => {
        this.qnaList = result.data

        this.qnaList.map(qna => {
          qna.date = this.formatDate(qna.createdAt, 'full')
        })

        this.pageLast = Math.ceil(result.total / limit)
      }, (result) => {
        this.notify('error', result.message)
      })
    },

    deleteQna(idx) {
      this.$axios_delete(`${this.$USER_MYPAGE_QNA}/${idx}`, 'QnaListDelete', true, (result) => {
        this.notify('success', result.message)
        this.rerenderKey += 1
        this.getQnaList(1)
      }, (result) => {
        this.notify('success', result.message)
      })
    },

    goToDetail(qnaIdx) {
      this.$router.push({ name: 'MyPageQnaDetail', params: { qnaIdx: qnaIdx }} )
    },

    async openDetail(idx) {
      this.$get(`${this.$USER_MYPAGE_QNA_DETAIL}/${idx}`, 'MyPageQnaListDetail', true, (result) => {
        this.qnaInfo = []
        this.answers = []

        let qnaDetail = result.data[0]
        let qnaKeys = Object.keys(qnaDetail)
        let qnaValues = Object.values(qnaDetail)

        this.getUserProfile((result) => {
          if(result.success) {
            this.userPhone = result.userProfile.phone
            this.userEmail = result.userProfile.email
          } else {
            console.log(result.userProfile)
          }
        })

        qnaKeys.forEach((qna, idx) => {
          if(qna === 'title') {
            this.title = qnaValues[idx]
          } else if (qna === 'comments'){
            this.answers = qnaValues[idx]
          } else if(qna !== 'state'){
            this.qnaInfo.push({
              title: qna,
              content: qnaValues[idx]
            })
          }
        })
      }, (result) => {
        console.log(result)
      })

      this.$refs.Modal.showDialog(this.$t('message.myInquiries'), '', this.$t('message.close'), () => {}, ()=>{})
    }
  }


}
</script>

<style scoped>

</style>
