<template>
  <div class="body_wrap pin_login">
    <div class="pagew">
      <div class="subcontainer1">
        <div class="secondAuth_con">
          <p class="title">{{ $t('message.2ndAuth') }} ({{ authMethod }})</p>
          <input type="password" class="phone_input" :placeholder="$t('message.pinCode')" v-model="pinCode" @keyup.enter="login" autofocus/>
          <span class="btn_user" @click="login">{{ $t('message.login') }}</span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PinLogin",
  data() {
    return {
      pinCode: '',
      authMethod: this.$route.params.secondAuthMethod

    }
  },

  created() {
    if(!this.authMethod) {
      this.$router.push({ name: 'Login' })
    }
  },

  methods: {
    login() {
      let formData = new FormData()
      formData.append('pinCode', this.pinCode)
      formData.append('verifyCode', this.$route.params.verifyCode)

      this.$post(this.$USER_LOGIN_PIN, 'loginPin', formData, false, (result) => {
        console.log(result)
        localStorage.setItem('accessToken', result.data[0].accessToken)
        localStorage.setItem('refreshToken', result.data[0].refreshToken)
        this.$store.state.isLoggedIn = true
        this.$router.push({ name: 'Main' })
      }, (result) => {
        console.log(result)
        this.notify('error', result.message)
      })
    }
  }


}
</script>

<style scoped>

</style>
