<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="subcontainer1">
        <h2 class="support_title fwm dblue_02">{{ $t('message.announcement') }}</h2>
        <div class="support_viewrap">
          <div class="view_top">
            <div class="view_title">
              <h5 class="fwm">{{ noticeDetail.title }}</h5>
            </div>
            <div class="view_date">
              <span class="fwm">{{ $t('message.createdTime') }}</span>
              <span class="fwm">{{ noticeDetail.date }}</span>
            </div>
          </div>
          <div class="view_content">
            <p v-html="escapeTag(noticeDetail.contents)"></p>
            <img v-show="noticeDetail.file" :src="noticeDetail.file" :alt="$t('message.file')" />
          </div>
          <div class="p_btn_wrap">
            <router-link :to="{ name: 'CustomerSupportCenter' }" class="p_btn_blue">{{ $t('message.customerCenter') }}</router-link>
            <router-link :to="{ name: 'CustomerSupportList' }" class="p_btn_blue">{{ $t('message.list') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "centerView",
  data() {
    return {
      noticeIdx: this.$route.params.articleIdx,
      noticeDetail: {},
    }
  },

  created() {
    if(this.noticeIdx) {
      sessionStorage.setItem('noticeIdx', this.noticeIdx)
    } else {
      this.noticeIdx = sessionStorage.getItem('noticeIdx')
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem('noticeIdx')
  },

  mounted() {
    this.$get(`${this.$BOARD_DETAIL}/${this.noticeIdx}`, this.$options.name + '_mountedDetail', false, (result) => {
      this.noticeDetail = result.data[0]
      this.noticeDetail.date = this.formatDate(this.noticeDetail.createdAt, 'full')
    }, (result) => {
      this.notify('error', result.message)
    })
  },
}
</script>

<style scoped>

</style>
