<template>
  <div class="body_wrap">
    <WalletMenuMobile ref="WalletMenuMobile"></WalletMenuMobile>
    <div class="pagew">
      <div class="sub_container">
        <WalletMenuPC ref="WalletMenuPC"></WalletMenuPC>
        <div class="wallet_tit mt20">
          <p class="wallet_total">{{ $t('message.totalAsset') }}: <span class="fwb">{{ subDec(totalUBIT) | numberWithCommas3 }} UBIT</span></p>
          <div class="checks">
            <input type="checkbox" id="check_01" v-model="checkbox" @change="toggleCheckbox">
            <label for="check_01">{{ $t('message.hideWallet') }}</label>
          </div>
          <div class="coin_search">
            <form class="form">
              <input type="text" :placeholder="$t('message.search')" v-model="coin" @keyup.enter="searchCoin">
              <span class="coin_search_icon">
                <i class="fas fa-search"></i>
              </span>
            </form>
          </div>
        </div>
        <div class="wallet_content1 mk_t_content">
          <table class="basic">
            <colgroup>
              <col style="width: 10%;">
              <col style="width: 25%;">
              <col style="width: 25%;">
              <col style="width: auto;">
              <col style="width: 15%;">
              <col>
            </colgroup>
            <thead>
            <tr>
              <th>{{ $t('message.coinName') }}</th>
              <th>{{ $t('message.totalBalance') }}</th>
              <th>{{ $t('message.availableQuantity') }}</th>
              <th>{{ $t('message.inOrdersQuantity') }}</th>
<!--              <th>{{ $t('message.estimatedAssetValue') }}</th>-->
              <th>{{ $t('message.depositWithdrawal') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="balance in balanceList" :key="balance.coin">
              <td>
                <router-link :to="{ name: 'AssetManagementWalletDetail', params: { coin: balance.coin } }" class="t_left point">
                  <img class="coin_icon" :src="getCoinImg(balance.coin)" :alt="balance.coin" @error="hideImg($event)"/>
                  <span>{{ balance.coin }}</span>
                </router-link>
              </td>
              <td class="t_right lh20">{{ balance.balance | numberWithCommas }}<p class="fs12 gray_02" v-show="balance.coin !== 'UBIT'">{{ subDec(balance.balanceUBIT, 'UBIT') | numberWithCommas }} UBIT</p></td>
              <td class="t_right lh20">{{ balance.available | numberWithCommas }}<p class="fs12 gray_02" v-show="balance.coin !== 'UBIT'">{{ subDec(balance.availableUBIT, 'UBIT') | numberWithCommas }} UBIT</p></td>
              <td class="t_right lh20">{{ balance.inOrders | numberWithCommas }}<p class="fs12 gray_02" v-show="balance.coin !== 'UBIT'">{{ subDec(balance.inOrdersUBIT, 'UBIT') | numberWithCommas }} UBIT</p></td>
              <td>
                <router-link :to="{ name: 'AssetManagementWalletDetail', params: { coin: balance.coin } }" class="outline_s_btn">
                  {{ $t('message.depositWithdrawal') }}
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)
import Loader from '@/components/cmp/loader'
import WalletMenuPC from "@/components/asset_management/wallet_menu/wallet_menu_pc"
import WalletMenuMobile from "@/components/asset_management/wallet_menu/wallet_menu_mobile"
import Big from "big-js";

export default {
  name: "AssetManagementWallet",

  components: {
    'WalletMenuPC': WalletMenuPC,
    'WalletMenuMobile': WalletMenuMobile
  },

  data() {
    return {
      checkbox: false,
      coins: [],
      coin: '',
      balanceList: [],
      rerenderKey: 0,
      loader: this.$loading.show({
        backgroundColor: '#65686F',
        opacity: 0.5,
        zIndex: 999,
      }, {
        default: this.$createElement(Loader)
      }),
      marketList: [],
      totalUBIT: 0,
      defaultSecondary: '',
    }
  },

  created() {
    this.defaultSecondary = sessionStorage.getItem('defaultSecondary')
    this.$get(`${this.$HOME_MARKET}?secondary=${this.defaultSecondary}`, 'getUbitCurr', false, (result) => {
      this.marketList = result.data
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$refs.WalletMenuPC.focusTab(0)
    this.$refs.WalletMenuMobile.selectMenu(this.$t('message.assetInfo'))

    this.getWallet()
  },

  beforeDestroy() {
    this.loader.hide()
  },

  methods: {
    goToGeneralMarket(coin) {
      let marketPair = `BTC-${this.defaultSecondary}`
      this.marketList.forEach(market => {
        if(market.marketPair.split('-')[0] === coin) {
          marketPair = `${coin}-${this.defaultSecondary}`
        }
      })

      this.$router.push({ name: 'GeneralMarket', params: { pair: marketPair } })
    },

    hideImg(e) {
      e.currentTarget.parentNode.classList.add('hide')
    },

    toggleCheckbox() {
      if(this.checkbox) {
        this.balanceList = this.balanceList.filter((balance) => (
            balance.balance !== 0 && balance.available !== 0
        ))
      } else {
        this.totalUBIT = 0
        this.getWallet()
      }
    },

    // getExchangePrice(bal) {
    //   let marketPair = `${bal.coin}usd`
    //   marketPair = marketPair.toLowerCase()
    //   this.$get(`${this.$OTC_DISPLAY_TICKER_BITFINEX}/${marketPair}`, 'otcListGetBitfinex_' + Math.random(), true, (result) => {
    //     bal.totalAsset = this.subDec(result.data[0].mid * bal.balance, 'USD')
    //     this.rerenderKey += 1
    //   }, (result) => {
    //     this.notify('error', result.message)
    //   })
    // },

    getWallet() {
      this.balanceList = []
      this.$get(this.$WALLET_BALANCE, 'AssetWalletGetTotal', true, (result) => {
        this.loader.hide()

        let list = result.data[0]
        this.coins = Object.keys(list)

        let total = new Big(0)

        this.coins.forEach((coin) => {
          this.balanceList.push({
            coin: coin,
            balance: list[coin].balance,
            available: list[coin].available,
            inOrders: list[coin].inOrders,
            balanceUBIT: this.subDec(list[coin].balance * list[coin].exchangeUbit),
            availableUBIT: this.subDec(list[coin].available * list[coin].exchangeUbit),
            inOrdersUBIT: this.subDec(list[coin].inOrders * list[coin].exchangeUbit)
          })

          if(coin === 'UBIT') {
            total = total.plus(new Big(list[coin].available))
          } else {
            total = total.plus(new Big(list[coin].balance).mul(new Big(list[coin].exchangeUbit)))
          }
        })

        this.totalUBIT = this.subDec(total.toFixed(20))
      }, (result) => {
        console.log(result)
      })
    },

    // getExchangeRate(bal) {
    //   this.$get(`${this.$OTC_DISPLAY_USDKRW}`, 'walletGetUSDKRW', true, (result) => {
    //     let rate = result.data[0].rate
    //     bal.totalAsset = this.subDec(rate * bal.balance, 'USD')
    //     this.rerenderKey += 1
    //   }, (result) => {
    //     console.log(result)
    //   })
    // },

    searchCoin() {
      this.balanceList = []
      this.coin = this.coin.toUpperCase()

      if(this.coin.trim() === '') {
        this.coin = ''
        this.getWallet()
      } else {
        this.$get(`${this.$WALLET_BALANCE_TICKER}?ticker=${this.coin}`, 'AssetWalletGetBalance', true, (result) => {
          let list = result.data[0]
          this.balanceList.push({
            coin: this.coin,
            balance: list.balance,
            available: list.available,
            inOrders: list.inOrders,
            balanceUBIT: this.subDec(list.balance * list.exchangeUbit),
            availableUBIT: this.subDec(list.available * list.exchangeUbit),
            inOrdersUBIT: this.subDec(list.inOrders * list.exchangeUbit)
          })
        }, (result) => {
          console.log(result)
        })
      }
    }
  },
}
</script>

<style scoped>

</style>
