<template>
  <div class="footer" v-if="show">
    <div class="pagew">
      <div class="f_wrap">
        <div class="f_left">
          <div class="f_logo" @click="goToMain"></div>
          <p class="txt" v-html="$t('message.footerDesc')"></p>
          <div class="sns">
            <div class="icon">
              <span class="img_wrap">
                <a :href="snsLink.telegramEn" target="_blank"><img src="/img/tel.svg" alt="telegram"></a>
              </span>
              <p class="lang">en</p>
            </div>
            <div class="icon">
              <span class="img_wrap">
                <a :href="snsLink.telegramCn" target="_blank"><img src="/img/tel.svg" alt="telegram"/></a>
              </span>
              <p class="lang">cn</p>
            </div>
            <div class="icon">
              <span class="img_wrap">
                <a :href="snsLink.facebook" target="_blank"><img src="/img/facebook.svg" alt="facebook"/></a>
              </span>
            </div>
            <div class="icon">
              <span class="img_wrap">
                <a :href="snsLink.twitter" target="_blank"><img src="/img/twitter.svg" alt="twitter"></a>
              </span>
            </div>
          </div>
        </div>
        <div class="f_link">
          <ul class="f_mainmenu">
            <li><a>{{ $t('message.helpCenter') }}</a>
              <ul class="f_submenu">
                <li><router-link :to="{ name: 'CustomerSupportList' }">{{ $t('message.announcement') }}</router-link></li>
                <li><router-link :to="{ name: 'CustomerSupportCenter' }">{{ $t('message.userGuide') }}</router-link></li>
                <li><router-link :to="{ name: 'CustomerSupportFee' }">{{ $t('message.fees') }}</router-link></li>
                <li><router-link :to="{ name: 'MyPageQnaRegist' }">{{ $t('message.inquiry') }}</router-link></li>
                <li><router-link :to="{ name: 'CustomerSupportCoinRequest' }">{{ $t('message.requestNewCoin') }}</router-link></li>
              </ul>
            </li>
            <li><a>{{ $t('message.AboutUs') }}</a>
              <ul class="f_submenu">
                <li><router-link :to="{ name: 'CompanyAbout' }">{{ $t('message.companyIntro') }}</router-link></li>
                <li><router-link :to="{ name: 'CompanyAgree' }">{{ $t('message.termsAndConditions') }}</router-link></li>
                <li><router-link :to="{ name: 'CompanyPrivacy' }">{{ $t('message.privacyStatement') }}</router-link></li>
              </ul>
            </li>
<!--            <li><a>{{ $t('message.docs') }}</a>-->
<!--              <ul class="f_submenu">-->
<!--                <li><router-link :to="{ name: 'DocumentsAppDownload' }">{{ $t('message.appDownload') }}</router-link></li>-->
<!--              </ul>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
      <div class="f_bot">
        <Language></Language>
        <p class="copyright">copyright ⓒ2021 UBIT. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Language from './language/lang_horizon'

export default {
  name: "Footer",
  components: {
    'Language': Language
  },

  data() {
    return {
      display: 'horizontal',
      show: true,
      snsLink: {
        twitter: 'https://twitter.com/',
        facebook: 'https://www.facebook.com/',
        telegramEn: 'https://t.me/',
        telegramCn: 'https://t.me/',
      }
    }
  },

  created() {
    this.$get(this.$HOME_SNS, 'homeSNS', false, (result) => {
      this.sns = result.data[0]
      this.snsLink.twitter = this.snsLink.twitter + this.sns.twitter
      this.snsLink.facebook = this.snsLink.facebook + this.sns.facebook
      this.snsLink.telegramEn = this.snsLink.telegramEn + this.sns.telegramEn
      this.snsLink.telegramCn = this.snsLink.telegramCn + this.sns.telegramCn
    }, (result) => {
      console.log(result)
    })
  },

  methods: {
    goToMain() {
      this.$router.push({ name: 'Main' })
    },

    showFooter(bool) {
      this.show = bool
    },
  }
}
</script>

<style scoped>

</style>
