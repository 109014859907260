import moment from "moment"

export default Object.freeze({
    install(Vue) {
        Vue.prototype.escapeTag = function (content) {
            if(content.includes('<')) {
                return content
            }

            return content.replace(/\r|\n/g, '<br/>');
        }

        Vue.prototype.notify = function (type, msg) {
            this.$root.$children[0].$refs.Alert.notify(type, msg)
        }

        Vue.prototype.refreshTokenRate = 5*60* 1000
        Vue.prototype.refreshToken = function() {
            let refreshToken = localStorage.getItem('refreshToken')
            let accessToken = localStorage.getItem('accessToken')

            if(!refreshToken) {
                return
            }

            let formData = new FormData()
            formData.append('refreshToken', refreshToken)

            this.$post(this.$USER_LOGIN_OAUTH_TOKEN, 'refreshToken', formData, false, (result) => {
                localStorage.setItem('refreshToken', result.data[0].refreshToken)

                if(accessToken !== result.data[0].accessToken) {
                    localStorage.setItem('accessToken', result.data[0].accessToken)

                    this.getUserProfile((result) => {
                        if(result.success) {
                            this.$intercom.boot({
                                user_id: result.userProfile.userIdx,
                                name: result.userProfile.nickName,
                                email: result.userProfile.email,
                                hide_default_launcher: false
                            })
                        } else {
                            console.log(result.userProfile)
                        }
                    })
                }

                setTimeout(() => {
                    this.refreshToken()
                }, this.refreshTokenRate)
            }, (result) => {
                this.notify('error', result.message)

                this.$router.replace({name: 'Login'})
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('accessToken')
                this.$store.state.isLoggedIn = false
            }, false)
        }

        Vue.prototype.refreshTokenShot = function(cb = null) {
            let refreshToken = localStorage.getItem('refreshToken')
            if(!refreshToken) {
                this.$router.replace({name: 'Login'})
                localStorage.removeItem('accessToken')
                this.$store.state.isLoggedIn = false
                return
            }
            let formData = new FormData()
            formData.append('refreshToken', refreshToken)
            this.$post(this.$USER_LOGIN_OAUTH_TOKEN, 'refreshToken', formData, false, (result) => {
                localStorage.setItem('accessToken', result.data[0].accessToken)
                localStorage.setItem('refreshToken', result.data[0].refreshToken)

                if(cb) {
                    cb()
                }
            }, () => {
                this.$router.replace({name: 'Login'})
                localStorage.removeItem('accessToken')
                this.$store.state.isLoggedIn = false
            }, false)
        }

        Vue.prototype.calendarOptions = function () {
            let lang = localStorage.getItem('lang')
            if(lang === 'cn') {
                lang = 'zh_cn'
            }

            return {
                format: 'YYYY-MM-DD',
                locale: moment.locale(`${lang}`),
                icons: {
                    // time: "fa fa-clock",
                    // date: "fa fa-calendar",
                    // up: "fa fa-arrow-up",
                    // down: "fa fa-arrow-down",
                    previous: "fa fa-chevron-left",
                    next: "fa fa-chevron-right",
                    // today: "fa fa-clock-o",
                    // clear: "fa fa-trash-o",
                    // close: 'fa fa-close',
                }
            }

        }

        Vue.prototype.getCoinFullName = function(coin) {
            if(coin === 'BTC') {
                return this.$t('message.bitcoin')
            } else if(coin === 'ETH') {
                return this.$t('message.ethereum')
            } else if(coin === 'USDT') {
                return this.$t('message.usdt')
            } else if(coin === 'USD') {
                return this.$t('message.usd')
            } else if(coin === 'USDPAY') {
                return this.$t('message.usdpay')
            } else if(coin === 'REAP') {
                return '립체인'
            } else if(coin === 'TRX') {
                return this.$t('message.tron')
            } else if(coin === 'UBIT') {
                return 'UBIT'
            } else if(coin === 'WIKI') {
                return this.$t('message.wikiexcoin')
            } else {
                return this.getFullnameFromTicker(coin)
            }
        }

        Vue.prototype.getFullnameFromTicker = function (coin) {
            let fullname = coin

            let tickers = JSON.parse(sessionStorage.getItem('tickers'))
            if(coin && tickers){
                tickers.forEach(ticker => {
                    if(ticker.ticker === coin) {
                        fullname = ticker.name
                    }
                })
            }

            return fullname
        }

        Vue.prototype.getCoinImg = function(coin) {
            return `/img/${coin.toLowerCase()}_coin.png`
        }

        Vue.filter('numberWithCommas', function (x) {
            if(!(x+'').includes('.')) {
                return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else {
               return `${((x+'').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(x+'').split('.')[1]}`
            }
        })

        Vue.filter('numberWithCommas3', function (x) {
            if(!(x+'').includes('.')) {
                return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.000'
            } else {
                let parts = x.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                parts[1] = parts[1] + '000'
                parts[1] = parts[1].substr(0, 3)
                return parts.join('.')
            }
        })

        Vue.prototype.tradeStateInLang = function (state) {
            if(state === 'ACCEPT_READY') {
                return 'waitingAccept'
            } else if(state === 'WAITING') {
                return 'waiting'
            } else if(state === 'DEPOSITED') {
                return 'deposited'
            } else if(state === 'TRADED') {
                return 'traded'
            } else if(state === 'CANCEL') {
                return 'tradeCancel'
            } else if(state === 'DECLINED') {
                return 'tradeRefuse'
            }
        }

        Vue.prototype.postStateInLang = function (state, boardIsBuy = null) {
            if(state === 'OPEN') {
                if(boardIsBuy) {
                    return 'doBuy'
                } else {
                    return 'doSell'
                }
            }
        }

        Vue.prototype.getMaxDecimal = function(tickerName) {
            let maxDecimal = 2
            let tickers = JSON.parse(sessionStorage.getItem('tickers'))
            if(tickerName && tickers){
                tickers.forEach(ticker => {
                    if(ticker.ticker === tickerName) {
                        maxDecimal = ticker.maxDecimalView
                    }
                })
            }

            return maxDecimal
        }

        Vue.prototype.subDec = function(num, tickerName, view = true) {
            if(num === undefined || isNaN(num) || !isFinite(num) || !num) {
                num = 0
            }
            let Big = require('big-js')
            num = new Big(num)
            let maxDecimal = 2

            let tickers = JSON.parse(sessionStorage.getItem('tickers'))
            if(tickerName && tickers){
                tickers.forEach(ticker => {
                    if(ticker.ticker === tickerName) {
                        if(view) {
                            maxDecimal = ticker.maxDecimalView
                        } else {
                            maxDecimal = ticker.maxDecimalFacto
                        }
                    }
                })
            }

            return num.toFixed(maxDecimal)
        }

        /**
         * 가격이나 수량을 해당 마켓페어에서 지정한 최대 소수점으로 고정소수점 문자열로 변환
         * @param num {any} 가격이나 수량 값
         * @param marketPair {string} 마켓페어 문자열. 예) 'BTC-USDT'
         * @param isPrimary {boolean} 마켓페어에서 주통화 혹은 부통화를 선택
         * @param view {boolean} 참인 경우 표시소수점 사용. 거짓인 경우 거래소수점 사용
         * @returns {string}
         */
        Vue.prototype.subMarket = function (num, marketPair, isPrimary, view = true) {
            if (num === undefined || isNaN(num) || !isFinite(num) || !num) {
                num = 0
            }

            let Big = require('big-js');
            num = new Big(num)

            let market = JSON.parse(sessionStorage.getItem('marketPairs'))
              .filter(market => market.pair === marketPair)

            let maxDecimal = 2

            if (market) {
                market = market[0]
                let primary = market.pair.split('-')[0]
                let secondary = market.pair.split('-')[1]
                if (isPrimary) {
                    if(view) {
                        if(market.decimalViewPrimary) {
                            maxDecimal = market.decimalViewPrimary
                        } else {
                            return this.subDec(num.toString(), primary, true)
                        }
                    } else {
                        if(market.decimalTradePrimary) {
                            maxDecimal = market.decimalTradePrimary
                        } else {
                            return this.subDec(num.toString(), primary, false)
                        }
                    }
                } else {
                    if(view) {
                        if(market.decimalViewSecond) {
                            maxDecimal = market.decimalViewSecond
                        } else {
                            return this.subDec(num.toString(), secondary, true)
                        }
                    } else {
                        if(market.decimalTradeSecond) {
                            maxDecimal = market.decimalTradeSecond
                        } else {
                            return this.subDec(num.toString(), secondary, false)
                        }
                    }
                }
            }

            return num.toFixed(maxDecimal)
        }

        Vue.prototype.getMyBalance = function(callback, coin) {
            this.$get(`${this.$WALLET_BALANCE_TICKER}?ticker=${coin}`, 'GetWalletAvailble', true, (result) => {
                result.data[0].available = this.subDec(result.data[0].available, coin, false)
                result.data[0].inOrders = this.subDec(result.data[0].inOrders, coin, false)

                callback({ success: true, myBalance: result.data[0] })
            }, (result) => {
                callback({ success: false, myBalance: result })
            })
        }

        Vue.prototype.getUserProfile = function (callback) {
            this.$get(this.$USER_MYPAGE_PROFILE, 'GetUserProfile' + Math.random(), true, (result) => {
                callback({ success: true, userProfile: result.data[0] })
            }, (result) => {
                callback({ success: false, userProfile: result })
            })
        }

        Vue.prototype.getNickname = function (callback, idx) {
            this.$get(`${this.$OTC_NICKNAME}?userIdx=${idx}`, 'GetNickname_' + Math.random(), true, (result) => {
                callback({ success: true, nickname: result.data[0] })
            }, (result) => {
                callback({ success: false, nickname: result.error })
            })
        }

        Vue.prototype.formatDate = function(timeStamp, dateType) {
            function makeTwoDigits(number) {
                return number >= 10 ? number : '0' + number
            }

            let d = new Date(timeStamp)
            let year = d.getFullYear()
            let month = makeTwoDigits(d.getMonth() + 1)
            let date = makeTwoDigits(d.getDate())
            let hour = makeTwoDigits(d.getHours())
            let min = makeTwoDigits(d.getMinutes())
            let sec = makeTwoDigits(d.getSeconds())
            if(dateType === 'full') {
                return `${year}-${month}-${date} ${hour}:${min}:${sec}`
            } else if(dateType === 'date'){
                return `${year}-${month}-${date}`
            } else {
                return `${hour}:${min}:${sec}`
            }
        }

        // Vue.prototype.getOtcState = function(state) {
        //     if(state === 'WAITING') {
        //         return this.$t('message.wait')
        //     } else if (state === 'DEPOSITED') {
        //         return this.$t('message.deposited')
        //     } else if (state === 'TRADED') {
        //         return this.$t('message.completed')
        //     } else if (state === 'TIMEOUT') {
        //         return this.$t('message.tradeDone')
        //     } else if(state === 'CANCEL'){
        //         return this.$t('message.orderCancelled')
        //     }
        // }

        Vue.prototype.getSymbol = function(ticker) {
            let symbol = ''
            let tickers = JSON.parse(sessionStorage.getItem('tickers'))
            if(tickers) {
                tickers.forEach(t => {
                    if(t.ticker === ticker) {
                        symbol = t.symbol
                    }
                })
                return symbol
            }
        }

        Vue.prototype.fullnameToTicker = function(fullname) {
            let ticker = ''
            let tickers = JSON.parse(sessionStorage.getItem('tickers'))
            if(tickers) {
                tickers.forEach(t => {
                    if(t.name === fullname) {
                        ticker = t.ticker
                    }
                })
                return ticker
            }
        }

        // 필요없는 소수점 뒤 0 빼기
        Vue.prototype.toFloat = function(number) {
            let newNum = number + ''
            if(newNum === '0' || !newNum.includes('.')) {
                return newNum
            }

            for(let i=newNum.length - 1; i>=0; i--) {
                if(newNum[i] === '0') {
                    newNum = newNum.slice(0, newNum.length - 1)
                } else if(newNum[i] === '.'){
                    newNum = newNum.slice(0, newNum.length - 1)
                    break
                } else {
                    break
                }
            }
            return newNum
        }
    }
})
