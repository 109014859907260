<template>
  <ul class="language">
    <li @click="selectLang('ko')">
      <a><img src="/img/kor.png" alt="Korean"></a>
    </li>
    <li @click="selectLang('cn')">
      <a><img src="/img/china.png" alt="Chinese"></a>
    </li>
    <li @click="selectLang('en')">
      <a><img src="/img/usa.png" alt="English"></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "LangHorizon",

  methods: {
    selectLang(lang) {
      this.$root.$i18n.locale = lang
      localStorage.setItem('lang', this.$root.$i18n.locale)

      this.$emit('closeMobileMenu')
    }
  },
}
</script>

<style scoped>

</style>
