<template>
    <li class="country">
      <a @click="showLangs = !showLangs">
        <img class="country_selected country_kr" :src="flag" :alt="$t('message.lang')">
      </a>
      <ul class="country_list" v-show="showLangs">
        <li><a @click="setLang('ko')"><img src="/img/kor.png" alt="Korea"/>Korea</a></li>
        <li><a @click="setLang('cn')"><img src="/img/china.png" alt="China"/>China</a></li>
        <li><a @click="setLang('en')"><img src="/img/usa.png" alt="English" />English </a></li>
      </ul>
    </li>
</template>

<script>
export default {
  name: "LangVerti",

  data() {
    return {
      showLangs: false,
      flag: '',
    }
  },

  mounted() {
    let defaultLang = localStorage.getItem('lang')
    this.selectLang(defaultLang)
  },

  methods: {
    selectLang(lang) {
      if(lang === 'ko') {
        this.flag = '/img/kor.png'
      } else if (lang === 'cn') {
        this.flag = '/img/china.png'
      } else {
        this.flag = '/img/usa.png'
      }
    },

    setLang(lang) {
      this.selectLang(lang)
      this.$root.$i18n.locale = lang
      localStorage.setItem('lang', this.$root.$i18n.locale)
      this.showLangs = false
    },
  },
}
</script>

<style scoped>

</style>
