<template>
  <div class="wrap" id="app">
    <Header ref="Header"></Header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Alert ref="Alert"></Alert>
    <Footer ref="Footer"></Footer>
  </div>
</template>

<script>
import Header from "@/components/cmp/header"
import Footer from "@/components/cmp/footer"
import Socket from '@/commonjs/socket'
import Alert from "@/components/cmp/alert"

export default {
  name: 'App',
  components: {
    'Header': Header,
    'Footer': Footer,
    'Alert': Alert,
  },

  created () {
    if(localStorage.getItem('lang')) {
      this.$i18n.locale = localStorage.getItem('lang')
    } else {
      let locale = navigator.language
      locale = locale.substring(0, 2)
      this.$i18n.locale = locale
      localStorage.setItem('lang', this.$i18n.locale)
    }

    let accessToken = localStorage.getItem('accessToken')
    if(accessToken) {
      this.refreshToken()
    }
    //모바일 체크. 모바일 환경에서는 모바일 싸이트로 이동시킴.
    if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone|iPod/i)) {
      if (window.location.href.indexOf('/#/findpwd/newpwd') > -1) {
        let url = window.location.href.split('/#/')[1]
        window.location.href = process.env.VUE_APP_MOBILE_DOMAIN + '/#/' + url
      } else {
        window.location.href = process.env.VUE_APP_MOBILE_DOMAIN
      }
    }

    Socket.$on("message", this.handleMessage)

    this.$get(this.$HOME_SECONDARY, 'MarketGetHomeSecondApp', false, (result) => {
      let secondCurrArr = result.data
      sessionStorage.setItem('defaultSecondary', secondCurrArr[0])
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.$intercom.boot()
  },

  methods: {
    showHeaderFooter(state) {
      this.$refs.Header.showHeader(state)
      this.$refs.Footer.showFooter(state)
    },

    handleMessage(msg){
      let promise = new Promise(resolve => {
        resolve(msg.text())
      })
      promise.then((value) => {
        let jsonData = JSON.parse(value)
        if(jsonData.t === 'tickers') {
          sessionStorage.setItem('tickers', JSON.stringify(jsonData.m))
        } else if (jsonData.t === 'marketPairs') {
          let pairsWS = JSON.parse(JSON.stringify(jsonData.m))
          pairsWS.sort(function(a, b) {
            return a.ordering - b.ordering
          })
          let marketPairs = []
          pairsWS.forEach((pair) => {
            marketPairs.push({
              pair: pair.marketPair,
              sellFeeRatio: pair.sellFeeRatio,
              buyFeeRatio: pair.buyFeeRatio,
              buyMinValue: pair.buyMinValue,
              sellMinValue: pair.sellMinValue,
              minBuyAmount: pair.minBuyAmount,
              minSellAmount: pair.minSellAmount,
              decimalTradePrimary: pair.decimalTradePrimary,
              decimalTradeSecond: pair.decimalTradeSecond,
              decimalViewPrimary: pair.decimalViewPrimary,
              decimalViewSecond: pair.decimalViewSecond
            })
          })
          sessionStorage.setItem('marketPairs', JSON.stringify(marketPairs))
          Socket.$off("message", this.handleMessage)
        }
      })
    },
  },

  beforeCreate() {
    // let console = (function(oldCons){
    //   return {
    //     log: function(){
    //       if (process.env.VUE_APP_DEBUG === "1") {
    //         oldCons.log.apply(console, arguments)
    //       }
    //     }
    //   }
    // }(window.console))
    // window.console = console
  }
}
</script>

<style>
.notify-header {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e1e1e1;
}
.notify-header-container {
  position: relative;
  width: 1300px;
  height: 48px;
  margin: auto;
  display: flex;
}

@media (min-width: 961px) {
  .notify-header-container.slide3 {
    display: flex;
  }
  .notify-header-container.slide2 {
    display: none;
  }
  .notify-header-container.slide1 {
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .notify-header-container.slide3 {
    display: none;
  }
  .notify-header-container.slide2 {
    display: flex;
  }
  .notify-header-container.slide1 {
    display: none;
  }
}

@media (max-width: 600px) {
  .notify-header-container.slide3 {
    display: none;
  }
  .notify-header-container.slide2 {
    display: none;
  }
  .notify-header-container.slide1 {
    display: flex;
  }
}

@media (min-width: 1221px) and (max-width: 1400px) {
  .notify-header {
    text-align: center;
    border-bottom: 1px solid #e1e1e1;
  }
  .notify-header-container {
    width: 90%;
  }

  .notify-header-close {
    right: 20px;
  }
}
@media (max-width: 1220px) {
  .notify-header-container {
    width: 100%;
    padding: 0 20px;
  }

  .notify-header-close {
    right: 20px !important;
  }


}
.notify-header-close {
  position: absolute;
  top: 50%;
  right: 0;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  cursor: pointer;
}
.notify-header-item {
  display: flex;
  width: 32%;
}

.slide2 .notify-header-item {
  width: 48%;
}

.notify-header-item img,
.slide1 img {
  height: 30px;
  margin-top: 9px;
  object-fit: contain;
  width: auto;
}
.notify-header-item strong,
.slide1 strong {
  line-height: 48px;
  margin-left: 10px;
  margin-right: 5px;
  overflow: hidden;
}

.notify-header-container .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: flex;
  flex-direction: row;
}

.notify-header-container strong {
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}

.notify-header-item span {
  line-height: 48px;
  margin-right: auto;
  overflow: hidden;
}
.bg-white {
  background-color: #FFFFFF;
}
</style>
