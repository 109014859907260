<template>
  <div class="body_wrap">
    <div class="pagew">
      <div class="agree" v-html="$t('message.agree')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyNoticeAgree"
}
</script>

<style scoped>

</style>
